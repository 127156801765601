import { STATE_KEY } from './constants';
import * as ActionsTypes from './actionTypes';

const reducer = (state = {}, action) => {
    switch (action.type) {
    case ActionsTypes.LOADED_FROM_API: {
        return Object.assign({}, state, {
            [ action.api ] : action.data
        });
    }

    default:
        return state;
    }
};

reducer.STATE_KEY = STATE_KEY;

export default reducer;