import * as ActionTypes from './actionTypes';
import { v4 } from 'uuid';

const addMessage = (className, message, displayTime, showOnRoute, id) => ({
    type: ActionTypes.ADD_MESSAGE,
    id: id || v4(),
    className,
    message,
    displayTime,
    showOnRoute
});

/**
 *
 * @param {string} message the message to show
 * @param {number} displayTime (optional) the number of seconds to show this message
 * @param {string|function} showOnRoute (optional) a string that will be matched on the route or a function
 *                                        that will receive the routers location object and that will need to return true of false
  * @param {string} id (optional) a unique id for this message
 */
export const addSuccessMessage = (message, displayTime = 0, showOnRoute, id) => addMessage('success', message, displayTime, showOnRoute, id);

/**
 *
 * @param {string} message the message to show
 * @param {number} displayTime (optional) the number of seconds to show this message
 * @param {string|function} showOnRoute (optional) a string that will be matched on the route or a function
 *                                        that will receive the routers location object and that will need to return true of false
  * @param {string} id (optional) a unique id for this message
 */
export const addWarningMessage = (message, displayTime = 0, showOnRoute, id) => addMessage('warning', message, displayTime, showOnRoute, id);

/**
 *
 * @param {string} message the message to show
 * @param {number} displayTime (optional) the number of seconds to show this message
 * @param {string|function} showOnRoute (optional) a string that will be matched on the route or a function
 *                                        that will receive the routers location object and that will need to return true of false
  * @param {string} id (optional) a unique id for this message
 */
export const addErrorMessage = (message, displayTime = 0, showOnRoute, id) => addMessage('error', message, displayTime, showOnRoute, id);

/**
 *
 * @param {string} id the id of the message to remove
 */
export const removeMessage = (id) => ({
    type: ActionTypes.REMOVE_MESSAGE,
    id
});