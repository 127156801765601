import * as Helpers from './helpers';
import Axios from 'axios';
import * as ApiRoutes from './constants.routes';

/**
 * Calls the backend to create an account
 *
 * @param {any} model
 */
export const login = (model, options = {
    suppressErrors : false
}) => {
    // this will always go through the loginApi!
    return Axios.post(`${Axios.defaults.loginURL}/${ApiRoutes.Login}`, model, options);
};

export const retrieveCookie = (token, options = {
    suppressErrors : false
}) => {
    return Helpers.post(ApiRoutes.Cookie, token, options);
};

export const deleteCookie = (options = {
    suppressErrors : false
}) => {
    return Helpers.delete_(ApiRoutes.Cookie, options);
};