const basicToArray = (stringOrArray) => (Array.isArray(stringOrArray) ? stringOrArray : [ stringOrArray ]);

const fieldNameHelper = (stringOrArrayOrMixed) => {

    const keys = basicToArray(stringOrArrayOrMixed);

    const result = toFieldString(keys);

    return result.split('.').filter(r => (r != null && r !== '')).map(r => (r));
};

const toFieldString = (array) => {
    if (array != null && Array.isArray(array)) {
        return array
            .map(item => Array.isArray(item) ? toFieldString(item) : (item || '').toString())
            .join('.');
    }

    if (array == null) {
        return '';
    }

    throw new Error(`Invalid parameter type for array, received ${typeof array}`);
};

const toFieldsString = (stringOrArrayOrMixed) => fieldNameHelper(stringOrArrayOrMixed).join('.');

export default fieldNameHelper;

export {
    fieldNameHelper as toFieldsArray,
    toFieldsString
};