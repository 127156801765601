import * as ActionTypes from './actionTypes';

export const activateAccount = (model, form) => ({
    type: ActionTypes.ACTIVATE_ACCOUNT,
    model,
    form
});

export const clear = () => ({
    type: ActionTypes.CLEAR
});