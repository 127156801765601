import log from '../util/log';
import * as Actions from './actions';
import * as ActionTypes from './actionTypes';
import * as LoadingActions from '../universe/molecules/loading/actions';
import * as AuthenticationActionTypes from '../modules/shared/authentication/actionTypes';
import * as AuthenticationActions from '../modules/shared/authentication/actions';
import { setupBuilder } from '../structure/dialog/index';

const logger = log('Configuration');
const axiosLogger = log('Axios');
const routerLogger = log('Router');
const validationLogger = log('Validation');
const sagaLogger = log('Saga');

export {
    Actions,
    ActionTypes,
    axiosLogger,
    LoadingActions,
    logger,
    routerLogger,
    validationLogger,
    AuthenticationActionTypes,
    setupBuilder,
    sagaLogger,
    AuthenticationActions
};