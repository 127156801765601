import React from 'react';

import Container from '../../universe/molecules/container/pageContainers/pageContainer';
import logo from '../../logo.png';

const OverOns = () => (
    <Container>
        <p className="privacy-about-logo"><img src={logo} alt="logo" /></p>
        <p className="privacy-about-alt-title">W.T. Privacy</p>
        <p className="privacy-about-slogan"><span>Take a proactive approach to data protection</span></p>
        <p>
            <b>W.T. Privacy</b> is een zusteronderneming van W.T. International,
            een IT bedrijf die haar sporen in de afgelopen 20 jaar verdiend heeft met het verzorgen van het complete IT Beheer voor uiteenlopende branches.
        </p>

        <p>
            <b>W.T. Privacy</b> is opgericht om het MKB te adviseren en te ondersteunen zodat ieder bedrijf,
            welke werkt met persoonsgegevens,
            de juiste stappen kan maken om de risico’s van datalekken te voorkomen.
        </p>

        <p>
            Op 25 mei 2018 is de Europese Privacy Verordening (EPV), in Nederland beter bekend onder te naam Algemene Verordening Gegevenvsbescherming (AVG), inwerking getreden.
            Een wet welke helder taal spreekt op het gebied van datalekken en de financiële consequenties voor de bedrijven.
            <br/>
            <b>Als blijkt dat ondernemingen niet kunnen aantonen dat er voldoende maatregelen zijn genomen om datalekken te voorkomen volgt er een boete
            welke kan oplopen tot een bedrag van  €20.000.000 of 4% van de wereldwijde omzet.</b>
        </p>

        <p>
            Om het u makkelijk te maken hebben we een programma samengesteld waardoor u met het beantwoorden van enkele vragen,
            uw dataveiligheid en privacy beleid verbetert, met als bijkomend voordeel:
            Het verzekert u van een bewijs dat uw onderneming voldoet aan de AVG.
        </p>

        <p>
            Een geruststellend idee, waardoor u kunt doen waar u goed in bent, namelijk:
            Uw vak uitoefenen en het leiden het leiden van uw eigen onderneming.
        </p>

    </Container>
);

export default OverOns;