export const NAME = 'Personen';

export const STATE_KEY = `${NAME}/e58541c5-094b-4442-b012-1836286256ad`;

export const FIELDS = {
    ADD: 'ADD',
    LIST: 'LIST',
    DETAILS: 'DETAILS',
    EDITOR: 'EDITOR',
    LOADING: 'LOADING'
};

export const SOURCES = {
    LIST: 'LIST',
    EDITOR: 'EDITOR'
};