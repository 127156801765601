import React from 'react';
import DateTime, { registerLocale } from 'react-datepicker';
import nl from 'date-fns/locale/nl';
import { parseISO, format } from 'date-fns';

registerLocale('nl', nl);

// https://reactdatepicker.com
const DateTimeComponent = ({ value, placeholder, onChange, dateOnly, ...props }) => {
    const innerOnChange = (currentValue) => {

        //When we want only the date, format the date as string without the time to make sure the API will receive an Unspecified DateTime in C#
        const formattedDate = dateOnly === true && currentValue != null ? format(currentValue, 'yyyy-MM-dd') : currentValue;

        // fake an event object
        const event = {
            persist: () => {},
            target: {
                type: 'textbox',
                value: formattedDate
            },
            currentTarget: {
                value: formattedDate
            }
        };

        onChange(event);
    };

    //When parsing a date only date, we remove the 'Z' part of the date to avoid the date component from creating an UTC date, which will not represent a date only.
    const parsedValue = typeof value === 'string' ? value.trim() === '' ? null : dateOnly === true ? parseISO(value.trim().replace('Z', '')) : parseISO(value.trim()) : value;

    return (<DateTime
        selected={parsedValue}
        className="form-control"
        placeholderText={placeholder}
        onChange={innerOnChange}
        fixedHeight
        autoComplete="off"
        locale="nl"
        dateFormat="dd-MM-yyyy"
        timeCaption="Tijd"
        timeFormat="HH:mm"

        {...props} />);
};

export default DateTimeComponent;