import {
    Constants,
    onChange,
    Actions,
    binder,
    STATE_KEY,
    stateHelper
} from './_references';

export const mapDispatchToProps = (dispatch) => ({
    onChange : onChange([ Constants.STATE_KEY, Constants.FIELDS.EDIT ], dispatch),
    load: () => dispatch(Actions.loadInformatie(Constants.SOURCE.EDIT)),
    update: (model) => dispatch(Actions.updateInformatie(model))
});

export const mapStateToProps = (state) => {

    return {
        bind: (field) => binder(state, [ STATE_KEY, Constants.FIELDS.EDIT ])(field),
        isLoading: stateHelper(state, [ Constants.STATE_KEY, Constants.FIELDS.LOADING ], false)
    };
};