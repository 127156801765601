import * as ActionTypes from './actionTypes';

export const load = () => ({
    type: ActionTypes.LOAD
});

export const loaded = (data) => ({
    type: ActionTypes.LOADED,
    data
});

export const select = (accountId) => ({
    type: ActionTypes.SELECT,
    accountId
});

export const unlink = (accountId) => ({
    type: ActionTypes.UNLINK,
    accountId
});