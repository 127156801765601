import * as ActionTypes from './actionTypes';

export const setLoggedInState = (isLoggedIn, accountInfo, manualLogin) => ({
    type: ActionTypes.LOGGED_IN,
    value: isLoggedIn,
    accountInfo,
    manualLogin
});

export const setAccountWarning = (showWarning) => ({
    type: ActionTypes.SET_ACCOUNT_WARNING,
    value: showWarning
});

export const setSelectAccount = (selectAccount) => ({
    type: ActionTypes.SET_SELECT_ACCOUNT,
    value: selectAccount
});

export const setRequiresVerification = (requiresVerification) => ({
    type: ActionTypes.REQUIRES_VERIFICATION,
    value: requiresVerification
});

export const handleLogin = (model) => ({
    type: ActionTypes.LOGIN,
    model,
    manualLogin: true
});

export const logout = () => ({
    type: ActionTypes.LOGOUT
});

export const loggedOut = () => ({
    type: ActionTypes.LOGGED_OUT
});

export const updateAccountInfo = () => ({
    type: ActionTypes.UPDATE_ACCOUNT_INFO
});

export const updatedAccountInfo = (data) => ({
    type: ActionTypes.UPDATED_ACCOUNT_INFO,
    data
});

export const setRoles = (roles) => ({
    type: ActionTypes.SET_ROLES,
    value: roles
});