import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ValidationWrapper = ({ LabelComponent, InputComponent, ValidationComponent, hasValidationErrors, className, noOffset }) => (
    <div className={`form-group${className != null ? ` ${className}` : ''}${hasValidationErrors ? ' has-error' : ''}`}>
        {LabelComponent ? LabelComponent : null}
        <div className={[ noOffset ? null : 'col-sm-10', 'col-xs-12' ].filter(c => c != null).join(' ')}>
            {InputComponent ? InputComponent : null }
            <div>
                { ValidationComponent ? ValidationComponent : null }
            </div>
        </div>
    </div>
);

const propTypes = {
    noOffset: PropTypes.bool,
    LabelComponent: PropTypes.node.isRequired,
    InputComponent: PropTypes.node.isRequired,
    ValidationComponent: PropTypes.node,
    hasValidationErrors: PropTypes.bool,
    className: PropTypes.string
};

ValidationWrapper.propTypes = propTypes;

export default ValidationWrapper;

const SimpleValidationWrapper = ({ InputComponent, ValidationComponent, hasValidationErrors, className }) => (
    <div className={`${className != null ? `${className}` : ''}${hasValidationErrors ? ' has-error' : ''}`}>
        { InputComponent ? InputComponent : null }
        { ValidationComponent ? ValidationComponent : null }
    </div>
);

SimpleValidationWrapper.propTypes = Object.assign({}, propTypes, {
    LabelComponent: PropTypes.node
});

const LoginValidationWrapper = ({ LabelComponent, InputComponent, ValidationComponent, hasValidationErrors, className }) => (
    <div className={`form-group${className != null ? ` ${className}` : ''}${hasValidationErrors ? ' has-error' : ''}`}>
        {LabelComponent ? LabelComponent : null}
        <Col sm={9} xs={12}>
            {InputComponent ? InputComponent : null }
            <div>
                { ValidationComponent ? ValidationComponent : null }
            </div>
        </Col>
    </div>
);

LoginValidationWrapper.propTypes = propTypes;

const CheckboxValidationWrapper = ({ LabelComponent, InputComponent, ValidationComponent, hasValidationErrors, noOffset, className }) => {
    return (
        <div className={`form-group${className != null ? ` ${className}` : ''}${hasValidationErrors ? ' has-error' : ''}`}>
            <div className={`col-sm-10${noOffset === true ? '' : ' col-sm-offset-2'}`}>
                <div className="checkbox">
                    <label title="">
                        {InputComponent ? InputComponent : null} {LabelComponent ? LabelComponent : null}
                    </label>
                    <div>
                        { ValidationComponent ? ValidationComponent : null }
                    </div>
                </div>
            </div>
        </div>
    );
};

CheckboxValidationWrapper.propTypes = propTypes;

const RadioButtonValidationWrapper = ({ LabelComponent, InputComponent, ValidationComponent, hasValidationErrors, noOffset, label, className }) => {
    return (
        <div className={`form-group${className != null ? ` ${className}` : ''}${hasValidationErrors ? ' has-error' : ''}`}>
            {label != null ? LabelComponent != null ? LabelComponent : null : null}
            <div className={`col-sm-${noOffset === true && label == null ? 12 : 10}${noOffset === true ? '' : ' col-sm-offset-2'}`}>
                <Row>

                    {InputComponent ? InputComponent : null}

                    <Col sm={noOffset === true && label == null ? 12: 10} smOffset={noOffset === true ? 0 : 2}>
                        { ValidationComponent ? ValidationComponent : null }
                    </Col>
                </Row>
            </div>
        </div>
    );
};

RadioButtonValidationWrapper.propTypes = Object.assign({}, propTypes, {
    LabelComponent: PropTypes.node
});

export {
    SimpleValidationWrapper,
    LoginValidationWrapper,
    CheckboxValidationWrapper,
    RadioButtonValidationWrapper
};

//  <span className="form-control-feedback fa col-xs-2"></span>
