import React from 'react';
import PropTypes from 'prop-types';
import { Panel, Row, Col } from 'react-bootstrap';
import FaIcon from 'react-fontawesome';
import { Link } from 'react-router';

import {
    Button,
    Container,
    Loading,
    PiaAdvisoryWorkingModel,
    Routes
} from './_references';

class PiaNieuw extends React.Component {

    componentDidMount() {
        this.props.load();
    }

    componentWillUnmount() {
        this.props.clear();
    }

    render() {

        const { isLoading, model, createNew, copyExisting } = this.props;

        return (
            isLoading == null || isLoading === true ? <Loading/> :

                <Container title="Privacy Impact Assessment aanmaken">

                    <Row>
                        <Col sm={12} xs={12}>
                            { model[ PiaAdvisoryWorkingModel.CanCopyExisting ] === false && model[ PiaAdvisoryWorkingModel.CanStartNew ] === true ? null :
                                <Panel>
                                    <Panel.Body>
                                        <div className="panel-inline-title">Nieuw of kopie?</div>
                                        <p>U kunt met een lege Privacy Impact Assessment starten of de antwoorden van de meest recente Privacy Impact Assessment overnemen.</p>

                                        <p>Wilt u een nieuwe Privacy Impact Assessment starten of een kopie maken?</p>
                                    </Panel.Body>
                                </Panel>
                            }
                            {
                                model[ PiaAdvisoryWorkingModel.AreVragenlijstIdsDifferent ] === false ? null :
                                    <Col xs={12} className={'bs-callout bs-callout-warning'}>
                                        <p>
                                            Let op: Sinds de laatste keer dat u een Privacy Impact Assessment heeft ingevuld is de vragenlijst veranderd.<br/>
                                            Indien u kiest om de antwoorden van de meeste recente Privacy Impact Assessment over te nemen dan krijgt u een nieuwe Privacy Impact Assessment die gebaseerd is op een oude vragenlijst.</p>
                                    </Col>
                            }
                            <div className="text-right">
                                <Link to={Routes.Dashboard.path}>annuleren</Link>
                                <span className="v-spacer"/>
                                <Button bsStyle="primary" onClick={copyExisting} ><FaIcon name="files-o" /> Bestaande kopieren</Button>
                                <span className="v-spacer"/>
                                <Button bsStyle="primary" onClick={createNew} ><FaIcon name="floppy-o" /> Nieuwe aanmaken</Button>
                            </div>
                        </Col>
                    </Row>

                </Container>
        );
    }
}

PiaNieuw.propTypes = {

    load: PropTypes.func.isRequired,
    clear: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    model: PropTypes.object,
    createNew: PropTypes.func.isRequired,
    copyExisting: PropTypes.func.isRequired
};

export default PiaNieuw;