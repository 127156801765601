import * as Constants from './constants';
import * as ActionTypes from './actionTypes';

const reducer = (state = {}, action) => {

    const { type, ...rest } = action;

    switch(type) {
    case ActionTypes.ADD_MESSAGE : {
        const messages = (state[ Constants.STATE_KEYS.MESSAGES ] || []).slice();
        messages.unshift({
            ...rest
        });
        const newState = Object.assign({}, state, {
            [ Constants.STATE_KEYS.MESSAGES ]: messages
        });
        return newState;
    }
    case ActionTypes.REMOVE_MESSAGE : {
        const messages = (state[ Constants.STATE_KEYS.MESSAGES ] || []).slice().filter((message) => {
            return message.id !== action.id;
        });

        const newState = Object.assign({}, state, {
            [ Constants.STATE_KEYS.MESSAGES ]: messages
        });
        return newState;
    }
    default:{
        return state;
    }
    }
};

reducer.STATE_KEY = Constants.STATE_KEY;

export default reducer;