import {
    Actions,
    Constants,
    onChange,
    binder
} from './_references';

export const mapDispatchToProps = (dispatch) => ({
    clear: () => dispatch(Actions.clear()),
    onChange : onChange([ Constants.STATE_KEY, Constants.FIELDS.DOSSIER ], dispatch),
    createIncident: (model) => dispatch(Actions.createIncident(model))
});

export const mapStateToProps = (state) => {
    return {
        bind: (field) => binder(state, [ Constants.STATE_KEY , Constants.FIELDS.DOSSIER ])(field),
    };
};