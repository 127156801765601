import * as Helpers from './helpers';
import * as Routes from './constants.routes';

/**
 * Calls the backend to get account information
 *
 */
export const availableAccounts = (config = {}) => {
    return Helpers.get(Routes.AccountKoppelen, config);
};

export const linkAccount = (model, config = {
    suppressErrors: true
}) => {
    return Helpers.post(Routes.AccountKoppelen, model, config);
};

export const unlinkAccount = (accountId, config = {
    suppressErrors: true
}) => {
    return Helpers.delete_(`${Routes.AccountKoppelen}?accountId=${accountId}`, config);
};