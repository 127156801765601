import { stateHelper } from '../../../util/stateManager';

import * as Constants from '../constants';
import * as Actions from '../actions';

export const mapStateToProps = (state) => ({
    list: stateHelper(state, [ Constants.STATE_KEY, Constants.FIELDS.LIST ], []),
    isLoading: stateHelper(state, [ Constants.STATE_KEY, Constants.FIELDS.LOADING ])
});

export const mapDispatchToProps = (dispatch) => ({
    loadIncidents: () => dispatch(Actions.getIncidents())
});