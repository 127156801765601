import React from 'react';
import PropTypes from 'prop-types';
import { DropdownButton, MenuItem, Panel, Row, Col } from 'react-bootstrap';
import Formsy from 'formsy-react';
import moment from 'moment';
import FaIcon from 'react-fontawesome';

import {
    Button,
    Constants,
    DateTime,
    FieldSetup,
    IncidentRapportDetailWorkingModel,
    IncidentRapportWorkingModel,
    TextArea
} from './_references';

class Rapport extends React.Component {

    render() {

        const { rapportFrmId, bind, onChange, rapport, rapportEditId, addToRapport, editRapportRow, confirmEditRapportRow, cancelEditRapportRow, deleteRapportRow } = this.props;

        return(
            <Panel>
                <Panel.Body>
                    <div className="panel-inline-title">Incident rapport</div>
                    <Formsy key={rapportFrmId} ref={(frm)=> {
                        this.reportFrm = frm;
                    }} className="form-horizontal" data-toggle="validator" onValidSubmit={() => {
                        addToRapport(this.reportFrm);
                    }}>

                        <p>Hier kunt u beschrijven wat er wanneer precies gebeurd is.</p>

                        <DateTime
                            {...bind(IncidentRapportDetailWorkingModel.Moment)}
                            {...new FieldSetup()
                                .for(IncidentRapportDetailWorkingModel.Moment)
                                .withFriendlyName('Moment')
                                .setLabel()
                                .setPlaceholder('Moment')
                                .isRequired()
                                .readOnly(rapportEditId != null)
                                .isNotInTheFuture()
                                .onChange(onChange)
                            }
                            showTimeSelect
                            timeIntervals={5}
                            dateFormat="dd-MM-yyyy HH:mm"
                        />

                        <TextArea
                            rows={5}

                            {...bind(IncidentRapportDetailWorkingModel.SituatieOmschrijving)}
                            {...new FieldSetup()
                                .for(IncidentRapportDetailWorkingModel.SituatieOmschrijving)
                                .withFriendlyName('Situatie omschrijving')
                                .setLabel()
                                .setPlaceholder('Wie, wat, waar?')
                                .isRequired()
                                .readOnly(rapportEditId != null)
                                .maxLength(10240)
                                .onChange(onChange)
                            }
                        />
                        <Row>
                            <Col xs={12} className="text-right">
                                <Button type="submit" bsStyle="primary" disabled={rapportEditId != null}><FaIcon name="plus" /> Toevoegen</Button>
                            </Col>
                        </Row>
                        <div className="v-spacer"/>
                    </Formsy>

                    { rapport == null ? null :

                        rapport.sort( (a,b) =>
                            a[ IncidentRapportWorkingModel.Moment ] < b[ IncidentRapportWorkingModel.Moment ] ? -1 : a[ IncidentRapportWorkingModel.Moment ] > b[ IncidentRapportWorkingModel.Moment ] ? 1 : 0
                        ).reduce((result, r) => {
                            const dt = moment(r[ IncidentRapportWorkingModel.Moment ]).format('YYYYMMDD');

                            if (result[ 0 ][ dt ] == null) {
                                result[ 0 ][ dt ] = [];
                            }

                            result[ 0 ][ dt ].push(r);
                            return result;
                        }, [ {} ])
                            .map(rg => (
                                Object.keys(rg).map(rgk => (
                                    <Panel key={rgk}>
                                        <Panel.Body>
                                            <div className="panel-inline-title">{moment(rg[ rgk ][ 0 ][ IncidentRapportWorkingModel.Moment ]).format('DD MMMM YYYY')}</div>

                                            {
                                                rg[ rgk ].map((r, index) => (

                                                    rapportEditId !== r[ IncidentRapportWorkingModel.Id ] ?
                                                        <Row className={`row-table row-table-${index % 2 === 0 ? 'even' : 'odd'}`} key={r[ IncidentRapportWorkingModel.Id ]}>
                                                            <Col xs={1}>{moment(r[ IncidentRapportWorkingModel.Moment ]).format('HH:mm')}</Col>
                                                            <Col xs={9}><pre className="rapport">{r[ IncidentRapportWorkingModel.SituatieOmschrijving ]}</pre></Col>
                                                            <Col xs={2} className="text-right">

                                                                {r[ IncidentRapportWorkingModel.Immutable ] !== true
                                                                    ? <DropdownButton title={<span><FaIcon name="table"/> Rij</span>} id="bg-nested-dropdown" disabled={rapportEditId != null}>
                                                                        <MenuItem eventKey="1" onClick={() => editRapportRow(r[ IncidentRapportWorkingModel.Id ])}><FaIcon name="pencil"/> bewerken</MenuItem>
                                                                        <MenuItem eventKey="2" onClick={() => deleteRapportRow(r[ IncidentRapportWorkingModel.Id ])}><FaIcon name="minus-square-o"/> verwijderen</MenuItem>
                                                                    </DropdownButton>
                                                                    : null
                                                                }
                                                            </Col>
                                                        </Row>
                                                        :

                                                        <Formsy key={rapportFrmId} ref={(frm)=> {
                                                            this.reportFrm = frm;
                                                        }} className="form-horizontal" data-toggle="validator" onValidSubmit={() => {
                                                            confirmEditRapportRow();
                                                        }}>

                                                            <Row className={`row-table row-table-${index % 2 === 0 ? 'even' : 'odd'}`} key={r[ IncidentRapportWorkingModel.Id ]}>
                                                                <Col xs={12}>
                                                                    <DateTime
                                                                        {...bind(Constants.FIELDS.EDIT_TIME)}
                                                                        {...new FieldSetup()
                                                                            .for(Constants.FIELDS.EDIT_TIME)
                                                                            .withFriendlyName('Moment')
                                                                            .setLabel()
                                                                            .setPlaceholder('Moment')
                                                                            .isRequired()
                                                                            .isNotInTheFuture()
                                                                            .onChange(onChange)
                                                                        }
                                                                        showTimeSelect
                                                                        timeIntervals={5}
                                                                        dateFormat="dd-MM-yyyy HH:mm"
                                                                    />
                                                                    <TextArea
                                                                        rows={5}

                                                                        {...bind(Constants.FIELDS.EDIT_TEXT)}
                                                                        {...new FieldSetup()
                                                                            .for(Constants.FIELDS.EDIT_TEXT)
                                                                            .withFriendlyName('Situatie omschrijving')
                                                                            .setLabel()
                                                                            .setPlaceholder('Wie, wat, waar?')
                                                                            .isRequired()
                                                                            .maxLength(10240)
                                                                            .onChange(onChange)
                                                                        }
                                                                    />
                                                                </Col>
                                                                <Col xs={12} className="text-right">
                                                                    <Button onClick={() => cancelEditRapportRow()}><FaIcon name="ban" /> Annuleren</Button>
                                                                    <span className="v-spacer"/>
                                                                    <Button type="submit"><FaIcon name="check" /> Opslaan</Button>
                                                                </Col>
                                                            </Row>
                                                        </Formsy>
                                                ))
                                            }
                                        </Panel.Body>
                                    </Panel>
                                )
                                )
                            ))
                    }
                </Panel.Body>
            </Panel>
        );
    }
}

Rapport.propTypes = {
    rapportFrmId: PropTypes.string,
    bind: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    rapport: PropTypes.arrayOf(PropTypes.object),
    rapportEditId: PropTypes.string,
    addToRapport: PropTypes.func.isRequired,
    editRapportRow: PropTypes.func.isRequired,
    confirmEditRapportRow: PropTypes.func.isRequired,
    cancelEditRapportRow: PropTypes.func.isRequired,
    deleteRapportRow : PropTypes.func.isRequired
};

export default Rapport;