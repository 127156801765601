//import React from 'react';
import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { actions as ToastrActions } from 'react-redux-toastr';

import {
    API,
    Builder,
    Routes,
    stateHelper,
} from './_references';

import * as ActionTypes from './actionTypes';
import * as Actions from './actions';
import * as Constants from './constants';

export default function* () {
    yield all([
        takeEvery(ActionTypes.GET_VERWERKER, getVerwerker),
        takeEvery(ActionTypes.GET_VERWERKEREN, getVerwerkeren),
        takeEvery(ActionTypes.ADD_VERWERKER, addVerwerker),
        takeEvery(ActionTypes.DELETE_VERWERKER, deleteVerwerker),
        takeEvery(ActionTypes.CONFIRMED_DELETE_VERWERKER, confirmedDeleteVerwerker),
        takeEvery(ActionTypes.EDIT_VERWERKER, editVerwerker),
        takeEvery(ActionTypes.UPDATE_VERWERKER, updateVerwerker),
    ]);
}

function* getVerwerker(action) {
    try {

        const verwerker = yield call (API.GetVerwerker, action.id);

        if (verwerker.success) {

            yield put (Actions.loadedVerwerker(verwerker.data, action.source));
        }
        else {
            //TODO
        }

    }
    catch (e) {
        // TODO:
    }
}

function* getVerwerkeren() {

    try {
        const verwerkeren = yield call (API.GetAllVerwerkeren);

        if (verwerkeren.success) {

            yield put (Actions.loadedVerwerkeren(verwerkeren.data));
        }
        else {
            //TODO
        }

    }
    catch (e) {
        // TODO:
    }
}

function* addVerwerker(action) {
    try {
        const incident = yield call (API.AddVerwerker, action.model);

        if (incident.success) {
            yield put (ToastrActions.add({
                type: 'success',
                title: 'Verwerker',
                attention: true, // This will add a shadow like the confirm method.
                message: 'Verwerker is aangemaakt.',
                options: {
                    showCloseButton: true
                }
            }));
            yield put (push(Routes.Dashboard.Verwerkersregister.path));
        }
        else {
            yield put (ToastrActions.add({
                type: 'error',
                title: 'Verwerker',
                attention: true, // This will add a shadow like the confirm method.
                message: 'Verwerker kon niet worden aangemaakt.',
                options: {
                    showCloseButton: true
                }
            }));
        }

    }
    catch (e) {
        // TODO:
    }
}

function* updateVerwerker() {
    try {

        const verwerker = yield select((state) => (stateHelper( state, [ Constants.STATE_KEY, Constants.FIELDS.EDITOR ], {})));

        const result = yield call (API.UpdateVerwerker, verwerker);

        if (result.success) {
            yield put(ToastrActions.add({
                type: 'success',
                title: 'Verwerker',
                attention: true, // This will add a shadow like the confirm method.
                message: 'Verwerker is opgeslagen.',
                options: {
                    showCloseButton: true
                }
            }));
            yield put(Actions.cancelEdit());
            yield put(Actions.getVerwerkeren());
        }
        else {
            yield put(ToastrActions.add({
                type: 'error',
                title: 'Verwerker',
                attention: true, // This will add a shadow like the confirm method.
                message: 'Verwerker kon niet worden opgeslagen.',
                options: {
                    showCloseButton: true
                }
            }));
        }

    }
    catch (e) {
        // TODO:
    }
}

function* editVerwerker(action) {
    yield getVerwerker(action);

}

function* deleteVerwerker(action) {

    const defaultDialog = Builder.confirmDelete({
        onJa : Actions.confirmedDeleteVerwerker(action.id)
    }).prepare();

    yield call(defaultDialog);
}

function* confirmedDeleteVerwerker(action) {
    // the reducer will already have deleted this record from the redux store
    const result =yield call(API.DeleteVerwerker, action.id);

    if (!result.success) {
        // reload list
        yield getVerwerkeren();

        yield put(ToastrActions.add({
            type: 'error',
            title: 'Verwerker',
            attention: true, // This will add a shadow like the confirm method.
            message: 'Het was helaas niet mogelijk de verwerker te verwijderen. Probeer het later nog eens.',
            options: {
                showCloseButton: true
            }
        }));
    }
}
