import * as Helpers from './helpers';
import * as Routes from './constants.routes';

export const get = (filter) => {

    const queryParts = [];

    if (filter) {
        queryParts.push(`$filter=contains(SearchField, '${filter.toLowerCase()}')`);
    }

    queryParts.push('$count=true');
    queryParts.push('$orderby=Word');

    return Helpers.get(`${Routes.Dictionary}/?${queryParts.join('&')}`);
};