import { all } from 'redux-saga/effects';

import { sagaLogger } from './_references';

import * as Sagas from './configureSaga.sagas';

/**
 * Adds all sagas to the root saga.
 */
export default function* rootSaga(...args) {
    yield all(
        Object.keys(Sagas)
            .map((saga) => {
                // avoid a catastrophic error in the root saga.
                const saveSaga = function*() {
                    try {
                        return yield Sagas[ saga ](...args);
                    }
                    catch (e) {
                        sagaLogger.error(`saga ${saga} crashed with an exception:`, e);
                        return yield Sagas[ saga ]({});
                    }
                };
                return saveSaga();
            })
    );
}