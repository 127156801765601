const requiredValidation = (propertyName) => {

    return {
        required: true, // support for plain HTML
        validationErrors: {
            required: `${ propertyName } mag niet leeg zijn`,
        },
        validationError: `${ propertyName } is ongeldig`,
    };
};

const requiredCheckboxValidation = (propertyName) => ({
    validators:  {
        equals: true,
        doesNotEqual: ''
    },
    validationErrors: {
        equals: `${propertyName} is niet aangevinkt`,
        doesNotEqual: `${propertyName} is niet aangevinkt`
    },
    validationError: `${propertyName} is ongeldig`
});

export default requiredValidation;

export {
    requiredCheckboxValidation
};
