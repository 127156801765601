import React from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';

import {
    FieldSetup,
    IncidentDetailUpdateWorkingModel,
    Text,
    TextArea
} from './_references';

const Melding = ({ bind, onChange }) => (
    <Panel>
        <Panel.Body>
            <div className="panel-inline-title">Melding bij de Autoriteit Persoongegevens</div>

            <p>Indien u een melding heeft gedaan bij de Autoriteit Persoonsgegevens dan krijgt u een meldingsnummer (uniek nummer). U kunt deze hier registreren bij de melding. U heeft dit nummer nodig zodra u de melding wilt aanpassen of intrekken.</p>

            <Text
                {...bind(IncidentDetailUpdateWorkingModel.Meldingsnummer)}
                {...new FieldSetup()
                    .for(IncidentDetailUpdateWorkingModel.Meldingsnummer)
                    .withFriendlyName('Meldingsnummer')
                    .setLabel()
                    .setPlaceholder('Meldingsnummer (uniek nummer) (optioneel)')
                    .matches(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i, '$ is geen geldig meldingsnummer.')
                    .maxLength(36)
                    .onChange(onChange)
                }
            />

            <TextArea
                rows={5}

                {...bind(IncidentDetailUpdateWorkingModel.Conclusie)}
                {...new FieldSetup()
                    .for(IncidentDetailUpdateWorkingModel.Conclusie)
                    .withFriendlyName('Conclusie')
                    .setLabel()
                    .setPlaceholder('Conclusie (optioneel)')
                    .maxLength(10240)
                    .onChange(onChange)
                }
            />

            <TextArea
                rows={5}

                {...bind(IncidentDetailUpdateWorkingModel.EindConclusie)}
                {...new FieldSetup()
                    .for(IncidentDetailUpdateWorkingModel.EindConclusie)
                    .withFriendlyName('Eind conclusie')
                    .setLabel()
                    .setPlaceholder('Eind conclusie (optioneel)')
                    .maxLength(10240)
                    .onChange(onChange)
                }
            />

            <TextArea
                rows={5}

                {...bind(IncidentDetailUpdateWorkingModel.VervolgActies)}
                {...new FieldSetup()
                    .for(IncidentDetailUpdateWorkingModel.VervolgActies)
                    .withFriendlyName('Vervolgacties')
                    .setLabel()
                    .setPlaceholder('Vervolgacties (optioneel)')
                    .maxLength(10240)
                    .onChange(onChange)
                }
            />

        </Panel.Body>
    </Panel>

);

Melding.propTypes = {
    bind: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
};

export default Melding;
