import React from 'react';
import { Router, Route, IndexRoute, /* Redirect,*/ browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';

import { routerLogger } from './_references';

import Homepage from '../modules/homepage';

import ErrorPage from '../modules/shared/error';
import Error404 from '../modules/shared/error/404';
import { Routes } from './constants.routes';

import onCreateElement from './configureRouter.onCreateElement';
import onError from './configureRouter.onError';
//import * as ErrorPageModule from '../modules/shared/error/500';
import PrivacyCheck from '../modules/privacyCheck';

import Container from '../universe/molecules/container';
import Login from '../modules/login';
import Loguit from '../modules/loguit';
import Account from '../modules/account';
import AccountAanvragen from '../modules/account/aanvragen';
import AccountAangevraagd from '../modules/account/aangevraagd';
import AccountBevestigen from '../modules/account/bevestigen';
import AccountBevestigd from '../modules/account/bevestigd';
import AccountActiveren from '../modules/account/activeren';
import AccountGeactiveerd from '../modules/account/geactiveerd';
import WachtwoordVergeten from '../modules/account/wachtwoordVergeten';
import WachtwoordHerstellen from '../modules/account/wachtwoordHerstellen';
import WachtwoordInstellen from '../modules/account/wachtwoordHerstellen/wachtwoordInstellen';
import AccountSelecteren from '../modules/account/select';
import AccountKoppelen from '../modules/account/koppelen';
import AccountBeveiligen from '../modules/account/beveiligen';
import MijnAccount from '../modules/account/mijnaccount';
import OverOns from '../modules/overOns';
import Woordenboek from '../modules/woordenboek';
import Dashboard from '../modules/dashboard';
import Pia from '../modules/pia';
import PiaNieuw from '../modules/pia/nieuw';
import PiaBewerken from '../modules/pia/bewerken';
import PiaInzien from '../modules/pia/inzien';

import Incident from '../modules/incident';
import IncidentAchtergrond from '../modules/incident/achtergrond';
import IncidentNieuw from '../modules/incident/nieuw';
import IncidentBewerken from '../modules/incident/bewerken';
import IncidentInzien from '../modules/incident/inzien';

import AlgemeneRegisterInformatieBewerken from '../modules/algemeneRegisterInformatie/bewerken';

import Verwerkingsregister from '../modules/verwerkingsregister';
import VerwerkingToevoegen from '../modules/verwerkingsregister/toevoegen';

import Verwerkersregister from '../modules/verwerkersregister';
import VerwerkerToevoegen from '../modules/verwerkersregister/toevoegen';

import Personen from '../modules/beheer/personen';
import PersoonToevoegen from '../modules/beheer/personen/toevoegen';

import Templates from '../modules/template';

const configureRouter = (store) => {
    const history = syncHistoryWithStore(browserHistory, store);

    //add screenreader notification when navigating to a new page
    //history.listen(url => setElementText(`url: ${(url.pathname === '/' ? 'start page' : url.pathname)}`, 'route')); //TODO: add translation
    history.listen(url => routerLogger.log(`Navigating to ${url.pathname}.`));

    const RouterConfig = () => {
        return (
            <Router history={history} createElement={onCreateElement} onError={onError}>
                {/* homepage */}
                <Route {...Routes.Home} component={Container}>
                    <IndexRoute component={Homepage} />

                    <Route {...Routes.OverOns} component={OverOns} />
                    <Route {...Routes.Check} component={PrivacyCheck} />
                    <Route {...Routes.Woordenboek} component={Woordenboek} />

                    <Route {...Routes.Account} >
                        <IndexRoute component={Account} />
                        <Route {...Routes.Account.Aanvragen} component={AccountAanvragen} />
                        <Route {...Routes.Account.Aangevraagd} component={AccountAangevraagd} />
                        <Route {...Routes.Account.Bevestigen} component={AccountBevestigen} />
                        <Route {...Routes.Account.Bevestigd} component={AccountBevestigd} />
                        <Route {...Routes.Account.Activeren} component={AccountActiveren} />
                        <Route {...Routes.Account.Geactiveerd} component={AccountGeactiveerd} />
                        <Route {...Routes.Account.Login} component={Login} />
                        <Route {...Routes.Account.Loguit} component={Loguit} />
                        <Route {...Routes.Account.WachtwoordVergeten} component={WachtwoordVergeten} />
                        <Route {...Routes.Account.WachtwoordHerstellen} component={WachtwoordHerstellen} />
                        <Route {...Routes.Account.WachtwoordInstellen} component={WachtwoordInstellen} />
                        <Route {...Routes.Account.Select} component={AccountSelecteren} />
                        <Route {...Routes.Account.Koppelen} component={AccountKoppelen} />
                        <Route {...Routes.Account.Beveiligen} component={AccountBeveiligen} />
                        <Route {...Routes.Account.MijnAccount} component={MijnAccount} />
                    </Route>

                    <Route {...Routes.Dashboard}>
                        <IndexRoute component={Dashboard} />
                        <Route {...Routes.Dashboard.Pia}>
                            <IndexRoute component={Pia} />
                            <Route {...Routes.Dashboard.Pia.Nieuw} component={PiaNieuw} />
                            <Route {...Routes.Dashboard.Pia.Bewerken} component={PiaBewerken} />
                            <Route {...Routes.Dashboard.Pia.Inzien} component={PiaInzien} />
                        </Route>
                        <Route {...Routes.Dashboard.Incident}>
                            <IndexRoute component={Incident} />
                            <Route {...Routes.Dashboard.Incident.Achtergrond} component={IncidentAchtergrond} />
                            <Route {...Routes.Dashboard.Incident.Nieuw} component={IncidentNieuw} />
                            <Route {...Routes.Dashboard.Incident.Bewerken} component={IncidentBewerken} />
                            <Route {...Routes.Dashboard.Incident.Inzien} component={IncidentInzien} />
                        </Route>
                        <Route {...Routes.Dashboard.AlgemeneRegisterinformatie}>
                            <IndexRoute component={AlgemeneRegisterInformatieBewerken} />
                        </Route>
                        <Route {...Routes.Dashboard.Verwerkingsregister} >
                            <IndexRoute component={Verwerkingsregister} />
                            <Route {...Routes.Dashboard.Verwerkingsregister.Toevoegen} component={VerwerkingToevoegen} />
                        </Route>
                        <Route {...Routes.Dashboard.Verwerkersregister} >
                            <IndexRoute component={Verwerkersregister} />
                            <Route {...Routes.Dashboard.Verwerkersregister.Toevoegen} component={VerwerkerToevoegen} />
                        </Route>
                    </Route>

                    <Route {...Routes.Templates}>
                        <IndexRoute component={Templates}/>
                    </Route>

                    <Route {...Routes.Beheer}>
                        <Route {...Routes.Beheer.Personen}>
                            <IndexRoute component={Personen}/>
                            <Route {...Routes.Beheer.Personen.Toevoegen} component={PersoonToevoegen} />
                        </Route>
                    </Route>
                </Route>

                {/* ../error page routing */}
                <Route {...Routes.Error} component={ErrorPage} />
                <Route {...Routes.Unknown} component={Error404} />
            </Router>
        );
    };

    return RouterConfig;
};

export default configureRouter;