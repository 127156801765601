import { addValidationRule } from 'formsy-react';

const emailValidator = (formValues, value) => {
    if (value == null || value === '') {
        return true;
    }

    const regularExpression = /^([a-zA-Z0-9-_.]+)@([A-Za-z0-9][a-zA-Z0-9-.]*(?:.[a-zA-Z0-9-.]+)*)(\.([a-zA-Z]{2,11}))$/;

    if (!regularExpression.test(value)) {
        return false;
    }

    return true;
};

emailValidator.NAME = 'email';

emailValidator.register = () => {
    addValidationRule(emailValidator.NAME, emailValidator);
};

export default emailValidator;