import React from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router';
import { Panel, Row, Col } from 'react-bootstrap';
import FaIcon from 'react-fontawesome';

import {
    Loading,
    Container,
    AccountWorkingModel,
    Button,
    Routes,
} from './_references';

class Koppelen extends React.Component {

    componentDidMount() {
        const { load } = this.props;

        if (typeof load === 'function' ) {
            load();
        }
    }

    render () {
        const { list, isLoading, koppel } = this.props;

        return (isLoading !== false ? <Loading /> :
            <Container>
                <Row>
                    <Col sm={12} xs={12}>
                        <Panel>

                            <Panel.Body>

                                <Panel>
                                    <Panel.Body>
                                        <div className="panel-inline-title">Gekoppelde accounts</div>
                                        <Row>
                                            <Col sm={12} xsHidden>
                                                <Row className="row-table row-table-header">
                                                    <Col xsHidden sm={4}>Klantnaam</Col>
                                                    <Col xsHidden sm={4}>Contact Persoon</Col>
                                                    <Col xsHidden sm={4} className="text-right"></Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        {
                                            list.map((a, index) => {

                                                const disabled = a[ AccountWorkingModel.MagConnectieMaken ] === false;
                                                const title = a[ AccountWorkingModel.MagConnectieMaken ] === false ? 'De klant heeft geen toestemming gegeven om te koppelen' : null;

                                                return (
                                                    <Row key={a[ AccountWorkingModel.AccountId ]} className={`row-table row-table-${index % 2 === 0 ? 'even' : 'odd'}`}>
                                                        <Col xs={12} smHidden mdHidden lgHidden className="row-table-header-title">Klantnaam</Col>
                                                        <Col xs={12} sm={4}>{a[ AccountWorkingModel.Bedrijfsnaam ]}</Col>
                                                        <Col xs={12} smHidden mdHidden lgHidden className="row-table-header-title">Contact Persoon</Col>
                                                        <Col xs={12} sm={4}>{a[ AccountWorkingModel.Contactpersoon ]}</Col>

                                                        <Col xsHidden sm={4} className="text-right">
                                                            <Button onClick={() => koppel(a[ AccountWorkingModel.AccountId ])} bsStyle="primary" title={title} disabled={disabled}><FaIcon name="user-plus" /> Koppelen</Button>
                                                        </Col>
                                                        <Col xs={12} smHidden mdHidden lgHidden>
                                                            <Button block onClick={() => koppel(a[ AccountWorkingModel.AccountId ])} bsStyle="primary" title={title} disabled={disabled}><FaIcon name="user-plus" /> Koppelen</Button>
                                                        </Col>

                                                    </Row>
                                                );
                                            }
                                            )
                                        }

                                    </Panel.Body>
                                </Panel>
                                <Row>
                                    <Col xsHidden sm={12} className="text-right">
                                        <Link to={Routes.Account.Select.path}>annuleren</Link>
                                    </Col>

                                    <Col smHidden mdHidden lgHidden xs={12} className="text-right">
                                        <Link to={Routes.Account.Select.path}>annuleren</Link>
                                    </Col>
                                </Row>

                            </Panel.Body>
                        </Panel>

                    </Col>
                </Row>

            </Container>
        );
    }

}

Koppelen.propTypes = {
    load: PropTypes.func.isRequired,
    list: PropTypes.array,
    isLoading: PropTypes.bool,
    koppel: PropTypes.func.isRequired
};

export default Koppelen;