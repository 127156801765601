import { connect } from 'react-redux';

import * as Actions from './actions';
import * as Constants from './constants';

import ErrorComponent from './index.component';
import { stateHelper } from '../../../util/stateManager';

const mapStateToProps = (state) => ({
    title: stateHelper( state, [ Constants.STATE_KEY, 'title' ]),
    message: stateHelper(state, [ Constants.STATE_KEY, 'message' ])
});

const mapDispatchToProps = (dispatch) => ({
    clearErrors: () => dispatch(Actions.clearErrorInformation())
});

const ErrorPage = connect(mapStateToProps, mapDispatchToProps)(ErrorComponent);

export default ErrorPage;