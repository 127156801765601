export const NAME = 'AlgemeneRegisterInformatie';

export const STATE_KEY = `${NAME}/7542cee0-f6d5-4200-8082-dd5364ba2cc0`;

export const FIELDS = {
    VIEW: 'VIEW',
    EDIT: 'EDIT',
    LOADING: 'LOADING'
};

export const SOURCE = {
    VIEW: 'VIEW',
    EDIT: 'EDIT'
};