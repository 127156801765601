import React from 'react';
import PropTypes from 'prop-types';
import { Panel, Col } from 'react-bootstrap';

import {
    FieldSetup,
    IncidentAnalyseAntwoordWorkingModel,
    IncidentAnalyseConclusieWorkingModel,
    IncidentAnalyseVraagWorkingModel,
    IncidentDetailUpdateWorkingModel,
    YesNoRadioButton
} from './_references';

const Analyse = ({ vragen, bindAntwoord, onChangeAntwoord, bind, onChange, disableMeldingDoorWTP }) => (
    <>
        <Panel>
            <Panel.Body>
                <div className="panel-inline-title">Analyse</div>

                <p>
                    Om te bepalen of een melding moet worden gedaan naar de Autoriteit Persoonsgegevens is het noodzakelijk een korte analyse te doen van de situatie.
                    Vul de vragen in om een indicatie te krijgen welke stappen genomen moeten worden.
                </p>

                {vragen.map(v =>
                    v[ IncidentAnalyseVraagWorkingModel.Conclusie ] == null ?
                        (

                            <div key={v[ IncidentAnalyseVraagWorkingModel.VraagId ]}>
                                <span>{v[ IncidentAnalyseVraagWorkingModel.Vraag ]}</span>

                                <YesNoRadioButton noOffset
                                    {...bindAntwoord(v[ IncidentAnalyseVraagWorkingModel.VraagId ])}
                                    {...new FieldSetup()
                                        .for(v[ IncidentAnalyseVraagWorkingModel.VraagId ])
                                        .onChange(onChangeAntwoord)
                                    }
                                    options={ v[ IncidentAnalyseVraagWorkingModel.Antwoorden ] }
                                    optionKey={IncidentAnalyseAntwoordWorkingModel.AntwoordId}
                                    optionValue={IncidentAnalyseAntwoordWorkingModel.Antwoord}
                                />
                            </div>
                        )
                        : (
                            <Col xs={12} key={v[ IncidentAnalyseVraagWorkingModel.VraagId ]} className={`bs-callout bs-callout-${v[ IncidentAnalyseVraagWorkingModel.Conclusie ][ IncidentAnalyseConclusieWorkingModel.KleurCode ]}`}>
                                <p>
                                    {v[ IncidentAnalyseVraagWorkingModel.Conclusie ][ IncidentAnalyseConclusieWorkingModel.Tekst ]}
                                </p>
                            </Col>)
                )
                }
            </Panel.Body>
        </Panel>
        {
            vragen.some(v => (v[ IncidentAnalyseVraagWorkingModel.Conclusie ] != null && v[ IncidentAnalyseVraagWorkingModel.Conclusie ][ IncidentAnalyseConclusieWorkingModel.Meldplicht ] ))
                ?

                <Panel>
                    <Panel.Body>
                        <div className="panel-inline-title">Uitvoeren van de melding bij de Autoriteit Persoonsgegevens</div>

                        <p>Uit het stappenplan is gebleken dat u (mogelijk) een melding moet doen bij de Autoriteit Persoonsgegevens.</p>

                        <p>Wilt u dat W.T. Privacy voor u de afhandeling doet?</p>

                        <p>Let op, hieraan kunnen extra kosten zijn verbonden.</p>

                        <YesNoRadioButton noOffset
                            {...bind(IncidentDetailUpdateWorkingModel.MeldingDoorWTP)}
                            {...new FieldSetup()
                                .for(IncidentDetailUpdateWorkingModel.MeldingDoorWTP)
                                .onChange(onChange)
                                .readOnly(disableMeldingDoorWTP)
                            }
                        />

                    </Panel.Body>
                </Panel>
                : null
        }
    </>
);

Analyse.propTypes = {
    vragen: PropTypes.arrayOf(PropTypes.object),
    bindAntwoord: PropTypes.func.isRequired,
    onChangeAntwoord: PropTypes.func.isRequired,
    bind: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    disableMeldingDoorWTP: PropTypes.bool,
};

export default Analyse;