import * as ActionTypes from './actionTypes';
import * as Constants from './constants';
import DocumentBody from '../../../util/documentBody';

/*
 * Reducer for setting the loading state.
 *
 * NOTE: The reducer will also update the document's <body> class to include 'loading' to reflect the fact that the document is in fact loading.
*/
const reducer = (state = {}, action) => {
    switch(action.type){
    case ActionTypes.ADD_LOADING_COUNTER:
    {
        let numberOfLoadingactions = (Number.isInteger(state[ Constants.Fields.COUNT ]) ? state[ Constants.Fields.COUNT ] + 1 : 1);

        const newState = Object.assign({}, state,
            {
                [ Constants.Fields.COUNT ]: numberOfLoadingactions,
                [ Constants.Fields.IS_LOADING ]: (numberOfLoadingactions > 0),
            }
        );

        //update document body class
        DocumentBody.addClass(Constants.LOADING_CLASSNAME);

        return newState;
    }
    case ActionTypes.REMOVE_LOADING_COUNTER:
    {
        let numberOfLoadingactions = (Number.isInteger(state[ Constants.Fields.COUNT ]) && state[ Constants.Fields.COUNT ] > 0 ? state[ Constants.Fields.COUNT ] - 1 : 0);

        const newState = Object.assign({}, state,
            {
                [ Constants.Fields.COUNT ]: numberOfLoadingactions,
                [ Constants.Fields.IS_LOADING ]: (numberOfLoadingactions > 0),
            }
        );

        //update document body class if needed
        if(newState[ Constants.Fields.IS_LOADING ] === false) {
            DocumentBody.removeClass(Constants.LOADING_CLASSNAME);
        }

        return newState;
    }
    case ActionTypes.RESET:
    {
        const newState = Object.assign({}, state,
            {
                [ Constants.Fields.COUNT ]: 0,
                [ Constants.Fields.IS_LOADING ]: false,
            }
        );

            //update document body class
        DocumentBody.removeClass(Constants.LOADING_CLASSNAME);

        return newState;
    }
    default:
        return state;
    }
};

reducer.STATE_KEY = Constants.STATE_KEY;

export default reducer;