import * as ActionTypes from './actionTypes';

export const load = () => ({
    type: ActionTypes.LOAD,
});

export const loaded = (data) => ({
    type: ActionTypes.LOADED,
    data
});

export const download = (id) => ({
    type: ActionTypes.DOWNLOAD,
    id
});