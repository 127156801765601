import { STATE_KEY } from './constants';
import * as Actions from './actions';

export const mapDispatchToProps = (dispatch) => ({
    loadFromApi: (api) => dispatch(Actions.loadFromApi(api))
});

export const mapStateToProps = (state = {}) => ({
    apiOptions: state[ STATE_KEY ]
});
