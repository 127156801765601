import {
    Actions,
    stateHelper,
    STATE_KEY,
    PiaWorkingModel,
    Constants,
    binder
} from './_references';

export const mapDispatchToProps = (dispatch) => ({
    load: (id) => dispatch(Actions.loadPia(id, true))
});

export const mapStateToProps = (state) => {
    return {
        vragen: stateHelper(state, [ STATE_KEY, Constants.FIELDS.Pia, PiaWorkingModel.Vragen ], []),
        categorien: stateHelper(state, [ STATE_KEY, Constants.FIELDS.Pia, PiaWorkingModel.VraagCategorien ], []),
        bind: (field) => binder(stateHelper(state, [ STATE_KEY, Constants.FIELDS.Pia, PiaWorkingModel.Antwoorden ], {}))(field),
        bindNotitie: (field) => binder(stateHelper(state, [ STATE_KEY, Constants.FIELDS.Pia, PiaWorkingModel.Notities ], {}))(field),
        isLoading: stateHelper(state, [ STATE_KEY, Constants.FIELDS.PiaLoading ], true),
        isChanged: stateHelper(state, [ STATE_KEY, Constants.FIELDS.Pia, Constants.FIELDS.PiaChanged ], false)
    };
};
