import React from 'react';

import InputComponent from '../../atoms/input';

const Text = (props) => (<InputComponent type='text' {...props}/>);

const Password = (props) => (<InputComponent type='password' {...props}/>);

export default Text;

export { Password };