import React from 'react';

import {
    Container
} from './_references';

import IncidentLijst from './lijst';

const Incident = () => (
    <Container>
        <IncidentLijst />
    </Container>
);

export default Incident;