import React from 'react';
import PropTypes from 'prop-types';

const ReadOnlyWrapper = ({ LabelComponent, DisplayComponent, className, noOffset }) => (
    <div className={`form-group${className != null ? ` ${className}` : ''}`}>
        {LabelComponent ? LabelComponent : null}
        <div className={[ noOffset ? null : 'col-sm-10', 'col-xs-12' ].filter(c => c != null).join(' ')}>
            {DisplayComponent ? DisplayComponent : null }
        </div>
    </div>
);

const propTypes = {
    LabelComponent: PropTypes.node.isRequired,
    DisplayComponent: PropTypes.node.isRequired,
    className: PropTypes.string,
    noOffset: PropTypes.bool
};

ReadOnlyWrapper.propTypes = propTypes;

export default ReadOnlyWrapper;
