import { logger, validationLogger } from './_references';

import * as Validators from '../validation/validators';

const configureFormsy = () => {
    logger.log('Configuring custom formsy validations');

    Object.keys(Validators).forEach(key => {
        if (Validators[ key ].register == null || typeof Validators[ key ].register !== 'function') {
            throw new Error(`Validators need to have a register function. Validator ${key} does not!`);
        }

        logger.info(`Registering validator: ${key}`);

        Validators[ key ].register(validationLogger);
    });
};

export default configureFormsy;
