import {
    binder,
    stateHelper,
    AuthenticationConstants,
    AccountInformationWorkingModel,
    AccountBeveiligenWorkingModel,
    onChange
} from './_references';

import { STATE_KEY } from './constants';
import * as Actions from './actions';
import * as Constants from './constants';

export const mapStateToProps = (state) => ({
    bind: (field) => binder(state[ STATE_KEY ])(field),
    security: stateHelper(state, [ AuthenticationConstants.STATE_KEY, AuthenticationConstants.Fields.ACCOUNT_INFO, AccountInformationWorkingModel.UserSecurity ], {}),
    externalReference: stateHelper(state, [ Constants.STATE_KEY, AccountBeveiligenWorkingModel.ExternalReference ])
});

export const mapDispatchToProps = (dispatch) => ({
    onChange : onChange(STATE_KEY, dispatch),
    secure: () => dispatch(Actions.secureAccount()),
    confirm: (model, form) => dispatch(Actions.addSecurity(model, form)),
    clear: () => dispatch(Actions.clear())
});