import { NAME } from './constants';

export const LOGIN = `${NAME}/LOGIN`;

export const LOGGED_IN = `${NAME}/LOGGED_IN`;

export const SET_ACCOUNT_WARNING = `${NAME}/SET_ACCOUNT_WARNING`;

export const REQUIRES_VERIFICATION = `${NAME}/REQUIRES_VERIFICATION`;

export const LOGOUT = `${NAME}/LOGOUT`;

export const LOGGED_OUT = `${NAME}/LOGGED_OUT`;

export const UPDATE_ACCOUNT_INFO = `${NAME}/UPDATE_ACCOUNT_INFO`;

export const UPDATED_ACCOUNT_INFO = `${NAME}/UPDATED_ACCOUNT_INFO`;

export const SET_ROLES = `${NAME}/SET_ROLES`;

export const SET_SELECT_ACCOUNT = `${NAME}/SET_SELECT_ACCOUNT`;