import { STATE_KEY, FIELDS } from './constants';
import * as ActionTypes from './actionTypes';

const reducer = (state = {}, action = {}) => {
    switch (action.type) {
    case ActionTypes.CLEAR: {
        const newState = Object.assign({}, state, {
            [ FIELDS.Dictionary ] : undefined
        });
        return newState;
    }
    case ActionTypes.LOADED: {
        const newState = Object.assign({}, state, {
            [ FIELDS.Dictionary ] : action.data
        });
        return newState;
    }
    default: {
        return state;
    }
    }
};

reducer.STATE_KEY = STATE_KEY;

export default reducer;