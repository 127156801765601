import * as ActionTypes from './actionTypes';

export const load = () => ({
    type: ActionTypes.LOAD
});

export const loaded = (data) => ({
    type: ActionTypes.LOADED,
    data
});

export const koppel = (accountId) => ({
    type: ActionTypes.KOPPEL,
    accountId
});