import LogLevel from '../util/logLevel';

export default {
    'action': LogLevel.NONE,
    'Configuration': LogLevel.ALL,
    'Application': LogLevel.NONE,
    'reduxBinder': LogLevel.NONE,
    'Router': LogLevel.NONE,
    'Axios': LogLevel.ALL,
    'API': LogLevel.ALL,
    'stateManager': LogLevel.NONE,
};

// 'API': LogLevel.NONE,
// 'Axios': LogLevel.NONE,
// 'Configuration': LogLevel.NONE,
// 'createAccountWizard': LogLevel.NONE,
// 'fieldValue': LogLevel.NONE,
// 'Homepage': LogLevel.NONE,
// 'MasterTableInput': LogLevel.NONE,
// 'Modal': LogLevel.NONE,
// 'Opleidingen': LogLevel.NONE,
// 'Q': LogLevel.NONE,
// 'reduxBinder': LogLevel.NONE,
// 'translate': LogLevel.WARNING,
// 'wizardBackAction': LogLevel.WARNING,
