import * as ActionTypes from './actionTypes';
import * as Constants from './constants';

/*
 * Reducer for the account aanvragen component.
*/
const reducer = (state = {}, action) => {
    switch(action.type){
    case ActionTypes.FIELD_CHANGED: {
        const newState = Object.assign({}, state, {
            [ action.field ]: action.value
        });
        return newState;
    }
    case ActionTypes.CLEAR:
        return {};
    default:
        return state;
    }
};

reducer.STATE_KEY = Constants.STATE_KEY;

export default reducer;