import {
    WachtwoordInstellenDetailWorkingModel,
    WachtwoordHerstellenWorkingModel
} from './_references';

import * as ActionTypes from './actionTypes';
import * as Constants from './constants';

/*
 * Reducer for the wachtwoord herstellen component.
*/
const reducer = (state = {}, action) => {
    switch(action.type){
    case ActionTypes.CLEAR:
        return {};
    case ActionTypes.PRESET_DATA:
        return {
            [ WachtwoordInstellenDetailWorkingModel.ExternalReference ]: action.model[ WachtwoordHerstellenWorkingModel.ExternalReference ],
            [ WachtwoordInstellenDetailWorkingModel.ResetToken ] : action.model[ WachtwoordHerstellenWorkingModel.ResetToken ]
        };
    default:
        return state;
    }
};

reducer.STATE_KEY = Constants.STATE_KEY;

export default reducer;