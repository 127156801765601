
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import FaIcon from 'react-fontawesome';
import { Link } from 'react-router';
import { Panel, Row, Col, ProgressBar } from 'react-bootstrap';

import {
    Button,
    Loading,
    PiaInfoWorkingModel,
    Routes
} from './_references';

/**
 * Displays a list of filled in Privacy Impact Assessments
 *
 * @class PiaLijst
 * @extends {Component}
 */
class PiaLijst extends React.Component {

    componentDidMount() {
        this.props.load();
    }

    render() {

        const { top, list, isLoading } = this.props;

        return (isLoading ? <Loading /> :
            <div>
                <Panel>
                    <Panel.Body>
                        <div className="panel-inline-title">Privacy Impact Assessments</div>

                        <Row>
                            <Col sm={12} xsHidden>
                                <Row className="row-table row-table-header">
                                    <Col xsHidden sm={2}>Datum aangemaakt</Col>
                                    <Col xsHidden sm={3}>Datum laatste wijziging</Col>
                                    <Col xsHidden sm={3}>Risico</Col>
                                    <Col xsHidden sm={2}>% gereed</Col>
                                    <Col xsHidden sm={2} className="text-right"></Col>
                                </Row>
                            </Col>
                        </Row>

                        {
                            list.slice(0, top).map((assessment, index) => (
                                <Row key={assessment[ PiaInfoWorkingModel.PiaAssessmentId ]} className={`row-table row-table-${index % 2 === 0 ? 'even' : 'odd'}`}>
                                    <Col xs={12} smHidden mdHidden lgHidden className="row-table-header-title">Datum aangemaakt</Col>
                                    <Col xs={12} sm={2}>{moment(assessment[ PiaInfoWorkingModel.DatumAangemaakt ]).format('DD-MM-YYYY')}</Col>
                                    <Col xs={12} smHidden mdHidden lgHidden className="row-table-header-title">Datum laatste wijziging</Col>
                                    <Col xs={12} sm={3}>{assessment[ PiaInfoWorkingModel.DatumLaatsteWijziging ] != null ? moment(assessment[ PiaInfoWorkingModel.DatumLaatsteWijziging ]).format('DD-MM-YYYY HH:mm') : '-'}</Col>
                                    <Col xs={12} smHidden mdHidden lgHidden className="row-table-header-title"># Hoog Risico</Col>
                                    <Col xs={12} sm={1}><FaIcon name="angle-double-up" className="risico-hoog" title="Hoog risico"/> {assessment[ PiaInfoWorkingModel.HoogRisicoAantal ]}</Col>
                                    <Col xs={12} smHidden mdHidden lgHidden className="row-table-header-title"># Gemiddeld Risico</Col>
                                    <Col xs={12} sm={1}><FaIcon name="angle-up" className="risico-middel" title="Gemiddeld risico"/> {assessment[ PiaInfoWorkingModel.MiddelRisicoAantal ]}</Col>
                                    <Col xs={12} smHidden mdHidden lgHidden className="row-table-header-title"># Laag Risico</Col>
                                    <Col xs={12} sm={1}><FaIcon name="angle-down" className="risico-laag" title="Laag risico"/> {assessment[ PiaInfoWorkingModel.LaagRisicoAantal ]}</Col>
                                    <Col xs={12} smHidden mdHidden lgHidden className="row-table-header-title">% gereed</Col>
                                    <Col xs={12} sm={2}><ProgressBar now={assessment[ PiaInfoWorkingModel.PercentageDone ]} label={`${assessment[ PiaInfoWorkingModel.PercentageDone ]}%`}/></Col>
                                    <Col xsHidden sm={2} className="text-right">
                                        { (assessment[ PiaInfoWorkingModel.Bewerkbaar ] === true) ?
                                            <Button to={Routes.Dashboard.Pia.Bewerken.createUrl(assessment[ PiaInfoWorkingModel.PiaAssessmentId ])} bsStyle="primary"><FaIcon name="pencil"/> Bewerken</Button>
                                            :
                                            <Button to={Routes.Dashboard.Pia.Inzien.createUrl(assessment[ PiaInfoWorkingModel.PiaAssessmentId ])} bsStyle="primary"><FaIcon name="file-text-o"/> Inzien</Button>
                                        }
                                    </Col>
                                    <Col xs={12} smHidden mdHidden lgHidden>
                                        { (assessment[ PiaInfoWorkingModel.Bewerkbaar ] === true) ?
                                            <Button to={Routes.Dashboard.Pia.Bewerken.createUrl(assessment[ PiaInfoWorkingModel.PiaAssessmentId ])} bsStyle="primary" block><FaIcon name="pencil"/> Bewerken</Button>
                                            :
                                            <Button to={Routes.Dashboard.Pia.Inzien.createUrl(assessment[ PiaInfoWorkingModel.PiaAssessmentId ])} bsStyle="primary" block><FaIcon name="file-text-o"/> Inzien</Button>
                                        }
                                    </Col>
                                </Row>

                            ))
                        }

                        {
                            top != null && top < list.length ?
                                <Row className="row-table row-table-footer"><Col xs={12}><Link to={Routes.Dashboard.Pia.path}>Alle {list.length} assessments bekijken</Link> </Col> </Row>
                                : null
                        }
                    </Panel.Body>
                </Panel>
                { list.some( pia => pia[ PiaInfoWorkingModel.Bewerkbaar ] === true) ? null :
                    <Row>
                        <Col xsHidden sm={12} className="text-right">
                            <Button bsStyle="primary" to={Routes.Dashboard.Pia.Nieuw.path} ><FaIcon name="search" /> Assessment starten</Button>
                        </Col>

                        <Col smHidden mdHidden lgHidden xs={12} >
                            <Button to={Routes.Dashboard.Pia.Nieuw.path} bsStyle="primary" block><FaIcon name="search" /> Assessment starten</Button>
                        </Col>
                    </Row>
                }
            </div>
        );
    }
}

PiaLijst.propTypes = {
    top: PropTypes.number,
    isLoading: PropTypes.bool,
    load: PropTypes.func.isRequired,
    list: PropTypes.arrayOf(PropTypes.object),
};

export default PiaLijst;