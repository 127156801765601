import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import { FormGroup,Col,Row } from 'react-bootstrap';

import Button from '../../universe/molecules/button';
import { Link } from 'react-router';
import { Routes } from '../../configuration/constants.routes';

import requiredValidation from '../../validation/required';
import emailValidation from '../../validation/email';
import combine from '../../validation/combine';

import { LoginInputs } from '../../universe/molecules/createFormsyInputComponent/prefab';

const Credentials = ({ handleLogin, bind, onChange }) => (
    <Formsy className="form-horizontal" data-toggle="validator" onValidSubmit={(model) => {
        handleLogin(model);
    }} >
        <LoginInputs.Text {...bind('email')} autoComplete="username" autoFocus onChange={onChange} label="E-mailadres" type="text" placeholder="E-mailadres" {...combine([ requiredValidation('E-mailadres'), emailValidation('E-mailadres') ])}/>

        <LoginInputs.Password {...bind('password')} autoComplete="current-password" onChange={onChange} label="Wachtwoord" type="password" placeholder="Wachtwoord" {...requiredValidation('Wachtwoord')}/>

        <FormGroup>
            <Col xsHidden sm={12} className="text-right">
                <Button type="submit" bsStyle="primary" default>Log in</Button>
            </Col>
            <Col smHidden mdHidden lgHidden xs={12} >
                <Button type="submit" bsStyle="primary" block default>Log in</Button>
            </Col>
        </FormGroup>
        <Row>
            <Col sm={12} className="center one-em-margin-bottom">
                            Heb je nog geen account?
            </Col>
            <Col sm={12} className="center one-em-margin-bottom">
                <Button to={Routes.Account.Aanvragen.path} block>Aanvragen</Button>
            </Col>
            <Col sm={12} className="center">
                <Link to={Routes.Account.WachtwoordVergeten.path}>Wachtwoord vergeten?</Link>
            </Col>
        </Row>
    </Formsy>
);

Credentials.propTypes = {
    handleLogin: PropTypes.func.isRequired,
    bind: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
};

export default Credentials;