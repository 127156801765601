import * as ActionTypes from './actionTypes';

export const loadMijnAccount = () => ({
    type: ActionTypes.LOAD_MIJNACCOUNT,
});

export const loadedMijnAccount = (model) => ({
    type: ActionTypes.LOADED_MIJNACCOUNT,
    model
});

export const updateMijnAccount = (model) => ({
    type: ActionTypes.UPDATE_MIJNACCOUNT,
    model
});