import {
    stateHelper,
    Constants,
    Actions,
    onChange,
    binder
} from './_references';

export const mapStateToProps = (state) => ({
    list: stateHelper(state, [ Constants.STATE_KEY, Constants.FIELDS.LIST ], []),
    editorObject: stateHelper(state,[ Constants.STATE_KEY, Constants.FIELDS.EDITOR ], {}),
    bind: (field) => binder(state, [ Constants.STATE_KEY, Constants.FIELDS.EDITOR ])(field),
    isLoading: stateHelper(state, [ Constants.STATE_KEY, Constants.FIELDS.LOADING ])
});

export const mapDispatchToProps = (dispatch) => ({
    onChange : onChange([ Constants.STATE_KEY, Constants.FIELDS.EDITOR ], dispatch),
    loadPersonen: () => dispatch(Actions.getPersonen()),
    showDetails: (id) => dispatch(Actions.getPersoon(id, Constants.SOURCES.LIST)),
    hideDetails: (id) => dispatch(Actions.hidePersoon(id)),
    editPersoon: (id) => dispatch(Actions.editPersoon(id, Constants.SOURCES.EDITOR)),
    cancelEdit: () => dispatch(Actions.cancelEdit()),
    deletePersoon: (id) => dispatch(Actions.deletePersoon(id)),
    updatePersoon: () => dispatch(Actions.updatePersoon())
});