import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Panel } from 'react-bootstrap';
import { Link } from 'react-router';
import FaIcon from 'react-fontawesome';
import Formsy from 'formsy-react';

import {
    Button,
    Container,
    FieldSetup,
    RegisterInformatieWorkingModel,
    RegisterInformatieDetailWorkingModel,
    PersoonWorkingModel,
    Routes,
    Select,
    ReadOnly,
    ApiRoutes,
    Loading
} from './_references';

class AlgemeneRegisterInformatie extends React.Component {

    componentDidMount() {
        this.props.load();
    }

    render() {
        const { bind, onChange, update, isLoading } = this.props;

        return (

            <Container>
                {isLoading ? <Loading/> :
                    <Formsy className="form-horizontal" data-toggle="validator" onValidSubmit={(model) => {
                        update(model);
                    }}>
                        <Panel>
                            <Panel.Body>
                                <div className="panel-inline-title">NAAM EN CONTACTGEGEVENS ORGANISATIE</div>

                                <ReadOnly
                                    {...bind(RegisterInformatieWorkingModel.NaamOrganisatie)}
                                    {...new FieldSetup()
                                        .for(RegisterInformatieWorkingModel.NaamOrganisatie)
                                        .withFriendlyName('Naam organisatie')
                                        .setLabel()
                                        .setPlaceholder()
                                    }
                                />

                                <ReadOnly
                                    {...bind(RegisterInformatieWorkingModel.Eigenaar)}
                                    {...new FieldSetup()
                                        .for(RegisterInformatieWorkingModel.Eigenaar)
                                        .withFriendlyName('Eigenaar')
                                        .setLabel()
                                        .setPlaceholder()
                                    }
                                />

                                <Select
                                    {...bind(RegisterInformatieDetailWorkingModel.ContactPersoonId)}
                                    {...new FieldSetup()
                                        .for(RegisterInformatieDetailWorkingModel.ContactPersoonId)
                                        .withFriendlyName('Vertegenwoordiger')
                                        .setLabel()
                                        .setPlaceholder('Vertegenwoordiger')
                                        .isRequired()
                                        .loadFrom(ApiRoutes.Personen)
                                        .onChange(onChange)
                                    }
                                    options={[
                                        {
                                            [ PersoonWorkingModel.Id ]: '',
                                            [ PersoonWorkingModel.FullName ]: ''
                                        }
                                    ]}

                                    optionKey={PersoonWorkingModel.Id}
                                    optionValue={PersoonWorkingModel.FullName}
                                />

                            </Panel.Body>
                        </Panel>

                        <Panel>
                            <Panel.Body>
                                <div className="panel-inline-title">Naam van de Functionaris voor de Gegevensbescherming</div>

                                <Select
                                    {...bind(RegisterInformatieDetailWorkingModel.FunctionarisGegevensbeschermingId)}
                                    {...new FieldSetup()
                                        .for(RegisterInformatieDetailWorkingModel.FunctionarisGegevensbeschermingId)
                                        .withFriendlyName('Naam')
                                        .setLabel()
                                        .setPlaceholder('Naam')
                                        .isRequired()
                                        .loadFrom(ApiRoutes.Personen)
                                        .onChange(onChange)
                                    }
                                    options={[
                                        {
                                            [ PersoonWorkingModel.Id ]: '',
                                            [ PersoonWorkingModel.FullName ]: ''
                                        }
                                    ]}

                                    optionKey={PersoonWorkingModel.Id}
                                    optionValue={PersoonWorkingModel.FullName}
                                />

                            </Panel.Body>
                        </Panel>

                        <Panel>
                            <Panel.Body>
                                <div className="panel-inline-title">Naam van de verwerkingsverantwoordelijke</div>

                                <Select
                                    {...bind(RegisterInformatieDetailWorkingModel.VerwerkingsverantwoordelijkeId)}
                                    {...new FieldSetup()
                                        .for(RegisterInformatieDetailWorkingModel.VerwerkingsverantwoordelijkeId)
                                        .withFriendlyName('Naam')
                                        .setLabel()
                                        .setPlaceholder('Naam')
                                        .isRequired()
                                        .loadFrom(ApiRoutes.Personen)
                                        .onChange(onChange)
                                    }
                                    options={[
                                        {
                                            [ PersoonWorkingModel.Id ]: '',
                                            [ PersoonWorkingModel.FullName ]: ''
                                        }
                                    ]}

                                    optionKey={PersoonWorkingModel.Id}
                                    optionValue={PersoonWorkingModel.FullName}
                                />

                            </Panel.Body>
                        </Panel>

                        <Row>
                            <Col xsHidden sm={12} className="text-right">
                                <Link to={Routes.Dashboard.path}>annuleren</Link>
                                <span className="v-spacer"/>
                                <Button bsStyle="primary" type="submit"><FaIcon name="floppy-o" /> Opslaan</Button>
                            </Col>

                            <Col smHidden mdHidden lgHidden xs={12} >
                                <Button bsStyle="primary" block type="submit"><FaIcon name="floppy-o" /> Opslaan</Button>
                                <span className="h-spacer"/>
                                <Link to={Routes.Dashboard.path}>annuleren</Link>
                            </Col>
                        </Row>
                    </Formsy>
                }
            </Container>
        );
    }
}

AlgemeneRegisterInformatie.propTypes = {
    load: PropTypes.func.isRequired,
    bind: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    isLoading: PropTypes.bool
};

export default AlgemeneRegisterInformatie;