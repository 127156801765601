export const NAME = 'Login';

export const STATE_KEY = `${NAME}/81889ab6-eb86-4055-8483-203109c93a60`;

export const FIELDS = {
    LOGGED_IN: 'LoggedIn',
    SHOW_ACCOUNT_WARNING: 'ShowAccountWarning',
    REQUIRES_VERIFICATION: 'RequiresVerification',
    ROLES: 'Roles',
    SELECT_ACCOUNT: 'SelectAccount'
};
