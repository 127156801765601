import React from 'react';

import {
    Container
} from './_references';

import PiaLijst from './lijst/index';

const Pia = () => (
    <Container>
        <PiaLijst />
    </Container>
);

export default Pia;