import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

const DisplayWrapper = ({ LabelComponent, DisplayComponent }) => (
    <Row className="display-wrapper">
        {LabelComponent ? LabelComponent : null}
        <Col xs={12}>
            {DisplayComponent ?  DisplayComponent : null }
        </Col>
    </Row>
);

const propTypes = {
    LabelComponent: PropTypes.node.isRequired,
    DisplayComponent: PropTypes.node.isRequired,
    ValidationComponent: PropTypes.node,
    hasValidationErrors: PropTypes.bool
};

DisplayWrapper.propTypes = propTypes;

export default DisplayWrapper;
