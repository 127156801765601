import React from 'react';
import PropTypes from 'prop-types';

import Container from '../../universe/molecules/container/pageContainers/pageContainer';

class Logout extends React.Component {
    componentDidMount() {
        this.props.logout();
    }

    render() {
        return (<Container>U wordt nu uitgelogd.</Container>);
    }
}

Logout.propTypes = {
    logout: PropTypes.func.isRequired
};

export default Logout;
