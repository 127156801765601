import * as Constants from './constants';
import * as Actions from './actions';

export const mapStateToProps = (state={}) => ({
    messages: (state[ Constants.STATE_KEY ] || {})[ Constants.STATE_KEYS.MESSAGES ] || []
});

export const mapDispatchToProps = (dispatch) => ({
    removeMessage: (id) => () => dispatch(Actions.removeMessage(id))
});
