import * as Helpers from './helpers';
import * as Routes from './constants.routes';

const defaultSettings = {
    suppressErrors: true,
    onValidationError: {
        showModal: true
    }
};

export const GetAllVerwerkeren = () => {
    return Helpers.get(Routes.Verwerkersregister);
};

export const GetVerwerker = (id) => {
    return Helpers.get(`${Routes.Verwerkersregister}/${id}`);
};

/**
 * Creates a new Verwerker
 *
 * @param {*} model
 */
export const AddVerwerker = (model) => {
    return Helpers.post(Routes.Verwerkersregister, model, defaultSettings);
};

/**
 * Updates a Verwerker
 *
 * @param {*} model
 */
export const UpdateVerwerker = (model) => {
    return Helpers.put(Routes.Verwerkersregister, model, defaultSettings);
};

export const DeleteVerwerker = (id) => {
    return Helpers.delete_(`${Routes.Verwerkersregister}/${id}`, {
        suppressErrors: true
    });
};