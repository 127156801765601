import React from 'react';

import Container from '../../universe/molecules/container/pageContainers/homepageContainer';

import Login from '../login';

const Homepage = () => {
    return (
        <Container>

            <Login />

        </Container>
    );
};

export default Homepage;
