import React from 'react';
import PropTypes from 'prop-types';
import { stateHelper } from '../../../util/stateManager';
import Loading from '../loading';

const restorePlaceholderVisibilityOnRerender = (pId) => {
    const placeholderTextElement = document.getElementById(`${pId}_placeholdertext`);

    if(placeholderTextElement && placeholderTextElement.classList.contains('hide')){
        placeholderTextElement.classList.remove('hide');
    }
};

/**
 * Combobox input component
 *
 * @param {any} {id, name, label, required, options, value, field, onChangeAction}
 * @returns
 */
class SelectInput extends React.Component {

    componentDidMount() {
        this.props.loadFromApi(this.props.api);
    }

    render() {
        const { id, name, label, field, value, options, apiOptions, optionKey, optionValue, onChange, autoFocus, tabIndex, api, customFilter } = this.props;

        const pId = (id || field);
        const additionalProps = {
            autoFocus,
            tabIndex
        };

        const onChangeAction = (e) => {
            //onChange(field, e.target.value);
            onChange(e);
        };

        /**
     * This action removes the optional text to avoid text collisions in certain situations.
     */
        const onFocusAction = () => {

            if(document.activeElement.id === pId) {
                const placeholderTextElement = document.getElementById(`${pId}_placeholdertext`);

                if(placeholderTextElement && !placeholderTextElement.classList.contains('hide')){
                    placeholderTextElement.classList.add('hide');
                }
            }
        };

        restorePlaceholderVisibilityOnRerender(pId);

        const optionsFromApi = stateHelper(apiOptions, api);

        const optionsToRender = typeof customFilter === 'function'
            ? options.concat(optionsFromApi || []).filter(customFilter)
            : options.concat(optionsFromApi || []);

        return (
            optionsFromApi == null ?
                <div>
                    <Loading />
                    {/* Adding an hidden input to give the posibility to add required validation for a field which has not been loaded yet */}
                    <input
                        id={id || field}
                        name={name}
                        value={(value ? value : '')}
                        {...additionalProps}
                        type="hidden"
                    />
                </div>
                :

                <select id={id || field} name={name} className="form-control" aria-label={label} value={(value ? value : '')} onChange={onChangeAction} {...additionalProps} onFocus={onFocusAction} onClick={onFocusAction}>
                    {optionsToRender.map((option) => {
                        return (<option key={option[ optionKey ]}
                            value={option[ optionKey ]}
                            aria-label={option[ optionValue ]} >
                            {option[ optionValue ]}
                        </option>);
                    })
                    }
                </select>
        );
    }
}

SelectInput.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    field: PropTypes.string,
    value: PropTypes.any,
    options: PropTypes.array.isRequired,
    optionKey: PropTypes.string.isRequired,
    optionValue: PropTypes.string.isRequired,
    onChangeAction: PropTypes.func,
    className: PropTypes.string,
    tabIndex: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
    customFilter: PropTypes.func,
};

export default SelectInput;