import {
    stateHelper,
    VerwerkingWorkingModel
} from './_references';

import * as ActionTypes from './actionTypes';
import * as Constants from './constants';

/*
 * Reducer for the verwerkingsregister component.
*/
const reducer = (state = {}, action) => {
    switch(action.type){
    case ActionTypes.CLEAR:
    {
        return Object.assign({},state,{
            [ Constants.FIELDS.ADD ]: {}
        });
    }
    case ActionTypes.GET_VERWERKINGEN:
    {
        const newState = Object.assign({}, state, {
            [ Constants.FIELDS.LOADING ]: true
        });
        return newState;
    }
    case ActionTypes.LOADED_VERWERKING:
    {
        switch (action.source) {
        case Constants.SOURCES.LIST: {
            const list = stateHelper(state, [ Constants.FIELDS.LIST ], []).slice();
            const target = list.filter(v => v[ VerwerkingWorkingModel.Id ] === action.model[ VerwerkingWorkingModel.Id ]);

            if (target.length !== 1) {
                return state;
            }
            target[ 0 ][ Constants.FIELDS.DETAILS ] = action.model;

            return Object.assign({},state,{
                [ Constants.FIELDS.LIST ]: list
            });
        }
        case Constants.SOURCES.EDITOR: {
            return Object.assign({}, state, {
                [ Constants.FIELDS.EDITOR ]: action.model
            });

        }
        default: {
            return state;
        }
        }
    }
    case ActionTypes.HIDE_VERWERKING: {
        const list = stateHelper(state, [ Constants.FIELDS.LIST ], []).slice();
        const target = list.filter(v => v[ VerwerkingWorkingModel.Id ] === action.id);

        if (target.length !== 1) {
            return state;
        }
        target[ 0 ][ Constants.FIELDS.DETAILS ] = null;

        return Object.assign({},state,{
            [ Constants.FIELDS.LIST ]: list
        });
    }
    case ActionTypes.LOADED_VERWERKINGEN:
    {
        return Object.assign({},state,{
            [ Constants.FIELDS.LIST ]: action.model,
            [ Constants.FIELDS.LOADING ]: false
        });
    }
    case ActionTypes.CONFIRMED_DELETE_VERWERKING: {
        const newList = stateHelper(state, Constants.FIELDS.LIST, []).filter(v => (v[ VerwerkingWorkingModel.Id ] !== action.id)).slice();

        return Object.assign({}, state, {
            [ Constants.FIELDS.LIST ] : newList
        });
    }
    case ActionTypes.CANCEL_EDIT: {
        return Object.assign({}, state, {
            [ Constants.FIELDS.EDITOR ]: null
        });
    }
    default:
        return state;
    }
};

reducer.STATE_KEY = Constants.STATE_KEY;

export default reducer;