import { addValidationRule } from 'formsy-react';
import { parseISO, isValid, isPast } from 'date-fns';

const notInTheFutureValidator = (formValues, value) => {
    const parsedValue = typeof value === 'string' ? value.trim() === '' ? null : parseISO(value.trim()) : value;

    if(parsedValue == null) {
        return true;
    }

    if (!isValid(parsedValue)) {
        return false;
    }

    return isPast(parsedValue);
};

notInTheFutureValidator.NAME = 'notInTheFutureValidator';

notInTheFutureValidator.register = () => {
    addValidationRule(notInTheFutureValidator.NAME, notInTheFutureValidator);
};

export default notInTheFutureValidator;