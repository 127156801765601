import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import { Panel, Col, Row } from 'react-bootstrap';
import FaIcon from 'react-fontawesome';
import { Link } from 'react-router';

import {
    Button,
    Container,
    DateTime,
    FieldSetup,
    IncidentDetailWorkingModel,
    Routes,
    TextArea,
    Text
} from './_references';

class IncidentNieuw extends React.Component {

    componentWillUnmount() {
        this.props.clear();
    }

    render() {
        const { bind, onChange, createIncident } = this.props;
        return (<Container title="Nieuw incident aanmaken">

            <Formsy ref={(frm)=> {
                this.frm = frm;
            }} className="form-horizontal" data-toggle="validator" onValidSubmit={(model) => {
                createIncident(model, this.frm);
            }}>

                <Row>
                    <Col sm={12} xs={12}>
                        <Panel>
                            <Panel.Body>
                                <div className="panel-inline-title">Start</div>

                                <p>U heeft een incident ontdekt, het is van belang om zo spoedig maar wel zo secuur mogelijk de situatie in kaart te brengen.
                            Als uit het onderzoek blijkt dat persoonsgegevens zijn gelekt dan moet u dit binnen 72 uur, na ontdekking, aan de autoriteiten melden.</p>
                            </Panel.Body>
                        </Panel>
                        <Panel>
                            <Panel.Body>
                                <div className="panel-inline-title">Algemene gegevens</div>

                                <p>Geef het onderzoek een naam, zoals bijvoorbeeld &apos;Gestolen laptop&apos; of &apos;Paspoort laten liggen onder kopieermachine&apos;.</p>
                                <p>Beschrijf eventueel voor latere referenatie aan dit incident kort op waar het om gaat.</p>

                                <Text
                                    {...bind(IncidentDetailWorkingModel.Naam)}
                                    {...new FieldSetup()
                                        .for(IncidentDetailWorkingModel.Naam)
                                        .withFriendlyName('Naam')
                                        .setLabel()
                                        .setPlaceholder()
                                        .isRequired()
                                        .maxLength(1001)
                                        .onChange(onChange)
                                    }
                                />

                                <DateTime
                                    {...bind(IncidentDetailWorkingModel.DatumIncident)}
                                    {...new FieldSetup()
                                        .for(IncidentDetailWorkingModel.DatumIncident)
                                        .withFriendlyName('Moment ontdekking')
                                        .setLabel()
                                        .setPlaceholder('Moment ontdekking')
                                        .isRequired()
                                        .isNotInTheFuture()
                                        .onChange(onChange)
                                    }
                                    showTimeSelect
                                    timeIntervals={5}
                                    dateFormat="dd-MM-yyyy HH:mm"
                                />

                                <TextArea
                                    rows={5}

                                    {...bind(IncidentDetailWorkingModel.Beschrijving)}
                                    {...new FieldSetup()
                                        .for(IncidentDetailWorkingModel.Beschrijving)
                                        .withFriendlyName('Omschrijving')
                                        .setLabel()
                                        .setPlaceholder('Omschrijving (optioneel)')
                                        .maxLength(1024)
                                        .onChange(onChange)
                                    }
                                />

                            </Panel.Body>
                        </Panel>
                        <div className="text-right">
                            <Link to={Routes.Dashboard.path}>annuleren</Link>
                            <span className="v-spacer"/>
                            <Button bsStyle="primary" type="submit"><FaIcon name="floppy-o" /> Aanmaken</Button>
                        </div>
                    </Col>
                </Row>
            </Formsy>
        </Container>
        );
    }
}

IncidentNieuw.propTypes = {
    clear: PropTypes.func.isRequired,
    bind: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    createIncident: PropTypes.func.isRequired
};

export default IncidentNieuw;