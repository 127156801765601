const isNumericValidation = (propertyName) => {
    return {
        validators: {
            isNumeric: true,
        },
        validationErrors: {
            isNumeric: `${ propertyName } mag alleen getallen bevatten`,
        },
        validationError: `${ propertyName } mag alleen getallen bevatten`,
    };
};

export default isNumericValidation;