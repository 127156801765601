import * as ActionTypes from './actionTypes';
import * as Constants from './constants';

const errorPageReducer = (state = {}, action) => {
    switch (action.type) {
    case ActionTypes.CLEAR_ERROR_INFORMATION:
    {
        return {};
    }
    case ActionTypes.SET_ERROR_INFORMATION:
    {
        const { title, message } = action;
        return {
            title, message
        };
    }
    default:
        return state;
    }
};

errorPageReducer.STATE_KEY = Constants.STATE_KEY;

export default errorPageReducer;