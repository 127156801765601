import { FIELDS, OUTCOME } from './constants';
import * as ActionTypes from './actionTypes';
import {  PrivacyCheckWorkingModel } from '../../model';

export const loadQuestion = (sortOrder = 0) => ({
    type: ActionTypes.LOAD_QUESTION,
    [ PrivacyCheckWorkingModel.SortOrder ]: sortOrder
});

export const setLoadingState = (isLoading = false) => ({
    type: ActionTypes.LOADING_QUESTION,
    [ FIELDS.LOADING ]: isLoading
});

export const questionLoaded = (question) => ({
    type: ActionTypes.QUESTION_LOADED,
    [ FIELDS.QUESTION ]: question
});

export const questionAnswered = (answer) => ({
    type: ActionTypes.QUESTION_ANSWERED,
    [ FIELDS.ANSWER ]: answer
});

export const setScore = (score) => ({
    type: ActionTypes.SET_SCORE,
    [ FIELDS.SCORE ]: score
});

export const finished = (outcome = OUTCOME.DONT_NEED_PIA) => ({
    type: ActionTypes.FINISHED,
    [ FIELDS.OUTCOME ]: outcome,
});