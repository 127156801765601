import { all, call, put, takeLatest, delay } from 'redux-saga/effects';

import * as Api from '../../../api/stam';
import * as ActionTypes from './actionTypes';
import * as Actions from './actions';

export default function* () {
    yield all([
        takeLatest(ActionTypes.LOAD_FROM_API, loadFromApi)
    ]);
}

function* loadFromApi(action) {
    yield delay(250);

    try{
        const result = yield call (Api.GetStam, action.api);

        if (result.success) {
            yield put(Actions.loadedFromApi(action.api, result.data));

        }
        else {
            // TODO
        }
    }
    catch(e) {
    }

}