import {
    onChange,
    binder,
    stateHelper
} from './_references';

import * as Constants from './constants';
import * as Actions from './actions';

export const mapDispatchToProps = (dispatch) => ({
    onChange : onChange([ Constants.STATE_KEY, Constants.FIELDS.EDIT ], dispatch),
    load: () => dispatch(Actions.loadMijnAccount()),
    update: (model) => dispatch(Actions.updateMijnAccount(model))
});

export const mapStateToProps = (state) => {
    return {
        bind: (field) => binder(state, [ Constants.STATE_KEY, Constants.FIELDS.EDIT ])(field),
        isLoading: stateHelper(state, [ Constants.STATE_KEY, Constants.FIELDS.LOADING ], false)
    };
};