import { addValidationRule } from 'formsy-react';

const doesNotEqualValidator = (formValues, value, compareValue) => {

    return (value !== compareValue);
};

doesNotEqualValidator.NAME = 'doesNotEqual';

doesNotEqualValidator.register = () => {
    addValidationRule(doesNotEqualValidator.NAME, doesNotEqualValidator);
};

export default doesNotEqualValidator;