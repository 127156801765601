import * as Helpers from './helpers';
import * as Routes from './constants.routes';

export const getRegisterInformatie = () => {
    return Helpers.get(`${Routes.RegisterInformatie}`);
};

/**
 * Creates a new Incident
 *
 * @param {*} model
 */
export const updateRegisterInformatie = (model) => {
    return Helpers.put(`${Routes.RegisterInformatie}`, model);
};