import * as ActionTypes from './actionTypes';
import * as Constants from './constants';

/*
 * Reducer for the account activeren component.
*/
const reducer = (state = {}, action) => {
    switch(action.type){
    case ActionTypes.CLEAR:
        return {};
    default:
        return state;
    }
};

reducer.STATE_KEY = Constants.STATE_KEY;

export default reducer;