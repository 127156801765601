import { NAME } from './constants';

export const ADD_PERSOON = `${NAME}/ADD_PERSOON`;
export const GET_PERSOON = `${NAME}/GET_PERSOON`;
export const HIDE_PERSOON = `${NAME}/HIDE_PERSOON`;
export const GET_PERSONEN = `${NAME}/GET_PERSONEN`;
export const LOADED_PERSOON = `${NAME}/LOADED_PERSOON`;
export const LOADED_PERSONEN = `${NAME}/LOADED_PERSOOEN`;
export const EDIT_PERSOON = `${NAME}/EDIT_PERSOON`;
export const CANCEL_EDIT = `${NAME}/CANCEL_EDIT`;
export const UPDATE_PERSOON = `${NAME}/UPDATE_PERSOON`;
export const DELETE_PERSOON = `${NAME}/DELETE_PERSOON`;
export const CONFIRMED_DELETE_PERSOON = `${NAME}/CONFIRMED_DELETE_PERSOON`;
export const CLEAR = `${NAME}/CLEAR`;