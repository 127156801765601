import createLogger from './log';

const logger = createLogger('stateManager');

/**
 * A helper for getting information from the state without needing to take care of undefined
 *
 * @param {*} state - the (comlete) state
 * @param {*} keys - the key or keys (array) to follow to get to the value
 * @param {*} defaultValue - the value to return when a key or subkey could not be found in the state
 */
const stateHelper = (state, keys, defaultValue) => {
    if (keys == null) {
        logger.info('no keys given, returning the current state object.');
        return state;
    }

    // join keys, and remove undefined or null keys
    const keysToString = Array.isArray(keys) ? keys.filter(k => k != null).join('.') : keys;

    if (state == null) {
        logger.info(`The given state is empty while retrieving key '${keysToString}'`);
        return defaultValue;
    }

    if (Array.isArray(keys)) {
        if (keys.length > 1) {
            const key = keys.shift();

            return stateHelper(state[ key ], keys, defaultValue);
        }
    }

    if (state[ keysToString ] == null) {
        logger.info(`The given key '${keysToString}' is not present in the state.`);
        return defaultValue;
    }
    return state[ keysToString ];
};

/**
 * A simple state manager to get values from a state without worrying about undefined (sub)objects
 *
 * @param {*} subKeys - the base key of keys (array) to use
 * @param {*} stateCreator - the function to call to create the mapStateToProps object
 *
 * Example:
 *
 * Without statemanager:
 * const mapStateToProps = (state) => ({
 *    field: (state[Constants.STATE_KEY] || {})['key'],
 *    nested: ((state[Constants.STATE_KEY] || {})['key'] || {})['subkey']),
 *    withDefaultString: (state[Constants.STATE_KEY] || {})['unknownkey'] || 'default string',
 *    withDefaultEmptyObject: (state[Constants.STATE_KEY] || {})['unknownkey'] || {},
 *    withDefaultEmptyArray: (state[Constants.STATE_KEY] || {})['unknownkey'] || [],
 * });
 *
 * With statemanager:
 * const mapStateToProps = stateManager(Constants.STATE_KEY, (get) => {
 *    field: get('key'),
 *    nested: get(['key', 'subkey']),
 *    withDefaultString: get('unknownkey', 'default string'),
 *    withDefaultEmptyObject: get('unknownkey', {}),
 *    withDefaultEmptyArray: get('unknownkey', []),
 * })
 *
 */
const stateManager = (subKeys, stateCreator) => {

    const baseArray = subKeys != null ? Array.isArray(subKeys) ? subKeys.slice() : [ subKeys ] : [];

    return (state = {}) => stateCreator((keys, defaultValue) => {

        const keysArray = [ ...baseArray,  ...(keys != null ? Array.isArray(keys) ? keys.slice() : [ keys ] : []) ];

        return stateHelper(state, keysArray, defaultValue);
    }, state);

};

export default stateManager;

export { stateHelper };