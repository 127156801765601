import Axios from 'axios';

import programConfig from '../configuration/logConfiguration';
import LogLevel from './logLevel';

export default (group, customConfig) => {

    const isLogEnabled = (flag = LogLevel.ALL) => {
        // get the external config with a fallback with the internal config
        const config = customConfig || Axios.defaults.logConfig || programConfig;

        // config will contain a number 0 through 31, based on this number we will show specific messages
        // the flag parameter will tell us the level of the message

        if (!Axios.defaults.loggerEnabled) {
            // not enabled
            return false;
        }

        // HACK: TODO: Logger losweken
        if (process.env.NODE_ENV === 'production' && Axios.defaults.logConfig == null) {
            // production but no external(!) config for groups
            return false;
        }

        const level = config[ group ];

        if (level == null) {
            // not configured, on production hide, on dev show
            return process.env.NODE_ENV !== 'production';
        }

        return ((flag === LogLevel.ALL && level !== LogLevel.NONE) || (level & flag) === flag);
    };

    return {
        log: function () {
            if (isLogEnabled(LogLevel.VERBOSE)) {
                console.log(`[${group}]`, ...arguments);
            }
        },
        info: function () {
            if (isLogEnabled(LogLevel.INFO)) {
                console.log(`[${group}]`, ...arguments);
            }
        },
        warn: function () {
            if (isLogEnabled(LogLevel.WARNING)) {
                console.warn(`[${group}]`, ...arguments);
            }
        },
        error: function () {
            if (isLogEnabled(LogLevel.ERROR)) {
                console.error(`[${group}]`, ...arguments);
            }
        },
        depricated: function (target, message) {
            if (isLogEnabled(LogLevel.WARNING)) {
                console.warn(`[${group}] [DEPRICATED] : ${message}`);
            }
        },
        group: function (groupName = group) {
            if (isLogEnabled() && console.group) {
                console.group(groupName);
            }
        },
        groupEnd: function () {
            if (isLogEnabled() && console.groupEnd) {
                console.groupEnd();
            }
        },
    };
};
