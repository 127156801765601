const Root = '';
const Home = `${Root}/`;

const Error = `${Root}/error`;
const Check = `${Root}/check`;

const Account = `${Root}/account`;
const AccountLogin = `${Account}/login`;
const AccountLoguit = `${Account}/loguit`;
const AccountAanvragen = `${Account}/aanvragen`;
const AccountAangevraagd = `${Account}/aangevraagd`;
const AccountBevestigen = `${Account}/bevestigen`;
const AccountBevestigd = `${Account}/bevestigd`;
const AccountActiveren = `${Account}/activeren`;
const AccountGeactiveerd = `${Account}/geactiveerd`;
const AccountWachtwoordVergeten = `${Account}/wachtwoordVergeten`;
const AccountWachtwoordHerstellen = `${Account}/wachtwoordHerstellen`;
const AccountWachtwoordInstellen = `${Account}/wachtwoordInstellen`;
const AccountSelecteren = `${Account}/selecteren`;
const AccountKoppelen = `${Account}/koppelen`;
const AccountBeveiligen = `${Account}/beveiligen`;
const MijnAccount = `${Account}/mijnaccount`;

const OverOns = `${Root}/over`;
const Woordenboek = `${Root}/woordenboek`;

const Dashboard = `${Root}/dashboard`;
const Pia = `${Dashboard}/pia`;
const PiaNieuw = `${Dashboard}/pia/nieuw`;
const PiaBewerken = `${Dashboard}/pia/bewerken`;
const PiaInzien = `${Dashboard}/pia/inzien`;

const Beheer =`${Root}/beheer`;
const Personen = `${Beheer}/personen`;
const PersoonToevoegen = `${Beheer}/personen/toevoegen`;

const Templates =`${Root}/templates`;

const Incident = `${Dashboard}/incident`;
const IncidentAchtergrond = `${Incident}/achtergrond`;
const IncidentNieuw = `${Incident}/nieuw`;
const IncidentBewerken = `${Incident}/bewerken`;
const IncidentInzien = `${Incident}/inzien`;

const AlgemeneRegisterinformatie = `${Dashboard}/algemeneRegisterinformatie`;

const Verwerkingsregister = `${Dashboard}/verwerkingsregister`;
const VerwerkingsregisterBewerken = `${Dashboard}/verwerkingsregister/bewerken`;
const VerwerkingsregisterToevoegen = `${Dashboard}/verwerkingsregister/toevoegen`;

const Verwerkersregister = `${Dashboard}/verwerkersregister`;
const VerwerkersregisterBewerken = `${Dashboard}/verwerkersregister/bewerken`;
const VerwerkersregisterToevoegen = `${Dashboard}/verwerkersregister/toevoegen`;

export const RouteParams = {
    Incident: {
        Bewerken: {
            Id: 'Id'
        },
        Inzien: {
            Id: 'Id'
        }
    },
    Pia: {
        Bewerken: {
            Id: 'Id'
        },
        Inzien: {
            Id: 'Id'
        }
    },
    Account: {
        Bevestigen: {
            ShortExternalReference: 'ShortExternalReference'
        },
        Activeren: {
            ActivatieCode: 'ActivatieCode'
        },
        WachtwoordHerstellen: {
            ResetCode: 'ResetCode'
        }
    }

};

export const Routes = {
    // Unknown will redirect to the React 404 page
    Unknown: {
        path: '*'
    },

    Account: {
        path: Account,
        title: 'Account',
        Aanvragen: {
            path: AccountAanvragen,
            title: 'Account aanvragen'
        },
        Aangevraagd: {
            path: AccountAangevraagd,
            title:'Account aangevraagd'
        },
        Bevestigen: {
            className: 'account-bevestigen',
            path: AccountBevestigen + `(/:${RouteParams.Account.Bevestigen.ShortExternalReference})`,
            title: 'Aanvraag bevestigen'
        },
        Bevestigd: {
            path: AccountBevestigd,
            title: 'Aanvraag bevestigd'
        },
        Activeren: {
            className: 'account-bevestigen', // TODO: clone class
            path: AccountActiveren + `(/:${RouteParams.Account.Activeren.ActivatieCode})`,
            title: 'Account activeren'
        },
        Geactiveerd: {
            path: AccountGeactiveerd,
            title: 'Account geactiveerd'
        },
        Login: {
            path: AccountLogin,
            title: 'Login'
        },
        Loguit: {
            path: AccountLoguit,
            title: 'Loguit'
        },
        WachtwoordVergeten: {
            path: AccountWachtwoordVergeten,
            title: 'Wachtwoord vergeten'
        },
        WachtwoordHerstellen: {
            className: 'account-bevestigen', // TODO: clone class
            path: AccountWachtwoordHerstellen + `(/:${RouteParams.Account.WachtwoordHerstellen.ResetCode})`,
            title: 'Wachtwoord herstellen'
        },
        WachtwoordInstellen: {
            path: AccountWachtwoordInstellen,
            title: 'Wachtwoord instellen'
        },
        Select: {
            path: AccountSelecteren,
            title: 'Account selecteren'
        },
        Koppelen: {
            path: AccountKoppelen,
            title: 'Account koppelen'
        },
        Beveiligen:{
            path: AccountBeveiligen,
            title: 'Account beveiligen'
        },
        MijnAccount:{
            path: MijnAccount,
            title: 'Mijn account'
        }

    },

    Dashboard: {
        path: Dashboard,
        title: 'Dashboard',
        Pia: {
            path: Pia,
            title: 'Privacy Impact Assessment',
            Nieuw: {
                path: PiaNieuw,
                title: 'Nieuw'
            },
            Bewerken: {
                className:'pia',
                path: PiaBewerken + `(/:${RouteParams.Pia.Bewerken.Id})`,
                title: 'Privacy Impact Assessment bewerken',
                createUrl: (id) => (`${PiaBewerken}/${id}`)
            },
            Inzien: {
                className:'pia-inzien',
                path: PiaInzien + `(/:${RouteParams.Pia.Inzien.Id})`,
                title: 'Privacy Impact Assessment inzien',
                createUrl: (id) => (`${PiaInzien}/${id}`)
            }
        },
        Incident: {
            path: Incident,
            title: 'Incidentenregister',
            Achtergrond: {
                path: IncidentAchtergrond,
                title: 'Achtergrond informatie'
            },
            Nieuw: {
                path: IncidentNieuw,
                title: 'Nieuw'
            },
            Bewerken: {
                className:'incident',
                path: IncidentBewerken + `(/:${RouteParams.Incident.Bewerken.Id})`,
                title: 'Incident bewerken',
                createUrl: (id) => (`${IncidentBewerken}/${id}`)
            },
            Inzien: {
                className:'incident-inzien',
                path: IncidentInzien + `(/:${RouteParams.Incident.Inzien.Id})`,
                title: 'Incident inzien',
                createUrl: (id) => (`${IncidentInzien}/${id}`)
            }
        },
        AlgemeneRegisterinformatie: {
            path: AlgemeneRegisterinformatie,
            title: 'Algemene Registerinformatie bewerken'
        },
        Verwerkingsregister: {
            path: Verwerkingsregister,
            title: 'Verwerkingsregister',
            Bewerken: {
                path: VerwerkingsregisterBewerken,
                title: 'Verwerkingsregister bewerken'
            },
            Toevoegen: {
                path: VerwerkingsregisterToevoegen,
                title: 'Verwerking toevoegen'
            }
        },
        Verwerkersregister: {
            path: Verwerkersregister,
            title: 'Verwerkersregister',
            Bewerken: {
                path: VerwerkersregisterBewerken,
                title: 'Verwerkersregister bewerken'
            },
            Toevoegen: {
                path: VerwerkersregisterToevoegen,
                title: 'Verwerker toevoegen'
            }
        }
    },

    Templates: {
        path: Templates,
        title: 'Templates',
        className:'templates'
    },

    Beheer: {
        Personen: {
            path: Personen,
            title: 'Personen',
            Toevoegen: {
                path: PersoonToevoegen,
                title: 'Persoon toevoegen'
            }
        }
    },

    Home: {
        path: Home,
        title: 'Homepage'
    },

    Check: {
        path: Check,
        title: 'Privacy Check'
    },

    OverOns: {
        path: OverOns,
        title: 'Waarom W.T. Privacy?'
    },

    Woordenboek: {
        path: Woordenboek,
        title: 'Woordenboek'
    },

    Error: {
        path: Error
    },

};
