import * as ActionTypes from './actionTypes';

export const clear = () => ({
    type: ActionTypes.CLEAR
});

export const getIncidents = () => ({
    type: ActionTypes.LOAD_INCIDENTS
});

export const getIncident = (id, readonly) => ({
    type: ActionTypes.GET_INCIDENT,
    id,
    readonly
});

export const createIncident = (model, frm) => ({
    type: ActionTypes.ADD_INCIDENT,
    model,
    frm
});

export const updateIncident = (id, model) => ({
    type: ActionTypes.UPDATE_INCIDENT,
    id,
    model
});

export const loadedIncident = (model) => ({
    type: ActionTypes.LOADED_INCIDENT,
    model
});

export const loadedIncidents = (model) => ({
    type: ActionTypes.LOADED_INCIDENTS,
    model
});

export const intermediateAnalysisComplete = (model) => ({
    type: ActionTypes.INTERMEDIATE_ANALYSIS_COMPLETE,
    model
});

export const addToRapport = (frm) => ({
    type: ActionTypes.ADD_TO_RAPPORT,
    frm
});

export const editRapportRow = (id) => ({
    type: ActionTypes.EDIT_RAPPORT_ROW,
    id
});

export const deleteRapportRow = (id) => ({
    type: ActionTypes.DELETE_RAPPORT_ROW,
    id
});

export const confirmedDeleteRapportRow = (id) => ({
    type: ActionTypes.CONFIRMED_DELETE_RAPPORT_ROW,
    id
});

export const cancelEditRapportRow = () => ({
    type: ActionTypes.CANCEL_EDIT_RAPPORT_ROW
});

export const confirmEditRapportRow = () => ({
    type: ActionTypes.CONFIRM_EDIT_RAPPORT_ROW
});

export const addToDocument = (frm) => ({
    type: ActionTypes.ADD_TO_DOCUMENT,
    frm
});

export const editDocumentRow = (id) => ({
    type: ActionTypes.EDIT_DOCUMENT_ROW,
    id
});

export const deleteDocumentRow = (id) => ({
    type: ActionTypes.DELETE_DOCUMENT_ROW,
    id
});

export const confirmedDeleteDocumentRow = (id) => ({
    type: ActionTypes.CONFIRMED_DELETE_DOCUMENT_ROW,
    id
});

export const cancelEditDocumentRow = () => ({
    type: ActionTypes.CANCEL_EDIT_DOCUMENT_ROW
});

export const confirmEditDocumentRow = () => ({
    type: ActionTypes.CONFIRM_EDIT_DOCUMENT_ROW
});

export const confirmClose = (id) => ({
    type: ActionTypes.CONFIRM_CLOSE,
    id
});