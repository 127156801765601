import {
    stateHelper
} from './_references';

import * as Actions from './actions';
import * as Constants from './constants';
import { STATE_KEY } from './constants';

export const mapDispatchToProps = (dispatch) => ({
    load: () => dispatch(Actions.load()),
    download: (id) => dispatch(Actions.download(id))
});

export const mapStateToProps = (state) => {

    return {
        list: stateHelper(state, [ STATE_KEY, Constants.FIELDS.LIST ], []),
        isLoading: stateHelper(state, [ Constants.STATE_KEY, Constants.FIELDS.LOADING ], false)
    };
};