import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import { Panel, FormGroup,Col } from 'react-bootstrap';
import FaIcon from 'react-fontawesome';

import {
    Container,
    CreateAccountDetailWorkingModel,
    Text,
    FieldSetup,
    Select,
    ApiRoutes,
    StamSectorWorkingModel,
    Radio,
    Password,
    CheckBox,
    Button
} from './_references';

class Aanvragen extends React.Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
    }

    render () {

        const { bind, onChange, createAccount, onValidationError } = this.props;

        return (
            <Container title="Account aanvragen">

                <Formsy ref={this.form} className="form-horizontal" data-toggle="validator" onInvalidSubmit={onValidationError} onValidSubmit={(model) => createAccount(model, this.form.current)}>

                    <Panel>
                        <Panel.Body>
                            <div className="panel-inline-title">Bedrijfsgegevens</div>

                            <Text
                                {...bind(CreateAccountDetailWorkingModel.KvKNummer)}
                                {...new FieldSetup()
                                    .for(CreateAccountDetailWorkingModel.KvKNummer)
                                    .withFriendlyName('KvK-nummer')
                                    .setLabel()
                                    .setAutoFocus()
                                    .setPlaceholder('KvK-nummer (optioneel)')
                                    .isNumeric()
                                    .minLength(9)
                                    .maxLength(12)
                                    .onChange(onChange)
                                }
                            />

                            <Text
                                {...bind(CreateAccountDetailWorkingModel.Bedrijfsnaam)}
                                {...new FieldSetup()
                                    .for(CreateAccountDetailWorkingModel.Bedrijfsnaam)
                                    .withFriendlyName('Bedrijfsnaam')
                                    .setLabel()
                                    .setPlaceholder()
                                    .isRequired()
                                    .maxLength(250)
                                    .onChange(onChange)
                                }
                            />
                            <Select
                                {...bind(CreateAccountDetailWorkingModel.SectorId)}
                                {...new FieldSetup()
                                    .for(CreateAccountDetailWorkingModel.SectorId)
                                    .withFriendlyName('Sector')
                                    .setLabel()
                                    .setPlaceholder('Sector')
                                    .isRequired()
                                    .loadFrom(ApiRoutes.StamSector)
                                    .onChange(onChange)
                                }
                                options={[
                                    {
                                        [ StamSectorWorkingModel.Id ]: '',
                                        [ StamSectorWorkingModel.Naam ]: ''
                                    }
                                ]}

                                optionKey={StamSectorWorkingModel.Id}
                                optionValue={StamSectorWorkingModel.Naam}
                                customFilter={(stamSector) => stamSector[ StamSectorWorkingModel.IsSelectable ] !== false}
                            />

                            <Text
                                {...bind(CreateAccountDetailWorkingModel.Telefoonnummer)}
                                {...new FieldSetup()
                                    .for(CreateAccountDetailWorkingModel.Telefoonnummer)
                                    .withFriendlyName('Telefoonnummer')
                                    .setLabel()
                                    .setPlaceholder()
                                    .isRequired()
                                    .maxLength(20)
                                    .isPhoneNumber()
                                    .onChange(onChange)
                                }
                            />

                            <Radio
                                {...bind(CreateAccountDetailWorkingModel.Eigenaar)}
                                {...new FieldSetup()
                                    .for(CreateAccountDetailWorkingModel.Eigenaar)
                                    .withFriendlyName('Bent u de eigenaar?')
                                    .setLabel()
                                    .setPlaceholder()
                                    .isRequired()
                                    .onChange(onChange)
                                }

                                options={[ {
                                    key: 'true', value: 'Ja'
                                },{
                                    key: 'false', value: 'Nee'
                                } ]}
                                optionKey="key"
                                optionValue="value"
                            />

                            {bind(CreateAccountDetailWorkingModel.Eigenaar).value === 'false' ?
                                <div>
                                    <Radio
                                        {...bind(CreateAccountDetailWorkingModel.EigenaarAanhef)}
                                        {...new FieldSetup()
                                            .for(CreateAccountDetailWorkingModel.EigenaarAanhef)
                                            .withFriendlyName('Aanhef')
                                            .setLabel()
                                            .setPlaceholder()
                                            .isRequired()
                                            .onChange(onChange)
                                        }

                                        options={[ {
                                            key: 'm', value: 'Dhr'
                                        },{
                                            key: 'v', value: 'Mvr'
                                        } ]}
                                        optionKey="key"
                                        optionValue="value"
                                    />

                                    <Text
                                        {...bind(CreateAccountDetailWorkingModel.EigenaarVoornaam)}
                                        {...new FieldSetup()
                                            .for(CreateAccountDetailWorkingModel.EigenaarVoornaam)
                                            .withFriendlyName('Voornaam')
                                            .setLabel()
                                            .setPlaceholder()
                                            .isRequired()
                                            .maxLength(100)
                                            .onChange(onChange)
                                        }
                                    />

                                    <Text
                                        {...bind(CreateAccountDetailWorkingModel.EigenaarTussenvoegsels)}
                                        {...new FieldSetup()
                                            .for(CreateAccountDetailWorkingModel.EigenaarTussenvoegsels)
                                            .withFriendlyName('Tussenvoegsels')
                                            .setLabel()
                                            .setPlaceholder('Tussenvoegsels (optioneel)')
                                            .maxLength(50)
                                            .onChange(onChange)
                                        }
                                    />

                                    <Text
                                        {...bind(CreateAccountDetailWorkingModel.EigenaarAchternaam)}
                                        {...new FieldSetup()
                                            .for(CreateAccountDetailWorkingModel.EigenaarAchternaam)
                                            .withFriendlyName('Achternaam')
                                            .setLabel()
                                            .setPlaceholder()
                                            .isRequired()
                                            .maxLength(100)
                                            .onChange(onChange)
                                        }
                                    />
                                </div>
                                : null}

                        </Panel.Body>
                    </Panel>
                    <Panel>
                        <Panel.Body>
                            <div className="panel-inline-title">Vestigingsadres</div>

                            <Text
                                {...bind(CreateAccountDetailWorkingModel.Straat)}
                                {...new FieldSetup()
                                    .for(CreateAccountDetailWorkingModel.Straat)
                                    .withFriendlyName('Straat')
                                    .setLabel()
                                    .setPlaceholder()
                                    .isRequired()
                                    .maxLength(75)
                                    .onChange(onChange)
                                }
                            />
                            <Text
                                {...bind(CreateAccountDetailWorkingModel.Huisnummer)}
                                {...new FieldSetup()
                                    .for(CreateAccountDetailWorkingModel.Huisnummer)
                                    .withFriendlyName('Huisnummer')
                                    .setLabel()
                                    .setPlaceholder()
                                    .isRequired()
                                    .isNumeric()
                                    .maxLength(10)
                                    .onChange(onChange)
                                }
                            />
                            <Text
                                {...bind(CreateAccountDetailWorkingModel.Toevoeging)}
                                {...new FieldSetup()
                                    .for(CreateAccountDetailWorkingModel.Toevoeging)
                                    .withFriendlyName('Toevoeging')
                                    .setLabel()
                                    .setPlaceholder()
                                    .maxLength(10)
                                    .onChange(onChange)
                                }
                            />
                            <Text
                                {...bind(CreateAccountDetailWorkingModel.Postcode)}
                                {...new FieldSetup()
                                    .for(CreateAccountDetailWorkingModel.Postcode)
                                    .withFriendlyName('Postcode')
                                    .setLabel()
                                    .setPlaceholder('Postcode (b.v. 2907 AX)')
                                    .isRequired()
                                    .maxLength(10)
                                    .matches(/^[0-9]{4}[ ]{0,1}[a-zA-Z]{2}$/, '$ is geen geldige postcode.')
                                    .onChange(onChange)
                                }
                            />
                            <Text
                                {...bind(CreateAccountDetailWorkingModel.Plaats)}
                                {...new FieldSetup()
                                    .for(CreateAccountDetailWorkingModel.Plaats)
                                    .withFriendlyName('Plaats')
                                    .setLabel()
                                    .setPlaceholder()
                                    .isRequired()
                                    .maxLength(75)
                                    .onChange(onChange)
                                }
                            />

                            <div className="form-group">
                                <label className="col-xs-12 col-sm-2 control-label">Land</label>
                                <div className="col-sm-10 col-xs-12">
                                    <div className="form-control" name="Land" placeholder="Land">Nederland</div>
                                </div>
                            </div>
                        </Panel.Body>
                    </Panel>

                    <Panel>
                        <Panel.Body>
                            <div className="panel-inline-title">Persoonlijk account &nbsp;<FaIcon data-toggle="tooltip" title="Vul hier de gegevens in van de gebruiker van de Privacy Tool. Dit kan de eigenaar zijn van het bedrijf of een verantwoordelijke die is aangesteld voor het privacybeleid binnen de organisatie. Het wachtwoord kan eventueel op en later tijdstip door de betreffende gebruiker zelf worden aangepast via het opvragen van een nieuw wachtwoord." name="question-circle"></FaIcon></div>

                            <Radio
                                {...bind(CreateAccountDetailWorkingModel.Aanhef)}
                                {...new FieldSetup()
                                    .for(CreateAccountDetailWorkingModel.Aanhef)
                                    .withFriendlyName('Aanhef')
                                    .setLabel()
                                    .setPlaceholder()
                                    .isRequired()
                                    .onChange(onChange)
                                }

                                options={[ {
                                    key: 'm', value: 'Dhr'
                                },{
                                    key: 'v', value: 'Mvr'
                                } ]}
                                optionKey="key"
                                optionValue="value"
                            />

                            <Text
                                {...bind(CreateAccountDetailWorkingModel.Voornaam)}
                                {...new FieldSetup()
                                    .for(CreateAccountDetailWorkingModel.Voornaam)
                                    .withFriendlyName('Voornaam')
                                    .setLabel()
                                    .setPlaceholder()
                                    .isRequired()
                                    .maxLength(100)
                                    .onChange(onChange)
                                }
                            />

                            <Text
                                {...bind(CreateAccountDetailWorkingModel.Tussenvoegsels)}
                                {...new FieldSetup()
                                    .for(CreateAccountDetailWorkingModel.Tussenvoegsels)
                                    .withFriendlyName('Tussenvoegsels')
                                    .setLabel()
                                    .setPlaceholder('Tussenvoegsels (optioneel)')
                                    .maxLength(50)
                                    .onChange(onChange)
                                }
                            />

                            <Text
                                {...bind(CreateAccountDetailWorkingModel.Achternaam)}
                                {...new FieldSetup()
                                    .for(CreateAccountDetailWorkingModel.Achternaam)
                                    .withFriendlyName('Achternaam')
                                    .setLabel()
                                    .setPlaceholder()
                                    .isRequired()
                                    .maxLength(100)
                                    .onChange(onChange)
                                }
                            />

                            <Text
                                {...bind(CreateAccountDetailWorkingModel.EmailAddress)}
                                {...new FieldSetup()
                                    .for(CreateAccountDetailWorkingModel.EmailAddress)
                                    .withFriendlyName('E-mailadres')
                                    .setLabel()
                                    .setPlaceholder()
                                    .isRequired()
                                    .isEmail()
                                    .maxLength(255)
                                    .onChange(onChange)
                                }
                                autoComplete="username"
                            />

                            <Text
                                {...bind(CreateAccountDetailWorkingModel.EmailAddressRepeat)}
                                {...new FieldSetup()
                                    .for(CreateAccountDetailWorkingModel.EmailAddressRepeat)
                                    .withFriendlyName('Herhaal e-mailadres')
                                    .setLabel()
                                    .setPlaceholder()
                                    .isRequired()
                                    .equalsField('E-mailadres', CreateAccountDetailWorkingModel.EmailAddress)
                                    .onChange(onChange)
                                }
                                autoComplete="username"
                            />

                            <Password
                                {...bind(CreateAccountDetailWorkingModel.Password)}
                                {...new FieldSetup()
                                    .for(CreateAccountDetailWorkingModel.Password)
                                    .withFriendlyName('Wachtwoord')
                                    .setLabel()
                                    .setPlaceholder()
                                    .isRequired()
                                    .password(8, 75)
                                    .maxLength(200)
                                    .doesNotContain('E-mail', CreateAccountDetailWorkingModel.EmailAddress)
                                    .doesNotContain('Voornaam', CreateAccountDetailWorkingModel.Voornaam)
                                    .doesNotContain('Achternaam', CreateAccountDetailWorkingModel.Achternaam)
                                    .onChange(onChange)
                                }
                                autoComplete="new-password"
                            />

                            <Password
                                {...bind(CreateAccountDetailWorkingModel.PasswordRepeat)}
                                {...new FieldSetup()
                                    .for(CreateAccountDetailWorkingModel.PasswordRepeat)
                                    .withFriendlyName('Herhaal wachtwoord')
                                    .setLabel()
                                    .setPlaceholder()
                                    .isRequired()
                                    .equalsField('Wachtwoord', CreateAccountDetailWorkingModel.Password)
                                    .onChange(onChange)
                                }
                                autoComplete="new-password"
                            />
                        </Panel.Body>
                    </Panel>
                    <Panel>
                        <Panel.Body>
                            <div className="panel-inline-title">Overige</div>

                            <Col smOffset={2} className="bs-callout bs-callout-default">
                                <h3><small>Nieuwsbrief</small></h3>
                                <CheckBox noOffset
                                    {...bind(CreateAccountDetailWorkingModel.Nieuwsbrief)}
                                    {...new FieldSetup()
                                        .for(CreateAccountDetailWorkingModel.Nieuwsbrief)
                                        .setLabel('U mag mij nieuwsbrieven sturen')
                                        .onChange(onChange)
                                    }
                                />
                            </Col>

                            <Col smOffset={2} className="bs-callout bs-callout-default">
                                <h3><small>Koppelen met uw account</small></h3>
                                <p>Indien u ondersteuning nodig heeft van een W.T. Privacy Officer is het noodzakelijk dat hij/zij gekoppeld wordt aan uw account, hier hebben wij uw toestemming voor nodig. Indien u toestemming verleent kan een W.T. Privacy Officer, wanneer u hierom verzoekt, toegang krijgen tot uw account om bijvoorbeeld mee te kijken, wijzigingen aan te brengen en/of te adviseren. U blijft te allen tijde eigenaar van uw account.</p>
                                <CheckBox noOffset
                                    {...bind(CreateAccountDetailWorkingModel.AkkoordConnectieMaken)}
                                    {...new FieldSetup()
                                        .for(CreateAccountDetailWorkingModel.AkkoordConnectieMaken)
                                        .setLabel('Ik geef een W.T. Privacy Officer toestemming om aan mijn account te koppelen')
                                        .onChange(onChange)
                                    }
                                />
                            </Col>
                        </Panel.Body>
                    </Panel>
                    <Panel>
                        <Panel.Body>
                            <div className="panel-inline-title">De kleine lettertjes</div>

                            <Col smOffset={2} className="bs-callout bs-callout-default">
                                <h3><small>Algemene voorwaarden</small></h3>
                                <p>Wij hanteren de algemene voorwaarden zoals deze zijn opgesteld in de <a href={process.env.PUBLIC_URL + '/files/WT - Nederland ICT Voorwaarden.pdf'} rel="noopener noreferrer" target="_blank">FENIT</a>.</p>

                                <p>W.T. Privacy b.v. stelt alles wat redelijk is in het werk om de beschikbaarheid van onze (on-line) tools zo hoog mogelijk te houden. Ondanks deze inspanningen kan het toch voorkomen dat onverhoopt bepaalde zaken niet en/of verminderd beschikbaar is. Voor de goede orde wijzen wij u erop dat W.T. Privacy b.v. in geen geval aansprakelijk kan worden gehouden voor eventuele gevolgen welke hieruit voortvloeien.</p>

                                <CheckBox noOffset
                                    {...bind(CreateAccountDetailWorkingModel.AkkoordVoorwaarden)}
                                    {...new FieldSetup()
                                        .for(CreateAccountDetailWorkingModel.AkkoordVoorwaarden)
                                        .withFriendlyName('Algemene voorwaarden')
                                        .setLabel('Ik ga akkoord met de algemene voorwaarden')
                                        .isRequired()
                                        .onChange(onChange)
                                    }
                                />

                            </Col>

                            <Col smOffset={2} className="bs-callout bs-callout-default">
                                <h3><small>Facturatie</small></h3>
                                <p>Facturatie zal jaarlijks per e-mail plaats vinden.</p>

                                <CheckBox noOffset
                                    {...bind(CreateAccountDetailWorkingModel.AkkoordFacturatie)}
                                    {...new FieldSetup()
                                        .for(CreateAccountDetailWorkingModel.AkkoordFacturatie)
                                        .withFriendlyName('Wijze van facturatie')
                                        .setLabel('Ik ga akkoord met de wijze van facturatie')
                                        .isRequired()
                                        .onChange(onChange)
                                    }
                                />

                            </Col>

                            <Col smOffset={2} className="bs-callout bs-callout-warning">
                                <h3><small>Persoonsgegevens</small></h3>
                                <p>
                                De persoonsgegevens &apos;Aanhef&apos;, &apos;Voornaam&apos; en &apos;Achternaam&apos; worden gebruikt voor het correct adresseren van u als klant.
                                </p>
                                <p>
                                    Het persoonsgegeven &apos;e-mail&apos; wordt gebruikt om u te identificeren, zodat u uw accountgegevens kunt inzien en toegang krijgt tot de diensten die wij leveren. Tevens wordt uw e-mailadres gebruikt om de diensten die wij leveren aan u te factureren.
                                </p>
                                <h4><small>Enkel van toepassing op zelfstandige ondernemers:</small></h4>
                                <p>
                                    Voor zelfstandig ondernemers kan het KvK-nummer, de bedrijfsnaam en het vestigingsadres aangemerkt worden als een persoonsgegeven. Het KvK-nummer wordt gebruikt om te voorkomen dat u zich meerdere keren met uw bedrijf kunt inschrijven, dit voorkomt meervoudige facturatie. De bedrijfsnaam en het vestigingsadres worden gebruikt voor de facturatie.
                                </p>
                                <CheckBox noOffset
                                    {...bind(CreateAccountDetailWorkingModel.AkkoordVerwerking)}
                                    {...new FieldSetup()
                                        .for(CreateAccountDetailWorkingModel.AkkoordVerwerking)
                                        .withFriendlyName('Akkoord gaan met de verwerking van persoonsgegevens')
                                        .setLabel('Ik ga akkoord met de verwerking van mijn persoonsgegevens')
                                        .isRequired()
                                        .onChange(onChange)
                                    }
                                />

                            </Col>

                            <Col smOffset={2} className="bs-callout bs-callout-success">
                                <h3><small>Bijzondere persoonsgegevens</small></h3>
                                <p>
                        Wij verzamelen geen bijzondere persoonsgegevens.
                                </p>
                            </Col>

                        </Panel.Body>
                    </Panel>
                    <FormGroup>
                        <Col xsHidden smOffset={10} sm={2} className="text-right">
                            <Button type="submit" bsStyle="primary">Aanvragen</Button>
                        </Col>
                        <Col smHidden mdHidden lgHidden xs={12} >
                            <Button type="submit" bsStyle="primary" block>Aanvragen</Button>
                        </Col>
                    </FormGroup>
                </Formsy>
            </Container>
        );
    }
}

Aanvragen.propTypes = {
    onChange: PropTypes.func.isRequired,
    bind: PropTypes.func.isRequired,
    createAccount: PropTypes.func.isRequired,
    onValidationError: PropTypes.func.isRequired
};

export default Aanvragen;