import { all, call, put, takeLatest, delay } from 'redux-saga/effects';
import Axios from 'axios';
import { push } from 'react-router-redux';
import { actions as ToastrActions } from 'react-redux-toastr';

import {
    Api,
    Routes,
    updateAccountInfo,
    ApiAccountKoppelen,
    Headers
} from './_references';

import * as ActionTypes from './actionTypes';
import * as Actions from './actions';

export default function* () {
    yield all([
        takeLatest(ActionTypes.LOAD, load),
        takeLatest(ActionTypes.SELECT, selectAccount),
        takeLatest(ActionTypes.UNLINK, UnlinkAccount)
    ]);
}

function* load() {
    yield delay(250);

    const response = yield call(Api.accounts);

    yield put (Actions.loaded(response.data));
}

function* selectAccount(action) {

    yield delay(250);

    Axios.defaults.headers.common[ Headers.WTPACCOUNT ] = action.accountId;

    yield put(updateAccountInfo());

    yield put(push(Routes.Dashboard.path));
}

function* UnlinkAccount(action) {

    yield delay(250);

    try{
        const result = yield call(ApiAccountKoppelen.unlinkAccount, action.accountId);

        if (action.accountId === Axios.defaults.headers.common[ Headers.WTPACCOUNT ]) {
            //Remove the account header, this will hide the menu items which cannot be used until another account is selected
            Axios.defaults.headers.common[ Headers.WTPACCOUNT ] = null;
            yield put(updateAccountInfo());
        }

        if (!result.success) {
            yield put (ToastrActions.add({
                type: 'error',
                title: 'Account ontkoppelen',
                attention: true, // This will add a shadow like the confirm method.
                message: 'Het ontkoppelen van het account is mislukt.',
                options: {
                    showCloseButton: true
                }
            }));

        }
        else {
            yield call(load);
        }
    }
    catch(e) {
        yield put (ToastrActions.add({
            type: 'error',
            title: 'Account ontkoppelen',
            attention: true, // This will add a shadow like the confirm method.
            message: e.message,
            options: {
                showCloseButton: true
            }
        }));

    }
}
