import { addValidationRule } from 'formsy-react';
import blacklist from './passwordStrength.blacklist';

// back enginered from www.passwordmeter.com with all flaws that I found

const matchCalculate = (password, regex, calculateFunction) => {
    if (password != null && password !== '') {
        const match = password.match(regex);
        //console.log(password, regex, 'match', match);
        if (match != null) {
            return calculateFunction(match.length, password.length, password);
        }
    }
    return 0;
};

const removeLeadingAndTrailing = (password) => {
    if (password != null && password.length > 2) {
        return password.substr(1, password.length - 2);
    }
    return '';
};

const upperCaseLettersRegex = /[A-Z]/g;
const lowerCaseLettersRegex = /[a-z]/g;
const letters = /[a-z]/gi;
const numbers = /[0-9]/g;
const symbols = /[$!@#%^&*(){}[\]\\/><]/g;
const spaces = /\s+/g;

const reverse = (str) => {
    var newstring = '';
    for (var s=0; s < str.length; s++) {
        newstring = str.charAt(s) + newstring;
    }
    return newstring;
};

const checkers = {
    // additions:
    numberOfCharacters: (password) => (password.length * 4),
    uppercaseLetters: (password) => ( matchCalculate(password, upperCaseLettersRegex, (matchLength, length) => ( (length - matchLength) * 2 ))),
    lowercaseLetters: (password) => ( matchCalculate(password, lowerCaseLettersRegex, (matchLength, length) => ( (length - matchLength) * 2 ))),
    numbers: (password) => ( matchCalculate(password, numbers, (matchLength, length) => (length > matchLength ? matchLength * 4 : 0))),
    symbols: (password) => ( matchCalculate(password, symbols, (matchLength) => (matchLength * 6))),
    middleNumbersOrSymbols: (password) => (
        matchCalculate(removeLeadingAndTrailing(password), symbols, (matchLength) => (matchLength * 2))
        + matchCalculate(removeLeadingAndTrailing(password), numbers, (matchLength) => (matchLength * 2))
    ),

    requirements: (password = '', minimumLength = 8) => {
        // minimal length, and at least 3 out of 4 sub requirement: [A-Z], [a-z], [0-9], [SYMBOLS]

        if (password.length < minimumLength) {
            return 0;
        }

        let result = 2;
        result += matchCalculate(password, upperCaseLettersRegex, (matchLength, length) => (length > matchLength ? 2 : 0));
        result += matchCalculate(password, lowerCaseLettersRegex, (matchLength, length) => (length > matchLength ? 2 : 0));
        result += matchCalculate(password, numbers, (matchLength, length) => (length > matchLength ? 2 : 0));
        result += matchCalculate(password, symbols, (matchLength, length) => (length > matchLength ? 2 : 0));

        return result >= 8 ? result : 0;
    },

    // deductions:
    lettersOnly: (password) => ( matchCalculate(password.replace(spaces, ''), letters, (matchLength, length) => (matchLength === length ? -password.length : 0))),
    numbersOnly: (password) => ( matchCalculate(password.replace(spaces, ''), numbers, (matchLength, length) => (matchLength === length ? -password.length : 0))),
    repeatCharacters: (password) => {

        if (password == null || password === '') {
            return 0;
        }
        const passwordArray = password.replace(spaces,'').split(/\s*/);
        const passwordLength = passwordArray.length;
        let repeatCount = 0;
        let penalty = 0;

        for (let outerIndex = 0; outerIndex < passwordLength; outerIndex += 1) {
            const outerChar = passwordArray[ outerIndex ];

            let found = false;

            for (let innerIndex = 0; innerIndex < passwordLength; innerIndex += 1) {
                const innerChar = passwordArray[ innerIndex ];

                if (outerIndex !== innerIndex && outerChar === innerChar) {
                    found = true;
                    //console.log('?', passwordArray.length, '"' + passwordArray + '"', outerIndex, innerIndex, passwordLength);
                    penalty += Math.abs(passwordLength/(innerIndex-outerIndex));
                    //console.log(penalty);
                }
            }

            if (found) {
                repeatCount++;
                const uniqueCharacters = passwordLength - repeatCount;
                //console.log('->', penalty);
                penalty = uniqueCharacters !== 0 ? Math.ceil(penalty/uniqueCharacters) : Math.ceil(penalty);
                //console.log('<-', penalty);
            }
        }

        return -penalty;
    },
    consecutiveUppercaseLetters: (password) => {
        if (password == null || password.length < 2) {
            return 0;
        }

        let result = 0;
        const pwd = password.replace(spaces, '');

        for (let index = 1; index < pwd.length; index += 1) {
            const char1 = pwd.charAt(index - 1);
            const char2 = pwd.charAt(index);

            if (char1.match(upperCaseLettersRegex) && char2.match(upperCaseLettersRegex)) {
                result -= 2;
            }
        }

        return result;
    },
    consecutiveLowercaseLetters: (password) => {
        if (password == null || password.length < 2) {
            return 0;
        }

        let result = 0;
        const pwd = password.replace(spaces, '');

        for (let index = 1; index < pwd.length; index += 1) {
            const char1 = pwd.charAt(index - 1);
            const char2 = pwd.charAt(index);

            if (char1.match(lowerCaseLettersRegex) && char2.match(lowerCaseLettersRegex)) {
                result -= 2;
            }
        }

        return result;
    },
    consecutiveNumbers: (password) => {
        if (password == null || password.length < 2) {
            return 0;
        }

        let result = 0;
        const pwd = password.replace(spaces, '');

        for (let index = 1; index < pwd.length; index += 1) {
            const char1 = pwd.charAt(index - 1);
            const char2 = pwd.charAt(index);

            if (char1.match(numbers) && char2.match(numbers)) {
                result -= 2;
            }
        }

        return result;
    },
    sequentialLetters: (password) => {
        if (password == null || password.length < 3) {
            return 0;
        }

        let result = 0;
        const sequence = '' +
            // abc's
            'abcdefghijklmnopqrstuvwxyz' +
            // qwerty keyboard (US standard)
            'qwertyuiopasdfghjklzxcvbnm' +
            // azerty (French standard)
            'azertyuiopqsdfghjklmwxcvbn' +
            // qwertz (German standard)
            'qwertzuiopasdfghjklyxcvbnm' +
            // us dvorak
            'pyfgcrlaoeuidhtnsqjkxbmwvz'
            ;

        const pwd = password.toLowerCase();

        for (let index = 0; index < (sequence.length - 2); index += 1) {
            const fwd = sequence.substr(index, 3);
            const rev = reverse(fwd);

            if (pwd.indexOf(fwd) !== -1 || pwd.indexOf(rev) !== -1) {
                result += 1;
            }
        }

        return result * -3;
    },
    sequentialNumbers: (password) => {
        if (password == null || password.length < 3) {
            return 0;
        }

        let result = 0;
        const sequence = '0123456789012';

        const pwd = password;

        for (let index = 0; index < (sequence.length - 3); index += 1) {
            const fwd = sequence.substr(index, 3);
            const rev = reverse(fwd);

            if (pwd.indexOf(fwd) !== -1 || pwd.indexOf(rev) !== -1) {
                result += 1;
            }
        }

        return result * -3;
    },
    sequentialSymbols: (password) => {
        if (password == null || password.length < 3) {
            return 0;
        }

        let result = 0;
        const sequence = '' +
        // qwerty keyboard (US standard)
        '~!@#$%^&*()_+' +
        // azerty (French standard)
        '&é"\'(-è_çà)=' +
        // qwertz (German standard)
        'º~"§$%&/()=?' +
        // us dvorak ('~!@#$%^&*(){}')
        '&*(){}'
        ;

        const pwd = password;

        for (let index = 0; index < (sequence.length - 3); index += 1) {
            const fwd = sequence.substr(index, 3);
            const rev = reverse(fwd);

            if (pwd.indexOf(fwd) !== -1 || pwd.indexOf(rev) !== -1) {
                result += 1;
            }
        }

        return result * -3;
    },
    blacklisted: (password) => {
        if (blacklist.find(bl => (password === bl)) != null) {
            // big penalty
            return -1000;
        }
        return 0;
    }
};

const strenghtCalculator = (password, minimumLength) => {

    // additions
    const lowercaseLettersResult = checkers.lowercaseLetters(password);
    const middleNumbersOrSymbolsResult = checkers.middleNumbersOrSymbols(password);
    const numberOfCharactersResult = checkers.numberOfCharacters(password);
    const numbersResult = checkers.numbers(password);
    const requirementsResult = checkers.requirements(password, minimumLength);
    const symbolsResult = checkers.symbols(password);
    const uppercaseLettersResult = checkers.uppercaseLetters(password);
    const lettersOnlyResult = checkers.lettersOnly(password);
    const numbersOnlyResult = checkers.numbersOnly(password);
    const repeatCharactersResult = checkers.repeatCharacters(password);
    const consecutiveUppercaseLettersResult = checkers.consecutiveUppercaseLetters(password);
    const consecutiveLowercaseLettersResult = checkers.consecutiveLowercaseLetters(password);
    const consecutiveNumbersResult = checkers.consecutiveNumbers(password);
    const sequentialLettersResult = checkers.sequentialLetters(password);
    const sequentialNumbersResult = checkers.sequentialNumbers(password);
    const sequentialSymbolsResult = checkers.sequentialSymbols(password);
    const blacklisted = checkers.blacklisted(password);

    /*
    console.log('numberOfCharactersResult', numberOfCharactersResult);
    console.log('uppercaseLettersResult', uppercaseLettersResult);
    console.log('lowercaseLettersResult', lowercaseLettersResult);
    console.log('numbersResult', numbersResult);
    console.log('symbolsResult', symbolsResult);
    console.log('middleNumbersOrSymbolsResult', middleNumbersOrSymbolsResult);
    console.log('requirementsResult', requirementsResult);
    console.log('lettersOnlyResult', lettersOnlyResult);
    console.log('numbersOnlyResult', numbersOnlyResult);
    console.log('repeatCharactersResult', repeatCharactersResult);
    console.log('consecutiveUppercaseLettersResult', consecutiveUppercaseLettersResult);
    console.log('consecutiveLowercaseLettersResult', consecutiveLowercaseLettersResult);
    console.log('consecutiveNumbersResult', consecutiveNumbersResult);
    console.log('sequentialLettersResult', sequentialLettersResult);
    console.log('sequentialNumbersResult', sequentialNumbersResult);
    console.log('sequentialSymbolsResult', sequentialSymbolsResult);
    */

    const result = 0 +
        lowercaseLettersResult +
        middleNumbersOrSymbolsResult +
        numberOfCharactersResult +
        numbersResult +
        requirementsResult +
        symbolsResult +
        uppercaseLettersResult +
        lettersOnlyResult +
        numbersOnlyResult +
        repeatCharactersResult +
        consecutiveUppercaseLettersResult +
        consecutiveLowercaseLettersResult +
        consecutiveNumbersResult +
        sequentialLettersResult +
        sequentialNumbersResult +
        sequentialSymbolsResult +
        blacklisted;

    return Math.max(0, Math.min(100, result));
};

const passwordStrengthValidator = (formValues, value, { minimumLength, minimumStrength }) => {
    const strength = strenghtCalculator(value, minimumLength);
    return strength >= minimumStrength;
};

passwordStrengthValidator.NAME = 'passwordStrength';

passwordStrengthValidator.register = () => {
    addValidationRule(passwordStrengthValidator.NAME, passwordStrengthValidator);
};

export default passwordStrengthValidator;
export { checkers , strenghtCalculator };