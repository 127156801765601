import React from 'react';

import DefaultDisplayComponent from '../display';
import DefaultWrapperComponent from '../displayWrapper';

const createDisplayComponent = ({
    LabelComponent = null,
    DisplayComponent = DefaultDisplayComponent,
    Wrapper = DefaultWrapperComponent
} = {}) => {
    class WrappedComponent extends React.Component {

        render() {

            return (
                <Wrapper {...this.props}
                    DisplayComponent={<DisplayComponent {...this.props} />}
                    LabelComponent={LabelComponent ? <LabelComponent {...this.props} /> : null}
                />
            );
        }
    }

    return WrappedComponent;
};

export default createDisplayComponent;