export const NAME = 'Incident';

export const STATE_KEY = `${NAME}/7bb7ddd2-493e-4a12-94f9-a338b82d585f`;

export const FIELDS = {
    DOSSIER: 'Dossier',
    LIST: 'List',
    LOADING: 'LOADING',
    RAPPORT_FRM_ID: 'RAPPORT_FRM_ID',
    RAPPORT_EDIT_ID: 'RAPPORT_EDIT_ID',
    DOCUMENT_FRM_ID: 'DOCUMENT_FRM_ID',
    DOCUMENT_EDIT_ID: 'DOCUMENT_EDIT_ID',
    EDIT_TIME: 'EDIT_TIME',
    EDIT_TEXT: 'EDIT_TEXT',
    EDIT_LINK: 'EDIT_LINK',
    EDIT_OMSCHRIJVING: 'EDIT_OMSCHRIJVING',
    DISABLE_MELDING_DOOR_WTP: 'DISABLE_MELDING_DOOR_WTP'
};

export const ACTION_OPTIONS = {
    ANALYSIS: 'ANALYSIS'
};