import React from 'react';
import { Panel, Row, Col } from 'react-bootstrap';

import {
    Container,
    Button,
    Routes
} from './_references';

const Bevestigd = () => (
    <Container>

        <Panel>
            <Panel.Body>
                <div className="panel-inline-title">Aanvraag bevestigd</div>

                Uw aanvraag voor een W.T. Privacy toolbox account is succesvol bevestigd. Hieronder leest u welke stappen u kunt verwachten:

                <ul>
                    <li>U ontvangt op dit mailadres de factuur van W.T. Privacy. ( meestal binnen 1 werkdag )</li>
                    <li>Nadat de factuur door u is voldaan, ontvangt u een mail met daarin de mededeling dat uw account is geactiveerd.</li>
                    <li>Uw toolbox is nu gereed voor gebruik.</li>
                </ul>

            </Panel.Body>
        </Panel>
        <Row>
            <Col xs={12} className="text-right">
                <Button to={Routes.Home.path} bsStyle="primary"> Terug naar de homepage</Button>
            </Col>
        </Row>

    </Container>
);

export default Bevestigd;