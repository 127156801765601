import React from 'react';
import { Panel } from 'react-bootstrap';

const Secured = () => {

    return (
        <Panel>
            <Panel.Body>
                <div className="panel-inline-title">Account beveiligd</div>

                <div>Uw account is extra beveiligd!</div>

            </Panel.Body>
        </Panel>);
};

export default Secured;