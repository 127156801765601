import {
    binder,
    Constants
} from './_references';

export const MapStateToProps = (state) => ({
    state
});

export const MergeProps = (stateProps, dispatchProps, componentProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...componentProps,

    bind: (field) => binder(componentProps.model, [ Constants.FIELDS.DETAILS ])(field)
});