import * as ActionTypes from './actionTypes';

export const loadInformatie = (source) => ({
    type: ActionTypes.LOAD_INFORMATIE,
    source
});

export const loadedInformatie = (model, source) => ({
    type: ActionTypes.LOADED_INFORMATIE,
    model,
    source
});

export const updateInformatie = (model) => ({
    type: ActionTypes.UPDATE_INFORMATIE,
    model
});