import CustomDialogComponent from './index.component';
import { connect } from 'react-redux';
import { Builder, ButtonBuilder, setupBuilder } from './dialogBuilder';

import { mapStateToProps } from './index.map';

const Dialog = connect(mapStateToProps)(CustomDialogComponent);

export {
    Builder,
    ButtonBuilder,
    Dialog,
    setupBuilder
};