import { STATE_KEY } from './constants';
import * as ActionTypes from './actionTypes';

const reducer = (state = {}, action) => {

    const { type, ...rest } = action;

    switch (type) {
    case ActionTypes.SHOW_DIALOG: {
        return rest;
    }
    case ActionTypes.HIDE_DIALOG: {
        return {};
    }
    default: {
        return state;
    }
    }

};

reducer.STATE_KEY = STATE_KEY;

export default reducer;