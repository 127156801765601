import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import FaIcon from 'react-fontawesome';
import { Panel, Row, Col, DropdownButton, MenuItem } from 'react-bootstrap';

import {
    Button,
    Constants,
    Editor,
    Inzage,
    VerwerkingInfoWorkingModel,
    Loading
} from './_references';

class VerwerkingLijst extends React.Component {

    componentDidMount() {
        this.props.loadVerwerkingen();
    }

    render() {
        const { isLoading, list, top, to, editable = false, showDetails, hideDetails, editVerwerking, deleteVerwerking, editorObject, bind, onChange, cancelEdit, updateVerwerking } = this.props;

        return (

            isLoading ? <Loading/> :
                <Panel>
                    <Panel.Body>
                        <div className="panel-inline-title">Registraties</div>

                        <Row>
                            <Col xsHidden sm={12}>
                                <Row className="row-table row-table-header">
                                    <Col xs={editable ? 5 : 7}>Verwerkingsdoel</Col>
                                    <Col xs={5}>Verantwoordelijke</Col>
                                    {editable ? <Col xs={2} className="text-right"></Col> : null}
                                </Row>
                            </Col></Row>

                        {
                            list.slice(0, top).sort((a,b) => (a[ VerwerkingInfoWorkingModel.Doel ] < b[ VerwerkingInfoWorkingModel.Doel ] ? -1 : a[ VerwerkingInfoWorkingModel.Doel ] > b[ VerwerkingInfoWorkingModel.Doel ] ? 1 : 0 )).map((verwerking, index) => (
                                [
                                    editorObject[ VerwerkingInfoWorkingModel.Id ] === verwerking[ VerwerkingInfoWorkingModel.Id ] ? null :

                                        <Row key={verwerking[ VerwerkingInfoWorkingModel.Id ]} className={`row-table row-table-${index % 2 === 0 ? 'even' : 'odd'}`}>
                                            <Col xs={12} smHidden mdHidden lgHidden className="row-table-header-title">Verwerkingsdoel</Col>
                                            <Col xs={12} sm={editable ? 5 : 7}>{verwerking[ VerwerkingInfoWorkingModel.Doel ]}</Col>
                                            <Col xs={12} smHidden mdHidden lgHidden className="row-table-header-title">Verantwoordelijke</Col>
                                            <Col xs={12} sm={5}>{verwerking[ VerwerkingInfoWorkingModel.ContactPersoon ]}</Col>
                                            {editable ?
                                                <Col xsHidden sm={2} className="text-right">

                                                    <DropdownButton title={<span><FaIcon name="file-text-o"/> Details</span>} id="bg-nested-dropdown">
                                                        { verwerking[ Constants.FIELDS.DETAILS ] == null ?
                                                            <MenuItem eventKey="1" onClick={()=>showDetails(verwerking[ VerwerkingInfoWorkingModel.Id ])}><FaIcon name="eye"/> Details tonen</MenuItem>
                                                            : <MenuItem eventKey="1" onClick={()=>hideDetails(verwerking[ VerwerkingInfoWorkingModel.Id ])}><FaIcon name="eye-slash"/> Details verbergen</MenuItem>}
                                                        <MenuItem eventKey="2" onClick={() => editVerwerking(verwerking[ VerwerkingInfoWorkingModel.Id ])}><FaIcon name="pencil"/> Bewerken</MenuItem>
                                                        <MenuItem eventKey="3" onClick={() => deleteVerwerking(verwerking[ VerwerkingInfoWorkingModel.Id ])}><FaIcon name="trash"/> Verwijderen</MenuItem>
                                                    </DropdownButton>

                                                </Col>
                                                : null }
                                            {editable ?
                                                <Col xs={12} smHidden mdHidden lgHidden className="text-right">
                                                    <div className="h-spacer"/>
                                                    { verwerking[ Constants.FIELDS.DETAILS ] == null ?
                                                        <Button block onClick={()=>showDetails(verwerking[ VerwerkingInfoWorkingModel.Id ])}><FaIcon name="eye"/> Details tonen</Button>
                                                        : <Button block onClick={()=>hideDetails(verwerking[ VerwerkingInfoWorkingModel.Id ])}><FaIcon name="eye-slash"/> Details verbergen</Button>
                                                    }
                                                    <Button block onClick={() => editVerwerking(verwerking[ VerwerkingInfoWorkingModel.Id ])}><FaIcon name="pencil"/> Bewerken</Button>
                                                    <Button bsStyle="danger" block onClick={() => deleteVerwerking(verwerking[ VerwerkingInfoWorkingModel.Id ])}><FaIcon name="trash"/> Verwijderen</Button>

                                                </Col>
                                                : null }
                                        </Row>
                                    ,
                                    !editable || editorObject[ VerwerkingInfoWorkingModel.Id ] === verwerking[ VerwerkingInfoWorkingModel.Id ] || verwerking[ Constants.FIELDS.DETAILS ] == null ? null :
                                        <Row key={`${verwerking[ VerwerkingInfoWorkingModel.Id ]}-details`} className={`row-table row-table-${index % 2 === 0 ? 'even' : 'odd'}`}>
                                            <Col><Inzage model={verwerking} /></Col>
                                        </Row>
                                    ,
                                    !editable || editorObject[ VerwerkingInfoWorkingModel.Id ] !== verwerking[ VerwerkingInfoWorkingModel.Id ] ? null :
                                        <Row key={`${verwerking[ VerwerkingInfoWorkingModel.Id ]}-details`} className={`row-table row-table-${index % 2 === 0 ? 'even' : 'odd'}`}>
                                            <Formsy ref={(frm)=> {
                                                this.frm = frm;
                                            }} className="form-horizontal" data-toggle="validator" onValidSubmit={() => {
                                                updateVerwerking();
                                            }}>
                                                <Col xs={12}><Editor {...{
                                                    bind, onChange
                                                }}/></Col>
                                                <Col xs={12} className="text-right">
                                                    <Button onClick={() => cancelEdit()}><FaIcon name="ban" /> Annuleren</Button>
                                                    <span className="v-spacer"/>
                                                    <Button type="submit"><FaIcon name="check" /> Opslaan</Button>
                                                </Col>
                                            </Formsy>
                                        </Row>
                                ]
                            ))
                        }
                        {
                            to != null && top != null && top < list.length ?
                                <Row><Col xs={12} className="row-table-footer"><i>Alleen de {top} (van {list.length}) meest recente toevoegingen worden hier getoond.</i></Col></Row>
                                : null
                        }
                    </Panel.Body>
                </Panel>
        );
    }
}

VerwerkingLijst.propTypes = {
    isLoading: PropTypes.bool,
    loadVerwerkingen: PropTypes.func.isRequired,
    list: PropTypes.array,
    top: PropTypes.number,
    to: PropTypes.string,
    editable: PropTypes.bool,
    showDetails: PropTypes.func.isRequired,
    hideDetails: PropTypes.func.isRequired,
    editVerwerking: PropTypes.func.isRequired,
    deleteVerwerking: PropTypes.func.isRequired,
    bind: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    cancelEdit: PropTypes.func.isRequired,
    updateVerwerking: PropTypes.func.isRequired,
    editorObject: PropTypes.object
};

export default VerwerkingLijst;