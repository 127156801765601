import React from 'react';
import PropTypes from 'prop-types';
import { Panel, Row, Col } from 'react-bootstrap';
import Formsy from 'formsy-react';

import {
    createFormsyInputComponent,
    SimpleValidationWrapper,
    TextComponent,
    Container,
    AccountActiverenDetailWorkingModel,
    RouteParams,
    FieldSetup,
    Button
} from './_references';

const Text = createFormsyInputComponent({
    Wrapper: SimpleValidationWrapper,
    InputComponent: TextComponent
});

class Activeren extends React.Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
    }

    render() {

        const { bind, onChange, params, confirm } = this.props;

        return(
            <Container>
                <Formsy ref={this.form} onValidSubmit={(model)=>{
                    model[ AccountActiverenDetailWorkingModel.ActivationCode ] = params[ RouteParams.Account.Activeren.ActivatieCode ];
                    confirm(model, this.form.current);
                }}>
                    <Panel>
                        <Panel.Body>
                            <div className="panel-inline-title">Account activatie</div>

                            <div>In de e-mail die u heeft ontvangen staat een activatiecode. Vul deze code hieronder in en klik vervolgens op de knop &apos;Activeren&apos;.</div>

                            <div className="text-center">
                                <Text
                                    wrapperClassName="display-inline-block"
                                    {...bind(AccountActiverenDetailWorkingModel.ActivationTokenFirstPart)}
                                    {...new FieldSetup()
                                        .for(AccountActiverenDetailWorkingModel.ActivationTokenFirstPart)
                                        .isRequired()
                                        .maxLength(4)
                                        .onChange(onChange)
                                    }
                                />
                                <Text
                                    {...bind(AccountActiverenDetailWorkingModel.ActivationTokenSecondPart)}
                                    {...new FieldSetup()
                                        .for(AccountActiverenDetailWorkingModel.ActivationTokenSecondPart)
                                        .isRequired()
                                        .maxLength(4)
                                        .onChange(onChange)
                                    } wrapperClassName="display-inline-block" />
                            </div>

                        </Panel.Body>
                    </Panel>
                    <Row>
                        <Col xs={12} className="text-right">
                            <Button bsStyle="primary" type="submit"> Activeren</Button>
                        </Col>
                    </Row>
                </Formsy>
            </Container>
        );
    }
}

Activeren.propTypes = {
    bind: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    confirm: PropTypes.func.isRequired,
    params: PropTypes.object
};

export default Activeren;