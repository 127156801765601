
import * as Constants from './constants';
import * as ActionTypes from './actionTypes';

const reducer = (state = '', action) => {
    switch (action.type) {
    case ActionTypes.NIEUWS_LOADED:
    {
        const newState = Object.assign ({}, state, {
            [ Constants.FIELDS.nieuwsLijst ]: action.data
        });

        return newState;
    }
    default:
        return state;
    }
};

reducer.STATE_KEY = Constants.STATE_KEY;

export default reducer;