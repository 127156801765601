import 'babel-es6-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import { FeatureToggleProvider } from 'react-feature-toggles';

//import registerServiceWorker from './registerServiceWorker';
import loadConfiguration from './configuration/loadConfiguration';

import createLogger from './util/log';
import configureRouter from './configuration/configureRouter';
import configureStore from './configuration/configureStore';

import { accountInformation } from './api/account';
import * as AuthenticationActions from './modules/shared/authentication/actions';

import { Dialog } from './structure/dialog';

import moment from 'moment';
import 'moment/locale/nl';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import './css/bscallout.css';
import './css/root.css';
import './css/print.css';

moment.locale('nl');

const logger = createLogger('Application');
logger.info('Bootstrapping application by loading configuration.');

// functie voor het daadwerkelijk starten van de applicatie
export const startApplication = (store, config) => {
    logger.info('Starting router configuration.');
    const Router = configureRouter(store);

    logger.info('Start rendering the router.');
    ReactDOM.render(
        <Provider store={store}>
            <FeatureToggleProvider featureToggleList={config.featureToggles}>
                <Router />
            </FeatureToggleProvider>
        </Provider>,
        document.getElementById('root')
    );

    // adds the modal dialog
    logger.info('Start rendering the modal dialog and toastr framework.');
    ReactDOM.render(
        <Provider store={store}>
            <div>
                <Dialog />
                <ReduxToastr
                    timeOut={5000}
                    newestOnTop={false}
                    preventDuplicates
                    transitionIn="fadeIn"
                    transitionOut="fadeOut"
                    progressBar
                    removeOnHover
                    showCloseButton
                />
            </div>
        </Provider>,
        document.getElementById('modalcontainer')
    );

    logger.info('finished loading.');
};

// loads the configuration (from public folder)
// provides the config to functions that need the config and cannot read it from the store
// and when the config is loaded and distributed, start the application
/* istanbul ignore next : internal working will not be covered */
loadConfiguration((config, store) => {
    logger.info('Configuration loaded.');
    logger.info('Config received.', config);
    logger.info('Store received.', store);
    logger.info('Environment', process.env.NODE_ENV);

    try {
        accountInformation({
            suppressErrors : true
        })
            .then (result => {
                if (result.status === 200) {
                    store.dispatch(AuthenticationActions.setLoggedInState(true, result.data));
                }
                startApplication(store, config);
            } )
            .catch(() => {
                // niet ingelogd/ of we kunnen het niet bepalen
                startApplication(store, config);
            });
        // TODO: Checken wat er fout gaat als we een 500 terug krijgen -> gaat die dan naar de algemene catch?
    }
    catch (err) {
        logger.info('Unable to bootstrap the application', err);
        ReactDOM.render(
            <div>De applicatie is momenteel niet beschikbaar</div>,
            document.getElementById('root')
        );
    }

}, configureStore);

// registerServiceWorker();
