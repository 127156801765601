import * as ActionTypes from './actionTypes';

export const load = (filter) => ({
    type: ActionTypes.LOAD,
    filter
});

export const loaded = (data) => ({
    type: ActionTypes.LOADED,
    data
});

export const clear = () => ({
    type: ActionTypes.CLEAR
});