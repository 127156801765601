import * as Actions from './actions';
import * as Constants from './constants';
import stateManager from '../../util/stateManager';
import { ResultWrapper } from '../../model';

export const mapStateToProps = stateManager([ Constants.STATE_KEY ], (get) => ({
    dictionary: get([ Constants.FIELDS.Dictionary, ResultWrapper.Result ])
}));

export const mapDispatchToProps = (dispatch) => ({
    load: (filter) => dispatch(Actions.load(filter))
});