import React from 'react';
import { Panel, Row, Col } from 'react-bootstrap';

import {
    Container,
    Button,
    Routes
} from './_references';

const Bevestigd = () => (
    <Container>

        <Panel>
            <Panel.Body>
                <div className="panel-inline-title">Account geactiveerd</div>

                Het account is geactiveerd, de klant ontvangt hierover een e-mail.

            </Panel.Body>
        </Panel>
        <Row>
            <Col xs={12} className="text-right">
                <Button to={Routes.Home.path} bsStyle="primary"> Terug naar de homepage</Button>
            </Col>
        </Row>

    </Container>
);

export default Bevestigd;