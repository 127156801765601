import React from 'react';
import { Col } from 'react-bootstrap';

const DefaultLabelComponent = ({ id, label, xs = 12, sm = 2 }) => (
    label ? <Col className="control-label text-right" xs={xs} sm={sm} ><label htmlFor={id} >{label}</label></Col> : null
);

const LoginLabelComponent = ({ id, label }) => (
    label ? <Col className="control-label text-right" xs={12} sm={3}><label htmlFor={id}>{label}</label></Col> : null
);

const PlainTextLabelComponent = ({ id, label }) => (
    label ? <span id={id}>{label}</span> : null
);

const RadioLabelComponent = ({ id, label }) => (
    label ? <Col className="text-right" xs={12} sm={2}><label htmlFor={id} className="control-label control-label-radio">{label}</label></Col> : null
);

const DisplayLabelComponent = ({ id, label }) => (
    label ? <Col className="display-label" xs={12} >{label}</Col> : null
);

export default DefaultLabelComponent;

export {
    LoginLabelComponent,
    PlainTextLabelComponent,
    RadioLabelComponent,
    DisplayLabelComponent
};