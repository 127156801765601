import React from 'react';
import PropTypes from 'prop-types';

import {
    VerwerkerDetailWorkingModel,
    FieldSetup,
    Display as Text, Display as DateTime
} from './_references';

const VerwerkersregisterInzage = ({ bind }) => (
    <div>
        <Text
            {...bind(VerwerkerDetailWorkingModel.NaamOrganisatie)}
            {...new FieldSetup()
                .for(VerwerkerDetailWorkingModel.NaamOrganisatie)
                .withFriendlyName('Naam organisatie')
                .setLabel()
                .setPlaceholder()
            }
        />

        <Text
            {...bind(VerwerkerDetailWorkingModel.ContactPersoon)}
            {...new FieldSetup()
                .for(VerwerkerDetailWorkingModel.ContactPersoon)
                .withFriendlyName('Contactpersoon')
                .setLabel()
                .setPlaceholder()
            }
        />

        <Text
            {...bind(VerwerkerDetailWorkingModel.EmailAddress)}
            {...new FieldSetup()
                .for(VerwerkerDetailWorkingModel.EmailAddress)
                .withFriendlyName('E-mailadres')
                .setLabel()
                .setPlaceholder()
            }
        />

        <Text
            {...bind(VerwerkerDetailWorkingModel.Doel)}
            {...new FieldSetup()
                .for(VerwerkerDetailWorkingModel.Doel)
                .withFriendlyName('Doel')
                .setLabel('Doel')
                .setPlaceholder('Doel van de gegevensverwerker')
            }
        />

        <Text
            {...bind(VerwerkerDetailWorkingModel.Persoonsgegevens)}
            {...new FieldSetup()
                .for(VerwerkerDetailWorkingModel.Persoonsgegevens)
                .withFriendlyName('Persoonsgegevens')
                .setLabel()
                .setPlaceholder('Welke persoonsgegevens worden verwerkt?')
            }
        />

        <DateTime
            {...bind(VerwerkerDetailWorkingModel.DatumOvereenkomst)}
            {...new FieldSetup()
                .for(VerwerkerDetailWorkingModel.DatumOvereenkomst)
                .setLabel('Datum verwerkingsovereenkomst')
                .withFriendlyName('Per welke datum heeft u een verwerkingsovereenkomst')
            }
            dateOnly
        />
    </div>
);

VerwerkersregisterInzage.propTypes = {
    bind: PropTypes.func.isRequired
};

export default VerwerkersregisterInzage;