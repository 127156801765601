import * as React from 'react';
import * as Actions from './actions';
//import { Button } from 'react-bootstrap';
import { Button } from './_references';

class InternalDialogButtonBuilder {
    configuration = {};

    constructor() {
        this.configuration = {
            baseComponent: Button,
            props: {
                className: 'btn-dialog-btn',
                bsStyle: 'default'
            },
            onClick: () => {
                if (__store && __store.dispatch) {
                    __store.dispatch(Actions.HideDialog());
                }
            }
        };
    }

    setButtonComponent(component) {
        this.configuration = {
            ...this.configuration,
            baseComponent: component
        };

        return this;
    }

    withTitle(title) {
        this.configuration = {
            ...this.configuration,
            title
        };
        return this;
    }

    onClick(...actionsOrMethods) {
        this.configuration = {
            ...this.configuration,
            onClick: () => {
                if (__store && __store.dispatch) {
                    __store.dispatch(Actions.HideDialog( ...actionsOrMethods ));
                }
            }
        };
        return this;
    }

    asBsPrimary() {
        return this.addProp('bsStyle', 'primary');
    }

    asBsSecondary() {
        return this.addProp('bsStyle', 'default');
    }

    asBsSuccess() {
        return this.addProp('bsStyle', 'success');
    }

    asBsDanger() {
        return this.addProp('bsStyle', 'danger');
    }

    asBsWarning() {
        return this.addProp('bsStyle', 'warning');
    }

    asBsInfo() {
        return this.addProp('bsStyle', 'info');
    }

    withClassName(className) {
        return this.addProp('className', className);
    }

    addProp(prop, value){
        const { props = {} } = this.configuration;
        this.configuration = {
            ...this.configuration,
            props: {
                ...props,
                [ prop ]: value
            }
        };
        return this;
    }

    construct() {
        const Component = this.configuration.baseComponent;

        return (<Component
            onClick={this.configuration.onClick}
            {...this.configuration.props}
        >{this.configuration.title}</Component>);
    }

}

export class DialogButtonBuilder {
    setup() {
        return new InternalDialogButtonBuilder();
    }

    ok() {
        return new InternalDialogButtonBuilder()
            .withTitle('OK')
            .asBsPrimary();
    }

    annuleer() {
        return new InternalDialogButtonBuilder()
            .withTitle('Annuleer')
            .asBsSecondary();
    }

    ja() {
        return new InternalDialogButtonBuilder()
            .withTitle('Ja')
            .asBsPrimary();
    }

    nee() {
        return new InternalDialogButtonBuilder()
            .withTitle('Nee')
            .asBsSecondary();
    }
}

export const ButtonBuilder = new DialogButtonBuilder();

const __defaultStore = {
    dispatch: () => {
        console.warn('Run setupBuilder from the customDialog first before trying to use the dialogs.');
    }
};

let __store = __defaultStore;

class InternalDialogBuilder {

    configuration = {};

    /**
     * Constructs a new DialogBuilder
     *
     */
    constructor() {

        this.configuration = {
            show: true,
            onHide: () => {
                if (__store && __store.dispatch) {
                    __store.dispatch(Actions.HideDialog());
                }
            },
            buttons: [ ButtonBuilder.ok().construct() ]
        };

    }

    withTitle(title) {
        this.configuration = {
            ...this.configuration,
            title
        };
        return this;
    }

    withButtons(buttons) {
        const actualButtons = (Array.isArray(buttons) ? buttons: [ buttons ])
            .map(b => {
            // making sure a lazy coder (moi) gets a button anyway
                if (b instanceof InternalDialogButtonBuilder) {
                    return b.construct();
                }
                return b;
            });

        this.configuration = {
            ...this.configuration,
            buttons: actualButtons
        };
        return this;
    }

    withBody(body, bodyItems) {
        this.configuration = {
            ...this.configuration,
            body,
            bodyItems
        };
        return this;
    }

    onHide(action) {
        if (typeof action === 'function') {
            this.configuration = {
                ...this.configuration,
                onHide: action
            };
        }
        return this;
    }

    show() {
        // prepare and show
        // the setTimeout makes sure you can ALSO use it in a reducer >:-)
        setTimeout(() => this.prepare()(), 0);
    }

    prepare() {
        const localConfig = this.configuration;

        return () => {
            if (__store && __store.dispatch) {
                __store.dispatch(Actions.ShowDialog(localConfig));
            }
        };
    }
}

export class DialogBuilder {
    setup() {
        return new InternalDialogBuilder();
    }

    confirmDelete( {
        title = 'Item verwijderen?',
        body = 'Weet u zeker dat u dit item wilt verwijderen?',
        onJa = undefined,
        onNee = undefined
    } = {} ) {
        return new InternalDialogBuilder()
            .withTitle(title)
            .withBody(body)
            .withButtons([
                ButtonBuilder.ja().onClick(onJa),
                ButtonBuilder.nee().onClick(onNee)
            ])
        ;
    }

    ok( {
        title = 'Ok',
        body = 'Ok',
        items = undefined,
        onOk = undefined
    } = {}) {
        return new InternalDialogBuilder()
            .withTitle(title)
            .withBody(body, items)
            .withButtons([
                ButtonBuilder.ok().onClick(onOk)
            ])
        ;
    }
}

export const Builder = new DialogBuilder();

export const setupBuilder = (store) => {
    __store = store;
};
