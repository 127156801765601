import React from 'react';
import { Col, Row } from 'react-bootstrap';
import FaIcon from 'react-fontawesome';

import {
    Container,
    Button,
    Routes
} from './_references';

import VerwerkingsregisterLijst from './lijst';

// https://autoriteitpersoonsgegevens.nl/nl/onderwerpen/avg-nieuwe-europese-privacywetgeving/verantwoordingsplicht?qa=verwerkingsregister&scrollto=1

const Verwerkingsregister = () => (
    <Container>
        <Row>

            <Col sm={12} xs={12}>

                <VerwerkingsregisterLijst editable/>

                <Row>
                    <Col xs={12} className="text-right">
                        <Button to={Routes.Dashboard.Verwerkingsregister.Toevoegen.path}><FaIcon name="plus" /> Verwerking toevoegen</Button>
                    </Col>
                </Row>

            </Col>
        </Row>
    </Container>
);

export default Verwerkingsregister;