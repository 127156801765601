import * as ActionTypes from './actionTypes';

export const secureAccount = () => ({
    type: ActionTypes.SECURE_ACCOUNT
});

export const secureAccountLoaded = (data) => ({
    type: ActionTypes.SECURE_ACCOUNT_LOADED,
    data
});

export const addSecurity = (model, form) => ({
    type: ActionTypes.ADD_SECURITY,
    model,
    form
});

export const clear = () => ({
    type: ActionTypes.CLEAR
});
