import React from 'react';
import DocumentBody from '../util/documentBody';
import { LOADING_CLASSNAME } from '../universe/molecules/loading/constants';
// import Container from '../universe/molecules/container';

import { routerLogger } from './_references';

// private method for the configureRouter
const onCreateElement = (Component, props) => {
    // setting the class on the body

    const classes = props.routes.filter((r) => r.className != null && r.className !== '' ).map((r) => {
        return r.className;
    });

    // classes.unshift('student');
    if (DocumentBody.hasClass(LOADING_CLASSNAME)) {
        classes.push(LOADING_CLASSNAME);
    }

    DocumentBody.clearAllExcept(classes);
    classes.forEach((c) => {
        if (!DocumentBody.hasClass(c)) {
            DocumentBody.addClass(c);
        }
    });

    routerLogger.log(`Creating component  ${Component.displayName || `Unnamed component on ${props.route.path}`} with${props.route.className ? ` style class ${props.route.className}` : 'out style class'  }`);

    return (
        <Component {...props} />
    );
};

export default onCreateElement;