import { all, call, put, takeEvery, delay } from 'redux-saga/effects';
import fileDownload from 'js-file-download';

import {
    API,
} from './_references';

import * as ActionTypes from './actionTypes';
import * as Actions from './actions';

export default function* () {
    yield all([
        takeEvery(ActionTypes.LOAD, load),
        takeEvery(ActionTypes.DOWNLOAD, download),
    ]);
}

function* load() {
    try {
        const templates = yield call (API.getTemplates);
        if (templates.success) {
            yield put (Actions.loaded(templates.data));
        }
        else {
            //TODO
        }
    }
    catch (e) {
        // TODO:
    }
}

const download = function* (action ) {
    yield delay(250);

    const response = yield call(API.getTemplate, action.id);

    const fileName = response.headers[ 'download-file-name' ];

    yield call (fileDownload, response.data, fileName);
};