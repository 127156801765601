/*
    This file is generated on 12 May 2020 by the CreateJsWorkingModels T4 template, located in WTPrivacy.Model project.
*/
export const AccountActiverenDetailWorkingModel = {
    /**
     * CSharp Type: System.String
     */
    ActivationCode: 'activationCode',
    /**
     * CSharp Type: System.String
     */
    ActivationTokenFirstPart: 'activationTokenFirstPart',
    /**
     * CSharp Type: System.String
     */
    ActivationTokenSecondPart: 'activationTokenSecondPart',
    /**
     * CSharp Type: System.Boolean
     */
    Success: 'success'
};

export const AccountActiverenWorkingModel = {
    /**
     * CSharp Type: System.Boolean
     */
    Success: 'success'
};

export const AccountBeveiligenResultWorkingModel = {
    /**
     * CSharp Type: System.Boolean
     */
    Success: 'success'
};

export const AccountBeveiligenWorkingModel = {
    /**
     * CSharp Type: System.String
     */
    ExternalReference: 'externalReference',
    /**
     * CSharp Type: System.String
     */
    Secret: 'secret',
    /**
     * CSharp Type: System.String
     */
    BarcodeUrl: 'barcodeUrl',
    /**
     * CSharp Type: System.String
     */
    Token: 'token'
};

export const AccountBevestigdWorkingModel = {
    /**
     * CSharp Type: System.Boolean
     */
    Success: 'success'
};

export const AccountBevestigenDetailWorkingModel = {
    /**
     * CSharp Type: System.String
     */
    ShortExternalReference: 'shortExternalReference',
    /**
     * CSharp Type: System.String
     */
    ShortConfirmationTokenFirstPart: 'shortConfirmationTokenFirstPart',
    /**
     * CSharp Type: System.String
     */
    ShortConfirmationTokenSecondPart: 'shortConfirmationTokenSecondPart',
    /**
     * CSharp Type: System.Boolean
     */
    Success: 'success'
};

export const AccountCreatedWorkingModel = {

};

export const AccountInformationWorkingModel = {
    /**
     * CSharp Type: System.Boolean
     */
    SelectAccount: 'selectAccount',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId',
    /**
     * CSharp Type: WTPrivacy.Model.User.UserInformationWorkingModel
     */
    UserInformation: 'userInformation',
    /**
     * CSharp Type: WTPrivacy.Model.User.UserSecurityWorkingModel
     */
    UserSecurity: 'userSecurity',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AccountId: 'accountId',
    /**
     * CSharp Type: System.String
     */
    Bedrijfsnaam: 'bedrijfsnaam'
};

export const AccountKoppelenDeleteWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    AccountId: 'accountId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId',
    /**
     * CSharp Type: System.Boolean
     */
    Success: 'success'
};

export const AccountKoppelenDetailWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    AccountId: 'accountId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId'
};

export const AccountsWorkingModel = {
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId',
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.Account.AccountWorkingModel>
     */
    Accounts: 'accounts'
};

export const AccountWorkingModel = {
    /**
     * CSharp Type: System.String
     */
    Bedrijfsnaam: 'bedrijfsnaam',
    /**
     * CSharp Type: System.Guid
     */
    AccountId: 'accountId',
    /**
     * CSharp Type: System.Boolean
     */
    MagConnectieMaken: 'magConnectieMaken'
};

export const CreateAccountDetailWorkingModel = {
    /**
     * CSharp Type: System.String
     */
    KvKNummer: 'kvKNummer',
    /**
     * CSharp Type: System.String
     */
    Bedrijfsnaam: 'bedrijfsnaam',
    /**
     * CSharp Type: System.String
     */
    Telefoonnummer: 'telefoonnummer',
    /**
     * CSharp Type: System.Guid
     */
    SectorId: 'sectorId',
    /**
     * CSharp Type: System.String
     */
    EigenaarAanhef: 'eigenaarAanhef',
    /**
     * CSharp Type: System.String
     */
    EigenaarVoornaam: 'eigenaarVoornaam',
    /**
     * CSharp Type: System.String
     */
    EigenaarTussenvoegsels: 'eigenaarTussenvoegsels',
    /**
     * CSharp Type: System.String
     */
    EigenaarAchternaam: 'eigenaarAchternaam',
    /**
     * CSharp Type: System.Boolean
     */
    Eigenaar: 'eigenaar',
    /**
     * CSharp Type: System.String
     */
    Straat: 'straat',
    /**
     * CSharp Type: System.String
     */
    Huisnummer: 'huisnummer',
    /**
     * CSharp Type: System.String
     */
    Toevoeging: 'toevoeging',
    /**
     * CSharp Type: System.String
     */
    Postcode: 'postcode',
    /**
     * CSharp Type: System.String
     */
    Plaats: 'plaats',
    /**
     * CSharp Type: System.String
     */
    Aanhef: 'aanhef',
    /**
     * CSharp Type: System.String
     */
    Voornaam: 'voornaam',
    /**
     * CSharp Type: System.String
     */
    Tussenvoegsels: 'tussenvoegsels',
    /**
     * CSharp Type: System.String
     */
    Achternaam: 'achternaam',
    /**
     * CSharp Type: System.String
     */
    EmailAddress: 'emailAddress',
    /**
     * CSharp Type: System.String
     */
    EmailAddressRepeat: 'emailAddressRepeat',
    /**
     * CSharp Type: System.String
     */
    Password: 'password',
    /**
     * CSharp Type: System.String
     */
    PasswordRepeat: 'passwordRepeat',
    /**
     * CSharp Type: System.Boolean
     */
    Nieuwsbrief: 'nieuwsbrief',
    /**
     * CSharp Type: System.Boolean
     */
    AkkoordVoorwaarden: 'akkoordVoorwaarden',
    /**
     * CSharp Type: System.Boolean
     */
    AkkoordFacturatie: 'akkoordFacturatie',
    /**
     * CSharp Type: System.Boolean
     */
    AkkoordVerwerking: 'akkoordVerwerking',
    /**
     * CSharp Type: System.Boolean
     */
    AkkoordConnectieMaken: 'akkoordConnectieMaken'
};

export const DashboardWorkingModel = {
    /**
     * CSharp Type: System.Int32
     */
    PiaPercentageDone: 'piaPercentageDone'
};

export const DictionaryWorkingModel = {
    /**
     * CSharp Type: System.String
     */
    Word: 'word',
    /**
     * CSharp Type: System.String
     */
    Definition: 'definition',
    /**
     * CSharp Type: System.String
     */
    Alias: 'alias',
    /**
     * CSharp Type: System.String
     */
    SearchField: 'searchField'
};

export const ForgotPasswordResultWorkingModel = {
    /**
     * CSharp Type: System.Boolean
     */
    Success: 'success'
};

export const ForgotPasswordWorkingModel = {
    /**
     * CSharp Type: System.String
     */
    Emailadres: 'emailadres',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AccountId: 'accountId'
};

export const IncidentAnalyseAntwoordWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    AntwoordId: 'antwoordId',
    /**
     * CSharp Type: System.String
     */
    Antwoord: 'antwoord',
    /**
     * CSharp Type: System.Int32
     */
    Volgorde: 'volgorde'
};

export const IncidentAnalyseConclusieWorkingModel = {
    /**
     * CSharp Type: System.String
     */
    Tekst: 'tekst',
    /**
     * CSharp Type: System.String
     */
    KleurCode: 'kleurCode',
    /**
     * CSharp Type: System.Boolean
     */
    Meldplicht: 'meldplicht'
};

export const IncidentAnalyseDetailWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    VragenlijstId: 'vragenlijstId',
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.Incident.IncidentAnalyseVraagAntwoordDetailWorkingModel>
     */
    Antwoorden: 'antwoorden'
};

export const IncidentAnalyseVraagAntwoordDetailWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    VraagId: 'vraagId',
    /**
     * CSharp Type: System.Guid
     */
    AntwoordId: 'antwoordId'
};

export const IncidentAnalyseVraagAntwoordWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    VraagId: 'vraagId',
    /**
     * CSharp Type: System.Guid
     */
    AntwoordId: 'antwoordId'
};

export const IncidentAnalyseVraagWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    VraagId: 'vraagId',
    /**
     * CSharp Type: System.String
     */
    Vraag: 'vraag',
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.Incident.IncidentAnalyseAntwoordWorkingModel>
     */
    Antwoorden: 'antwoorden',
    /**
     * CSharp Type: System.Int32
     */
    Volgorde: 'volgorde',
    /**
     * CSharp Type: WTPrivacy.Model.Incident.IncidentAnalyseConclusieWorkingModel
     */
    Conclusie: 'conclusie'
};

export const IncidentAnalyseWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    VragenlijstId: 'vragenlijstId',
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.Incident.IncidentAnalyseVraagWorkingModel>
     */
    Vragen: 'vragen',
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.Incident.IncidentAnalyseVraagAntwoordWorkingModel>
     */
    Antwoorden: 'antwoorden'
};

export const IncidentDetailPmtWorkingModel = {
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AccountId: 'accountId',
    /**
     * CSharp Type: System.String
     */
    Bedrijfsnaam: 'bedrijfsnaam',
    /**
     * CSharp Type: WTPrivacy.Model.Incident.IncidentStatus
     */
    Status: 'status'
};

export const IncidentDetailUpdateWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    Id: 'id',
    /**
     * CSharp Type: System.String
     */
    Naam: 'naam',
    /**
     * CSharp Type: System.String
     */
    Beschrijving: 'beschrijving',
    /**
     * CSharp Type: System.Nullable<System.DateTimeOffset>
     */
    DatumIncident: 'datumIncident',
    /**
     * CSharp Type: System.Nullable<System.Boolean>
     */
    MeldingDoorWTP: 'meldingDoorWTP',
    /**
     * CSharp Type: System.Nullable<System.Boolean>
     */
    Afgesloten: 'afgesloten',
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.Incident.IncidentAnalyseVraagAntwoordDetailWorkingModel>
     */
    Antwoorden: 'antwoorden',
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.Incident.IncidentRapportDetailWorkingModel>
     */
    Rapport: 'rapport',
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.Incident.IncidentDocumentLinkDetailWorkingModel>
     */
    Document: 'document',
    /**
     * CSharp Type: System.String
     */
    Meldingsnummer: 'meldingsnummer',
    /**
     * CSharp Type: System.String
     */
    Conclusie: 'conclusie',
    /**
     * CSharp Type: System.String
     */
    EindConclusie: 'eindConclusie',
    /**
     * CSharp Type: System.String
     */
    VervolgActies: 'vervolgActies',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AccountId: 'accountId'
};

export const IncidentDetailWorkingModel = {
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId',
    /**
     * CSharp Type: System.String
     */
    Naam: 'naam',
    /**
     * CSharp Type: System.String
     */
    Beschrijving: 'beschrijving',
    /**
     * CSharp Type: System.Nullable<System.DateTimeOffset>
     */
    DatumIncident: 'datumIncident',
    /**
     * CSharp Type: System.Guid
     */
    VragenlijstId: 'vragenlijstId',
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.Incident.IncidentRapportDetailWorkingModel>
     */
    Rapport: 'rapport',
    /**
     * CSharp Type: System.String
     */
    Meldingsnummer: 'meldingsnummer',
    /**
     * CSharp Type: System.String
     */
    Conclusie: 'conclusie',
    /**
     * CSharp Type: System.String
     */
    EindConclusie: 'eindConclusie',
    /**
     * CSharp Type: System.String
     */
    VervolgActies: 'vervolgActies',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AccountId: 'accountId'
};

export const IncidentDocumentLinkDetailWorkingModel = {
    /**
     * CSharp Type: System.String
     */
    DocumentLink: 'documentLink',
    /**
     * CSharp Type: System.String
     */
    DocumentOmschrijving: 'documentOmschrijving'
};

export const IncidentDocumentLinkWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    Id: 'id',
    /**
     * CSharp Type: System.String
     */
    DocumentLink: 'documentLink',
    /**
     * CSharp Type: System.String
     */
    DocumentOmschrijving: 'documentOmschrijving'
};

export const IncidentInfoWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    IncidentId: 'incidentId',
    /**
     * CSharp Type: System.String
     */
    Naam: 'naam',
    /**
     * CSharp Type: System.DateTimeOffset
     */
    DatumIncident: 'datumIncident',
    /**
     * CSharp Type: System.String
     */
    Status: 'status',
    /**
     * CSharp Type: System.Nullable<System.Boolean>
     */
    Afgesloten: 'afgesloten'
};

export const IncidentListPmtWorkingModel = {
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AccountId: 'accountId',
    /**
     * CSharp Type: WTPrivacy.Model.Incident.IncidentStatus
     */
    WhereStatusEquals: 'whereStatusEquals',
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.Incident.IncidentPmtWorkingModel>
     */
    Data: 'data'
};

export const IncidentPmtUpdateWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    Id: 'id',
    /**
     * CSharp Type: WTPrivacy.Model.Incident.IncidentStatus
     */
    NewStatus: 'newStatus',
    /**
     * CSharp Type: System.String
     */
    Comment: 'comment'
};

export const IncidentPmtWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    Id: 'id',
    /**
     * CSharp Type: System.String
     */
    Naam: 'naam',
    /**
     * CSharp Type: System.DateTimeOffset
     */
    DatumIncident: 'datumIncident',
    /**
     * CSharp Type: WTPrivacy.Model.Incident.IncidentStatus
     */
    Status: 'status',
    /**
     * CSharp Type: System.String
     */
    Bedrijfsnaam: 'bedrijfsnaam'
};

export const IncidentRapportDetailWorkingModel = {
    /**
     * CSharp Type: System.Nullable<System.DateTimeOffset>
     */
    Moment: 'moment',
    /**
     * CSharp Type: System.String
     */
    SituatieOmschrijving: 'situatieOmschrijving'
};

export const IncidentRapportWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    Id: 'id',
    /**
     * CSharp Type: System.DateTimeOffset
     */
    Moment: 'moment',
    /**
     * CSharp Type: System.String
     */
    SituatieOmschrijving: 'situatieOmschrijving',
    /**
     * CSharp Type: System.Boolean
     */
    Immutable: 'immutable'
};

export const IncidentsWorkingModel = {
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AccountId: 'accountId',
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.Incident.IncidentInfoWorkingModel>
     */
    Incidents: 'incidents'
};

export const IncidentWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    Id: 'id',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AccountId: 'accountId',
    /**
     * CSharp Type: System.Nullable<System.Boolean>
     */
    MeldingDoorWTP: 'meldingDoorWTP',
    /**
     * CSharp Type: System.Nullable<System.Boolean>
     */
    Afgesloten: 'afgesloten',
    /**
     * CSharp Type: System.String
     */
    Naam: 'naam',
    /**
     * CSharp Type: System.DateTimeOffset
     */
    DatumIncident: 'datumIncident',
    /**
     * CSharp Type: System.String
     */
    Beschrijving: 'beschrijving',
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.Incident.IncidentRapportWorkingModel>
     */
    Rapport: 'rapport',
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.Incident.IncidentDocumentLinkWorkingModel>
     */
    Document: 'document',
    /**
     * CSharp Type: System.String
     */
    Meldingsnummer: 'meldingsnummer',
    /**
     * CSharp Type: System.String
     */
    Conclusie: 'conclusie',
    /**
     * CSharp Type: System.String
     */
    EindConclusie: 'eindConclusie',
    /**
     * CSharp Type: System.String
     */
    VervolgActies: 'vervolgActies'
};

export const LoginWorkingModel = {
    /**
     * CSharp Type: System.String
     */
    UserGuid: 'userGuid',
    /**
     * CSharp Type: System.String
     */
    Emailadres: 'emailadres',
    /**
     * CSharp Type: System.String
     */
    Wachtwoord: 'wachtwoord',
    /**
     * CSharp Type: System.Nullable<System.DateTime>
     */
    LastLoginDateTime: 'lastLoginDateTime'
};

export const MijnAccountDetailWorkingModel = {
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AccountId: 'accountId',
    /**
     * CSharp Type: System.Nullable<System.Boolean>
     */
    MagConnectieMaken: 'magConnectieMaken'
};

export const MijnAccountWorkingModel = {
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AccountId: 'accountId',
    /**
     * CSharp Type: System.String
     */
    Naam: 'naam',
    /**
     * CSharp Type: System.Boolean
     */
    MagConnectieMaken: 'magConnectieMaken'
};

export const NewsFilterWorkingModel = {
    /**
     * CSharp Type: System.Int32
     */
    NumberOfItems: 'numberOfItems',
    /**
     * CSharp Type: System.Boolean
     */
    HomepageNewsOnly: 'homepageNewsOnly'
};

export const NewsListWorkingModel = {
    /**
     * CSharp Type: WTPrivacy.Model.NewsFilterWorkingModel
     */
    Filter: 'filter',
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.NewsWorkingModel>
     */
    News: 'news'
};

export const NewsWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    Id: 'id',
    /**
     * CSharp Type: System.String
     */
    Icon: 'icon',
    /**
     * CSharp Type: System.String
     */
    HeadLine: 'headLine',
    /**
     * CSharp Type: System.String
     */
    Intro: 'intro',
    /**
     * CSharp Type: System.String
     */
    Article: 'article',
    /**
     * CSharp Type: System.String
     */
    Link: 'link',
    /**
     * CSharp Type: System.String
     */
    LinkText: 'linkText'
};

export const OrganisatiePmtDetailWorkingModel = {
    /**
     * CSharp Type: System.String
     */
    KvK: 'kvK',
    /**
     * CSharp Type: System.String
     */
    TelefoonnummerOrganisatie: 'telefoonnummerOrganisatie',
    /**
     * CSharp Type: System.String
     */
    EmailFacturatie: 'emailFacturatie',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    EigenaarId: 'eigenaarId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    SectorId: 'sectorId',
    /**
     * CSharp Type: System.String
     */
    FunctionarisGegevensbescherming: 'functionarisGegevensbescherming',
    /**
     * CSharp Type: System.String
     */
    Verwerkingsverantwoordelijke: 'verwerkingsverantwoordelijke'
};

export const OrganisatiePmtListWorkingModel = {
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.Organisatie.OrganisatiePmtWorkingModel>
     */
    Data: 'data'
};

export const OrganisatiePmtUpdateWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    AccountId: 'accountId',
    /**
     * CSharp Type: System.String
     */
    Naam: 'naam',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    EigenaarId: 'eigenaarId',
    /**
     * CSharp Type: System.String
     */
    EmailFacturatie: 'emailFacturatie',
    /**
     * CSharp Type: System.String
     */
    TelefoonnummerOrganisatie: 'telefoonnummerOrganisatie',
    /**
     * CSharp Type: System.String
     */
    KvK: 'kvK',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    SectorId: 'sectorId'
};

export const OrganisatiePmtWorkingModel = {
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AccountId: 'accountId',
    /**
     * CSharp Type: System.String
     */
    Naam: 'naam',
    /**
     * CSharp Type: System.String
     */
    Vertegenwoordiger: 'vertegenwoordiger',
    /**
     * CSharp Type: System.String
     */
    Eigenaar: 'eigenaar',
    /**
     * CSharp Type: System.String
     */
    TelefoonnummerContactPersoon: 'telefoonnummerContactPersoon',
    /**
     * CSharp Type: System.String
     */
    EmailContactPersoon: 'emailContactPersoon',
    /**
     * CSharp Type: System.String
     */
    Sector: 'sector'
};

export const PersonenListWorkingModel = {
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AccountId: 'accountId',
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.Personen.PersoonWorkingModel>
     */
    Personen: 'personen'
};

export const PersoonDeleteWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    PersoonId: 'persoonId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AccountId: 'accountId'
};

export const PersoonDetailPmtWorkingModel = {
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    Id: 'id',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AccountId: 'accountId',
    /**
     * CSharp Type: System.String
     */
    Aanhef: 'aanhef',
    /**
     * CSharp Type: System.String
     */
    Voornaam: 'voornaam',
    /**
     * CSharp Type: System.String
     */
    Tussenvoegsel: 'tussenvoegsel',
    /**
     * CSharp Type: System.String
     */
    Achternaam: 'achternaam',
    /**
     * CSharp Type: System.String
     */
    Telefoonnummer: 'telefoonnummer',
    /**
     * CSharp Type: System.String
     */
    Email: 'email'
};

export const PersoonDetailWorkingModel = {
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AccountId: 'accountId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    Id: 'id',
    /**
     * CSharp Type: System.String
     */
    Aanhef: 'aanhef',
    /**
     * CSharp Type: System.String
     */
    Voornaam: 'voornaam',
    /**
     * CSharp Type: System.String
     */
    Tussenvoegsel: 'tussenvoegsel',
    /**
     * CSharp Type: System.String
     */
    Achternaam: 'achternaam',
    /**
     * CSharp Type: System.String
     */
    Telefoonnummer: 'telefoonnummer',
    /**
     * CSharp Type: System.String
     */
    Email: 'email'
};

export const PersoonListPmtWorkingModel = {
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AccountId: 'accountId',
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.Personen.PersoonWorkingModel>
     */
    Data: 'data'
};

export const PersoonWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    Id: 'id',
    /**
     * CSharp Type: System.String
     */
    FullName: 'fullName'
};

export const PiaAdvisoryWorkingModel = {
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserAccountId: 'userAccountId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    LastUsedVragenlijstId: 'lastUsedVragenlijstId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    MostRecentVragenlijstId: 'mostRecentVragenlijstId',
    /**
     * CSharp Type: System.Boolean
     */
    CanStartNew: 'canStartNew',
    /**
     * CSharp Type: System.Boolean
     */
    CanCopyExisting: 'canCopyExisting',
    /**
     * CSharp Type: System.Boolean
     */
    AreVragenlijstIdsDifferent: 'areVragenlijstIdsDifferent',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    MostRecentPiaId: 'mostRecentPiaId'
};

export const PiaAnalysisWorkingModel = {
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.PrivacyImpactAssessment.PiaVraagWorkingModel>
     */
    Vragen: 'vragen',
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.PrivacyImpactAssessment.PiaVraagAntwoordWorkingModel>
     */
    Antwoorden: 'antwoorden',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId',
    /**
     * CSharp Type: System.Guid
     */
    VragenlijstId: 'vragenlijstId',
    /**
     * CSharp Type: System.Collections.Generic.List<WTPrivacy.Model.PrivacyImpactAssessment.PiaVraagCategorieWorkingModel>
     */
    VraagCategorien: 'vraagCategorien'
};

export const PiaAntwoordWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    AntwoordId: 'antwoordId',
    /**
     * CSharp Type: System.String
     */
    Antwoord: 'antwoord',
    /**
     * CSharp Type: System.Int32
     */
    Volgorde: 'volgorde'
};

export const PiaConclusieWorkingModel = {
    /**
     * CSharp Type: System.String
     */
    Tekst: 'tekst',
    /**
     * CSharp Type: System.String
     */
    KleurCode: 'kleurCode'
};

export const PiaDetailUpdateWorkingModel = {
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    Id: 'id',
    /**
     * CSharp Type: System.DateTimeOffset
     */
    DatumAangemaakt: 'datumAangemaakt',
    /**
     * CSharp Type: System.Boolean
     */
    Afgesloten: 'afgesloten',
    /**
     * CSharp Type: System.Guid
     */
    VragenlijstId: 'vragenlijstId',
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.PrivacyImpactAssessment.PiaVraagWorkingModel>
     */
    Vragen: 'vragen',
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.PrivacyImpactAssessment.PiaVraagAntwoordWorkingModel>
     */
    Antwoorden: 'antwoorden',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserAccountId: 'userAccountId',
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.PrivacyImpactAssessment.PiaVraagNotitieWorkingModel>
     */
    Notities: 'notities'
};

export const PiaDetailWorkingModel = {
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    Id: 'id',
    /**
     * CSharp Type: System.DateTimeOffset
     */
    DatumAangemaakt: 'datumAangemaakt',
    /**
     * CSharp Type: System.Boolean
     */
    Afgesloten: 'afgesloten',
    /**
     * CSharp Type: System.Guid
     */
    VragenlijstId: 'vragenlijstId',
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.PrivacyImpactAssessment.PiaVraagWorkingModel>
     */
    Vragen: 'vragen',
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.PrivacyImpactAssessment.PiaVraagAntwoordWorkingModel>
     */
    Antwoorden: 'antwoorden',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserAccountId: 'userAccountId',
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.PrivacyImpactAssessment.PiaVraagNotitieWorkingModel>
     */
    Notities: 'notities'
};

export const PiaGetSingleWorkingModel = {
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserAccountId: 'userAccountId',
    /**
     * CSharp Type: WTPrivacy.Model.PrivacyImpactAssessment.PiaWorkingModel
     */
    Result: 'result',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    Id: 'id'
};

export const PiaGetWorkingModel = {
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserAccountId: 'userAccountId',
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.PrivacyImpactAssessment.PiaInfoWorkingModel>
     */
    Result: 'result',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    Id: 'id'
};

export const PiaInfoWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    PiaAssessmentId: 'piaAssessmentId',
    /**
     * CSharp Type: System.DateTimeOffset
     */
    DatumAangemaakt: 'datumAangemaakt',
    /**
     * CSharp Type: System.Nullable<System.DateTimeOffset>
     */
    DatumAfgerond: 'datumAfgerond',
    /**
     * CSharp Type: System.Nullable<System.DateTimeOffset>
     */
    DatumLaatsteWijziging: 'datumLaatsteWijziging',
    /**
     * CSharp Type: System.Int32
     */
    LaagRisicoAantal: 'laagRisicoAantal',
    /**
     * CSharp Type: System.Int32
     */
    MiddelRisicoAantal: 'middelRisicoAantal',
    /**
     * CSharp Type: System.Int32
     */
    HoogRisicoAantal: 'hoogRisicoAantal',
    /**
     * CSharp Type: System.Int32
     */
    TotaalAantalVragen: 'totaalAantalVragen',
    /**
     * CSharp Type: System.Int32
     */
    AantalVragenBeantwoord: 'aantalVragenBeantwoord',
    /**
     * CSharp Type: System.Double
     */
    PercentageDone: 'percentageDone',
    /**
     * CSharp Type: System.Boolean
     */
    Bewerkbaar: 'bewerkbaar'
};

export const PiaVraagAntwoordWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    VraagId: 'vraagId',
    /**
     * CSharp Type: System.Guid
     */
    AntwoordId: 'antwoordId'
};

export const PiaVraagCategorieWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    Id: 'id',
    /**
     * CSharp Type: System.String
     */
    Naam: 'naam',
    /**
     * CSharp Type: System.Int32
     */
    Volgorde: 'volgorde'
};

export const PiaVraagNotitieWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    VraagId: 'vraagId',
    /**
     * CSharp Type: System.String
     */
    Notitie: 'notitie'
};

export const PiaVraagWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    VraagId: 'vraagId',
    /**
     * CSharp Type: System.String
     */
    VraagCode: 'vraagCode',
    /**
     * CSharp Type: System.String
     */
    Vraag: 'vraag',
    /**
     * CSharp Type: System.String
     */
    Categorie: 'categorie',
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.PrivacyImpactAssessment.PiaAntwoordWorkingModel>
     */
    Antwoorden: 'antwoorden',
    /**
     * CSharp Type: System.Int32
     */
    Volgorde: 'volgorde',
    /**
     * CSharp Type: WTPrivacy.Model.PrivacyImpactAssessment.PiaConclusieWorkingModel
     */
    Conclusie: 'conclusie',
    /**
     * CSharp Type: System.String
     */
    Toelichting: 'toelichting'
};

export const PiaWorkingModel = {
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    Id: 'id',
    /**
     * CSharp Type: System.DateTime
     */
    DatumAangemaakt: 'datumAangemaakt',
    /**
     * CSharp Type: System.Nullable<System.DateTime>
     */
    DatumAfgesloten: 'datumAfgesloten',
    /**
     * CSharp Type: System.Boolean
     */
    Afgesloten: 'afgesloten',
    /**
     * CSharp Type: System.Guid
     */
    VragenlijstId: 'vragenlijstId',
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.PrivacyImpactAssessment.PiaVraagWorkingModel>
     */
    Vragen: 'vragen',
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.PrivacyImpactAssessment.PiaVraagAntwoordWorkingModel>
     */
    Antwoorden: 'antwoorden',
    /**
     * CSharp Type: System.Collections.Generic.List<WTPrivacy.Model.PrivacyImpactAssessment.PiaVraagCategorieWorkingModel>
     */
    VraagCategorien: 'vraagCategorien',
    /**
     * CSharp Type: System.Collections.Generic.List<WTPrivacy.Model.PrivacyImpactAssessment.PiaVraagNotitieWorkingModel>
     */
    Notities: 'notities'
};

export const PrivacyCheckFilterWorkingModel = {
    /**
     * CSharp Type: System.Int32
     */
    CurrentSortOrder: 'currentSortOrder'
};

export const PrivacyCheckWorkingModel = {
    /**
     * CSharp Type: WTPrivacy.Model.PrivacyCheckFilterWorkingModel
     */
    Filter: 'filter',
    /**
     * CSharp Type: System.Boolean
     */
    IsFinalQuestion: 'isFinalQuestion',
    /**
     * CSharp Type: System.String
     */
    Question: 'question',
    /**
     * CSharp Type: System.String
     */
    Information: 'information',
    /**
     * CSharp Type: System.Decimal
     */
    Weight: 'weight',
    /**
     * CSharp Type: System.String
     */
    ExternalReference: 'externalReference',
    /**
     * CSharp Type: System.Int32
     */
    SortOrder: 'sortOrder'
};

export const RegisterInformatieDetailWorkingModel = {
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AccountId: 'accountId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    ContactPersoonId: 'contactPersoonId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    FunctionarisGegevensbeschermingId: 'functionarisGegevensbeschermingId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    VerwerkingsverantwoordelijkeId: 'verwerkingsverantwoordelijkeId'
};

export const RegisterInformatieWorkingModel = {
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AccountId: 'accountId',
    /**
     * CSharp Type: System.String
     */
    NaamOrganisatie: 'naamOrganisatie',
    /**
     * CSharp Type: System.String
     */
    Vertegenwoordiger: 'vertegenwoordiger',
    /**
     * CSharp Type: System.String
     */
    Eigenaar: 'eigenaar',
    /**
     * CSharp Type: System.String
     */
    TelefoonnummerOrganisatie: 'telefoonnummerOrganisatie',
    /**
     * CSharp Type: System.String
     */
    EmailOrganisatie: 'emailOrganisatie',
    /**
     * CSharp Type: System.String
     */
    NaamFunctionarisGegevensbescherming: 'naamFunctionarisGegevensbescherming',
    /**
     * CSharp Type: System.String
     */
    TelefoonnummerFunctionarisGegevensbescherming: 'telefoonnummerFunctionarisGegevensbescherming',
    /**
     * CSharp Type: System.String
     */
    EmailFunctionarisGegevensbescherming: 'emailFunctionarisGegevensbescherming',
    /**
     * CSharp Type: System.String
     */
    NaamVerwerkingsverantwoordelijke: 'naamVerwerkingsverantwoordelijke',
    /**
     * CSharp Type: System.String
     */
    TelefoonnummermVerwerkingsverantwoordelijke: 'telefoonnummermVerwerkingsverantwoordelijke',
    /**
     * CSharp Type: System.String
     */
    EmailVerwerkingsverantwoordelijke: 'emailVerwerkingsverantwoordelijke',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    ContactPersoonId: 'contactPersoonId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    FunctionarisGegevensbeschermingId: 'functionarisGegevensbeschermingId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    VerwerkingsverantwoordelijkeId: 'verwerkingsverantwoordelijkeId'
};

export const RemoveAccountSecurityResultWorkingModel = {
    /**
     * CSharp Type: System.Boolean
     */
    Success: 'success'
};

export const RemoveAccountSecurityWorkingModel = {
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AccountId: 'accountId'
};

export const ResultWrapper = {
    /**
     * CSharp Type: System.Int64
     */
    Count: 'count',
    /**
     * CSharp Type: System.Collections.Generic.IList<T>
     */
    Result: 'result'
};

export const SetAccountIsActiveResultWorkingModel = {
    /**
     * CSharp Type: System.Boolean
     */
    Success: 'success'
};

export const SetAccountIsActiveWorkingModel = {
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AccountId: 'accountId',
    /**
     * CSharp Type: System.Nullable<System.Boolean>
     */
    SetAccountIsActiveTo: 'setAccountIsActiveTo'
};

export const StamSectorWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    Id: 'id',
    /**
     * CSharp Type: System.String
     */
    Naam: 'naam',
    /**
     * CSharp Type: System.Boolean
     */
    IsSelectable: 'isSelectable'
};

export const TemplateDetailWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    Id: 'id',
    /**
     * CSharp Type: System.String
     */
    Filename: 'filename',
    /**
     * CSharp Type: System.Byte[]
     */
    Content: 'content',
    /**
     * CSharp Type: System.String
     */
    ContentType: 'contentType'
};

export const TemplateWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    Id: 'id',
    /**
     * CSharp Type: System.String
     */
    Description: 'description'
};

export const UserInformationListPmtWorkingModel = {
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.User.UserInformationPmtWorkingModel>
     */
    Users: 'users'
};

export const UserInformationPmtWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    Id: 'id',
    /**
     * CSharp Type: System.String
     */
    Bedrijfsnaam: 'bedrijfsnaam',
    /**
     * CSharp Type: System.String
     */
    Email: 'email',
    /**
     * CSharp Type: System.Boolean
     */
    IsPrivacyOfficer: 'isPrivacyOfficer',
    /**
     * CSharp Type: System.Boolean
     */
    IsActive: 'isActive'
};

export const UserInformationWorkingModel = {
    /**
     * CSharp Type: System.String
     */
    Voornaam: 'voornaam',
    /**
     * CSharp Type: System.String
     */
    Achternaam: 'achternaam',
    /**
     * CSharp Type: System.String
     */
    Aanhef: 'aanhef',
    /**
     * CSharp Type: System.String
     */
    Tussenvoegsel: 'tussenvoegsel'
};

export const UserSecurityWorkingModel = {
    /**
     * CSharp Type: System.Boolean
     */
    UserSecurityRequired: 'userSecurityRequired',
    /**
     * CSharp Type: System.Boolean
     */
    UserSecurityEnabled: 'userSecurityEnabled',
    /**
     * CSharp Type: System.Int32
     */
    UserSecurityDaysLeft: 'userSecurityDaysLeft'
};

export const UserWorkingModel = {
    /**
     * CSharp Type: System.String
     */
    UserId: 'userId'
};

export const VerwerkerDeleteWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    Id: 'id',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AccountId: 'accountId'
};

export const VerwerkerDetailWorkingModel = {
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    Id: 'id',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AccountId: 'accountId',
    /**
     * CSharp Type: System.String
     */
    NaamOrganisatie: 'naamOrganisatie',
    /**
     * CSharp Type: System.String
     */
    ContactPersoon: 'contactPersoon',
    /**
     * CSharp Type: System.String
     */
    EmailAddress: 'emailAddress',
    /**
     * CSharp Type: System.String
     */
    Doel: 'doel',
    /**
     * CSharp Type: System.String
     */
    DocumentLink: 'documentLink',
    /**
     * CSharp Type: System.String
     */
    DocumentOmschrijving: 'documentOmschrijving',
    /**
     * CSharp Type: System.String
     */
    Persoonsgegevens: 'persoonsgegevens',
    /**
     * CSharp Type: System.Nullable<System.DateTime>
     */
    DatumOvereenkomst: 'datumOvereenkomst'
};

export const VerwerkerInfoWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    Id: 'id',
    /**
     * CSharp Type: System.String
     */
    NaamOrganisatie: 'naamOrganisatie',
    /**
     * CSharp Type: System.String
     */
    ContactPersoon: 'contactPersoon',
    /**
     * CSharp Type: System.String
     */
    EmailAddress: 'emailAddress',
    /**
     * CSharp Type: System.Nullable<System.DateTime>
     */
    DatumOvereenkomst: 'datumOvereenkomst',
    /**
     * CSharp Type: System.String
     */
    DocumentLink: 'documentLink',
    /**
     * CSharp Type: System.String
     */
    DocumentOmschrijving: 'documentOmschrijving'
};

export const VerwerkersWorkingModel = {
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AccountId: 'accountId',
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.Verwerkersregister.VerwerkerInfoWorkingModel>
     */
    Verwerkers: 'verwerkers'
};

export const VerwerkerUpdateWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    Id: 'id',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AccountId: 'accountId',
    /**
     * CSharp Type: System.String
     */
    NaamOrganisatie: 'naamOrganisatie',
    /**
     * CSharp Type: System.String
     */
    ContactPersoon: 'contactPersoon',
    /**
     * CSharp Type: System.String
     */
    EmailAddress: 'emailAddress',
    /**
     * CSharp Type: System.String
     */
    Doel: 'doel',
    /**
     * CSharp Type: System.String
     */
    DocumentLink: 'documentLink',
    /**
     * CSharp Type: System.String
     */
    DocumentOmschrijving: 'documentOmschrijving',
    /**
     * CSharp Type: System.String
     */
    Persoonsgegevens: 'persoonsgegevens',
    /**
     * CSharp Type: System.Nullable<System.DateTime>
     */
    DatumOvereenkomst: 'datumOvereenkomst'
};

export const VerwerkerWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    Id: 'id',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId',
    /**
     * CSharp Type: System.String
     */
    NaamOrganisatie: 'naamOrganisatie',
    /**
     * CSharp Type: System.String
     */
    ContactPersoon: 'contactPersoon',
    /**
     * CSharp Type: System.String
     */
    EmailAddress: 'emailAddress',
    /**
     * CSharp Type: System.String
     */
    Doel: 'doel',
    /**
     * CSharp Type: System.String
     */
    DocumentLink: 'documentLink',
    /**
     * CSharp Type: System.String
     */
    DocumentOmschrijving: 'documentOmschrijving',
    /**
     * CSharp Type: System.String
     */
    Persoonsgegevens: 'persoonsgegevens',
    /**
     * CSharp Type: System.Nullable<System.DateTime>
     */
    DatumOvereenkomst: 'datumOvereenkomst',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AccountId: 'accountId'
};

export const VerwerkingDeleteWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    Id: 'id',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AccountId: 'accountId'
};

export const VerwerkingDetailWorkingModel = {
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    Id: 'id',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId',
    /**
     * CSharp Type: System.String
     */
    Doel: 'doel',
    /**
     * CSharp Type: System.String
     */
    Categorieen: 'categorieen',
    /**
     * CSharp Type: System.String
     */
    CategorieenPersoonsgegevens: 'categorieenPersoonsgegevens',
    /**
     * CSharp Type: System.Nullable<System.Boolean>
     */
    WettelijkeGrondslag: 'wettelijkeGrondslag',
    /**
     * CSharp Type: System.String
     */
    WettelijkeGrondslagToelichting: 'wettelijkeGrondslagToelichting',
    /**
     * CSharp Type: System.Nullable<System.Boolean>
     */
    DelenBuitenEU: 'delenBuitenEU',
    /**
     * CSharp Type: System.String
     */
    Bewaartermijn: 'bewaartermijn',
    /**
     * CSharp Type: System.String
     */
    Opdrachtgever: 'opdrachtgever',
    /**
     * CSharp Type: System.Nullable<System.Boolean>
     */
    AfwijkendeVerantwoordelijke: 'afwijkendeVerantwoordelijke',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AfwijkendeVerantwoordelijkeId: 'afwijkendeVerantwoordelijkeId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AccountId: 'accountId'
};

export const VerwerkingInfoWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    Id: 'id',
    /**
     * CSharp Type: System.String
     */
    Doel: 'doel',
    /**
     * CSharp Type: System.String
     */
    ContactPersoon: 'contactPersoon'
};

export const VerwerkingsWorkingModel = {
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AccountId: 'accountId',
    /**
     * CSharp Type: System.Collections.Generic.IList<WTPrivacy.Model.Verwerkingsregister.VerwerkingInfoWorkingModel>
     */
    Verwerkingen: 'verwerkingen'
};

export const VerwerkingUpdateWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    Id: 'id',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId',
    /**
     * CSharp Type: System.String
     */
    Doel: 'doel',
    /**
     * CSharp Type: System.String
     */
    Categorieen: 'categorieen',
    /**
     * CSharp Type: System.String
     */
    CategorieenPersoonsgegevens: 'categorieenPersoonsgegevens',
    /**
     * CSharp Type: System.Nullable<System.Boolean>
     */
    WettelijkeGrondslag: 'wettelijkeGrondslag',
    /**
     * CSharp Type: System.String
     */
    WettelijkeGrondslagToelichting: 'wettelijkeGrondslagToelichting',
    /**
     * CSharp Type: System.Nullable<System.Boolean>
     */
    DelenBuitenEU: 'delenBuitenEU',
    /**
     * CSharp Type: System.String
     */
    Bewaartermijn: 'bewaartermijn',
    /**
     * CSharp Type: System.String
     */
    Opdrachtgever: 'opdrachtgever',
    /**
     * CSharp Type: System.Nullable<System.Boolean>
     */
    AfwijkendeVerantwoordelijke: 'afwijkendeVerantwoordelijke',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AfwijkendeVerantwoordelijkeId: 'afwijkendeVerantwoordelijkeId',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AccountId: 'accountId'
};

export const VerwerkingWorkingModel = {
    /**
     * CSharp Type: System.Guid
     */
    Id: 'id',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    UserId: 'userId',
    /**
     * CSharp Type: System.String
     */
    Doel: 'doel',
    /**
     * CSharp Type: System.String
     */
    Categorieen: 'categorieen',
    /**
     * CSharp Type: System.String
     */
    CategorieenPersoonsgegevens: 'categorieenPersoonsgegevens',
    /**
     * CSharp Type: System.Nullable<System.Boolean>
     */
    WettelijkeGrondslag: 'wettelijkeGrondslag',
    /**
     * CSharp Type: System.String
     */
    WettelijkeGrondslagToelichting: 'wettelijkeGrondslagToelichting',
    /**
     * CSharp Type: System.Boolean
     */
    DelenBuitenEU: 'delenBuitenEU',
    /**
     * CSharp Type: System.String
     */
    Bewaartermijn: 'bewaartermijn',
    /**
     * CSharp Type: System.String
     */
    Opdrachtgever: 'opdrachtgever',
    /**
     * CSharp Type: System.Boolean
     */
    AfwijkendeVerantwoordelijke: 'afwijkendeVerantwoordelijke',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AfwijkendeVerantwoordelijkeId: 'afwijkendeVerantwoordelijkeId',
    /**
     * CSharp Type: System.String
     */
    AfwijkendeVerantwoordelijkePersoon: 'afwijkendeVerantwoordelijkePersoon',
    /**
     * CSharp Type: System.Nullable<System.Guid>
     */
    AccountId: 'accountId'
};

export const WachtwoordHerstellenDetailWorkingModel = {
    /**
     * CSharp Type: System.String
     */
    HerstelWachtwoordCode: 'herstelWachtwoordCode',
    /**
     * CSharp Type: System.String
     */
    HerstelWachtwoordTokenFirstPart: 'herstelWachtwoordTokenFirstPart',
    /**
     * CSharp Type: System.String
     */
    HerstelWachtwoordTokenSecondPart: 'herstelWachtwoordTokenSecondPart'
};

export const WachtwoordHerstellenWorkingModel = {
    /**
     * CSharp Type: System.Boolean
     */
    HerstelMogelijk: 'herstelMogelijk',
    /**
     * CSharp Type: System.String
     */
    ExternalReference: 'externalReference',
    /**
     * CSharp Type: System.String
     */
    ResetToken: 'resetToken'
};

export const WachtwoordInstellenDetailWorkingModel = {
    /**
     * CSharp Type: System.String
     */
    Password: 'password',
    /**
     * CSharp Type: System.String
     */
    PasswordRepeat: 'passwordRepeat',
    /**
     * CSharp Type: System.String
     */
    ExternalReference: 'externalReference',
    /**
     * CSharp Type: System.String
     */
    ResetToken: 'resetToken'
};

export const WachtwoordInstellenWorkingModel = {
    /**
     * CSharp Type: System.Boolean
     */
    Success: 'success'
};
