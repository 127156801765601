import { NAME } from './constants';

export const ADD_VERWERKING = `${NAME}/ADD_VERWERKING`;
export const GET_VERWERKING = `${NAME}/GET_VERWERKING`;
export const HIDE_VERWERKING = `${NAME}/HIDE_VERWERKING`;
export const GET_VERWERKINGEN = `${NAME}/GET_VERWERKINGEN`;
export const LOADED_VERWERKING = `${NAME}/LOADED_VERWERKING`;
export const LOADED_VERWERKINGEN = `${NAME}/LOADED_VERWERKINGEN`;
export const EDIT_VERWERKING = `${NAME}/EDIT_VERWERKING`;
export const CANCEL_EDIT = `${NAME}/CANCEL_EDIT`;
export const UPDATE_VERWERKING = `${NAME}/UPDATE_VERWERKING`;
export const DELETE_VERWERKING = `${NAME}/DELETE_VERWERKING`;
export const CONFIRMED_DELETE_VERWERKING = `${NAME}/CONFIRMED_DELETE_VERWERKING`;
export const CLEAR = `${NAME}/CLEAR`;