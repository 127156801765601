import * as ActionTypes from './actionTypes';

export const loadFromApi = (api) => ({
    type: ActionTypes.LOAD_FROM_API,
    api
});

export const loadedFromApi = (api, data) => ({
    type: ActionTypes.LOADED_FROM_API,
    api,
    data
});