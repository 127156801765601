import * as ActionTypes from './actionTypes';

export const addVerwerker = (model) => ({
    type: ActionTypes.ADD_VERWERKER,
    model
});

export const getVerwerker = (id, source) => ({
    type: ActionTypes.GET_VERWERKER,
    id,
    source
});

export const hideVerwerker = (id) => ({
    type: ActionTypes.HIDE_VERWERKER,
    id
});

export const loadedVerwerker = (model, source) => ({
    type: ActionTypes.LOADED_VERWERKER,
    model,
    source
});

export const getVerwerkeren = () => ({
    type: ActionTypes.GET_VERWERKEREN
});

export const loadedVerwerkeren = (model) => ({
    type: ActionTypes.LOADED_VERWERKEREN,
    model
});

export const editVerwerker = (id, source) => ({
    type: ActionTypes.EDIT_VERWERKER,
    id,
    source
});

export const cancelEdit = () => ({
    type: ActionTypes.CANCEL_EDIT
});

export const updateVerwerker = () => ({
    type: ActionTypes.UPDATE_VERWERKER
});

export const deleteVerwerker = (id) => ({
    type: ActionTypes.DELETE_VERWERKER,
    id
});

export const confirmedDeleteVerwerker = (id) => ({
    type: ActionTypes.CONFIRMED_DELETE_VERWERKER,
    id
});

export const clear = () => ({
    type: ActionTypes.CLEAR
});