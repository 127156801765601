import {
    Actions,
    Constants,
    stateHelper,
    STATE_KEY,
    IncidentAnalyseWorkingModel,
    IncidentDetailUpdateWorkingModel,
    binder
} from './_references';

export const mapDispatchToProps = (dispatch) => ({
    clear: () => dispatch(Actions.clear()),
    getIncident: (id) => dispatch(Actions.getIncident(id, true))
});

export const mapStateToProps = (state) => {

    return {
        vragen: stateHelper(state, [ STATE_KEY, Constants.FIELDS.DOSSIER, IncidentAnalyseWorkingModel.Vragen ], []),
        rapport: stateHelper(state, [ STATE_KEY, Constants.FIELDS.DOSSIER, IncidentDetailUpdateWorkingModel.Rapport ], []),
        document: stateHelper(state, [ STATE_KEY, Constants.FIELDS.DOSSIER, IncidentDetailUpdateWorkingModel.Document ], []),
        bind: (field) => binder(state, [ STATE_KEY, Constants.FIELDS.DOSSIER ])(field).value,
        bindAntwoord: (field) => binder(stateHelper(state, [ STATE_KEY, Constants.FIELDS.DOSSIER, IncidentAnalyseWorkingModel.Antwoorden ], {}))(field).value,
        isLoading: stateHelper(state, [ Constants.STATE_KEY, Constants.FIELDS.LOADING ])
    };
};
