import {
    stateHelper,
    FIELD_CHANGED
} from './_references';

import * as ActionTypes from './actionTypes';
import * as Constants from './constants';

/*
 * Reducer for the login component.
*/
const reducer = (state = {}, action) => {
    switch(action.type){
    //#region PIA Advisory
    case ActionTypes.LOAD_PIA_ADVISORY: {
        const newState = Object.assign({}, state, {
            [ Constants.FIELDS.AdvisoryLoading ]: true
        });
        return newState;
    }
    case ActionTypes.LOADED_PIA_ADVISORY: {
        const newState = Object.assign({}, state, {
            [ Constants.FIELDS.Advisory ] : action.model,
            [ Constants.FIELDS.AdvisoryLoading ]: false
        });
        return newState;
    }
    case ActionTypes.CLEAR_PIA_ADVISORY: {
        const newState = Object.assign({}, state);

        delete newState[ Constants.FIELDS.Advisory ];
        delete newState[ Constants.FIELDS.AdvisoryLoading ];

        return newState;
    }
    //#endregion
    //#region PIA List
    case ActionTypes.LOAD_PIA_LIST : {
        const newState = Object.assign({}, state, {
            [ Constants.FIELDS.PiaListLoading ]: true
        });
        return newState;
    }
    case ActionTypes.LOADED_PIA_LIST : {
        const newState = Object.assign({}, state, {
            [ Constants.FIELDS.PiaList ]: action.model,
            [ Constants.FIELDS.PiaListLoading ]: false
        });
        return newState;
    }
    //#endregion PIA List
    //#region PIA
    case ActionTypes.LOAD_PIA : {
        const newState = Object.assign({}, state, {
            [ Constants.FIELDS.PiaLoading ]: true
        });
        return newState;
    }
    case ActionTypes.LOADED_PIA : {
        const newState = Object.assign({}, state, {
            [ Constants.FIELDS.Pia ]: action.model,
            [ Constants.FIELDS.PiaLoading ]: false
        });
        return newState;
    }
    case ActionTypes.INTERMEDIATE_ANALYSIS_COMPLETE:
    {
        const pia = stateHelper(state, Constants.FIELDS.Pia, {});
        const newPia = Object.assign({}, pia, action.model);

        const result = Object.assign({}, state, {
            [ Constants.FIELDS.Pia ]: newPia
        });
        return result;
    }
    case FIELD_CHANGED: {

        if (action[ Constants.ACTION_OPTIONS.PiaAnalysis ] === Constants.ACTION_OPTIONS.PiaAnalysis){

            const pia = stateHelper(state, Constants.FIELDS.Pia, {});

            const newPia = Object.assign({}, pia, {
                [ Constants.FIELDS.PiaChanged ] : true
            });

            const newState = Object.assign({}, state, {
                [ Constants.FIELDS.Pia ]: newPia
            });

            return newState;
        }
        return state;
    }
    //#endregion
    default:
        return state;
    }
};

reducer.STATE_KEY = Constants.STATE_KEY;

export default reducer;