import React from 'react';
import { Link, withRouter } from 'react-router';

const Breadcrumb = ({ routes }) => {

    const filtered = routes.slice(routes.length === 1 ? 1 : 2).filter(route => (route.path != null));

    return (
        filtered.length === 0 ? null :
            <ul className="breadcrumb hide-print">
                {filtered.map((route, index) => (<li key={route.path}>{(index < filtered.length - 1) ? <Link to={route.path}>{route.title}</Link> : route.title}</li>))}
            </ul>
    );
};

export default withRouter(Breadcrumb);