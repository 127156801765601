import React from 'react';
import { Panel, Row, Col } from 'react-bootstrap';

import { Link } from 'react-router';

class ErrorPage extends React.Component {
    componentWillUnmount() {
        this.props.clearErrors();
    }

    render() {

        const { title, message } = this.props;

        return (

            <Panel>
                <Panel.Heading>
                    <h1>{title}</h1>
                </Panel.Heading>
                <Panel.Body>
                    <p>{message}</p>
                </Panel.Body>

                <Panel.Footer>
                    <Row className="text-right">
                        <Col xs={12}>
                            <Link className="button button-primary" to="/">
                                Naar de homepage
                            </Link>
                        </Col>
                    </Row>
                </Panel.Footer>
            </Panel>

        );
    }
}

export default ErrorPage;