import Axios from 'axios';
import { all, call, put, takeEvery, takeLatest, delay } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import { Routes } from '../../../configuration/constants.routes';
import * as LoginApi from '../../../api/login';
import * as AccountApi from '../../../api/account';

import * as ActionTypes from './actionTypes';
import * as Actions from './actions';
import * as Constants from './constants';

import createLogger from '../../../util/log';

import resolve from '../../../resources';

import { AccountInformationWorkingModel, UserSecurityWorkingModel } from '../../../model';
import { Builder } from '../../../structure/dialog/dialogBuilder';

import { JWT_TOKEN_FOUND } from '../../../configuration/actionTypes';

const Log = createLogger('Authentication');

export default function* saga() {
    yield all([
        takeLatest(ActionTypes.LOGIN, handleLogin),
        takeEvery(ActionTypes.LOGOUT, handleLogout),
        takeEvery(ActionTypes.LOGGED_IN, onLoggedIn),
        takeLatest(ActionTypes.UPDATE_ACCOUNT_INFO, updateAccountInfo),
        takeEvery(JWT_TOKEN_FOUND, processJWTToken)
    ]);
}

function* handleLogin(action) {
    yield delay(250);

    Log.log('login action', action);

    try {
        const temporaryToken = yield call(LoginApi.login, {
            userName: action.model.email,
            password: action.model.password,
            key: action.model.key
        }, {
            suppressErrors: true
        });

        Log.log('temporary token result', temporaryToken);

        if (temporaryToken.success) {

            if (temporaryToken.data.requiresVerification === true) {
                yield put(Actions.setRequiresVerification(true));
            }
            else{
                // by calling this action we will receive a http cookie
                const result = yield call(LoginApi.retrieveCookie, temporaryToken.data.token, {
                    suppressErrors: true
                });

                if (result.success) {

                    Log.log('logged in');

                    // do not directly go to the dashboard, first you need to figure out if multiple accounts are being supported
                    const accountInfo = yield call(AccountApi.accountInformation);

                    yield put(Actions.setLoggedInState(true, accountInfo.data, action.manualLogin));

                }
                else {
                    Log.log('unable to get cookie');
                }
            }
        }
        else {
            Log.log('Invalid credentials');
        }
    }
    catch (e) {
        Log.log('exception', e);
        yield handleLoginFailed({
            action, response: e.response
        });
    }
}

function* onLoggedIn({ value : isLoggedIn , accountInfo, manualLogin = false }) {
    if (!isLoggedIn) {
        // do nothing when not logged in (in the future we could navigate to a not secured page)
        return;
    }

    yield put(Actions.setAccountWarning(accountInfo[ AccountInformationWorkingModel.UserSecurity ][ UserSecurityWorkingModel.UserSecurityEnabled ] === false));

    yield put(Actions.setSelectAccount(accountInfo[ AccountInformationWorkingModel.SelectAccount ]));

    if (accountInfo[ AccountInformationWorkingModel.UserSecurity ][ UserSecurityWorkingModel.UserSecurityRequired ] === true &&
        accountInfo[ AccountInformationWorkingModel.UserSecurity ][ UserSecurityWorkingModel.UserSecurityEnabled ] === false){
        yield put(push(Routes.Account.Beveiligen.path));
    }
    else {

        // when no account should be selected or a header for an account is present
        if (accountInfo[ AccountInformationWorkingModel.SelectAccount ] === false || Axios.defaults.headers.common[ Constants.Headers.WTPACCOUNT ] != null) {

            if (manualLogin) {
                yield put(push(Routes.Dashboard.path));
            }
        // else keep current route
        }
        else {
            yield put(push(Routes.Account.Select.path));
        }
    }
}

function* handleLogout() {

    try {
        delete Axios.defaults.headers.common[ Constants.Headers.WTPACCOUNT ];
        yield call(LoginApi.deleteCookie);
    }
    finally{
        // remove any account header
        yield put(Actions.setLoggedInState(false));
        yield put(Actions.loggedOut());
        yield put(push(Routes.Home.path));
    }
}

export function* handleLoginFailed(action) {
    const response = action.response;

    Log.info('Login failed', response);
    if (response && response.status === 422 && response.data) {
        Log.info('Posted data was invalid');

        const bodyText = (response.data[ 'userName' ] ? resolve(response.data[ 'userName' ][ 0 ].errorCode) : ''); //TODO: change propertyname 'username' in the backend

        const defaultDialog = Builder.ok({
            title: 'Validatiefout',
            body: bodyText,
        }).prepare();

        yield call(defaultDialog);
    }
    else {
        Log.info('Probably a network related error occurred.', response);

        const defaultDialog = Builder.ok({
            title: 'Communicatiefout',
            body: 'Het is momenteel niet mogelijk om contact te maken met de server. Probeer het later nogmaals.',
        }).prepare();

        yield call(defaultDialog);
    }
}

function* updateAccountInfo() {
    yield delay(250);

    const accountInfo = yield call(AccountApi.accountInformation);

    yield put(Actions.updatedAccountInfo(accountInfo.data));
}

function* processJWTToken(action) {
    const { token } = action;

    if (token != null && token.Role != null){
        const roles = (Array.isArray(token.Role) ? token.Role : [ token.Role ])
            .filter(r => Constants.Claims[ r ] != null )
            .map(r => Constants.Roles[ [ Constants.Claims[ r ] ] ]) ;

        yield put(Actions.setRoles(roles));
    }

}