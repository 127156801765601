import React from 'react';
import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { actions as ToastrActions } from 'react-redux-toastr';

import {
    AnalysisAPI,
    API,
    Builder,
    RootActionTypes,
    Routes,
    stateHelper,
    IncidentWorkingModel,
    IncidentDetailUpdateWorkingModel,
    IncidentAnalyseWorkingModel,
    IncidentAnalyseVraagAntwoordWorkingModel,
    IncidentAnalyseDetailWorkingModel,
    IncidentAnalyseVraagWorkingModel,
    IncidentAnalyseConclusieWorkingModel
} from './_references';

import * as ActionTypes from './actionTypes';
import * as Actions from './actions';
import * as Constants from './constants';

export default function* () {
    yield all([
        takeEvery(ActionTypes.GET_INCIDENT, getIncident),
        takeEvery(ActionTypes.LOAD_INCIDENTS, getIncidents),
        takeEvery(ActionTypes.ADD_INCIDENT, addIncident),
        takeEvery(ActionTypes.UPDATE_INCIDENT, updateIncident),
        takeEvery(RootActionTypes.FIELD_CHANGED, fieldChanged),
        takeEvery(ActionTypes.DELETE_RAPPORT_ROW, deleteRapportRow),
        takeEvery(ActionTypes.DELETE_DOCUMENT_ROW, deleteDocumentRow),
        takeEvery(ActionTypes.CONFIRM_CLOSE, confirmClose)
    ]);
}

function convertToAnswersObject(answersArray = []) {
    const answers = {};

    answersArray.forEach(answer => {
        answers[ answer[ IncidentAnalyseVraagAntwoordWorkingModel.VraagId ] ] = answer[ IncidentAnalyseVraagAntwoordWorkingModel.AntwoordId ];
    });

    return answers;
}

function convertToAnswersArray(answersObject = {}) {
    const answers =
         Object.keys(answersObject).map(k => ({
             [ IncidentAnalyseVraagAntwoordWorkingModel.VraagId ]: k,
             [ IncidentAnalyseVraagAntwoordWorkingModel.AntwoordId ]: answersObject[ k ]
         }))
    ;
    return answers;
}

function* getIncident(action) {
    try {
        yield put (Actions.clear());

        const incident = yield call (API.GetIncident, action.id);

        if (incident.success) {

            if (action.readonly !== true && incident.data[ IncidentWorkingModel.Afgesloten ] === true) {
                const defaultDialog = Builder.ok({
                    title: 'Dossier gesloten',
                    body: 'Dit dossier is gesloten, u kunt deze niet langer meer bewerken.',
                }).prepare();

                yield call(defaultDialog);

                yield put (push(Routes.Dashboard.path));
            }
            else {
                // convert array to object
                const answersArray = stateHelper(incident.data, [ IncidentAnalyseWorkingModel.Antwoorden ], []);

                const data = Object.assign({}, incident.data);
                data[ IncidentAnalyseWorkingModel.Antwoorden ] = convertToAnswersObject(answersArray);
                data[ Constants.FIELDS.DISABLE_MELDING_DOOR_WTP ] = data[ IncidentDetailUpdateWorkingModel.MeldingDoorWTP ] === true;

                yield put (Actions.loadedIncident(data));
            }
        }
        else {
            //TODO
        }

    }
    catch (e) {
        // TODO:
    }
}

function* getIncidents() {

    try {
        const incidents = yield call (API.GetAllIncidents);

        if (incidents.success) {

            yield put (Actions.loadedIncidents(incidents.data));
        }
        else {
            //TODO
        }

    }
    catch (e) {
        // TODO:
    }
}

function* addIncident(action) {
    try {
        const incident = yield call (API.AddIncident, action.model);

        if (incident.success) {
            yield put (ToastrActions.add({
                type: 'success',
                title: 'Incident dossier',
                attention: true, // This will add a shadow like the confirm method.
                message: 'Dossier is aangemaakt.',
                options: {
                    showCloseButton: true
                }
            }));
            yield put (push(Routes.Dashboard.Incident.Bewerken.createUrl(incident.data)));
            // reset the form
            yield call (action.frm.reset);
        }
        else {
            yield put (ToastrActions.add({
                type: 'error',
                title: 'Incident dossier',
                attention: true, // This will add a shadow like the confirm method.
                message: 'Dossier kon niet worden aangemaakt.',
                options: {
                    showCloseButton: true
                }
            }));
        }

    }
    catch (e) {
        // TODO:
    }
}

function* updateIncident(action) {
    try {

        const incident = yield select((state) => (stateHelper( state, [ Constants.STATE_KEY, Constants.FIELDS.DOSSIER ], {})));

        const gegevenAntwoorden = stateHelper(incident, IncidentAnalyseWorkingModel.Antwoorden);
        const gegevenAntwoordenArray = convertToAnswersArray(gegevenAntwoorden);

        const putData = Object.assign({} , incident, {
            [ IncidentDetailUpdateWorkingModel.Id ] : action.id,
            [ IncidentAnalyseDetailWorkingModel.Antwoorden ] : gegevenAntwoordenArray
        });

        const vragen = yield select(state => (stateHelper(state, [ Constants.STATE_KEY, Constants.FIELDS.DOSSIER, IncidentAnalyseWorkingModel.Vragen ], [])));

        if (!vragen.some(v => (v[ IncidentAnalyseVraagWorkingModel.Conclusie ] != null && v[ IncidentAnalyseVraagWorkingModel.Conclusie ][ IncidentAnalyseConclusieWorkingModel.Meldplicht ] ))) {
            // remove the value from MeldingDoorWTP when no melding should be send.
            delete putData[ IncidentDetailUpdateWorkingModel.MeldingDoorWTP ];
        }

        const result = yield call (API.UpdateIncident, putData);

        if (result.success) {
            yield put(ToastrActions.add({
                type: 'success',
                title: 'Incident dossier',
                attention: true, // This will add a shadow like the confirm method.
                message: 'Dossier is opgeslagen.',
                options: {
                    showCloseButton: true
                }
            }));
            yield put (push(Routes.Dashboard.path));
        }
        else {
            yield put(ToastrActions.add({
                type: 'error',
                title: 'Incident dossier',
                attention: true, // This will add a shadow like the confirm method.
                message: 'Dossier kon niet worden opgeslagen.',
                options: {
                    showCloseButton: true
                }
            }));
        }

    }
    catch (e) {
        // TODO:
    }
}

function* fieldChanged(action) {
    if (action[ Constants.ACTION_OPTIONS.ANALYSIS ] === Constants.ACTION_OPTIONS.ANALYSIS) {

        var incidentAnalyse = yield select((state) => (stateHelper( state, [ Constants.STATE_KEY, Constants.FIELDS.DOSSIER ], {})));
        var gegevenAntwoorden = stateHelper(incidentAnalyse, IncidentAnalyseWorkingModel.Antwoorden);

        // convert from object back to array
        const answers = convertToAnswersArray(gegevenAntwoorden);

        const payload = Object.assign({}, incidentAnalyse, {
            [ IncidentAnalyseWorkingModel.Antwoorden ] : answers
        } );

        try {
            const result = yield call(AnalysisAPI.Analyse, payload);

            const answersArray = stateHelper(result.data, [ IncidentAnalyseWorkingModel.Antwoorden ], []);

            const data = Object.assign({}, result.data);

            data[ IncidentAnalyseWorkingModel.Antwoorden ] = convertToAnswersObject(answersArray);

            yield put (Actions.intermediateAnalysisComplete(data));
        }
        catch (e) {
            // TODO:
        }

    }
}

function* deleteRapportRow (action) {

    const defaultDialog = Builder.confirmDelete({
        onJa : Actions.confirmedDeleteRapportRow(action.id)
    }).prepare();

    yield call(defaultDialog);
}

function* deleteDocumentRow (action) {

    const defaultDialog = Builder.confirmDelete({
        onJa : Actions.confirmedDeleteDocumentRow(action.id)
    }).prepare();

    yield call(defaultDialog);
}

function* confirmClose(action) {

    const defaultDialog = Builder.confirmDelete({
        title: 'Dossier afsluiten',
        body: <p>U staat op het punt dit dossier af te sluiten. Hierna zijn wijzigingen niet meer mogelijk.<br/>Wilt u doorgaan?</p>,
        onJa : Actions.updateIncident(action.id)
    }).prepare();

    yield call(defaultDialog);

}