import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import { Panel, FormGroup,Col, Row } from 'react-bootstrap';

import {
    Container,
    ForgotPasswordWorkingModel,
    FieldSetup,
    Button,
    LoginInputs
} from './_references';

class WachtwoordVergeten extends React.Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
    }

    render() {

        const { requestPassword, bind, onChange } = this.props;

        return(

            <Container>
                <Row>
                    <Col mdOffset={2} lgOffset={3} md={8} lg={6}>
                        <Panel>
                            <Panel.Body>
                                <Formsy ref={this.form} className="form-horizontal" data-toggle="validator" onValidSubmit={(model) => {
                                    requestPassword(model, this.form.current);
                                }} >

                                    <LoginInputs.Text
                                        {...bind(ForgotPasswordWorkingModel.Emailadres)}
                                        {...new FieldSetup()
                                            .for(ForgotPasswordWorkingModel.Emailadres)
                                            .withFriendlyName('E-mail')
                                            .setLabel()
                                            .setAutoFocus()
                                            .setPlaceholder()
                                            .isRequired()
                                            .isEmail()
                                            .onChange(onChange)
                                        }
                                    />

                                    <FormGroup>
                                        <Col xsHidden sm={12} className="text-right">
                                            <Button type="submit" bsStyle="primary" default>Wachtwoord herstellen</Button>
                                        </Col>
                                        <Col smHidden mdHidden lgHidden xs={12} >
                                            <Button type="submit" bsStyle="primary" block default>Wachtwoord herstellen</Button>
                                        </Col>
                                    </FormGroup>
                                </Formsy>
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </Container>
        );
    }
}
WachtwoordVergeten.propTypes = {
    requestPassword: PropTypes.func.isRequired,
    bind: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
};

export default WachtwoordVergeten;