import React from 'react';
import PropTypes from 'prop-types';
import { Button as BootstrapButton } from 'react-bootstrap';

class Button extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            clicked: false
        };
    }

    componentDidUpdate(_prevProps, _prevState, snapshot = {}) {
        if (snapshot.isLoading === false && this.clicked === true) {
            this.setState({
                clicked: false
            });
        }
    }

    render () {
        const { onClick, to, children, dispatch, isLoading, disabled, routeTo, type, ...otherProps } = this.props;

        const onClickInternal = (...rest) => {
            this.setState({
                clicked: true
            });

            if (!disabled) {
                if (onClick != null) {
                    onClick(...rest);

                }
                if (to != null && (type || '').toLowerCase() !== 'submit') {
                    routeTo(to);
                }
            }
        };

        const props = Object.assign({}, otherProps, {
            onClick: onClickInternal,
            children: this.state.clicked && isLoading ? <span><span className="fa fa-refresh fa-spin"/> {children}</span> : children,
            disabled: isLoading || disabled,
            type
        });

        return (<BootstrapButton {...props} />);
    }
}

Button.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.node,
    dispatch: PropTypes.func,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool
};

export default Button;