import * as AuthenticationActionTypes from '../shared/authentication/actionTypes';
import * as ActionTypes from './actionTypes';
import * as Constants from './constants';
import setStateValue from '../../util/setStateValue';
import { } from '../../model';

/*
 * Reducer for the login component.
*/
const reducer = (state = {}, action) => {
    switch(action.type){
    case AuthenticationActionTypes.LOGGED_IN:
    {
        return setStateValue( Constants.FIELDS.LOGGED_IN, action.value, state);
    }
    case AuthenticationActionTypes.SET_ACCOUNT_WARNING:
    {
        return setStateValue( Constants.FIELDS.SHOW_ACCOUNT_WARNING, action.value, state);
    }
    case AuthenticationActionTypes.SET_SELECT_ACCOUNT:
    {
        return setStateValue( Constants.FIELDS.SELECT_ACCOUNT, action.value, state);
    }
    case AuthenticationActionTypes.REQUIRES_VERIFICATION:
    {
        let newState = setStateValue( Constants.FIELDS.REQUIRES_VERIFICATION, action.value, state);
        if (action.value === false) {
            newState = setStateValue ('key', null, newState);
        }
        return newState;
    }
    case AuthenticationActionTypes.SET_ROLES:
    {
        return setStateValue( Constants.FIELDS.ROLES, action.value, state);
    }
    case ActionTypes.CLEAR: {
        if (action.clearPartial === true) {
            let newState = setStateValue ('key', null, state);
            newState = setStateValue ('email', null, newState);
            newState = setStateValue ('password', null, newState);
            newState = setStateValue (Constants.FIELDS.REQUIRES_VERIFICATION, null, newState);
            return newState;
        }

        return {};
    }
    default:
        return state;
    }
};

reducer.STATE_KEY = Constants.STATE_KEY;

export default reducer;