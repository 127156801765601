import { connect } from 'react-redux';

import stateManager from '../../util/stateManager';

import PrivacyCheckComponent from './index.component';
import * as Actions from './actions';
import * as Constants from './constants';

const stateToProps = stateManager([ Constants.STATE_KEY ], (get) => ({
    loading: get(Constants.FIELDS.LOADING),
    question: get(Constants.FIELDS.QUESTION),
    finished: get(Constants.FIELDS.OUTCOME)
}));

const dispatchToProps = (dispatch) => ({
    loadQuestion: (sortOrder) => dispatch(Actions.loadQuestion(sortOrder)),
    questionAnswered: (answer) => dispatch(Actions.questionAnswered(answer))
});

export default connect(stateToProps, dispatchToProps)(PrivacyCheckComponent);