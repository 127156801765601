import React from 'react';
import PropTypes from 'prop-types';

import {
    PersoonDetailWorkingModel,
    Text,
    Radio,
    FieldSetup
} from './_references';

const PersoonEditor = ({ bind, onChange }) => (
    <div>
        <Radio
            {...bind(PersoonDetailWorkingModel.Aanhef)}
            {...new FieldSetup()
                .for(PersoonDetailWorkingModel.Aanhef)
                .withFriendlyName('Aanhef')
                .setLabel()
                .setPlaceholder()
                .isRequired()
                .onChange(onChange)
            }

            options={[ {
                key: 'm', value: 'Dhr'
            },{
                key: 'v', value: 'Mvr'
            } ]}
            optionKey="key"
            optionValue="value"
        />

        <Text
            {...bind(PersoonDetailWorkingModel.Voornaam)}
            {...new FieldSetup()
                .for(PersoonDetailWorkingModel.Voornaam)
                .withFriendlyName('Voornaam')
                .setLabel()
                .setPlaceholder()
                .setAutoFocus()
                .isRequired()
                .maxLength(100)
                .onChange(onChange)
            }
        />

        <Text
            {...bind(PersoonDetailWorkingModel.Tussenvoegsel)}
            {...new FieldSetup()
                .for(PersoonDetailWorkingModel.Tussenvoegsel)
                .withFriendlyName('Tussenvoegsels')
                .setLabel()
                .setPlaceholder('Tussenvoegsels (optioneel)')
                .maxLength(50)
                .onChange(onChange)
            }
        />

        <Text
            {...bind(PersoonDetailWorkingModel.Achternaam)}
            {...new FieldSetup()
                .for(PersoonDetailWorkingModel.Achternaam)
                .withFriendlyName('Achternaam')
                .setLabel()
                .setPlaceholder()
                .isRequired()
                .maxLength(100)
                .onChange(onChange)
            }
        />

        <Text
            {...bind(PersoonDetailWorkingModel.Telefoonnummer)}
            {...new FieldSetup()
                .for(PersoonDetailWorkingModel.Telefoonnummer)
                .withFriendlyName('Telefoonnummer')
                .setLabel('Telefoonnummer')
                .setPlaceholder()
                .isRequired()
                .maxLength(20)
                .isPhoneNumber()
                .onChange(onChange)
            }
        />

        <Text
            {...bind(PersoonDetailWorkingModel.Email)}
            {...new FieldSetup()
                .for(PersoonDetailWorkingModel.Email)
                .withFriendlyName('E-mailadres')
                .setLabel('E-mailadres')
                .setPlaceholder()
                .isRequired()
                .isEmail()
                .maxLength(255)
                .onChange(onChange)
            }
        />
    </div>
);

PersoonEditor.propTypes = {
    bind: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
};

export default PersoonEditor;