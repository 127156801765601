import {
    stateHelper,
    Constants,
    Actions
} from './_references';

export const mapStateToProps = (state) => ({
    isLoading: stateHelper(state, [ Constants.STATE_KEY, Constants.FIELDS.AdvisoryLoading ], false),
    model: stateHelper(state, [ Constants.STATE_KEY, Constants.FIELDS.Advisory ], {}),
});

export const mapDispatchToProps = (dispatch) => ({
    load: () => dispatch(Actions.loadAdvisory()),
    clear: () => dispatch(Actions.clearAdvisory()),
    copyExisting: () => dispatch(Actions.copyExisting()),
    createNew: () => dispatch(Actions.createNew())
});