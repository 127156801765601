import { all, call, put, takeLatest, delay } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { actions as ToastrActions } from 'react-redux-toastr';

import {
    Api,
    AccountActiverenWorkingModel,
    Routes
} from './_references';

import * as ActionTypes from './actionTypes';
import * as Actions from './actions';

export default function* () {

    yield all([
        takeLatest(ActionTypes.ACTIVATE_ACCOUNT, activateAccount)
    ]);
}

function* activateAccount(action) {

    yield delay(250);

    try{
        const result = yield call (Api.activateAccount, action.model);

        if (result.success) {

            if (result.data[ AccountActiverenWorkingModel.Success ] === true) {

                yield put(Actions.clear());
                action.form.reset();

                yield put (ToastrActions.add({
                    type: 'success',
                    title: 'Account activeren',
                    message: 'Het account is geactiveerd.',
                    options: {
                        showCloseButton: true,
                        attention: false, // This will add a shadow like the confirm method.

                    }
                }));
                yield put (push(Routes.Account.Geactiveerd.path));
            }
            else {
                // TODO:
                yield put (ToastrActions.add({
                    type: 'error',
                    title: 'Account activeren',
                    message: 'Het account kon niet worden geactiveerd. Neem contact op met W.T. Privacy.',
                    options: {
                        timeOut: 0,
                        attention: true, // This will add a shadow like the confirm method.
                        showCloseButton: true,
                        onAttentionClick: () => {/* avoid closing by clicking on the shadow */}
                    }
                }));
            }
        }
        else {
            yield put (ToastrActions.add({
                type: 'error',
                title: 'Account activeren',
                message: 'Het account kon niet worden geactiveerd. Neem contact op met W.T. Privacy.',
                options: {
                    timeOut: 0,
                    attention: true, // This will add a shadow like the confirm method.
                    showCloseButton: true,
                    onAttentionClick: () => {/* avoid closing by clicking on the shadow */}
                }
            }));
        }
    }
    catch(e){
        // yield put (ToastrActions.add({
        //     type: 'error',
        //     title: 'Account aanvragen',
        //     attention: true, // This will add a shadow like the confirm method.
        //     message: 'Uw account kon niet worden aangemaakt. Neem contact op met W.T. Privacy.',
        //     options: {
        //         showCloseButton: true
        //     }
        // }));
    }

}
