import { all, call, put, takeEvery } from 'redux-saga/effects';
import { actions as ToastrActions } from 'react-redux-toastr';

import {
    API
} from './_references';

import * as ActionTypes from './actionTypes';
import * as Actions from './actions';

export default function* () {
    yield all([
        takeEvery(ActionTypes.LOAD_MIJNACCOUNT, getMijnAccount),
        takeEvery(ActionTypes.UPDATE_MIJNACCOUNT, updateMijnAccount)
    ]);
}

function* getMijnAccount() {
    try {

        const mijnAccount = yield call (API.getMijnAccount);
        if (mijnAccount.success) {
            yield put (Actions.loadedMijnAccount(mijnAccount.data));
        }
        else {
            //TODO
        }
    }
    catch (e) {
        // TODO:
    }
}

function* updateMijnAccount(action) {

    try {
        yield call(API.updateMijnAccount, action.model);

        yield put(ToastrActions.add({
            type: 'success',
            title: 'Mijn account',
            attention: true, // This will add a shadow like the confirm method.
            message: 'Uw account informatie is opgeslagen.',
            options: {
                showCloseButton: true
            }
        }));
    }
    catch(e) {
        // TODO:
    }

}