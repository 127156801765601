import React from 'react';
import PropTypes from 'prop-types';
import { Panel, Row, Col } from 'react-bootstrap';
import Formsy from 'formsy-react';

import{
    Container,
    Password,
    FieldSetup,
    WachtwoordInstellenDetailWorkingModel,
    Button
} from './_references';

class Bevestigen extends React.Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
    }

    render () {

        const { bind, onChange, set } = this.props;

        return(
            <Container>
                <Formsy ref={this.form} className="form-horizontal" onValidSubmit={(model)=>{
                    set(model, this.form.current);
                }}>
                    <Panel>
                        <Panel.Body>
                            <div className="panel-inline-title">Wachtwoord instellen</div>

                            <div>U kunt nu een nieuw wachtwoord opgeven.</div>

                            <div className="h-spacer"/>

                            <div>
                                <Password
                                    {...bind(WachtwoordInstellenDetailWorkingModel.Password)}
                                    {...new FieldSetup()
                                        .for(WachtwoordInstellenDetailWorkingModel.Password)
                                        .withFriendlyName('Wachtwoord')
                                        .setLabel()
                                        .setPlaceholder()
                                        .setAutoFocus()
                                        .isRequired()
                                        .password(8, 75)
                                        .maxLength(200)
                                        .onChange(onChange)
                                    }
                                />
                                <Password
                                    {...bind(WachtwoordInstellenDetailWorkingModel.PasswordRepeat)}
                                    {...new FieldSetup()
                                        .for(WachtwoordInstellenDetailWorkingModel.PasswordRepeat)
                                        .withFriendlyName('Herhaal wachtwoord')
                                        .setLabel()
                                        .setPlaceholder()
                                        .isRequired()
                                        .equalsField('Wachtwoord', WachtwoordInstellenDetailWorkingModel.Password)
                                        .password(8, 75)
                                        .maxLength(200)
                                        .onChange(onChange)
                                    }
                                />
                            </div>

                        </Panel.Body>
                    </Panel>
                    <Row>
                        <Col xs={12} className="text-right">
                            <Button bsStyle="primary" type="submit"> Bevestigen</Button>
                        </Col>
                    </Row>
                </Formsy>
            </Container>
        );
    }
}
Bevestigen.propTypes = {
    bind: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    set: PropTypes.func.isRequired
};

export default Bevestigen;