import * as ActionTypes from './actionTypes';

export const loadAdvisory = () => ({
    type: ActionTypes.LOAD_PIA_ADVISORY
});

export const loadedAdvisory = (model) => ({
    type: ActionTypes.LOADED_PIA_ADVISORY,
    model
});

export const clearAdvisory = () => ({
    type: ActionTypes.CLEAR_PIA_ADVISORY
});

export const loadPiaList = () => ({
    type: ActionTypes.LOAD_PIA_LIST
});

export const loadedList = (model) => ({
    type: ActionTypes.LOADED_PIA_LIST,
    model
});

export const loadPia = (id, readonly) => ({
    type: ActionTypes.LOAD_PIA,
    id,
    readonly
});

export const loadedPia = (model) => ({
    type: ActionTypes.LOADED_PIA,
    model
});

export const updatePia = (id) => ({
    type: ActionTypes.UPDATE_PIA,
    id
});

export const closePia = (form) => ({
    type: ActionTypes.CLOSE_PIA,
    form
});

export const intermediateAnalysisComplete = (model) => ({
    type: ActionTypes.INTERMEDIATE_ANALYSIS_COMPLETE,
    model
});

export const copyExisting = () => ({
    type: ActionTypes.COPY_EXISTING
});

export const createNew = () => ({
    type: ActionTypes.CREATE_NEW
});