//import React from 'react';
import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { actions as ToastrActions } from 'react-redux-toastr';

import {
    AlgemeneRegisterInformatieActionTypes,
    API,
    Builder,
    Routes,
    stateHelper,
    VerwerkingDetailWorkingModel,
    VerwerkingUpdateWorkingModel
} from './_references';

import * as ActionTypes from './actionTypes';
import * as Actions from './actions';
import * as Constants from './constants';

export default function* () {
    yield all([
        takeEvery(ActionTypes.GET_VERWERKING, getVerwerking),
        takeEvery(ActionTypes.GET_VERWERKINGEN, getVerwerkingen),
        takeEvery(ActionTypes.ADD_VERWERKING, addVerwerking),
        takeEvery(ActionTypes.DELETE_VERWERKING, deleteVerwerking),
        takeEvery(ActionTypes.CONFIRMED_DELETE_VERWERKING, confirmedDeleteVerwerking),
        takeEvery(ActionTypes.EDIT_VERWERKING, editVerwerking),
        takeEvery(ActionTypes.UPDATE_VERWERKING, updateVerwerking),
        takeEvery(AlgemeneRegisterInformatieActionTypes.UPDATE_INFORMATIE, getVerwerkingen)
    ]);
}

function* getVerwerking(action) {
    try {

        const verwerking = yield call (API.GetVerwerking, action.id);

        if (verwerking.success) {

            yield put (Actions.loadedVerwerking(verwerking.data, action.source));
        }
        else {
            //TODO
        }
    }
    catch (e) {
        // TODO:
    }
}

function* getVerwerkingen() {

    try {
        const verwerkingen = yield call (API.GetAllVerwerkingen);

        if (verwerkingen.success) {

            yield put (Actions.loadedVerwerkingen(verwerkingen.data));
        }
        else {
            //TODO
        }
    }
    catch (e) {
        // TODO:
    }
}

function* addVerwerking(action) {
    try {

        const model = Object.assign({}, action.model);
        if (model[ VerwerkingDetailWorkingModel.AfwijkendeVerantwoordelijke ] === 'false') {
            delete model[ VerwerkingDetailWorkingModel.AfwijkendeVerantwoordelijkeId ];
        }

        const verwerking = yield call (API.AddVerwerking, model);

        if (verwerking.success) {
            yield put (ToastrActions.add({
                type: 'success',
                title: 'Verwerking',
                attention: true, // This will add a shadow like the confirm method.
                message: 'Verwerking is aangemaakt.',
                options: {
                    showCloseButton: true
                }
            }));
            yield put (push(Routes.Dashboard.Verwerkingsregister.path));
        }
        else {
            yield put (ToastrActions.add({
                type: 'error',
                title: 'Verwerking',
                attention: true, // This will add a shadow like the confirm method.
                message: 'Verwerking kon niet worden aangemaakt.',
                options: {
                    showCloseButton: true
                }
            }));
        }
    }
    catch (e) {
        // TODO:
    }
}

function* updateVerwerking() {
    try {

        const verwerking = yield select((state) => (stateHelper( state, [ Constants.STATE_KEY, Constants.FIELDS.EDITOR ], {})));

        const model = Object.assign({}, verwerking);
        if (model[ VerwerkingUpdateWorkingModel.AfwijkendeVerantwoordelijke ] === 'false') {
            delete model[ VerwerkingUpdateWorkingModel.AfwijkendeVerantwoordelijkeId ];
        }

        const result = yield call (API.UpdateVerwerking, model);

        if (result.success) {
            yield put(ToastrActions.add({
                type: 'success',
                title: 'Verwerking',
                attention: true, // This will add a shadow like the confirm method.
                message: 'Verwerking is opgeslagen.',
                options: {
                    showCloseButton: true
                }
            }));
            yield put(Actions.cancelEdit());
            yield put(Actions.getVerwerkingen());
        }
        else {
            yield put(ToastrActions.add({
                type: 'error',
                title: 'Verwerking',
                attention: true, // This will add a shadow like the confirm method.
                message: 'Verwerking kon niet worden opgeslagen.',
                options: {
                    showCloseButton: true
                }
            }));
        }
    }
    catch (e) {
        // TODO:
    }
}

function* editVerwerking(action) {
    yield getVerwerking(action);

}

function* deleteVerwerking(action) {

    const defaultDialog = Builder.confirmDelete({
        onJa : Actions.confirmedDeleteVerwerking(action.id)
    }).prepare();

    yield call(defaultDialog);
}

function* confirmedDeleteVerwerking(action) {
    // the reducer will already have deleted this record from the redux store
    const result =yield call(API.DeleteVerwerking, action.id);

    if (!result.success) {
        // reload list
        yield getVerwerkingen();

        yield put(ToastrActions.add({
            type: 'error',
            title: 'Verwerking',
            attention: true, // This will add a shadow like the confirm method.
            message: 'Het was helaas niet mogelijk de verwerking te verwijderen. Probeer het later nog eens.',
            options: {
                showCloseButton: true
            }
        }));
    }
}
