import React from 'react';
import validationErrorsBase from './errors';

const overridedValidationErrors = {

    'LP01': <span>U kunt niet inloggen met de opgegeven gegevens.<br/><br/> Dit kan om verschillende redenen zijn: <ul>
        <li>De combinatie van gebruikersnaam en wachtwoord klopt niet, of;</li>
        <li>Uw heeft een ongeldige code ingevoerd, of;</li>
        <li>Uw account is geblokkeerd.</li>
    </ul>
        U kunt dit eventueel oplossen door gebruik te maken van de link &quot;Wachtwoord vergeten?&quot;.
    </span>,
    'LP02': 'De gegevens konden niet worden gevalideerd',
    'SECA02': 'Bedrijfsnaam is een verplicht veld',
    'SECA03': 'Telefoonnummer is een verplicht veld',
    'SECA04': 'Straat is een verplicht veld',
    'SECA05': 'Huisnummer is een verplicht veld',
    'SECA06': 'Postcode is een verplicht veld',
    'SECA07': 'Plaats is een verplicht veld',
    'SECA08': 'Aanhef is een verplicht veld',
    'SECA09': 'Aanhef bevat een ongeldige waarde',
    'SECA10': 'Voornaam is een verplicht veld',
    'SECA11': 'Achternaam is een verplicht veld',
    'SECA12': 'E-mailadres is een verplicht veld',
    'SECA13': 'E-mailadres en Herhaal e-mailadres komen niet overeen',
    'SECA14': 'Wachtwoord is een verplicht veld',
    'SECA15': 'Wachtwoord en Herhaal wachtwoord komen niet overeen',
    'SECA16': 'Niet akkoord gegaan met wijze van facturatie',
    'SECA17': 'Niet akkoord gegaan met verwerking van persoonsgegevens',
    'SECA18': 'Niet akkoord gegaan met algemene voorwaarden',
    'SECA19': 'Het veld e-mailadres bevat geen geldig e-mailadres',
    'SECA20': 'E-mailadres mag maar {1} karakters lang zijn',
    'SECA21': 'Aanhef mag maar {1} karakters lang zijn',
    'SECA22': 'Voornaam mag maar {1} karakters lang zijn',
    'SECA23': 'Tussenvoegsel mag maar {1} karakters lang zijn',
    'SECA24': 'Achternaam mag maar {1} karakters lang zijn',
    'SECA25': 'Straat mag maar {1} karakters lang zijn',
    'SECA26': 'Huisnummer mag maar {1} karakters lang zijn',
    'SECA27': 'Toevoeging mag maar {1} karakters lang zijn',
    'SECA28': 'Postcode mag maar {1} karakters lang zijn',
    'SECA29': 'Plaats mag maar {1} karakters lang zijn',
    'SECA30': 'KvK mag maar {1} karakters lang zijn',
    'SECA31': 'Bedrijfsnaam mag maar {1} karakters lang zijn',
    'SECA32': 'Telefoonnummer mag maar {1} karakters lang zijn',
    'SECA33': 'Wachtwoord is niet sterk genoeg',
    'SECA34': 'Postcode is onjuist',
    'SECA35': 'Het veld telefoonnummer bevat geen geldig telefoonnummer',
    'SECA36': 'Sector is een verplicht veld',
    'SECA37': 'Onbekende sector ingevuld',
    'SECA38': 'KvK moet minimaal 9 karakters lang zijn',
    'SECA39': 'Uw voornaam mag niet voorkomen in het wachtwoord',
    'SECA40': 'Uw achternaam mag niet voorkomen in het wachtwoord',
    'SECA41': 'Uw e-mailadres mag niet voorkomen in het wachtwoord',
    'SECA42': 'Eigenaar aanhef is een verplicht veld',
    'SECA43': 'Eigenaar aanhef bevat een ongeldige waarde',
    'SECA44': 'Eigenaar aanhef mag maar {1} karakters lang zijn',
    'SECA45': 'Eigenaar voornaam is een verplicht veld',
    'SECA46': 'Eigenaar voornaam mag maar {1} karakters lang zijn',
    'SECA47': 'Eigenaar tussenvoegsel mag maar {1} karakters lang zijn',
    'SECA48': 'Eigenaar achternaam is een verplicht veld',
    'SECA49': 'Eigenaar achternaam mag maar {1} karakters lang zijn',
    'SECA50': 'Eigenaar is een verplicht veld',

    'SEI01': 'Naam is verplicht',
    'SEI02': 'Naam mag maar {1} karakters lang zijn',
    'SEI03': 'Moment ontdekking kan niet in de toekomst liggen',
    'SEI04': 'Moment ontdekking is een verplicht veld',
    'SEI05': 'Beschrijving mag maar {1} karakters lang zijn',
    'SEI06': 'Ongeldige combinatie van vragen en antwoorden ontvangen',
    'SEI07': 'Melding door W.T. Privacy is niet beschikbaar',
    'SEI08': 'Meldingsnummer mag maar {1} karakters lang zijn',
    'SEI09': 'Meldingsnummer is ongeldig',
    'SEI10': 'Conclusie mag maar {1} karakters lang zijn',
    'SEI11': 'Eind conclusie mag maar {1} karakters lang zijn',
    'SEI12': 'Vervolg acties mag maar {1} karakters lang zijn',
    'SEI13': 'Moment (incident rapport) mag niet in de toekomst liggen',
    'SEI14': 'Moment (incident rapport) is een verplicht veld',
    'SEI15': 'Situatie omschrijving (incident rapport) is een verplicht veld',
    'SEI16': 'Situatie omschrijving (incident rapport) mag maar {1} karakters lang zijn',
    'SEI17': 'Link naar het document is verplicht',
    'SEI18': 'Link naar het document mag maar {1} karakters lang zijn',
    'SEI19': 'Link naar het document is geen geldige link',
    'SEI20': 'Document omschrijving is verplicht',
    'SEI21': 'Document omschrijving mag maar {1} karakters lang zijn',

    'SEG01': 'Record id is niet opgegeven',
    'SEG02': 'Dit record bestaat niet',

    'SEV01' : 'Doel is een verplicht veld',
    'SEV02' : 'Catogorieën is een verplicht veld',
    'SEV03' : 'Categorieën persoonsgegevens is een verplicht veld',
    'SEV04' : 'Categorieën persoonsgegevens bevat een ongeldige waarde',
    'SEV05' : 'Wettelijke Grondslag is niet beschikbaar',
    'SEV06' : 'Wettelijke Grondslag is een verplicht veld',
    'SEV07' : 'Wettelijke Grondslag Toelichting is een verplicht veld',
    'SEV08' : 'Wettelijke Grondslag Toelichting is niet beschikbaar',
    'SEV09' : 'Delen buiten de EU is een verplicht veld',
    'SEV10' : 'De tekst in Bewaartermijn is te lang',
    'SEV11' : 'Opdrachtgever is een verplicht veld',
    'SEV12' : 'Afwijkende Verantwoordelijke is een verplicht veld',
    'SEV13' : 'Afwijkende Verantwoordelijke Naam is niet beschikbaar',
    'SEV14' : 'Afwijkende Verantwoordelijke Naam is een verplicht veld',
    'SEV15' : 'Afwijkende Verantwoordelijke Naam is onbekend',

    'SEVI01' : 'Organisatie naam is verplicht',
    'SEVI02' : 'Organisatie naam mag maar {1} karakters lang zijn',
    'SEVI03' : 'Contactpersoon is verplicht',
    'SEVI04' : 'Contactpersoon mag maar {1} karakters lang zijn',
    'SEVI05' : 'Contact gegevens is verplicht',
    'SEVI06' : 'Contact gegevens mag maar {1} karakters lang zijn',
    'SEVI07' : 'Doel is verplicht',
    'SEVI08' : 'Persoonsgegevens is verplicht',
    'SEVI09' : 'Datum overeenkomst is verplicht',
    'SEVI10' : 'Datum overeenkomst mag niet in de toekomst liggen',
    'SEVI11' : 'Link naar de overeenkomst mag maar {1} karakters lang zijn',
    'SEVI12' : 'Link naar de overeenkomst is geen geldige link',
    'SEVI13' : 'Omschrijving van de overeenkomst mag maar {1} karakters lang zijn',
    'SEVI14' : 'Het veld e-mailadres bevat een geen geldig e-mailadres',

    'SERI01' : 'Vertegenwoordiger is een verplicht veld',
    'SERI02' : 'Vertegenwoordiger is onbekend',
    'SERI03' : 'Functionaris voor de gegevensbescherming is een verplicht veld',
    'SERI04' : 'Functionaris voor de gegevensbescherming is onbekend',
    'SERI05' : 'Verwerkingsverantwoordelijke is een verplicht veld',
    'SERI06' : 'Verwerkingsverantwoordelijke is onbekend',

    'SEP01' : 'Aanhef is een verplicht veld',
    'SEP02' : 'Aanhef bevat een ongeldige waarde',
    'SEP03' : 'Aanhef mag maar {1} karakters lang zijn',
    'SEP04' : 'Voornaam is een verplicht veld',
    'SEP05' : 'Voornaam mag maar {1} karakters lang zijn',
    'SEP06' : 'Tussenvoegsel mag maar {1} karakters lang zijn',
    'SEP07' : 'Achternaam is een verplicht veld',
    'SEP08' : 'Achternaam mag maar {1} karakters lang zijn',
    'SEP09' : 'Telefoonnummer is een verplicht veld',
    'SEP10' : 'Telefoonnummer mag maar {1} karakters lang zijn',
    'SEP11' : 'Het veld telefoonnummer bevat geen geldig telefoonnummer',
    'SEP12' : 'Emailadres is een verplicht veld',
    'SEP13' : 'Emailadres mag maar {1} karakters lang zijn',
    'SEP14' : 'Het veld emailadres bevat een geen geldig emailadres',
    'SEP15' : 'Deze persoon kan niet verwijderd worden, omdat deze persoon nog gekoppeld staat aan de bedrijfsgegevens, algemene register informatie of het verwerkingsregister',

    'SEMA01' : 'Mag koppelen is een verplicht veld',
};

const validationErrors = Object.assign({}, validationErrorsBase, overridedValidationErrors);

export default validationErrors;