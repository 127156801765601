import { all, call, put, takeLatest, delay } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { actions as ToastrActions } from 'react-redux-toastr';

import {
    Api,
    AccountBevestigdWorkingModel,
    Routes
} from './_references';

import * as ActionTypes from './actionTypes';
import * as Actions from './actions';

export default function* () {
    yield all([
        takeLatest(ActionTypes.CONFIRM_ACCOUNT, confirmAccount)
    ]);
}

function* confirmAccount(action) {
    yield delay(250);

    try{
        const result = yield call (Api.confirmAccount, action.model);

        if (result.success) {

            if (result.data[ AccountBevestigdWorkingModel.Success ] === true) {

                yield put(Actions.clear());
                action.form.reset();

                yield put (ToastrActions.add({
                    type: 'success',
                    title: 'Aanvraag bevestigen',
                    attention: true, // This will add a shadow like the confirm method.
                    message: 'Uw aanvraag is bevestigd.',
                    options: {
                        showCloseButton: true
                    }
                }));
                yield put (push(Routes.Account.Bevestigd.path));
            }
            else {
                // TODO:
                yield put (ToastrActions.add({
                    type: 'error',
                    title: 'Aanvraag bevestigen',
                    attention: true, // This will add a shadow like the confirm method.
                    message: 'Uw aanvraag kon niet worden bevestigd.',
                    options: {
                        showCloseButton: true
                    }
                }));
            }
        }
        else {
            yield put (ToastrActions.add({
                type: 'error',
                title: 'Aanvraag bevestigen',
                attention: true, // This will add a shadow like the confirm method.
                message: 'Uw aanvraag kon niet worden bevestigd. Neem contact op met W.T. Privacy.',
                options: {
                    showCloseButton: true
                }
            }));
        }
    }
    catch(e){
        // yield put (ToastrActions.add({
        //     type: 'error',
        //     title: 'Account aanvragen',
        //     attention: true, // This will add a shadow like the confirm method.
        //     message: 'Uw account kon niet worden aangemaakt. Neem contact op met W.T. Privacy.',
        //     options: {
        //         showCloseButton: true
        //     }
        // }));
    }

}
