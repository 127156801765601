// https://bootsnipp.com/snippets/featured/funky-radio-buttons

import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';

import Input from '../../atoms/input';

const RadioComponent = ({ value, ...props }) => {
    return (<Input {...props} type='radio' value={value} />);
};

RadioComponent.propTypes = {
    value: PropTypes.any.isRequired
};

const Radio = ({ name, value, options, optionKey, optionValue, ...props }) => (
    (options || []).map((option, index) => (
        <Col key={option[ optionKey ]} xs={12} sm={options.length === 2 ? 6 : 12}>
            <div className="funkyradio">
                <div className="funkyradio-primary">
                    <RadioComponent id={`${name}_${index}`} name={name} value={option[ optionKey ]} checked={option[ optionKey ] === value} {...props} />
                    <label htmlFor={`${name}_${index}`}>{option[ optionValue ]}</label>
                </div>
            </div>
        </Col>
    )));

Radio.propTypes = {
    value: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool ]),
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    optionKey: PropTypes.string.isRequired,
    optionValue: PropTypes.string.isRequired
};

export default Radio;
