export const NAME = 'Verwerkersregister';

export const STATE_KEY = `${NAME}/ac5e457a-e3f5-4a4d-84f1-2bb25bd0aba4`;

export const FIELDS = {
    ADD: 'ADD',
    LIST: 'LIST',
    DETAILS: 'DETAILS',
    EDITOR: 'EDITOR',
    LOADING: 'LOADING'
};

export const SOURCES = {
    LIST: 'LIST',
    EDITOR: 'EDITOR'
};