import { all, call, put, takeLatest, delay } from 'redux-saga/effects';

import * as Api from '../../api/dictionary';
import * as ActionTypes from './actionTypes';
import * as Actions from './actions';

export default function* () {
    yield all([
        takeLatest(ActionTypes.LOAD, loadDictionary)
    ]);
}

function* loadDictionary(action) {
    yield delay(250);
    yield put(Actions.clear());
    try{
        const result = yield call(Api.get, action.filter);

        if (result.success) {
            yield put (Actions.loaded(result.data));
        }
    }
    catch (e) {
        /**/
    }

}