import * as Helpers from './helpers';
import * as Routes from './constants.routes';

/**
 * Intermediate posts to the analysis engine
 *
 * @param {*} model
 */
export const Analyse = (model) => {
    return Helpers.post(`${Routes.IncindentAnalyse}`, model);
};