export const NAME = 'Verwerkingsregister';

export const STATE_KEY = `${NAME}/cd29a393-d0f9-4df4-83fa-29bf093cef91`;

export const FIELDS = {
    ADD: 'ADD',
    LIST: 'LIST',
    DETAILS: 'DETAILS',
    EDITOR: 'EDITOR',
    LOADING: 'LOADING'
};

export const SOURCES = {
    LIST: 'LIST',
    EDITOR: 'EDITOR'
};