const emailValidation = (propertyName) => {
    return {
        validators: {
            email: true,
        },
        validationErrors: {
            email: `${ propertyName } is geen geldig e-mailadres`,
        },
        validationError: `${ propertyName } is ongeldig`,
    };
};

export default emailValidation;