import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import FaIcon from 'react-fontawesome';
import { Panel, Row, Col, DropdownButton, MenuItem } from 'react-bootstrap';

import {
    VerwerkerInfoWorkingModel,
    Constants,
    Button,
    Inzage,
    Editor,
    prepareValue,
    Loading
} from './_references';

class VerwerkerLijst extends React.Component {

    componentDidMount() {
        this.props.loadVerwerkeren();
    }

    render() {
        const { isLoading, list, top, to, editable = false, showDetails, hideDetails, editVerwerker, deleteVerwerker, editorObject, bind, onChange, cancelEdit, updateVerwerker } = this.props;

        return (

            isLoading ? <Loading/> :
                <Panel>
                    <Panel.Body>
                        <div className="panel-inline-title">Registraties</div>

                        <Row><Col xsHidden sm={12}>
                            <Row className="row-table row-table-header">
                                <Col xs={2}>Naam organisatie</Col>
                                <Col xs={3}>Contactpersoon</Col>
                                <Col xs={3}>E-mailadres</Col>
                                <Col xs={2}>Overeenkomst</Col>
                                {editable ? <Col xs={2} className="text-right"></Col> : <Col xs={2}>Overeenkomst afgesloten</Col>}
                            </Row>
                        </Col></Row>

                        {
                            list.slice(0, top).sort((a,b) => (a[ VerwerkerInfoWorkingModel.Doel ] < b[ VerwerkerInfoWorkingModel.Doel ] ? -1 : a[ VerwerkerInfoWorkingModel.Doel ] > b[ VerwerkerInfoWorkingModel.Doel ] ? 1 : 0 )).map((verwerker, index) => (
                                [
                                    editorObject[ VerwerkerInfoWorkingModel.Id ] === verwerker[ VerwerkerInfoWorkingModel.Id ] ? null :

                                        <Row key={verwerker[ VerwerkerInfoWorkingModel.Id ]} className={`row-table row-table-${index % 2 === 0 ? 'even' : 'odd'}`}>
                                            <Col xs={12} smHidden mdHidden lgHidden className="row-table-header-title">Naam organisatie</Col>
                                            <Col xs={12} sm={2}>{verwerker[ VerwerkerInfoWorkingModel.NaamOrganisatie ]}</Col>
                                            <Col xs={12} smHidden mdHidden lgHidden className="row-table-header-title">Contactpersoon</Col>
                                            <Col xs={12} sm={3}>{verwerker[ VerwerkerInfoWorkingModel.ContactPersoon ]}</Col>
                                            <Col xs={12} smHidden mdHidden lgHidden className="row-table-header-title">E-mailadres</Col>
                                            <Col xs={12} sm={3}>{verwerker[ VerwerkerInfoWorkingModel.EmailAddress ]}</Col>
                                            <Col xs={12} smHidden mdHidden lgHidden className="row-table-header-title">Overeenkomst</Col>
                                            <Col xs={12} sm={2}>{verwerker[ VerwerkerInfoWorkingModel.DocumentLink ] ? <a target="_blank" rel="noopener noreferrer" title={verwerker[ VerwerkerInfoWorkingModel.DocumentOmschrijving ]} href={verwerker[ VerwerkerInfoWorkingModel.DocumentLink ]}><FaIcon name="file-text-o"/></a> : null} </Col>
                                            {editable ?
                                                <Col xsHidden sm={2} className="text-right">

                                                    <DropdownButton title={<span><FaIcon name="file-text-o"/> Details</span>} id="bg-nested-dropdown">
                                                        { verwerker[ Constants.FIELDS.DETAILS ] == null ?
                                                            <MenuItem eventKey="1" onClick={()=>showDetails(verwerker[ VerwerkerInfoWorkingModel.Id ])}><FaIcon name="eye"/> Details tonen</MenuItem>
                                                            : <MenuItem eventKey="1" onClick={()=>hideDetails(verwerker[ VerwerkerInfoWorkingModel.Id ])}><FaIcon name="eye-slash"/> Details verbergen</MenuItem>
                                                        }
                                                        <MenuItem eventKey="2" onClick={() => editVerwerker(verwerker[ VerwerkerInfoWorkingModel.Id ])}><FaIcon name="pencil"/> Bewerken</MenuItem>
                                                        <MenuItem eventKey="3" onClick={() => deleteVerwerker(verwerker[ VerwerkerInfoWorkingModel.Id ])}><FaIcon name="trash"/> Verwijderen</MenuItem>
                                                    </DropdownButton>

                                                </Col>
                                                :
                                                [
                                                    <Col key={`${verwerker[ VerwerkerInfoWorkingModel.Id ]}l`} xs={12} smHidden mdHidden lgHidden className="row-table-header-title">Ovenkomst afgeloten</Col>,
                                                    <Col key={`${verwerker[ VerwerkerInfoWorkingModel.Id ]}v`} xs={12} sm={2}>{prepareValue({
                                                        value: verwerker[ VerwerkerInfoWorkingModel.DatumOvereenkomst ]
                                                    })}</Col>
                                                ]
                                            }
                                            {editable ?
                                                <Col xs={12} smHidden mdHidden lgHidden className="text-right">
                                                    <div className="h-spacer"/>
                                                    { verwerker[ Constants.FIELDS.DETAILS ] == null ?
                                                        <Button block onClick={()=>showDetails(verwerker[ VerwerkerInfoWorkingModel.Id ])}><FaIcon name="eye"/> Details tonen</Button>
                                                        : <Button block onClick={()=>hideDetails(verwerker[ VerwerkerInfoWorkingModel.Id ])}><FaIcon name="eye-slash"/> Details verbergen</Button>
                                                    }
                                                    <Button block onClick={() => editVerwerker(verwerker[ VerwerkerInfoWorkingModel.Id ])}><FaIcon name="pencil"/> Bewerken</Button>
                                                    <Button bsStyle="danger" block onClick={() => deleteVerwerker(verwerker[ VerwerkerInfoWorkingModel.Id ])}><FaIcon name="trash"/> Verwijderen</Button>

                                                </Col>
                                                : null }
                                        </Row>
                                    ,
                                    !editable || editorObject[ VerwerkerInfoWorkingModel.Id ] === verwerker[ VerwerkerInfoWorkingModel.Id ] || verwerker[ Constants.FIELDS.DETAILS ] == null ? null :
                                        <Row key={`${verwerker[ VerwerkerInfoWorkingModel.Id ]}-details`} className={`row-table row-table-${index % 2 === 0 ? 'even' : 'odd'}`}>
                                            <Col><Inzage model={verwerker} /></Col>
                                        </Row>
                                    ,
                                    !editable || editorObject[ VerwerkerInfoWorkingModel.Id ] !== verwerker[ VerwerkerInfoWorkingModel.Id ] ? null :
                                        <Row key={`${verwerker[ VerwerkerInfoWorkingModel.Id ]}-details`} className={`row-table row-table-${index % 2 === 0 ? 'even' : 'odd'}`}>
                                            <Formsy ref={(frm)=> {
                                                this.frm = frm;
                                            }} className="form-horizontal" data-toggle="validator" onValidSubmit={() => {
                                                updateVerwerker();
                                            }}>
                                                <Col xs={12}><Editor {...{
                                                    bind, onChange
                                                }}/></Col>
                                                <Col xs={12} className="text-right">
                                                    <Button onClick={() => cancelEdit()}><FaIcon name="ban" /> Annuleren</Button>
                                                    <span className="v-spacer"/>
                                                    <Button type="submit"><FaIcon name="check" /> Opslaan</Button>
                                                </Col>
                                            </Formsy>
                                        </Row>
                                ]
                            ))
                        }

                        {
                            to != null && top != null && top < list.length ?
                                <Row><Col xs={12} className="row-table-footer"><i>Alleen de {top} (van {list.length}) meest recente toevoegingen worden hier getoond.</i></Col></Row>
                                : null
                        }
                    </Panel.Body>
                </Panel>

        );
    }
}

VerwerkerLijst.propTypes = {
    isLoading: PropTypes.bool,
    loadVerwerkeren: PropTypes.func.isRequired,
    list: PropTypes.array,
    top: PropTypes.number,
    to: PropTypes.string,
    editable: PropTypes.bool,
    showDetails: PropTypes.func.isRequired,
    hideDetails: PropTypes.func.isRequired,
    editVerwerker: PropTypes.func.isRequired,
    deleteVerwerker: PropTypes.func.isRequired,
    bind: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    cancelEdit: PropTypes.func.isRequired,
    updateVerwerker: PropTypes.func.isRequired,
    editorObject: PropTypes.object
};

export default VerwerkerLijst;