import React from 'react';
import { Row, Col, Panel } from 'react-bootstrap';

const QuickPanel = ({ title, children, ...columnProps }) => (

    <Row>
        <Col {...columnProps}>
            <Panel>
                <Panel.Body>
                    {title == null ? null :<div className="panel-inline-title">{title}</div>}
                    {children}
                </Panel.Body>
            </Panel>
        </Col>
    </Row>

);

export default QuickPanel;