import * as ActionTypes from './actionTypes';

export const ShowDialog = (configuration = {}) => ({
    type: ActionTypes.SHOW_DIALOG,
    ...configuration
});

export const HideDialog = (...actionsOrMethods) => ({
    type: ActionTypes.HIDE_DIALOG,
    actions: actionsOrMethods
});