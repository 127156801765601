import * as Helpers from './helpers';

import * as ApiRoutes from './constants.routes';

export const GetStam = (uri) => {
    return Helpers.get(uri);
};

export const GetStamSector = () => {
    return Helpers.get(ApiRoutes.StamSector);
};