import * as Helpers from './helpers';
import * as Routes from './constants.routes';

export const getAdvisory = () => {
    return Helpers.get(Routes.PrivacyImpactAssessmentAdvisory);
};

export const getPrivacyImpactAssessments = () => {
    return Helpers.get(Routes.PrivacyImpactAssessment);
};

export const getPrivacyImpactAssessment = (id) => {
    return Helpers.get(`${Routes.PrivacyImpactAssessment}/${id}`);
};

export const createNew = (model) => {
    return Helpers.post(`${Routes.PrivacyImpactAssessment}`, model);
};

export const analyse = (model) => {
    return Helpers.post(`${Routes.PrivacyImpactAssessmentAnalysis}`, model, {
        suppressErrors: true
    });
};

export const updatePia = (model) => {
    return Helpers.put(`${Routes.PrivacyImpactAssessment}`, model, {
        suppressErrors: true // will be using a toastr to show something went wrong
    });
};