import React from 'react';

import { NavDropdown } from 'react-bootstrap';

import FaIcon from 'react-fontawesome';

import { UserInformationWorkingModel } from '../../../model';

const UserAccountIcon = ({ user }) => (
    <span>
        <FaIcon name="user" title={`${user[ UserInformationWorkingModel.Voornaam ] || ''} ${user[ UserInformationWorkingModel.Tussenvoegsel ] || ''} ${user[ UserInformationWorkingModel.Achternaam ] || ''}`}/>
    </span>
);

const UserNavDropDown = ({ user, company, children, eventKey, id }) => (

    <NavDropdown eventKey={eventKey} title={<FaIcon name="user" />} id={id}>
        <li role="presentation">
            <div style={{
                padding: '3px 20px',
                fontStyle: 'italic',
                whiteSpace: 'nowrap'
            }}>
                {`${user[ UserInformationWorkingModel.Voornaam ] || ''} ${user[ UserInformationWorkingModel.Tussenvoegsel ] || ''} ${user[ UserInformationWorkingModel.Achternaam ] || ''}`}
            </div>
        </li>
        { company == null ? null :
            <li role="presentation">
                <div style={{
                    padding: '3px 20px',
                    fontStyle: 'italic',
                    whiteSpace: 'nowrap'
                }}>
                    { company }
                </div>
            </li>
        }

        {children}
    </NavDropdown>

);

export {
    UserAccountIcon,
    UserNavDropDown
};