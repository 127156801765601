import {
    binder,
    onChange
} from './_references';

import * as Actions from './actions';
import { STATE_KEY } from './constants';

export const mapDispatchToProps = (dispatch) => ({
    createAccount: (model, form) => dispatch(Actions.createAccount(model, form)),
    onValidationError: (e) => dispatch(Actions.validationError(e)),
    onChange : onChange(STATE_KEY, dispatch)
});

export const mapStateToProps = (state) => {
    return {
        bind: (field) => binder(state[ STATE_KEY ])(field),
    };
};