const getValidInputProps = (props, type = props.type || 'text') => {
    // valid props according to w3schools
    const validHtml4Props = [ 'id',
        {
            'accept':'file'
        }, {
            'alt':'image'
        },{
            'checked':[ 'checkbox','radio' ]
        },'disabled','name', 'readonly','size', 'type', {
            'src':'image'
        },'value' ];

    const validHtml5Props = [ 'autocomplete','autofocus','dirname','form_id', {
        'formaction': [ 'submit', 'image' ]
    },{
        'formenctype': [ 'submit', 'image' ]
    }, {
        'method': [ 'submit', 'image' ]
    }, 'formnovalidate', {
        'formtarget': [ 'submit', 'image' ]
    }, {
        'height':'image'
    },
    'list', 'max', 'maxlength', 'min', 'multiple','pattern','placeholder','required','step',{
        'width':'image'
    }
    ];

    // Accessible Rich Internet Applications (https://www.w3.org/TR/wai-aria/states_and_properties)
    const accessibilityProps = [
        // states
        'aria-busy',
        'aria-current',
        'aria-disabled',
        'aria-grabbed',
        'aria-hidden',
        'aria-invalid',

        // properties
        'aria-atomic',
        'aria-controls',
        'aria-describedby',
        'aria-details',
        'aria-dropeffect',
        'aria-errormessage',
        'aria-flowto',
        'aria-haspopup',
        'aria-keyshortcuts',
        'aria-label',
        'aria-labelledby',
        'aria-live',
        'aria-owns',
        'aria-relevant',
        'aria-roledescription'
    ];

    const validEvents = [
        'onblur',
        'onchange',
        'onfocus',
        'onsearch',
        'onselect',
        'onsubmit',
        // html 5
        'oncontextmenu',
        'oninput',
        'oninvalid',
        'onreset',
    ];

    const validReactProps = [
        'className'
    ];
    // props that are ignored:
    // align : because it is only for HTML 4 and not supported in HTML 5

    const getProps = (prop) => {

        if (typeof(prop) === 'string') {
            return prop.toLowerCase();
        }

        if (typeof(prop) === 'object') {
            const keys = Object.keys(prop);
            if (keys.length === 1) {
                if (typeof(prop[ keys[ 0 ] ]) === 'string') {
                    if (prop[ keys[ 0 ] ].toLowerCase() === type.toLowerCase()) {
                        return keys[ 0 ];
                    }
                }
                else if (Array.isArray(prop[ keys[ 0 ] ])) {
                    if (prop[ keys[ 0 ] ].some(value => {
                        return value.toLowerCase() === type.toLowerCase();
                    })) {
                        return keys[ 0 ];
                    }
                }
            }
            else {
                // TODO: Logger inzetten
                // To many properties defined for valid html properties
            }
        }

    };

    const validProps = [
        ...validHtml4Props.map(getProps).filter(prop => prop != null),
        ...validHtml5Props.map(getProps).filter(prop => prop != null),
        ...validEvents.map(getProps).filter(prop => prop != null),
        ...accessibilityProps.map(getProps).filter(prop => prop != null),
        ...validReactProps.map(getProps).filter(prop => prop != null),
    ];

    const allowedProps = Object.keys(props).filter(key => {
        return validProps.indexOf ( key.toLowerCase() ) !== -1;
    }).map(key => {
        return {
            [ key ] : props[ key ]
        };

    });

    if (allowedProps.value != null && typeof(allowedProps.value) === 'boolean') {
        allowedProps.value = (allowedProps.value ? 'True' : 'False');
    }

    const result = Object.assign({}, ...allowedProps);
    return result;
};

export default getValidInputProps;