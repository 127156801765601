import {
    Actions,
    Constants,
    onChange,
    STATE_KEY,
    IncidentAnalyseWorkingModel,
    stateHelper,
    binder,
    IncidentDetailUpdateWorkingModel
} from './_references';

export const mapDispatchToProps = (dispatch) => ({
    clear: () => dispatch(Actions.clear()),
    onChange : onChange([ Constants.STATE_KEY, Constants.FIELDS.DOSSIER ], dispatch),
    onChangeAntwoord : onChange([ STATE_KEY, Constants.FIELDS.DOSSIER, IncidentAnalyseWorkingModel.Antwoorden ], dispatch, {
        [ Constants.ACTION_OPTIONS.ANALYSIS ] : Constants.ACTION_OPTIONS.ANALYSIS
    }),
    getIncident: (id) => dispatch(Actions.getIncident(id)),
    updateIncident: (id) => dispatch(Actions.updateIncident(id)),
    addToRapport: (frm) => dispatch(Actions.addToRapport(frm)),
    editRapportRow: (id) => dispatch(Actions.editRapportRow(id)),
    deleteRapportRow: (id) => dispatch(Actions.deleteRapportRow(id)),
    cancelEditRapportRow: () => dispatch(Actions.cancelEditRapportRow()),
    confirmEditRapportRow: () => dispatch(Actions.confirmEditRapportRow()),
    confirmClose: (id) => dispatch(Actions.confirmClose(id)),
    addToDocument: (frm) => dispatch(Actions.addToDocument(frm)),
    editDocumentRow: (id) => dispatch(Actions.editDocumentRow(id)),
    deleteDocumentRow: (id) => dispatch(Actions.deleteDocumentRow(id)),
    cancelEditDocumentRow: () => dispatch(Actions.cancelEditDocumentRow()),
    confirmEditDocumentRow: () => dispatch(Actions.confirmEditDocumentRow()),
});

export const mapStateToProps = (state) => {

    return {
        vragen: stateHelper(state, [ STATE_KEY, Constants.FIELDS.DOSSIER, IncidentAnalyseWorkingModel.Vragen ], []),
        rapport: stateHelper(state, [ STATE_KEY, Constants.FIELDS.DOSSIER, IncidentDetailUpdateWorkingModel.Rapport ], []),
        bind: (field) => binder(state, [ STATE_KEY, Constants.FIELDS.DOSSIER ])(field),
        bindAntwoord: (field) => binder(stateHelper(state, [ STATE_KEY, Constants.FIELDS.DOSSIER, IncidentAnalyseWorkingModel.Antwoorden ], {}))(field),
        rapportFrmId: stateHelper(state, [ STATE_KEY, Constants.FIELDS.DOSSIER, Constants.FIELDS.RAPPORT_FRM_ID ], 'd0c021b9-1497-4d43-9ce6-201068749342'),
        rapportEditId: stateHelper(state, [ STATE_KEY,Constants.FIELDS.DOSSIER, Constants.FIELDS.RAPPORT_EDIT_ID ]),
        disableMeldingDoorWTP: stateHelper(state, [ STATE_KEY, Constants.FIELDS.DOSSIER, Constants.FIELDS.DISABLE_MELDING_DOOR_WTP ], false ),
        document: stateHelper(state, [ STATE_KEY, Constants.FIELDS.DOSSIER, IncidentDetailUpdateWorkingModel.Document ], []),
        documentFrmId: stateHelper(state, [ STATE_KEY, Constants.FIELDS.DOSSIER, Constants.FIELDS.DOCUMENT_FRM_ID ], 'a1b76cee-8076-48a7-9e0f-de036336ae64'),
        documentEditId: stateHelper(state, [ STATE_KEY,Constants.FIELDS.DOSSIER, Constants.FIELDS.DOCUMENT_EDIT_ID ]),
        isLoading: stateHelper(state, [ Constants.STATE_KEY, Constants.FIELDS.LOADING ])
    };
};
