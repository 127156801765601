import React from 'react';
import { all, call, put, takeLatest, select, delay } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { actions as ToastrActions } from 'react-redux-toastr';

import {
    Api,
    Routes,
    stateHelper,
    WachtwoordInstellenWorkingModel,
    WachtwoordHerstellenWorkingModel,
    Builder
} from './_references';

import * as Constants from './constants';
import * as ActionTypes from './actionTypes';
import * as Actions from './actions';

export default function* () {
    yield all([
        takeLatest(ActionTypes.RESET_PASSWORD, resetPassword),
        takeLatest(ActionTypes.SET_PASSWORD, setPassword)
    ]);
}

function* resetPassword(action) {

    yield delay(250);

    try{
        const result = yield call (Api.resetPassword, action.model);

        if (result.success) {
            if (result.data[ WachtwoordHerstellenWorkingModel.HerstelMogelijk ] === true) {

                yield put(Actions.clear());

                action.form.reset();

                yield put(Actions.presetData(result.data));

                yield put (push(Routes.Account.WachtwoordInstellen.path));
            }
            else {

                const defaultDialog = Builder.ok({
                    title: 'Wachtwoord herstellen niet mogelijk',
                    body: <div>
                    Het was niet mogelijk om uw wachtwoord te herstellen. Dit kan komen doordat:
                        <ul>
                            <li>U een verkeerde bevestigingscode heeft opgegeven, of;</li>
                            <li>De bevestigingscode is verlopen.</li>
                        </ul>
                    U kunt via wachtwoord vergeten een nieuwe bevestigingscode aanvragen.<br/>
                    Mocht u problemen blijven ondervinden, neem dan even contact met ons op.
                    </div>,
                }).prepare();

                yield call(defaultDialog);
            }
        }
        else {
            yield put (ToastrActions.add({
                type: 'error',
                title: 'Wachtwoord herstellen',
                attention: true, // This will add a shadow like the confirm method.
                message: 'Op dit moment is het niet mogelijk uw wachtwoord te herstellen. Neem contact op met W.T. Privacy.',
                options: {
                    showCloseButton: true
                }
            }));
        }
    }
    catch(e){

        yield put (ToastrActions.add({
            type: 'error',
            title: 'Wachtwoord herstellen',
            attention: true, // This will add a shadow like the confirm method.
            message: 'Op dit moment is het niet mogelijk uw wachtwoord te herstellen. Neem contact op met W.T. Privacy.',
            options: {
                showCloseButton: true
            }
        }));
    }

}

function* setPassword (action) {
    yield delay(250);

    try {

        var model = yield select((state) => (stateHelper( state, [ Constants.STATE_KEY ], {})));

        const result = yield call (Api.setPassword, model);

        if (result.success) {
            if (result.data[ WachtwoordInstellenWorkingModel.Success ] === true) {

                yield put(Actions.clear());

                action.form.reset();

                yield put (ToastrActions.add({
                    type: 'success',
                    title: 'Wachtwoord herstellen',
                    attention: true, // This will add a shadow like the confirm method.
                    message: 'U heeft uw wachtwoord aangepast. U kunt nu inloggen met uw nieuwe wachtwoord.',
                    options: {
                        showCloseButton: true
                    }
                }));

                yield put (push(Routes.Home.path));
            }
            else {
                yield put (ToastrActions.add({
                    type: 'error',
                    title: 'Wachtwoord herstellen',
                    attention: true, // This will add a shadow like the confirm method.
                    message: 'Op dit moment is het niet mogelijk uw wachtwoord te herstellen. Neem contact op met W.T. Privacy.',
                    options: {
                        showCloseButton: true
                    }
                }));
            }
        }
        else {
            yield put (ToastrActions.add({
                type: 'error',
                title: 'Wachtwoord herstellen',
                attention: true, // This will add a shadow like the confirm method.
                message: 'Op dit moment is het niet mogelijk uw wachtwoord te herstellen. Neem contact op met W.T. Privacy.',
                options: {
                    showCloseButton: true
                }
            }));
        }
    }
    catch(e){

        yield put (ToastrActions.add({
            type: 'error',
            title: 'Wachtwoord herstellen',
            attention: true, // This will add a shadow like the confirm method.
            message: 'Op dit moment is het niet mogelijk uw wachtwoord te herstellen. Neem contact op met W.T. Privacy.',
            options: {
                showCloseButton: true
            }
        }));
    }
}
