import React from 'react';
import { Panel, Row, Col } from 'react-bootstrap';

import {
    Container,
    Button,
    Routes
} from './_references';

const Aangevraagd = () => (
    <Container>

        <Panel>
            <Panel.Body>
                <div className="panel-inline-title">Account aangevraagd</div>

                Dank voor uw aanmelding.<br/><br/>
                W.T. Privacy verwerkt uw aanmelding. Hieronder leest u welke stappen u kunt verwachten:

                <ul>
                    <li>U ontvangt een e-mail ter bevestiging van uw aanmelding. Volg de instructies zoals beschreven in deze mail.</li>
                    <li>Vervolgens ontvangt u op hetzelfde mailadres de factuur van W.T. Privacy. </li>
                    <li>Nadat de factuur door u is voldaan volgt een activatiemail.</li>
                    <li>Uw Privacy Toolbox is klaar voor gebruik. U kunt inloggen met het door uzelf opgegeven emailadres en wachtwoord.</li>
                </ul>

            </Panel.Body>
        </Panel>
        <Row>
            <Col xs={12} className="text-right">
                <Button to={Routes.Home.path} bsStyle="primary"> Terug naar de homepage</Button>
            </Col>
        </Row>

    </Container>
);

export default Aangevraagd;