import {
    stateHelper
} from './_references';

import * as Constants from './constants';
import * as Actions from './actions';

export const mapStateToProps = (state) => ({
    model: stateHelper(state, [ Constants.STATE_KEY, Constants.FIELDS.VIEW ]),
    isLoading: stateHelper(state, [ Constants.STATE_KEY, Constants.FIELDS.LOADING ], false)
});

export const mapDispatchToProps = (dispatch) => ({
    load: () => dispatch(Actions.loadInformatie(Constants.SOURCE.VIEW))
});