import { connect } from 'react-redux';
import Axios from 'axios';

import {
    AuthenticationConstants,
    stateHelper
} from './_references';

import * as Constants from './constants';

const mapStateToProps = (state) => ({
    loggedIn: stateHelper(state, [ Constants.STATE_KEY, Constants.FIELDS.LOGGED_IN ] , false),
    show2FAWarning: stateHelper(state, [ Constants.STATE_KEY, Constants.FIELDS.SHOW_ACCOUNT_WARNING ] , false),
    roles: stateHelper(state, [ Constants.STATE_KEY, Constants.FIELDS.ROLES ], []),
    selectAccount: stateHelper(state, [ Constants.STATE_KEY, Constants.FIELDS.SELECT_ACCOUNT ])
});

export const isAccessAllowed = (roles = stateHelper.emptyArray, allowed = stateHelper.emptyArray, denied = stateHelper.emptyArray) => {

    const accessDenied = roles.some(r => denied.includes(r));

    if (accessDenied){
        return false;
    }

    //Empty safeAllowedRole means you have to be logged in, but not with a specific role
    const accessAllowed = (allowed.length === 0) || (roles.some(r => allowed.includes(r)));

    return accessAllowed;
};

const IfLoggedInComponent = ({ loggedIn, roles, role, denied, children }) => {

    if (loggedIn !== true) {
        return null;
    }

    const safeAllowedRole = role || [];
    const safeDeniedRole = denied || [];

    return isAccessAllowed(roles, safeAllowedRole, safeDeniedRole) ? children : null;
};

const IfLoggedOutComponent = ({ loggedIn, children }) => (loggedIn ? null : children);

const IfShow2FAWarningComponent = ({ show2FAWarning, children }) => (show2FAWarning ? children : null);

const IfAccountSelectedComponent = ({ selectAccount, children }) => (selectAccount === false || Axios.defaults.headers.common[ AuthenticationConstants.Headers.WTPACCOUNT ] != null ? children : null );

const IfLoggedIn = connect(mapStateToProps)(IfLoggedInComponent);

const IfLoggedOut = connect(mapStateToProps)(IfLoggedOutComponent);

const IfShow2FAWarning = connect(mapStateToProps)(IfShow2FAWarningComponent);

const IfAccountSelected = connect(mapStateToProps)(IfAccountSelectedComponent);

export {
    IfLoggedIn,
    IfLoggedOut,
    IfShow2FAWarning,
    IfAccountSelected
};
