import {
    stateHelper,
    binder,
    Constants,
    Actions,
    onChange
} from './_references';

export const mapStateToProps = (state) => ({
    list: stateHelper(state, [ Constants.STATE_KEY, Constants.FIELDS.LIST ], []),
    editorObject: stateHelper(state,[ Constants.STATE_KEY, Constants.FIELDS.EDITOR ], {}),
    bind: (field) => binder(state, [ Constants.STATE_KEY, Constants.FIELDS.EDITOR ])(field),
    isLoading: stateHelper(state,[ Constants.STATE_KEY, Constants.FIELDS.LOADING ], false)
});

export const mapDispatchToProps = (dispatch) => ({
    onChange : onChange([ Constants.STATE_KEY, Constants.FIELDS.EDITOR ], dispatch),
    loadVerwerkingen: () => dispatch(Actions.getVerwerkingen()),
    showDetails: (id) => dispatch(Actions.getVerwerking(id, Constants.SOURCES.LIST)),
    hideDetails: (id) => dispatch(Actions.hideVerwerking(id)),
    editVerwerking: (id) => dispatch(Actions.editVerwerking(id, Constants.SOURCES.EDITOR)),
    cancelEdit: () => dispatch(Actions.cancelEdit()),
    deleteVerwerking: (id) => dispatch(Actions.deleteVerwerking(id)),
    updateVerwerking: () => dispatch(Actions.updateVerwerking())
});