import { NAME } from './constants';

export const ADD_VERWERKER = `${NAME}/ADD_VERWERKER`;
export const GET_VERWERKER = `${NAME}/GET_VERWERKER`;
export const HIDE_VERWERKER = `${NAME}/HIDE_VERWERKER`;
export const GET_VERWERKEREN = `${NAME}/GET_VERWERKEREN`;
export const LOADED_VERWERKER = `${NAME}/LOADED_VERWERKER`;
export const LOADED_VERWERKEREN = `${NAME}/LOADED_VERWERKEREN`;
export const EDIT_VERWERKER = `${NAME}/EDIT_VERWERKER`;
export const CANCEL_EDIT = `${NAME}/CANCEL_EDIT`;
export const UPDATE_VERWERKER = `${NAME}/UPDATE_VERWERKER`;
export const DELETE_VERWERKER = `${NAME}/DELETE_VERWERKER`;
export const CONFIRMED_DELETE_VERWERKER = `${NAME}/CONFIRMED_DELETE_VERWERKER`;
export const CLEAR = `${NAME}/CLEAR`;