import {
    stateHelper
} from './_references';

import * as Constants from './constants';
import * as Actions from './actions';

export const mapState = (state) => ({
    list: stateHelper(state, [ Constants.STATE_KEY, Constants.StateFields.DATA ], []),
    isLoading : stateHelper(state, [ Constants.STATE_KEY, Constants.StateFields.LOADING ])
});

export const mapDispatch = (dispatch) => ({
    load: () => dispatch(Actions.load()),
    koppel: (accountId) => dispatch(Actions.koppel(accountId))
});