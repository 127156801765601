import * as ActionTypes from './actionTypes';

export const confirmAccount = (model, form) => ({
    type: ActionTypes.CONFIRM_ACCOUNT,
    model,
    form
});

export const clear = () => ({
    type:ActionTypes.CLEAR
});