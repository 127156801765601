import React from 'react';
import PropTypes from 'prop-types';

import {
    UserSecurityWorkingModel,
    Container
} from './_references';

import Secured from './secured';
import NotSecured from './notSecured';
import AddSecurity from './addSecurity';

const Beveiligen = ({ security, externalReference, ...rest }) => {

    const allProps =
    {
        security,
        externalReference,
        ...rest
    };

    return (
        <Container>
            {security[ UserSecurityWorkingModel.UserSecurityEnabled ] ? <Secured {...allProps} /> : externalReference ? <AddSecurity {...allProps}/> : <NotSecured {...allProps}/>}
        </Container>);
};

Beveiligen.propTypes = {
    security: PropTypes.object.isRequired,
    externalReference: PropTypes.string
};

export default Beveiligen;