import React from 'react';
import { Col, Row } from 'react-bootstrap';
import FaIcon from 'react-fontawesome';

import {
    Container,
    Button,
    Routes
} from './_references';

import VerwerkersregisterLijst from './lijst';

// https://autoriteitpersoonsgegevens.nl/nl/onderwerpen/avg-nieuwe-europese-privacywetgeving/verantwoordingsplicht?qa=verwerkersregister&scrollto=1

const Verwerkersregister = () => (
    <Container>
        <Row>

            <Col sm={12} xs={12}>

                <VerwerkersregisterLijst editable/>

                <Row>
                    <Col xs={12} className="text-right">
                        <Button to={Routes.Dashboard.Verwerkersregister.Toevoegen.path}><FaIcon name="plus" /> Verwerker toevoegen</Button>
                    </Col>
                </Row>

            </Col>
        </Row>
    </Container>
);

export default Verwerkersregister;