import {
    binder,
    onChange
} from './_references';

import { STATE_KEY } from './constants';
import * as Actions from './actions';

export const mapStateToProps = (state) => ({
    bind: (field) => binder(state[ STATE_KEY ])(field),
});

export const mapDispatchToProps = (dispatch) => ({
    onChange : onChange(STATE_KEY, dispatch),
    confirm: (model, form) => dispatch(Actions.activateAccount(model, form))
});