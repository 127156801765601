import React from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';

import {
    ApiRoutes,
    FieldSetup,
    PersoonWorkingModel,
    RadioButton,
    Select,
    Text,
    VerwerkingDetailWorkingModel,
    YesNoRadioButton
} from './_references';

const VerwerkingsregisterEditor = ({ bind, onChange }) => (
    <div>
        <Text
            {...bind(VerwerkingDetailWorkingModel.Doel)}
            {...new FieldSetup()
                .for(VerwerkingDetailWorkingModel.Doel)
                .withFriendlyName('Verwerkingsdoel')
                .setLabel('Verwerkingsdoel')
                .setPlaceholder('Doel van de gegevensverwerking')
                .setAutoFocus()
                .isRequired()
                .onChange(onChange)
            }
        />

        <Text
            {...bind(VerwerkingDetailWorkingModel.Categorieen)}
            {...new FieldSetup()
                .for(VerwerkingDetailWorkingModel.Categorieen)
                .withFriendlyName('Categorieën')
                .setLabel()
                .setPlaceholder('Categorieën, bijvoorbeeld uitkeringsgerechtigden, klanten, patiënten etc.')
                .isRequired()
                .onChange(onChange)
            }
        />

        <RadioButton
            noOffset
            wrapperClassName="radio-compact"
            {...bind(VerwerkingDetailWorkingModel.CategorieenPersoonsgegevens)}
            {...new FieldSetup()
                .for(VerwerkingDetailWorkingModel.CategorieenPersoonsgegevens)
                .withFriendlyName('Persoonsgegevens')
                .setLabel()
                .setPlaceholder('Categoriën Persoonsgegevens, Gewone pof  ')
                .isRequired()
                .onChange(onChange)
            }
            options={[ {
                key: 'G', value: 'Gewone persoonsgegevens'
            },
            {
                key: 'B', value: 'Bijzondere persoonsgegevens'
            },
            {
                key: 'A', value: 'Zowel gewone als bijzondere persoonsgegevens'
            },
            ]}
            optionKey="key"
            optionValue="value"
        />

        {/* Tonen bij CategorieenPersoonsgegevens A & B */}
        { bind(VerwerkingDetailWorkingModel.CategorieenPersoonsgegevens).value === 'B' || bind(VerwerkingDetailWorkingModel.CategorieenPersoonsgegevens).value === 'A' ?
            <YesNoRadioButton
                wrapperClassName="radio-compact"
                noOffset
                {...bind(VerwerkingDetailWorkingModel.WettelijkeGrondslag)}
                {...new FieldSetup()
                    .for(VerwerkingDetailWorkingModel.WettelijkeGrondslag)
                    .withFriendlyName('Wettelijke Grondslag')
                    .setLabel('Wettelijke Grondslag')
                    .isRequired()
                    .onChange(onChange)
                }
            />
            : null }

        { (bind(VerwerkingDetailWorkingModel.CategorieenPersoonsgegevens).value === 'B' || bind(VerwerkingDetailWorkingModel.CategorieenPersoonsgegevens).value === 'A') && bind(VerwerkingDetailWorkingModel.WettelijkeGrondslag).value === 'true' ?
            <Text
                wrapperClassName="radio-compact"
                noOffset
                {...bind(VerwerkingDetailWorkingModel.WettelijkeGrondslagToelichting)}
                {...new FieldSetup()
                    .for(VerwerkingDetailWorkingModel.WettelijkeGrondslagToelichting)
                    .withFriendlyName('Toelichting Wettelijke Grondslag')
                    .setLabel('Toelichting Wettelijke Grondslag')
                    .setPlaceholder('Welke grondslag geldt er?')
                    .isRequired()
                    .onChange(onChange)
                }

            />
            : null }

        <YesNoRadioButton
            wrapperClassName="radio-compact"
            noOffset
            {...bind(VerwerkingDetailWorkingModel.DelenBuitenEU)}
            {...new FieldSetup()
                .for(VerwerkingDetailWorkingModel.DelenBuitenEU)
                .setLabel('Deelt u buiten de EU')
                .withFriendlyName('Deelt u gegevens met een land of internationale organisatie buiten de EU?')
                .isRequired()
                .onChange(onChange)
            }
        />

        <Text
            {...bind(VerwerkingDetailWorkingModel.Bewaartermijn)}
            {...new FieldSetup()
                .for(VerwerkingDetailWorkingModel.Bewaartermijn)
                .withFriendlyName('Bewaartermijn')
                .setLabel()
                .setPlaceholder()
                .maxLength(50)
                .onChange(onChange)
            }
        />

        <Text
            {...bind(VerwerkingDetailWorkingModel.Opdrachtgever)}
            {...new FieldSetup()
                .for(VerwerkingDetailWorkingModel.Opdrachtgever)
                .withFriendlyName('Opdrachtgever')
                .setLabel()
                .setPlaceholder('Wie is de opdrachtgever? U, een specifieke klant of al uw klanten?')
                .isRequired()
                .onChange(onChange)
            }
        />

        <YesNoRadioButton
            wrapperClassName="radio-compact"
            noOffset
            {...bind(VerwerkingDetailWorkingModel.AfwijkendeVerantwoordelijke)}
            {...new FieldSetup()
                .for(VerwerkingDetailWorkingModel.AfwijkendeVerantwoordelijke)
                .setLabel('Is er een afwijkende verantwoordelijke')
                .withFriendlyName('Afwijkende verantwoordelijke')
                .onChange(onChange)
            }
        />

        {bind(VerwerkingDetailWorkingModel.AfwijkendeVerantwoordelijke).value === 'true' ?
            <Panel>
                <Panel.Body>
                    <div className="panel-inline-title">Contactgegevens afwijkende verantwoordelijke</div>

                    <Select
                        {...bind(VerwerkingDetailWorkingModel.AfwijkendeVerantwoordelijkeId)}
                        {...new FieldSetup()
                            .for(VerwerkingDetailWorkingModel.AfwijkendeVerantwoordelijkeId)
                            .withFriendlyName('Naam')
                            .setLabel()
                            .setPlaceholder('Naam')
                            .isRequired()
                            .loadFrom(ApiRoutes.Personen)
                            .onChange(onChange)
                        }
                        options={[
                            {
                                [ PersoonWorkingModel.Id ]: '',
                                [ PersoonWorkingModel.FullName ]: ''
                            }
                        ]}

                        optionKey={PersoonWorkingModel.Id}
                        optionValue={PersoonWorkingModel.FullName}
                    />
                </Panel.Body>
            </Panel>
            : null }
    </div>
);

VerwerkingsregisterEditor.propTypes = {
    bind: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
};

export default VerwerkingsregisterEditor;