import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import {
    API,
    Routes,
    stateHelper,
    RegisterInformatieWorkingModel,
    RegisterInformatieDetailWorkingModel
} from './_references';

import * as ActionTypes from './actionTypes';
import * as Actions from './actions';
import * as Constants from './constants';

export default function* () {
    yield all([
        takeEvery(ActionTypes.LOAD_INFORMATIE, getRegisterInformatie),
        takeEvery(ActionTypes.UPDATE_INFORMATIE, updateRegisterInformatie)
    ]);
}

function* getRegisterInformatie(action) {
    try {

        let loadData = false;

        if (action.source === Constants.SOURCE.EDIT) {

            const model = yield select((state) => stateHelper(state, [ Constants.STATE_KEY, Constants.FIELDS.VIEW ]));

            if (model) {
                const data = {
                    [ RegisterInformatieWorkingModel.NaamOrganisatie ] : model[ RegisterInformatieWorkingModel.NaamOrganisatie ],
                    [ RegisterInformatieWorkingModel.Eigenaar ] : model[ RegisterInformatieWorkingModel.Eigenaar ],
                    [ RegisterInformatieDetailWorkingModel.ContactPersoonId ] : model[ RegisterInformatieDetailWorkingModel.ContactPersoonId ],
                    [ RegisterInformatieDetailWorkingModel.FunctionarisGegevensbeschermingId ] : model[ RegisterInformatieDetailWorkingModel.FunctionarisGegevensbeschermingId ],
                    [ RegisterInformatieDetailWorkingModel.VerwerkingsverantwoordelijkeId ] : model[ RegisterInformatieDetailWorkingModel.VerwerkingsverantwoordelijkeId ]
                };
                yield put (Actions.loadedInformatie(data, action.source));
            }
            else {
                loadData = true;
            }
        }
        else{
            loadData = true;
        }

        if (loadData){
            const registerInformatie = yield call (API.getRegisterInformatie);
            if (registerInformatie.success) {
                yield put (Actions.loadedInformatie(registerInformatie.data, action.source));
            }
            else {
                //TODO
            }
        }
    }
    catch (e) {
        // TODO:
    }
}

function* updateRegisterInformatie(action) {

    try {
        yield call(API.updateRegisterInformatie, action.model);

        yield put (push(Routes.Dashboard.path));
    }
    catch(e) {
        // TODO:
    }

}