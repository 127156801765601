import React from 'react';

import createFormsyInputComponent from './';
import createDisplayComponent from './createDisplayInputComponent';

import InputComponent from '../../atoms/input';
import TextAreaInputComponent from '../../atoms/input/textArea';
import TextInputComponent from '../text';
import RadioComponent from '../radio';
import RadioButtonComponent from '../radioButton';
import Label, { LoginLabelComponent, PlainTextLabelComponent, RadioLabelComponent, DisplayLabelComponent } from '../../atoms/label';
import SelectBaseComponent from '../select';
import { LoginValidationWrapper, CheckboxValidationWrapper, RadioButtonValidationWrapper } from '../validationWrapper';
import ReadOnlyWrapper from '../readOnlyWrapper';
import DateTimeComponent from './dateTime';
import DisplayComponent from '../display';

const TextComponent = (props) => (<TextInputComponent className="form-control" {...props} />);
const PasswordComponent = (props) => (<TextInputComponent className="form-control" {...props} type="password" />);
const CheckBoxComponent = ({ value, ...props }) => {

    props.checked = value === true || value === 'true';

    return (<InputComponent value={value} {...props} type="checkbox" />);
};
const TextAreaComponent = (props) => (<TextAreaInputComponent {...props} className="form-control" />);
const SelectComponent = (props) => (<SelectBaseComponent {...props} className="form-control" />);
const DisplayLabel = ({ name, label, className }) => (<DisplayLabelComponent {...{
    className, name, label
}}></DisplayLabelComponent>);

const YesNoRadioButtonComponent = (props) => (<RadioButtonComponent options={[ {
    key: 'true', value: 'Ja'
},{
    key: 'false', value: 'Nee'
} ]}
optionKey="key"
optionValue="value"
{...props}
/>);

const Text = createFormsyInputComponent({
    InputComponent: TextComponent,
    LabelComponent : Label
});

const ReadOnly = createDisplayComponent({
    InputComponent: DisplayComponent,
    LabelComponent : Label,
    Wrapper: ReadOnlyWrapper
});

const TextArea = createFormsyInputComponent({
    InputComponent: TextAreaComponent,
    LabelComponent: Label
});

const Password = createFormsyInputComponent({
    InputComponent: PasswordComponent,
    LabelComponent : Label
});

const Radio = createFormsyInputComponent({
    InputComponent: RadioComponent,
    LabelComponent : Label
});

const RadioButton = createFormsyInputComponent({
    LabelComponent: RadioLabelComponent,
    InputComponent: RadioButtonComponent,
    Wrapper: RadioButtonValidationWrapper
});

const YesNoRadioButton = createFormsyInputComponent({
    LabelComponent: RadioLabelComponent,
    InputComponent: YesNoRadioButtonComponent,
    Wrapper: RadioButtonValidationWrapper
});

const CheckBox = createFormsyInputComponent({
    InputComponent: CheckBoxComponent,
    Wrapper: CheckboxValidationWrapper,
    LabelComponent: PlainTextLabelComponent
});

const LoginInputs = {
    Text: createFormsyInputComponent({
        InputComponent: TextComponent,
        LabelComponent : LoginLabelComponent,
        Wrapper: LoginValidationWrapper
    }),
    Password: createFormsyInputComponent({
        InputComponent: PasswordComponent,
        LabelComponent : LoginLabelComponent,
        Wrapper: LoginValidationWrapper
    })
};

const DateTime = createFormsyInputComponent({
    InputComponent: DateTimeComponent,
    LabelComponent: Label
});

const Select = createFormsyInputComponent({
    InputComponent: SelectComponent,
    LabelComponent: Label
});

const Display = createDisplayComponent({
    DisplayComponent: DisplayComponent,
    LabelComponent: DisplayLabel
});

export {
    CheckBox,
    Display,
    Text,
    ReadOnly,
    TextArea,
    Password,
    Radio,
    RadioButton,
    YesNoRadioButton,
    DateTime,
    LoginInputs,
    Select
};