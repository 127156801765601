import React from 'react';
import { Col, Row } from 'react-bootstrap';
import FaIcon from 'react-fontawesome';

import {
    Container,
    Routes,
    Button
} from './_references';

import PersonenLijst from './lijst';

const Personen = () => (
    <Container>
        <Row>

            <Col sm={12} xs={12}>

                <PersonenLijst editable/>

                <Row>
                    <Col xs={12} className="text-right">
                        <Button to={Routes.Beheer.Personen.Toevoegen.path}><FaIcon name="plus" /> Persoon toevoegen</Button>
                    </Col>
                </Row>

            </Col>
        </Row>
    </Container>
);

export default Personen;