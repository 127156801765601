export const NAME = 'PrivacyImpactAssessment';

export const STATE_KEY = `${NAME}/5599fd29-04e3-49f5-8c5d-6c23432ea6ca`;

export const FIELDS = {
    Advisory: 'Advisory',
    AdvisoryLoading: 'AdvisoryLoading',
    PiaList: 'PiaList',
    PiaListLoading: 'PiaListLoading',
    Pia: 'Pia',
    PiaLoading: 'PiaLoading',
    PiaChanged: 'PiaChanged'
};

export const ACTION_OPTIONS = {
    PiaAnalysis : 'PiaAnalysis'
};