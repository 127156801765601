import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Panel } from 'react-bootstrap';
import FaIcon from 'react-fontawesome';

import {
    RegisterInformatieWorkingModel,
    Routes,
    Button,
    Loading
} from './_references';

class AlgemeneRegisterInformatie extends React.Component {

    componentDidMount() {
        this.props.load();
    }

    render() {
        const { model, isLoading } = this.props;

        return (
            <Panel>
                <Panel.Heading><h2>Algemene registerinformatie</h2></Panel.Heading>
                <Panel.Body>
                    <p className="hide-print">Voor zowel het verwerkings- als het verwerkersregister moet algemene informatie over de verantwoordelijke worden opgenomen.</p>

                    { model == null || isLoading ? <Loading/> :
                        <>
                            <Panel>
                                <Panel.Body>
                                    <div className="panel-inline-title">Naam en contactgegevens organisatie</div>
                                    <Col xs={12} sm={4} className="field-label">Naam organisatie:</Col>
                                    <Col xs={12} sm={8}>{model[ RegisterInformatieWorkingModel.NaamOrganisatie ]}</Col>

                                    <Col xs={12} sm={4} className="field-label">Eigenaar:</Col>
                                    <Col xs={12} sm={8}>{model[ RegisterInformatieWorkingModel.Eigenaar ] || '-'}</Col>

                                    <Col xs={12} sm={4} className="field-label">Vertegenwoordiger:</Col>
                                    <Col xs={12} sm={8}>{model[ RegisterInformatieWorkingModel.Vertegenwoordiger ] || '-'}</Col>

                                    <Col xs={12} sm={4} className="field-label">Telefoonnummer:</Col>
                                    <Col xs={12} sm={8}>{model[ RegisterInformatieWorkingModel.TelefoonnummerOrganisatie ] || '-'}</Col>

                                    <Col xs={12} sm={4} className="field-label">E-mailadres:</Col>
                                    <Col xs={12} sm={8}>{model[ RegisterInformatieWorkingModel.EmailOrganisatie ] || '-'}</Col>

                                </Panel.Body>
                            </Panel>

                            <Panel>
                                <Panel.Body>
                                    <div className="panel-inline-title">Naam en contactgegevens van de Functionaris voor de Gegevensbescherming</div>
                                    <Col xs={12} sm={4} className="field-label">Naam :</Col>
                                    <Col xs={12} sm={8}>{model[ RegisterInformatieWorkingModel.NaamFunctionarisGegevensbescherming ] || '-'}</Col>

                                    <Col xs={12} sm={4} className="field-label">Telefoonnummer:</Col>
                                    <Col xs={12} sm={8}>{model[ RegisterInformatieWorkingModel.TelefoonnummerFunctionarisGegevensbescherming ] || '-'}</Col>

                                    <Col xs={12} sm={4} className="field-label">E-mailadres:</Col>
                                    <Col xs={12} sm={8}>{model[ RegisterInformatieWorkingModel.EmailFunctionarisGegevensbescherming ] || '-'}</Col>
                                </Panel.Body>
                            </Panel>

                            <Panel>
                                <Panel.Body>
                                    <div className="panel-inline-title">Naam en contactgegevens van de verwerkingsverantwoordelijke</div>

                                    <Col xs={12} sm={4} className="field-label">Naam :</Col>
                                    <Col xs={12} sm={8}>{model[ RegisterInformatieWorkingModel.NaamVerwerkingsverantwoordelijke ] || '-'}</Col>

                                    <Col xs={12} sm={4} className="field-label">Telefoonnummer:</Col>
                                    <Col xs={12} sm={8}>{model[ RegisterInformatieWorkingModel.TelefoonnummermVerwerkingsverantwoordelijke ] || '-'}</Col>

                                    <Col xs={12} sm={4} className="field-label">E-mailadres:</Col>
                                    <Col xs={12} sm={8}>{model[ RegisterInformatieWorkingModel.EmailVerwerkingsverantwoordelijke ] || '-'}</Col>
                                </Panel.Body>
                            </Panel>

                            <Row>
                                <Col xsHidden sm={12} className="text-right">
                                    <Button to={Routes.Dashboard.AlgemeneRegisterinformatie.path} bsStyle="primary"><FaIcon name="pencil" /> Bewerken</Button>
                                </Col>

                                <Col smHidden mdHidden lgHidden xs={12} >
                                    <Button to={Routes.Dashboard.AlgemeneRegisterinformatie.path} bsStyle="primary" block><FaIcon name="pencil" /> Bewerken</Button>
                                </Col>
                            </Row>
                        </>
                    }
                </Panel.Body>
            </Panel>
        );
    }
}

AlgemeneRegisterInformatie.propTypes = {
    load: PropTypes.func.isRequired,
    model: PropTypes.object,
    isLoading: PropTypes.bool
};

export default AlgemeneRegisterInformatie;