import * as Helpers from './helpers';
import * as Routes from './constants.routes';

const defaultSettings = {
    suppressErrors: true,
    onValidationError: {
        showModal: true
    }
};

const deleteSettings = {
    suppressErrors: false,
    onValidationError: {
        showModal: true
    }
};

export const GetAllPersonen = () => {
    return Helpers.get(Routes.Personen);
};

export const GetPersoon = (id) => {
    return Helpers.get(`${Routes.Personen}/${id}`);
};

/**
 * Creates a new Persoon
 *
 * @param {*} model
 */
export const AddPersoon = (model) => {
    return Helpers.post(Routes.Personen, model, defaultSettings);
};

/**
 * Updates a Persoon
 *
 * @param {*} model
 */
export const UpdatePersoon = (model) => {
    return Helpers.put(Routes.Personen, model, defaultSettings);
};

export const DeletePersoon = (id) => {
    return Helpers.delete_(`${Routes.Personen}/${id}`, deleteSettings);
};