import React from 'react';

import prepareValue from './prepareValue';

const Display = ({ value, className, options, apiOptions, optionKey, optionValue, dateFormat = 'DD-MM-YYYY' }) => {

    // TODO: Naar CSS
    return (<div style={{
        paddingTop: '7px'
    }}
    className={className}
    >{prepareValue({
            value, className, options, apiOptions, optionKey, optionValue, dateFormat
        })}</div>);

};

export default Display;
