import { all /*, takeEvery, call, put*/ } from 'redux-saga/effects';

//import * as NieuwsApi from '../../api/nieuws';
//import * as ConfigurationActionTypes from '../../configuration/actionTypes';
//import * as Actions from './actions';

export default function* saga() {
    yield all([
        //takeEvery(ConfigurationActionTypes.PRE_LOAD, preloadNews)
    ]);
}

// function* preloadNews() {
//     try {
//         const result = yield call (NieuwsApi.GetAllNieuws);

//         if (result.status === 200) {
//             yield put (Actions.nieuwsLoaded(result.data));
//         }
//     }
//     catch (e) {
//         console.log('Error loading news', e);
//     }
// }
