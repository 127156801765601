import * as Helpers from './helpers';
import * as Routes from './constants.routes';

const defaultSettings = {
    suppressErrors: true,
    onValidationError: {
        showModal: true
    }
};

export const GetAllIncidents = () => {
    return Helpers.get(`${Routes.Incindent}`);
};

export const GetIncident = (id) => {
    return Helpers.get(`${Routes.Incindent}/${id}`);
};

/**
 * Creates a new Incident
 *
 * @param {*} model
 */
export const AddIncident = (model) => {
    return Helpers.post(`${Routes.Incindent}`, model, defaultSettings);
};

/**
 * Updates an Incident
 *
 * @param {*} model
 */
export const UpdateIncident = (model) => {
    return Helpers.put(`${Routes.Incindent}`, model, defaultSettings);
};