import{
    onChange,
    STATE_KEY,
    Constants,
    PiaWorkingModel,
    Actions,
    stateHelper,
    binder
} from './_references';

export const mapDispatchToProps = (dispatch) => ({
    // clear: () => dispatch(Actions.clear()),
    onChange : onChange([ STATE_KEY, Constants.FIELDS.Pia, PiaWorkingModel.Antwoorden ], dispatch, {
        [ Constants.ACTION_OPTIONS.PiaAnalysis ] : Constants.ACTION_OPTIONS.PiaAnalysis
    }),
    onChangeNotitie : onChange([ STATE_KEY, Constants.FIELDS.Pia, PiaWorkingModel.Notities ], dispatch, {
        [ Constants.ACTION_OPTIONS.PiaAnalysis ] : Constants.ACTION_OPTIONS.PiaAnalysis
    }),
    load: (id) => dispatch(Actions.loadPia(id)),
    update: (id) => dispatch(Actions.updatePia(id)),
    onToggleClose: onChange([ STATE_KEY, Constants.FIELDS.Pia ], dispatch, {
        [ Constants.ACTION_OPTIONS.PiaAnalysis ] : Constants.ACTION_OPTIONS.PiaAnalysis
    })
});

export const mapStateToProps = (state) => {

    return {
        vragen: stateHelper(state, [ STATE_KEY, Constants.FIELDS.Pia, PiaWorkingModel.Vragen ], []),
        categorien: stateHelper(state, [ STATE_KEY, Constants.FIELDS.Pia, PiaWorkingModel.VraagCategorien ], []),
        bind: (field) => binder(stateHelper(state, [ STATE_KEY, Constants.FIELDS.Pia, PiaWorkingModel.Antwoorden ], {}))(field),
        bindNotitie: (field) => binder(stateHelper(state, [ STATE_KEY, Constants.FIELDS.Pia, PiaWorkingModel.Notities ], {}))(field),
        model: stateHelper(state, [ STATE_KEY, Constants.FIELDS.Pia ], {}),
        isLoading: stateHelper(state, [ STATE_KEY, Constants.FIELDS.PiaLoading ], true),
        isChanged: stateHelper(state, [ STATE_KEY, Constants.FIELDS.Pia, Constants.FIELDS.PiaChanged ], false)
    };
};
