import React from 'react';
import Md from 'react-markdown-renderer';
import { Form,FormGroup,Col,FormControl, Panel, PanelGroup, Row } from 'react-bootstrap';

import {
    Container,
    Loading
} from './_references';

class Woordenboek extends React.Component {

    componentDidMount() {
        this.props.load();
    }

    render() {

        const { dictionary } = this.props;

        return (
            <Container title="Woordenboek">
                <Form horizontal>
                    <FormGroup controlId="formHorizontalEmail">
                        <Col sm={12}>
                            <FormControl type="search" placeholder="Zoeken" onChange={(e) => this.props.load( e.target.value )} />
                        </Col>
                    </FormGroup>
                </Form>

                {!dictionary ? <Loading /> :
                    <PanelGroup id="Woordenlijst">
                        {dictionary.map((word, index) => (
                            <Panel key={index}>
                                <Panel.Body>
                                    <div className="panel-inline-title">{word[ 'word' ]}</div>

                                    <Md options={{
                                        preset: 'default', html: true
                                    }} markdown={word[ 'definition' ]} />
                                </Panel.Body>
                            </Panel>
                        ))}

                    </PanelGroup>
                }
                <Row>
                    <Col xs={12} className="privacy-bron-disclaimer">
                        Voor de inhoud van dit woordenboek is gebruik gemaakt van meerdere bronnen. Hierbij wordt veelvuldig geciteerd uit door de <a href="https://autoriteitpersoonsgegevens.nl" rel="noopener noreferrer" target="_blank">Autoriteit Persoonsgegevens</a> geschreven teksten.
                    </Col>
                </Row>

            </Container>
        );
    }
}

export default Woordenboek;