import * as ActionTypes from './actionTypes';

export const addVerwerking = (model) => ({
    type: ActionTypes.ADD_VERWERKING,
    model
});

export const getVerwerking = (id, source) => ({
    type: ActionTypes.GET_VERWERKING,
    id,
    source
});

export const hideVerwerking = (id) => ({
    type: ActionTypes.HIDE_VERWERKING,
    id
});

export const loadedVerwerking = (model, source) => ({
    type: ActionTypes.LOADED_VERWERKING,
    model,
    source
});

export const getVerwerkingen = () => ({
    type: ActionTypes.GET_VERWERKINGEN
});

export const loadedVerwerkingen = (model) => ({
    type: ActionTypes.LOADED_VERWERKINGEN,
    model
});

export const editVerwerking = (id, source) => ({
    type: ActionTypes.EDIT_VERWERKING,
    id,
    source
});

export const cancelEdit = () => ({
    type: ActionTypes.CANCEL_EDIT
});

export const updateVerwerking = () => ({
    type: ActionTypes.UPDATE_VERWERKING
});

export const deleteVerwerking = (id) => ({
    type: ActionTypes.DELETE_VERWERKING,
    id
});

export const confirmedDeleteVerwerking = (id) => ({
    type: ActionTypes.CONFIRMED_DELETE_VERWERKING,
    id
});

export const clear = () => ({
    type: ActionTypes.CLEAR
});