import * as ActionTypes from './actionTypes';

export const clear = () => ({
    type: ActionTypes.CLEAR
});

export const resetPassword = (model, form) => ({
    type: ActionTypes.RESET_PASSWORD,
    model,
    form
});

export const setPassword = (model, form) => ({
    type: ActionTypes.SET_PASSWORD,
    model,
    form
});

export const presetData = (model) => ({
    type: ActionTypes.PRESET_DATA,
    model
});