import passwordStrengthValidator from './validators/passwordStrength';
import doesNotContainValidator from './validators/doesNotContain';
import notInTheFutureValidator from './validators/notInTheFutureValidator';

class FieldSetup {

    constructor() {
        this.friendlyName = 'Veld';
        this.validators = { };
        this.validationErrors = {};
        this.validationError = 'Ongeldig';
    }

    for(name) {
        this.name = name;

        return this;
    }

    withFriendlyName(name) {
        this.friendlyName = name;
        this.validationError = `${name} is ongeldig.`;

        return this;
    }

    setAutoFocus() {
        this.autoFocus = true;

        return this;
    }

    setLabel(label) {
        this.label = label || this.friendlyName;

        return this;
    }

    setPlaceholder(placeholder) {
        this.placeholder = placeholder || this.friendlyName;

        return this;
    }

    /**
     * Sets the api property with a function
     * @param {*} api
     */
    loadFrom(api) {
        this.api = api;

        return this;
    }

    readOnly(isReadOnly) {
        if (isReadOnly) {
            this.disabled = true;
        }
        return this;
    }

    isNotInTheFuture() {
        this.validators[ notInTheFutureValidator.NAME ] = true;
        this.validationErrors[ notInTheFutureValidator.NAME ] = `${this.friendlyName} mag niet in de toekomst liggen`;

        return this;
    }

    equals(value, message = `is niet gelijk aan ${value}`) {
        this.validators.equals = value;
        this.validationErrors.equals = `${this.friendlyName} ${message}`;

        return this;
    }

    equalsField(otherField, nameOtherField) {
        this.validators.equalsField = nameOtherField;
        this.validationErrors.equalsField = `${this.friendlyName} en ${otherField} komen niet overeen.`;

        return this;
    }

    notEquals(value, message = `is niet ongelijk aan ${value}`) {
        this.validators.doesNotEqual = value;
        this.validationErrors.doesNotEqual = `${this.friendlyName} ${message}`;

        return this;
    }

    doesNotContain(otherField, nameOtherField) {

        if (this.validators[ doesNotContainValidator.NAME ] == null) {

            this.validators[ doesNotContainValidator.NAME ] = {
                'Fields': {},
                'Format': (fieldName) => (`${fieldName} mag niet voorkomen in ${this.friendlyName}.`)
            };

        }

        this.validators[ doesNotContainValidator.NAME ][ 'Fields' ][ nameOtherField ] = otherField;

        // this.validators[ doesNotContainValidator.NAME ] = nameOtherField;
        // this.validationErrors[ doesNotContainValidator.NAME ] = `${otherField} mag niet voorkomen in ${this.friendlyName}.`;

        return this;
    }

    isRequired(isCheckbox = false) {

        if (isCheckbox) {
            this.equals(true, 'is niet aangevinkt');
            this.notEquals('', 'is niet aangevinkt');
        }
        else {
            this.required = true;

            this.validationErrors.required = `${this.friendlyName} mag niet leeg zijn.`;
        }

        return this;
    }

    minLength(min) {
        this.validators.minLength = min;
        this.validationErrors.minLength = `${this.friendlyName} moet minimaal ${min} tekens lang zijn.`;

        return this;
    }

    maxLength(max) {
        this.validators.maxLength = max;
        this.validationErrors.maxLength = `${this.friendlyName} mag maar ${max} tekens lang zijn.`;
        this.maxLength = max;

        return this;
    }

    password(minLength, minStrenght = 50) {
        this.minLength(minLength);
        this.validators[ passwordStrengthValidator.NAME ] = {
            minimumLength: minLength,
            minimumStrength: minStrenght
        };
        this.validationErrors[ passwordStrengthValidator.NAME ] = `${this.friendlyName} is niet sterk genoeg, gebruik meer (hoofd)letters, cijfers en symbolen.`;

        return this;
    }

    isEmail() {
        this.validators.email = true;
        this.validationErrors.email = `${this.friendlyName} is geen geldig e-mail adres`;

        return this;
    }

    isUrl() {

        this.validators.matchRegexp = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
        this.validationErrors.matchRegexp = `${this.friendlyName} is geen geldige link`;

        return this;
    }

    isNumeric() {
        this.validators.isNumeric = true;
        this.validationErrors.isNumeric = `${this.friendlyName} mag alleen getallen bevatten`;

        return this;
    }

    isPhoneNumber() {

        this.validators.matchRegexp = /^[0]{1}[1-9]{1}[0-9]{8}$/;
        this.validationErrors.matchRegexp = `${this.friendlyName} is niet geldig, gebruik alleen cijfers en begin met een 0`;

        return this;
    }

    matches(regex, message = '$ bevat geen geldige waarde') {
        this.validators.matchRegexp = regex;
        this.validationErrors.matchRegexp = message.replace(/\$/, this.friendlyName);

        return this;
    }

    onChange(func) {
        this.onChange = func;

        return this;
    }
}

export default FieldSetup;