import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Panel } from 'react-bootstrap';
import FaIcon from 'react-fontawesome';

import {
    Container,
    TemplateWorkingModel,
    Button,
    Loading
} from './_references';

class Templates extends React.Component {

    componentDidMount() {
        this.props.load();
    }

    render() {

        const { list, download, isLoading } = this.props;

        return (
            <Container>
                <Row>
                    <Col sm={12} xs={12}>
                        <Panel>
                            <Panel.Heading>
                                <h2>Templates</h2>
                            </Panel.Heading>
                            <Panel.Body>
                                <p className="hide-print">Op deze pagina kunt u een aantal templates terugvinden die handig kunnen zijn om te gebruiken.</p>
                                <p className="hide-print">Om een template te gebruiken, klikt u op de download link waarna het document op uw computer bewerkt kan worden.</p>

                                { isLoading ? <Loading /> :

                                    <Panel>
                                        <Panel.Body>
                                            <div className="panel-inline-title">Templates</div>

                                            <Row><Col xsHidden sm={12}>
                                                <Row className="row-table row-table-header">
                                                    <Col xs={11}>Omschrijving</Col>
                                                    <Col xs={1}></Col>
                                                </Row>
                                            </Col></Row>

                                            {
                                                list
                                                    .map((dg, index) =>(
                                                        <Row className={`row-text row-table row-table-${index % 2 === 0 ? 'even' : 'odd'}`} key={dg[ TemplateWorkingModel.Id ]}>
                                                            <Col xs={11}>{dg[ TemplateWorkingModel.Description ]}</Col>
                                                            <Col xs={1}><Button className="download" onClick={() => download(dg[ TemplateWorkingModel.Id ])}><FaIcon name="download"/></Button></Col>
                                                        </Row>
                                                    ))
                                            }
                                        </Panel.Body>
                                    </Panel>
                                }
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </Container>
        );
    }
}

Templates.propTypes = {
    load: PropTypes.func.isRequired,
    list: PropTypes.array,
    download: PropTypes.func.isRequired,
    isLoading: PropTypes.bool
};

export default Templates;