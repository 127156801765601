import * as Helpers from './helpers';
import * as Routes from './constants.routes';

const defaultSettings = {
    suppressErrors: true,
    onValidationError: {
        showModal: true
    }
};

export const GetAllVerwerkingen = () => {
    return Helpers.get(Routes.Verwerkingsregister);
};

export const GetVerwerking = (id) => {
    return Helpers.get(`${Routes.Verwerkingsregister}/${id}`);
};

/**
 * Creates a new Verwerking
 *
 * @param {*} model
 */
export const AddVerwerking = (model) => {
    return Helpers.post(Routes.Verwerkingsregister, model, defaultSettings);
};

/**
 * Updates a Verwerking
 *
 * @param {*} model
 */
export const UpdateVerwerking = (model) => {
    return Helpers.put(Routes.Verwerkingsregister, model, defaultSettings);
};

export const DeleteVerwerking = (id) => {
    return Helpers.delete_(`${Routes.Verwerkingsregister}/${id}`, {
        suppressErrors: true
    });
};