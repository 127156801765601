import React from 'react';

import { Row, Col, Panel } from 'react-bootstrap';
import Button from '../../universe/molecules/button';
import FaIcon from 'react-fontawesome';
import { Link } from 'react-router';

import { Routes } from '../../configuration/constants.routes';
import Container from '../../universe/molecules/container/pageContainers/pageContainer';
import IncidentLijst from '../incident/lijst';
import Verwerkingsregister from '../verwerkingsregister/lijst';
import Verwerkersregister from '../verwerkersregister/lijst';
import AlgemeneRegisterInformatie from '../algemeneRegisterInformatie';
import PiaLijst from '../pia/lijst';
import * as Features from '../../configuration/constants.features';
import FeatureToggle from 'react-feature-toggles/lib/FeatureToggle';

const Dashboard = () => (
    <Container>
        {/* <Col xs={12} className="bs-callout bs-callout-warning">
            <h3><small>Privacy Impact Assessment</small></h3>
            <p>
                        Let op, u heeft nog geen Privacy Impact Assessment uitgevoerd.
            </p>
        </Col> */}
        <Row>
            <Col sm={12} xs={12}>
                <Panel>
                    <Panel.Heading><h2>Eerste Hulp Bij Onregelmatigheden</h2></Panel.Heading>
                    <Panel.Body>
                        <p className="hide-print">Heeft u een incident ontdekt? Onderneem dan nu actie! Start de Eerste Hulp Bij Onregelmatigheden module om u te begeleiden bij het onderzoek.</p>

                        <p className="hide-print"><Link to={ Routes.Dashboard.Incident.Achtergrond.path }>Klik hier voor achtergrond informatie over wat een datalek is.</Link></p>

                        <span className="v-spacer" />

                        <IncidentLijst top={5} to={Routes.Dashboard.Incident.path} />
                    </Panel.Body>
                </Panel>
            </Col>
            <Col sm={12} xs={12}>
                <AlgemeneRegisterInformatie />
            </Col>
            <Col sm={12} xs={12}>
                <Panel>
                    <Panel.Heading><h2>Verwerkingsregister</h2></Panel.Heading>
                    <Panel.Body>

                        <p className="hide-print">In het verwerkingsregister neemt u op welke persoonsgegevens uw organisatie verwerkt en wie de verantwoordelijke binnen uw organisatie is.</p>

                        <p className="hide-print">Volgens de huidige beschrijving op de website van de Autoriteit Persoonsgegevens is er eigenlijk altijd een verwerkingsregister noodzakelijk zodra u ook maar iets registreert van een groep mensen.</p>

                        <Verwerkingsregister top={5} to={Routes.Dashboard.Verwerkingsregister.path} />

                        <Row>
                            <Col xsHidden sm={12} className="text-right">
                                <Button to={Routes.Dashboard.Verwerkingsregister.path} bsStyle="primary" ><FaIcon name="address-book-o" /> Openen</Button>
                            </Col>

                            <Col smHidden mdHidden lgHidden xs={12} >
                                <Button to={Routes.Dashboard.Verwerkingsregister.path} bsStyle="primary" block><FaIcon name="address-book-o" /> Openen</Button>
                            </Col>
                        </Row>
                    </Panel.Body>
                </Panel>
            </Col>
            <Col sm={12} xs={12}>
                <Panel>
                    <Panel.Heading><h2>Verwerkersregister</h2></Panel.Heading>
                    <Panel.Body>

                        <p className="hide-print">In het verwerkersregister neemt u alle personen en bedrijven op die voor u verwerking doen van persoonsgegevens waar u zelf de eindverantwoordelijke voor bent.</p>

                        <Verwerkersregister top={5} to={Routes.Dashboard.Verwerkersregister.path} />

                        <Row>
                            <Col xsHidden sm={12} className="text-right">
                                <Button to={Routes.Dashboard.Verwerkersregister.path} bsStyle="primary" ><FaIcon name="address-book-o" /> Openen</Button>
                            </Col>

                            <Col smHidden mdHidden lgHidden xs={12} >
                                <Button to={Routes.Dashboard.Verwerkersregister.path} bsStyle="primary" block><FaIcon name="address-book-o" /> Openen</Button>
                            </Col>
                        </Row>
                    </Panel.Body>
                </Panel>
            </Col>
            <FeatureToggle featureName={Features.ModulePia}>
                <Col xs={12}>
                    <Panel >
                        <Panel.Heading><h2>Privacy Impact Assessment</h2></Panel.Heading>
                        <Panel.Body>
                            <p>Een Privacy Impact Assessment (PIA) voert u uit om een beter beeld te krijgen op de risico&#39;s die u mogelijk loopt.</p>

                            <PiaLijst top={5} />

                        </Panel.Body>
                    </Panel>
                </Col>
            </FeatureToggle>
            <FeatureToggle featureName={Features.ModuleDownloads}>

                <Col xs={12}>
                    <Panel>
                        <Panel.Heading><h2>Downloads</h2></Panel.Heading>
                        <Panel.Body>

                            {/* <ul>
                            <li><a href="/downloads/verwerkersovereenkomst">Voorbeeld Verwerkersovereenkomst</a></li>
                        </ul> */}

                        </Panel.Body>
                    </Panel>
                </Col>
            </FeatureToggle>

        </Row>

    </Container>
);

export default Dashboard;