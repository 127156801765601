import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Panel } from 'react-bootstrap';
import { Link } from 'react-router';
import FaIcon from 'react-fontawesome';
import Formsy from 'formsy-react';

import {
    Button,
    Container,
    Editor,
    Routes
} from './_references';

class VerwerkerToevoegen extends React.Component {

    componentWillUnmount() {
        this.props.clear();
    }

    render() {
        const { bind, onChange, addVerwerker } = this.props;

        return (
            <Container title="Verwerkersregister">
                <Formsy ref={(frm)=> {
                    this.frm = frm;
                }} className="form-horizontal" data-toggle="validator" onValidSubmit={(model) => {
                    addVerwerker(model);
                }}>

                    <Panel><Panel.Body>
                        <div className="panel-inline-title">Nieuwe verwerker</div>
                        <Editor {...{
                            bind, onChange
                        }}/>
                    </Panel.Body></Panel>
                    <Row>
                        <Col xs={12} className="text-right">
                            <Link to={Routes.Dashboard.Verwerkersregister.path}>annuleren</Link>
                            <span className="v-spacer"/>
                            <Button bsStyle="primary" type="submit"><FaIcon name="floppy-o" /> Opslaan</Button>
                        </Col>
                    </Row>
                </Formsy>
            </Container>
        );
    }
}

VerwerkerToevoegen.propTypes = {
    clear: PropTypes.func.isRequired,
    bind: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    addVerwerker: PropTypes.func.isRequired
};

export default VerwerkerToevoegen;