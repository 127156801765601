import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Panel, Row, Col } from 'react-bootstrap';
import FaIcon from 'react-fontawesome';
import { Link } from 'react-router';

import {
    Button,
    IncidentInfoWorkingModel,
    Loading,
    Routes
} from './_references';

class IncidentLijst extends React.Component {

    componentDidMount() {
        this.props.loadIncidents();
    }

    render() {
        const { isLoading, list, top, to } = this.props;

        return (isLoading !== false ? <Loading /> :
            <div>
                <Panel>
                    <Panel.Body>
                        <div className="panel-inline-title">Incidenten</div>

                        <Row>
                            <Col sm={12} xsHidden>
                                <Row className="row-table row-table-header">
                                    <Col xsHidden sm={2}>Datum</Col>
                                    <Col xsHidden sm={5}>Naam</Col>
                                    <Col xsHidden sm={5} className="text-right"></Col>
                                </Row>
                            </Col>
                        </Row>

                        {
                            list.slice(0, top).map((incident, index) => (
                                <Row key={incident[ IncidentInfoWorkingModel.IncidentId ]} className={`row-table row-table-${index % 2 === 0 ? 'even' : 'odd'}`}>
                                    <Col xs={12} smHidden mdHidden lgHidden className="row-table-header-title">Datum</Col>
                                    <Col xs={12} sm={2}>{moment(incident[ IncidentInfoWorkingModel.DatumIncident ]).format('DD-MM-YYYY')}</Col>
                                    <Col xs={12} smHidden mdHidden lgHidden className="row-table-header-title">Naam</Col>
                                    <Col xs={12} sm={5}>{incident[ IncidentInfoWorkingModel.Naam ]}</Col>
                                    <Col xsHidden sm={5} className="text-right">
                                        <span className="v-spacer"/>
                                        <Button to={Routes.Dashboard.Incident.Inzien.createUrl(incident[ IncidentInfoWorkingModel.IncidentId ])} bsStyle="primary"><FaIcon name="file-text-o"/> Inzien</Button>
                                        <span className="v-spacer"/>
                                        <Button to={Routes.Dashboard.Incident.Bewerken.createUrl(incident[ IncidentInfoWorkingModel.IncidentId ])} bsStyle="primary" disabled={incident[ IncidentInfoWorkingModel.Afgesloten ] === true}><FaIcon name="pencil"/> Bewerken</Button>
                                    </Col>
                                    <Col xs={12} smHidden mdHidden lgHidden>
                                        <Button to={Routes.Dashboard.Incident.Inzien.createUrl(incident[ IncidentInfoWorkingModel.IncidentId ])} bsStyle="primary" block><FaIcon name="file-text-o"/> Inzien</Button>
                                        <span className="h-spacer"/>
                                        <Button to={Routes.Dashboard.Incident.Bewerken.createUrl(incident[ IncidentInfoWorkingModel.IncidentId ])} bsStyle="primary" block disabled={incident[ IncidentInfoWorkingModel.Afgesloten ] === true}><FaIcon name="pencil"/> Bewerken</Button>
                                    </Col>
                                </Row>

                            ))
                        }

                        {
                            to != null && top != null && top < list.length ?
                                <Row className="row-table row-table-footer"><Col xs={12}><Link to={to}>Alle {list.length} incidenten bekijken</Link> </Col> </Row>
                                : null
                        }
                    </Panel.Body>
                </Panel>

                <Row>
                    <Col xsHidden sm={12} className="text-right">
                        <Button bsStyle="primary" to={Routes.Dashboard.Incident.Nieuw.path} ><FaIcon name="medkit" /> Start</Button>
                    </Col>

                    <Col smHidden mdHidden lgHidden xs={12} >
                        <Button to={Routes.Dashboard.Incident.Nieuw.path} bsStyle="primary" block><FaIcon name="medkit" /> Start</Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

IncidentLijst.propTypes = {
    loadIncidents: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    list: PropTypes.array,
    top: PropTypes.number,
    to: PropTypes.string
};

export default IncidentLijst;