import * as ActionTypes from './actionTypes';
import * as Constants from './constants';

/*
 * Reducer for the algemene register informatie component.
*/
const reducer = (state = {}, action) => {
    switch(action.type){
    case ActionTypes.LOAD: {
        const newState = Object.assign({}, state, {
            [ Constants.FIELDS.LOADING ]: true
        });
        return newState;
    }
    case ActionTypes.LOADED:
    {
        return Object.assign({},state,{
            [ Constants.FIELDS.LIST ]: action.data,
            [ Constants.FIELDS.LOADING ]: false
        });
    }
    default:
        return state;
    }
};

reducer.STATE_KEY = Constants.STATE_KEY;

export default reducer;