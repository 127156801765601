import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import * as API from '../../api/privacyScan';
import {  PrivacyCheckWorkingModel } from '../../model';
import { stateHelper } from '../../util/stateManager';

import * as ActionTypes from './actionTypes';
import * as Actions from './actions';
import * as Constants from './constants';

export default function* () {
    yield all([
        takeEvery(ActionTypes.LOAD_QUESTION, loadQuestion),
        takeEvery(ActionTypes.QUESTION_ANSWERED, handleAnswer)
    ]);
}

function* loadQuestion(action) {
    yield put(Actions.setLoadingState(true));

    try {
        const question = yield call (API.GetPrivacyCheckQuestion, action[ PrivacyCheckWorkingModel.SortOrder ]);

        yield put(Actions.questionLoaded(question.data));
    }
    catch (e) {
        // TODO:
    }

    yield put(Actions.setLoadingState(false));

}

function* handleAnswer(action) {
    const state = yield select();

    const question = stateHelper(state, [ Constants.STATE_KEY, Constants.FIELDS.QUESTION ]);

    if (action[ Constants.FIELDS.ANSWER ] === false) {

        if (question[ PrivacyCheckWorkingModel.IsFinalQuestion ] === true) {
            yield put(Actions.finished( Constants.OUTCOME.DONT_NEED_PIA ));
        }
        else {
            yield loadQuestion({
                [ PrivacyCheckWorkingModel.SortOrder ]: question[ PrivacyCheckWorkingModel.SortOrder ]
            });
        }
    }
    else {
        const score = stateHelper(state, [ Constants.STATE_KEY, Constants.FIELDS.SCORE ], 0.0);
        const newScore = score + question[ PrivacyCheckWorkingModel.Weight ];
        yield put (Actions.setScore( newScore ));

        if (newScore >= 1.0) {
            yield put(Actions.finished( Constants.OUTCOME.NEED_PIA ));
        }
        else {
            if (question[ PrivacyCheckWorkingModel.IsFinalQuestion ] === true) {
                yield put(Actions.finished( Constants.OUTCOME.DONT_NEED_PIA ));
            }
            else {
                yield loadQuestion({
                    [ PrivacyCheckWorkingModel.SortOrder ]: question[ PrivacyCheckWorkingModel.SortOrder ]
                });
            }
        }

    }

}