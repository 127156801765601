import { reducer as toastrReducer } from 'react-redux-toastr';
import { routerReducer } from 'react-router-redux';
import nieuwsReducer from '../modules/nieuws/reducer';
import privacyCheckReducer from '../modules/privacyCheck/reducer';
import dictionaryReducer from '../modules/woordenboek/reducer';
import loginReducer from '../modules/login/reducer';
import authenticationReducer from '../modules/shared/authentication/reducer';
import createAccountReducer from '../modules/account/aanvragen/reducer';
import bevestigenAccountReducer from '../modules/account/bevestigen/reducer';
import activerenAccountReducer from '../modules/account/activeren/reducer';
import feedbackReducer from '../universe/molecules/feedback/reducer';
import privacyImpactAssessmentReducer from '../modules/pia/reducer';
import loadingReducer from '../universe/molecules/loading/reducer';
import errorReducer from '../modules/shared/error/reducer';
import incidentReducer from '../modules/incident/reducer';
import selectReducer from '../universe/molecules/select/reducer';
import verwerkingsregisterReducer from '../modules/verwerkingsregister/reducer';
import verwerkersregisterReducer from '../modules/verwerkersregister/reducer';
import algemeneRegisterInformatieReducer from '../modules/algemeneRegisterInformatie/reducer';
import wachtwoordVergetenReducer from '../modules/account/wachtwoordVergeten/reducer';
import wachtwoordHerstellenReducer from '../modules/account/wachtwoordHerstellen/reducer';
import selectAccountReducer from '../modules/account/select/reducer';
import koppelAccountReducer from '../modules/account/koppelen/reducer';
import beveiligenAccountReducer from '../modules/account/beveiligen/reducer';
import mijnAccountReducer from '../modules/account/mijnaccount/reducer';
import beheerPersonenReducer from '../modules/beheer/personen/reducer';
import templatesReducer from '../modules/template/reducer';
import dialogReducer from '../structure/dialog/reducer';

// the router reducer needs to 'live' in the routing key.
routerReducer.STATE_KEY = 'routing';

// HACK
toastrReducer.STATE_KEY = 'toastr';

export {
    activerenAccountReducer,
    algemeneRegisterInformatieReducer,
    authenticationReducer,
    beveiligenAccountReducer,
    mijnAccountReducer,
    bevestigenAccountReducer,
    createAccountReducer,
    dictionaryReducer,
    errorReducer,
    feedbackReducer,
    incidentReducer,
    koppelAccountReducer,
    loadingReducer,
    loginReducer,
    nieuwsReducer,
    privacyCheckReducer,
    privacyImpactAssessmentReducer,
    routerReducer,
    selectAccountReducer,
    selectReducer,
    toastrReducer,
    verwerkersregisterReducer,
    verwerkingsregisterReducer,
    wachtwoordHerstellenReducer,
    wachtwoordVergetenReducer,
    beheerPersonenReducer,
    templatesReducer,
    dialogReducer
};
