import * as ActionTypes from './actionTypes';

export const clearErrorInformation = () => ({
    type: ActionTypes.CLEAR_ERROR_INFORMATION
});

export const setErrorInformation = (title, message) => ({
    type: ActionTypes.SET_ERROR_INFORMATION,
    title, 
    message
});