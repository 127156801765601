export const NAME = 'authentication';

export const STATE_KEY = `${NAME}/00baf32d-c4af-4b0b-87bc-34d3235a2b26`;

export const Headers = {
    WTPACCOUNT: 'WTP-ACCOUNT'
};

export const Fields = {
    ACCOUNT_INFO : 'AccountInfo'
};

export const Roles = {
    Officer : 'db2d71bd-a2c4-4f6b-bd2b-3a3930073be0',
    Manager : 'cd63a78a-6dfb-4857-8630-a7ecf34fcf18'
};

export const Claims = {
    'W.T. Privacy Officer' : 'Officer',
    'W.T. Privacy Manager' : 'Manager'
};