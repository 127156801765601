import React from 'react';

import {
    Container,
    Panel
} from './_references';

const Incident = () => (
    <Container>
        <Panel xs={12} sm={12} title="Wat is een datalek?">
            <p>Je spreekt van een datalek als er sprake is van het ongeoorloofd in handen vallen van persoonsgegevens aan derden die geen toegang tot die gegevens zouden mogen hebben. “Een datalek is het gevolg van ……”</p>

            <p><i>Alle datalekken dient u te administreren en eventueel kenbaar te maken.</i></p>

        </Panel>

        <Panel xs={12} sm={12} title="Moet ik altijd een datalek melden?">
            <p>De algemene meldplicht datalekken voor bedrijven (MKB) en overheid wordt de brede meldplicht genoemd. Deze wordt opgenomen in een nieuw artikel in de Algemene verordening gegevensbescherming (AVG). De klemtoon bij deze meldplicht komt te liggen op het lekken van persoonsgegevens als gevolg van beveiligingsproblemen. Via het meldloket Autoriteit Persoonsgegevens (AP) dienen deze datalekken, <u>indien zij als voldoende ernstig te zijn aangemerkt</u>, te worden gemeld. U krijgt dan een meldingsnummer waarmee u kunt aantonen zich bewust te zijn van een ‘probleem’.</p>
        </Panel>

        <Panel xs={12} sm={12} title="Moet ik betrokkenen personen op de hoogte stellen?">
            <p>Hier is geen vaste stelregel voor, dit is afhankelijk van de gevoeligheid van de gelekte (persoons)gegevens. Aan de hand van de eventuele gevolgen (zowel privé als zakelijk) voor de betrokkenen bepaalt u zelf of inlichten wel of niet noodzakelijk is. Registreren van wat u beslist is een optie. Mogelijkheden zijn: Ik heb het lek gemeld op (datum), Ik ga het lek melden op (datum), nog niet bekend.</p>
            <p>Ook de inhoud van de melding vastleggen, is aan te bevelen en op welke manier u betrokkenen in kennis hebt gesteld (schriftelijk/mondeling etc.)</p>
            <p>Maar ook waarom u afziet van kennisgeving is aan te bevelen om vast te leggen.</p>
        </Panel>

        <Panel xs={12} sm={12} title="Voorbeelden van een analoog datalek">
            <ul>
                <li>Een identiteitsbewijs is onbeheerd ergens blijven liggen;</li>
                <li>Een identiteitsbewijs verloren gegaan, bijv. met andere papieren vernietigd;</li>
                <li>Een printversie van een lijst met NAW gegevens is in verkeerde handen gevallen;</li>
                <li>Toegangs-identiteitsgegevens, inloggegevens etc;</li>
                <li>BSI nummers/sofinummers verloren/verdwenen;</li>
                <li>Lezen/kopiëren van vertrouwelijke informatie door niet bevoegde personen.</li>
            </ul>

            <i>Dit zijn slechts een paar voorbeelden. De privacy-officers van W.T. Privacy kunnen u altijd de juiste informatie geven.</i>
        </Panel>

        <Panel xs={12} sm={12} title="Voorbeelden van een digitaal datalek">
            <ul>
                <li>Een kwijtgeraakte USB stick met gevoelige informatie erop;</li>
                <li>Een gestolen laptop;</li>
                <li>Een inbraak door een hacker op uw server/computer;</li>
                <li>Een malwarebesmetting;</li>
                <li>Een calamiteit zoals brand o.i.d. in een datacentrum.</li>
            </ul>

            <i>Dit zijn slechts een paar voorbeelden. De privacy-officers van W.T. Privacy kunnen u altijd de juiste informatie geven.</i>
        </Panel>

        <Panel xs={12} sm={12} title="Ik heb een datalek of beveiligings-incident">
            <p>De module van W.T. Privacy, <u><b>E</b>erste <b>H</b>ulp <b>B</b>ij <b>O</b>nregelmatigheden</u>, helpt u om snel te zoeken en te bepalen wat er moet gebeuren. Door gebruik te maken van deze module voldoet u direct aan de administratieplicht die u heeft omtrent het vastleggen van een incident.</p>
        </Panel>
    </Container>
);

export default Incident;