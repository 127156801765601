import * as Helpers from './helpers';
import * as Routes from './constants.routes';
import * as PatchActions from './constants.patch';

export const createAcccount = (model) => {
    return Helpers.post(Routes.Account, model, {
        suppressErrors: true,
        onValidationError: {
            showModal: true,
            modalTitle: 'Aanvragen account niet gelukt'
        }
    });
};

export const secureAccount = (config = {}) => {
    return Helpers.get(Routes.AccountBeveiligen, config);
};

export const addSecurity = (model) => {
    return Helpers.post(Routes.AccountBeveiligen, model, {
        suppressErrors: true,
        onValidationError: {
            showModal: true,
            modalTitle: 'Account beveiligen niet gelukt'
        }
    });
};

/**
 * Calls the backend to get account information
 *
 */
export const accountInformation = (config = {}) => {
    return Helpers.get(Routes.Account, config);
};

export const confirmAccount = (model) => {
    return Helpers.put(Routes.Account, model, {
        suppressErrors: true,
        onValidationError: {
            showModal: true,
            modalTitle: 'Aanvraag bevestigen niet gelukt'
        }
    });
};

const patch = (patchAction, model, config) => {
    return Helpers.patch(Routes.Account, patchAction, model, config);
};

export const requestPassword = (model) => {
    return patch(PatchActions.AccountPatchActions.FORGOTPASSWORD, model);
};

export const resetPassword = (model) => {
    return patch(PatchActions.AccountPatchActions.RESETPASSWORD, model);
};

export const setPassword = (model) => {
    return patch(PatchActions.AccountPatchActions.SETPASSWORD, model, {
        suppressErrors: true,
        onValidationError: {
            showModal: true,
            modalTitle: 'Herstellen van het wachtwoord niet gelukt'
        }
    });
};

export const linkAccount = (id) => {
    return patch(PatchActions.AccountPatchActions.LINK, {
        id
    }, {
        suppressErrors: true,
        onValidationError: {
            showModal: true,
            modalTitle: 'Koppelen niet gelukt'
        }
    });
};

export const unlinkAccount = (id) => {
    return patch(PatchActions.AccountPatchActions.UNLINK, {
        id
    }, {
        suppressErrors: true,
        onValidationError: {
            showModal: true,
            modalTitle: 'Ontkoppelen niet gelukt'
        }
    });
};