import React from 'react';
import PropTypes from 'prop-types';

const ValidationBase = ({ errorMessage, color }) => (errorMessage ? <span className="validation-message" style={color ? {
    color
} : {}}>{errorMessage}</span> : null);

const Validation = ({ errorMessage }) => (<ValidationBase errorMessage={errorMessage} />);

ValidationBase.propTypes = {
    errorMessage: PropTypes.string,
    color: PropTypes.string
};

const propTypes = {
    errorMessage: PropTypes.string
};

Validation.propTypes = propTypes;

export default Validation;
