import { addValidationRule } from 'formsy-react';

const doesNotContainValidator = (formValues, value, config) => {

    if (config == null) {
        return true;
    }
    if(value == null || value.trim() === '') {
        return true;
    }

    const errors = Object.keys(config.Fields)
        .filter(field => (formValues[ field ] != null && formValues[ field ].trim() !== ''))
        .map(field => ({
            name: field, valid: value.toLowerCase().indexOf(formValues[ field ].toLowerCase()) === -1
        }))
        .filter(field => (!field.valid) )
    ;

    if (errors.length === 0) {
        return true;
    }

    return config.Format(config.Fields[ errors[ 0 ].name ]);

};

doesNotContainValidator.NAME = 'doesNotContain';

doesNotContainValidator.register = () => {
    addValidationRule(doesNotContainValidator.NAME, doesNotContainValidator);
};

export default doesNotContainValidator;