import { all, call, put, takeLatest, delay } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { actions as ToastrActions } from 'react-redux-toastr';

import {
    Api,
    Routes
} from './_references';

import * as ActionTypes from './actionTypes';
import * as Actions from './actions';

export default function* () {
    yield all([
        takeLatest(ActionTypes.REQUEST_PASSWORD, requestPassword)
    ]);
}

function* requestPassword(action) {

    yield delay(250);

    try{
        const result = yield call (Api.requestPassword, action.model);

        if (result.success) {
            yield put(Actions.clear());
            action.form.reset();

            yield put (ToastrActions.add({
                type: 'success',
                title: 'Wachtwoord herstellen',
                message: 'Indien we uw account herkennen krijgt u een mail met verdere instructies toegestuurd.',
                options: {
                    showCloseButton: true,
                    timeOut: 5000,
                    attention: false, // This will add a shadow like the confirm method.
                }
            }));
            yield put (push(Routes.Home.path));
        }
        else {
            yield put (ToastrActions.add({
                type: 'error',
                title: 'Wachtwoord herstellen',
                message: 'Op dit moment is het niet mogelijk uw wachtwoord te herstellen. Neem contact op met W.T. Privacy.',
                options: {
                    showCloseButton: true,
                    attention: true, // This will add a shadow like the confirm method.
                }
            }));
        }
    }
    catch(e){
        yield put (ToastrActions.add({
            type: 'error',
            title: 'Wachtwoord herstellen',
            attention: true, // This will add a shadow like the confirm method.
            message: 'Op dit moment is het niet mogelijk uw wachtwoord te herstellen. Neem contact op met W.T. Privacy.',
            options: {
                showCloseButton: true
            }
        }));
    }

}
