import { stateHelper } from '../../../util/stateManager';
import * as Constants from '../loading/constants';

import { push } from 'react-router-redux';

export const mapStateToProps = (state) => ({
    isLoading: stateHelper(state, [ Constants.STATE_KEY, Constants.Fields.IS_LOADING ], false)
});

export const mapDispatchToProps = (dispatch) => ({
    routeTo: (route) => dispatch(push(route))
});