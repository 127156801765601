import {
    stateHelper,
    Constants,
    Actions
} from './_references';

export const mapStateToProps = (state) => ({
    list: stateHelper(state, [ Constants.STATE_KEY, Constants.FIELDS.PiaList ], []),
    isLoading: stateHelper(state, [ Constants.STATE_KEY, Constants.FIELDS.PiaListLoading ], false)
});

export const mapDisptchToProps = (dispatch) => ({
    load: () => dispatch(Actions.loadPiaList())
});