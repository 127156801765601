import React from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';

import {
    DateTime,
    FieldSetup,
    IncidentDetailUpdateWorkingModel,
    Text,
    TextArea
} from './_references';

const AlgemeneGevens = ({ onChange, bind }) =>(
    <Panel>
        <Panel.Body>
            <div className="panel-inline-title">Algemene gegevens</div>

            <p>Geef het onderzoek een naam, zoals bijvoorbeeld &apos;Gestolen laptop&apos; of &apos;Paspoort laten liggen onder kopieermachine&apos;.</p>
            <p>Beschrijf eventueel voor latere referenatie aan dit incident kort op waar het om gaat.</p>

            <Text
                {...bind(IncidentDetailUpdateWorkingModel.Naam)}
                {...new FieldSetup()
                    .for(IncidentDetailUpdateWorkingModel.Naam)
                    .withFriendlyName('Naam')
                    .setLabel()
                    .setPlaceholder()
                    .isRequired()
                    .maxLength(100)
                    .onChange(onChange)
                }
            />

            <DateTime
                {...bind(IncidentDetailUpdateWorkingModel.DatumIncident)}
                {...new FieldSetup()
                    .for(IncidentDetailUpdateWorkingModel.DatumIncident)
                    .withFriendlyName('Moment ontdekking')
                    .setLabel()
                    .setPlaceholder('Moment ontdekking')
                    .isRequired()
                    .isNotInTheFuture()
                    .onChange(onChange)
                }
                showTimeSelect
                timeIntervals={5}
                dateFormat="dd-MM-yyyy HH:mm"
            />

            <TextArea
                rows={5}

                {...bind(IncidentDetailUpdateWorkingModel.Beschrijving)}
                {...new FieldSetup()
                    .for(IncidentDetailUpdateWorkingModel.Beschrijving)
                    .withFriendlyName('Omschrijving')
                    .setLabel()
                    .setPlaceholder('Omschrijving (optioneel)')
                    .maxLength(1024)
                    .onChange(onChange)
                }
            />

        </Panel.Body>
    </Panel>

);

AlgemeneGevens.propTypes = {
    bind: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
};

export default AlgemeneGevens;