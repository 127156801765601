import * as ActionTypes from './actionTypes';

export const addPersoon = (model) => ({
    type: ActionTypes.ADD_PERSOON,
    model
});

export const getPersoon = (id, source) => ({
    type: ActionTypes.GET_PERSOON,
    id,
    source
});

export const hidePersoon = (id) => ({
    type: ActionTypes.HIDE_PERSOON,
    id
});

export const loadedPersoon = (model, source) => ({
    type: ActionTypes.LOADED_PERSOON,
    model,
    source
});

export const getPersonen = () => ({
    type: ActionTypes.GET_PERSONEN
});

export const loadedPersonen = (model) => ({
    type: ActionTypes.LOADED_PERSONEN,
    model
});

export const editPersoon = (id, source) => ({
    type: ActionTypes.EDIT_PERSOON,
    id,
    source
});

export const cancelEdit = () => ({
    type: ActionTypes.CANCEL_EDIT
});

export const updatePersoon = () => ({
    type: ActionTypes.UPDATE_PERSOON
});

export const deletePersoon = (id) => ({
    type: ActionTypes.DELETE_PERSOON,
    id
});

export const confirmedDeletePersoon = (id) => ({
    type: ActionTypes.CONFIRMED_DELETE_PERSOON,
    id
});

export const clear = () => ({
    type: ActionTypes.CLEAR
});