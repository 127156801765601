import React from 'react';
import PropTypes from 'prop-types';
import { Panel, Row, Col } from 'react-bootstrap';
import Formsy from 'formsy-react';

import {
    createFormsyInputComponent,
    SimpleValidationWrapper,
    TextComponent,
    Container,
    WachtwoordHerstellenDetailWorkingModel,
    RouteParams,
    FieldSetup,
    Button
} from './_references';

const Text = createFormsyInputComponent({
    Wrapper: SimpleValidationWrapper,
    InputComponent: TextComponent
});

class WachtwoordHerstellen extends React.Component{

    constructor(props) {
        super(props);
        this.form = React.createRef();
    }

    render () {

        const { bind, onChange, params, resetPassword } = this.props;

        return (
            <Container>
                <Formsy ref={this.form} onValidSubmit={(model)=>{
                    model[ WachtwoordHerstellenDetailWorkingModel.HerstelWachtwoordCode ] = params[ RouteParams.Account.WachtwoordHerstellen.ResetCode ];
                    resetPassword(model, this.form.current);
                }}>
                    <Panel>
                        <Panel.Body>
                            <div className="panel-inline-title">Wachtwoord herstellen</div>

                            <div>In de e-mail die u heeft ontvangen staat een bevestigingscode. Vul deze code hieronder in en klik vervolgens op de knop &apos;Bevestigen&apos;.</div>

                            <div className="text-center">
                                <Text
                                    wrapperClassName="display-inline-block"
                                    {...bind(WachtwoordHerstellenDetailWorkingModel.HerstelWachtwoordTokenFirstPart)}
                                    {...new FieldSetup()
                                        .for(WachtwoordHerstellenDetailWorkingModel.HerstelWachtwoordTokenFirstPart)
                                        .isRequired()
                                        .maxLength(4)
                                        .onChange(onChange)
                                    }
                                />
                                <Text
                                    {...bind(WachtwoordHerstellenDetailWorkingModel.HerstelWachtwoordTokenSecondPart)}
                                    {...new FieldSetup()
                                        .for(WachtwoordHerstellenDetailWorkingModel.HerstelWachtwoordTokenSecondPart)
                                        .isRequired()
                                        .maxLength(4)
                                        .onChange(onChange)
                                    } wrapperClassName="display-inline-block" />
                            </div>

                        </Panel.Body>
                    </Panel>
                    <Row>
                        <Col xs={12} className="text-right">
                            <Button bsStyle="primary" type="submit"> Bevestigen</Button>
                        </Col>
                    </Row>
                </Formsy>
            </Container>
        );
    }
}
WachtwoordHerstellen.propTypes = {
    bind: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    params: PropTypes.object,
    resetPassword: PropTypes.func
};

export default WachtwoordHerstellen;