import { NAME } from './constants';

export const LOAD_PIA_ADVISORY = `${NAME}/LOAD_PIA_ADVISORY`;
export const LOADED_PIA_ADVISORY = `${NAME}/LOADED_PIA_ADVISORY`;
export const CLEAR_PIA_ADVISORY = `${NAME}/CLEAR_PIA_ADVISORY`;

export const LOAD_PIA_LIST = `${NAME}/LOAD_PIA_LIST`;
export const LOADED_PIA_LIST = `${NAME}/LOADED_PIA_LIST`;

export const LOAD_PIA = `${NAME}/LOAD_PIA`;
export const LOADED_PIA = `${NAME}/LOADED_PIA`;
export const UPDATE_PIA= `${NAME}/UPDATE_PIA`;
export const CLOSE_PIA= `${NAME}/CLOSE_PIA`;
export const INTERMEDIATE_ANALYSIS_COMPLETE = `${NAME}/INTERMEDIATE_ANALYSIS_COMPLETE`;
export const COPY_EXISTING = `${NAME}/COPY_EXISTING`;
export const CREATE_NEW = `${NAME}/CREATE_NEW`;