const Api = 'api';

export const Nieuws = `${Api}/news`;
export const PrivacyCheck = `${Api}/privacyCheck`;
export const PrivacyCheckParameters = {
    CurrentSortOrder : 'currentSortOrder'
};

export const Dictionary = `${Api}/dictionary`;
export const Login = `${Api}/credentials`;

export const Cookie = 'cookie';

export const Account = `${Api}/account`;
export const Accounts = `${Api}/accounts`;
export const AccountActiveren = `${Api}/accountActiveren`;
export const AccountKoppelen = `${Api}/accountKoppelen`;
export const AccountBeveiligen = `${Api}/accountBeveiligen`;
export const MijnAccount = `${Api}/mijnaccount`;

export const PrivacyImpactAssessment = `${Api}/privacyImpactAssessment`;
export const PrivacyImpactAssessmentAdvisory = `${Api}/privacyImpactAssessmentAdvisory`;
export const PrivacyImpactAssessmentAnalysis = `${Api}/PrivacyImpactAssessmentAnalysis`;

export const Incindent = `${Api}/incident`;
export const IncindentAnalyse = `${Api}/incidentAnalyse`;

export const StamSector = `${Api}/stamSector`;

export const Template = `${Api}/template`;

export const Verwerkingsregister = `${Api}/verwerkingsregister`;

export const Verwerkersregister = `${Api}/verwerkersregister`;

export const RegisterInformatie = `${Api}/registerInformatie`;

export const Personen = `${Api}/personen`;