import React from 'react';
import { Panel, Col, Row } from 'react-bootstrap';
import Button from '../../universe/molecules/button';
import Md from 'react-markdown-renderer';
import FaIcon from 'react-fontawesome';

import Container from '../../universe/molecules/container/pageContainers/pageContainer';
import { PrivacyCheckWorkingModel } from '../../model';

import { OUTCOME } from './constants';
import PIA from './pia';
import NPIA from './npia';

class PrivacyCheck extends React.Component {

    componentDidMount() {
        this.props.loadQuestion();
    }

    render() {

        const { question, questionAnswered, loading, finished } = this.props;

        return (
            <Container title="Privacy Check">

                <Row>
                    <Col xs={12}>
                        <p>Aan de hand van enkele vragen kunt u bepalen of u voor uw organisatie een Privacy Impact Assessment dient uit te voeren.</p>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        { !finished ?
                            <Panel>
                                <Panel.Body>
                                    {loading ? 'Laden...' : null}

                                    {!loading && question ? <div className="privacy-check-question">{question[ PrivacyCheckWorkingModel.Question ]}</div> : null }

                                    {!loading && question && question[ PrivacyCheckWorkingModel.Information ] ?
                                        <Panel className="privacy-check-info">
                                            <Panel.Heading>
                                                <Panel.Title toggle>
                                                    <span><FaIcon name="info" /> Achtergrond informatie</span>
                                                </Panel.Title>
                                            </Panel.Heading>
                                            <Panel.Body collapsible>
                                                <Md options={{
                                                    preset: 'default', html: true
                                                }} markdown={question[ PrivacyCheckWorkingModel.Information ]} />
                                            </Panel.Body>
                                        </Panel>
                                        : null
                                    }

                                    <div className="privacy-check-buttons">
                                        <Button bsStyle="primary" className="margin-right-10" onClick={()=>questionAnswered(true)}>Ja</Button>
                                        <Button bsStyle="primary" onClick={()=>questionAnswered(false)}>Nee</Button>
                                    </div>
                                </Panel.Body>
                            </Panel>
                            :
                            <Panel>
                                <Panel.Body>
                                    { finished === OUTCOME.NEED_PIA ? <PIA /> : <NPIA /> }
                                </Panel.Body>
                            </Panel>
                        }
                    </Col>
                </Row>

            </Container>
        );
    }
}

export default PrivacyCheck;