import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { actions as ToastrActions } from 'react-redux-toastr';

import {
    API,
    Routes,
    stateHelper,
    Builder
} from './_references';

import * as ActionTypes from './actionTypes';
import * as Actions from './actions';
import * as Constants from './constants';

export default function* () {
    yield all([
        takeEvery(ActionTypes.GET_PERSOON, getPersoon),
        takeEvery(ActionTypes.GET_PERSONEN, getPersonen),
        takeEvery(ActionTypes.ADD_PERSOON, addPersoon),
        takeEvery(ActionTypes.DELETE_PERSOON, deletePersoon),
        takeEvery(ActionTypes.CONFIRMED_DELETE_PERSOON, confirmedDeletePersoon),
        takeEvery(ActionTypes.EDIT_PERSOON, editPersoon),
        takeEvery(ActionTypes.UPDATE_PERSOON, updatePersoon),
    ]);
}

function* getPersoon(action) {
    try {

        const persoon = yield call (API.GetPersoon, action.id);

        if (persoon.success) {

            yield put (Actions.loadedPersoon(persoon.data, action.source));
        }
        else {
            //TODO
        }
    }
    catch (e) {
        // TODO:
    }
}

function* getPersonen() {
    try {
        const personen = yield call (API.GetAllPersonen);

        if (personen.success) {

            yield put (Actions.loadedPersonen(personen.data));
        }
        else {
            //TODO
        }
    }
    catch (e) {
        // TODO:
    }
}

function* addPersoon(action) {
    try {
        const incident = yield call (API.AddPersoon, action.model);

        if (incident.success) {
            yield put (ToastrActions.add({
                type: 'success',
                title: 'Persoon',
                attention: true, // This will add a shadow like the confirm method.
                message: 'Persoon is aangemaakt.',
                options: {
                    showCloseButton: true
                }
            }));
            yield put (push(Routes.Beheer.Personen.path));
        }
        else {
            yield put (ToastrActions.add({
                type: 'error',
                title: 'Persoon',
                attention: true, // This will add a shadow like the confirm method.
                message: 'Persoon kon niet worden aangemaakt.',
                options: {
                    showCloseButton: true
                }
            }));
        }
    }
    catch (e) {
        // TODO:
    }
}

function* updatePersoon() {
    try {

        const persoon = yield select((state) => (stateHelper( state, [ Constants.STATE_KEY, Constants.FIELDS.EDITOR ], {})));

        const result = yield call (API.UpdatePersoon, persoon);

        if (result.success) {
            yield put(ToastrActions.add({
                type: 'success',
                title: 'Persoon',
                attention: true, // This will add a shadow like the confirm method.
                message: 'Persoon is opgeslagen.',
                options: {
                    showCloseButton: true
                }
            }));
            yield put(Actions.cancelEdit());
            yield put(Actions.getPersonen());
        }
        else {
            yield put(ToastrActions.add({
                type: 'error',
                title: 'Persoon',
                attention: true, // This will add a shadow like the confirm method.
                message: 'Persoon kon niet worden opgeslagen.',
                options: {
                    showCloseButton: true
                }
            }));
        }
    }
    catch (e) {
        // TODO:
    }
}

function* editPersoon(action) {
    yield getPersoon(action);
}

function* deletePersoon(action) {

    const defaultDialog = Builder.confirmDelete({
        onJa : Actions.confirmedDeletePersoon(action.id)
    }).prepare();

    yield call(defaultDialog);
}

function* confirmedDeletePersoon(action) {
    // the reducer will already have deleted this record from the redux store
    const result = yield call(API.DeletePersoon, action.id);

    if (!result.success && result.response.status === 422) {
        yield getPersonen();
    }
}
