/*
    This file is generated on 5-2-2020 by the CreateJsErrorCodes T4 template, located in WTPrivacy.Model.Validation project.
*/
const validationErrorsBase = {
    // Incident (ErrorCodes.Incident.cs)
    /** 'SEI01' : 'SEI01' */
    'SEI01' : 'SEI01',
    /** 'SEI02' : 'SEI02' */
    'SEI02' : 'SEI02',
    /** 'SEI03' : 'SEI03' */
    'SEI03' : 'SEI03',
    /** 'SEI04' : 'SEI04' */
    'SEI04' : 'SEI04',
    /** 'SEI05' : 'SEI05' */
    'SEI05' : 'SEI05',
    /** 'SEI06' : 'SEI06' */
    'SEI06' : 'SEI06',
    /** 'SEI07' : 'SEI07' */
    'SEI07' : 'SEI07',
    /** 'SEI08' : 'SEI08' */
    'SEI08' : 'SEI08',
    /** 'SEI09' : 'SEI09' */
    'SEI09' : 'SEI09',
    /** 'SEI10' : 'SEI10' */
    'SEI10' : 'SEI10',
    /** 'SEI11' : 'SEI11' */
    'SEI11' : 'SEI11',
    /** 'SEI12' : 'SEI12' */
    'SEI12' : 'SEI12',
    /** 'SEI13' : 'SEI13' */
    'SEI13' : 'SEI13',
    /** 'SEI14' : 'SEI14' */
    'SEI14' : 'SEI14',
    /** 'SEI15' : 'SEI15' */
    'SEI15' : 'SEI15',
    /** 'SEI16' : 'SEI16' */
    'SEI16' : 'SEI16',
    /** 'SEI17' : 'SEI17' */
    'SEI17' : 'SEI17',
    /** 'SEI18' : 'SEI18' */
    'SEI18' : 'SEI18',
    /** 'SEI19' : 'SEI19' */
    'SEI19' : 'SEI19',
    /** 'SEI20' : 'SEI20' */
    'SEI20' : 'SEI20',
    /** 'SEI21' : 'SEI21' */
    'SEI21' : 'SEI21',

    // Generic (ErrorCodes.cs)
    /** 'SEG01' : 'SEG01' */
    'SEG01' : 'SEG01',
    /** 'SEG02' : 'SEG02' */
    'SEG02' : 'SEG02',
    /** 'SEG03' : 'SEG03' */
    'SEG03' : 'SEG03',

    // Account (ErrorCodes.Account.cs)
    // No property found named E!
    // CreateAccount (ErrorCodes.Account.cs)
    /** 'SECA02' : 'Bedrijfsnaam is een verplicht veld' */
    'SECA02' : 'Bedrijfsnaam is een verplicht veld',
    /** 'SECA03' : 'Telefoonnummer is een verplicht veld' */
    'SECA03' : 'Telefoonnummer is een verplicht veld',
    /** 'SECA04' : 'Straat is een verplicht veld' */
    'SECA04' : 'Straat is een verplicht veld',
    /** 'SECA05' : 'Huisnummer is een verplicht veld' */
    'SECA05' : 'Huisnummer is een verplicht veld',
    /** 'SECA06' : 'Postcode is een verplicht veld' */
    'SECA06' : 'Postcode is een verplicht veld',
    /** 'SECA07' : 'Plaats is een verplicht veld' */
    'SECA07' : 'Plaats is een verplicht veld',
    /** 'SECA08' : 'Aanhef is een verplicht veld' */
    'SECA08' : 'Aanhef is een verplicht veld',
    /** 'SECA09' : 'Aanhef bevat een ongeldige waarde' */
    'SECA09' : 'Aanhef bevat een ongeldige waarde',
    /** 'SECA10' : 'Voornaam is een verplicht veld' */
    'SECA10' : 'Voornaam is een verplicht veld',
    /** 'SECA11' : 'Achternaam is een verplicht veld' */
    'SECA11' : 'Achternaam is een verplicht veld',
    /** 'SECA12' : 'E-mailadres is een verplicht veld' */
    'SECA12' : 'E-mailadres is een verplicht veld',
    /** 'SECA13' : 'E-mailadres en Herhaal e-mailadres komen niet overeen' */
    'SECA13' : 'E-mailadres en Herhaal e-mailadres komen niet overeen',
    /** 'SECA14' : 'Wachtwoord is een verplicht veld' */
    'SECA14' : 'Wachtwoord is een verplicht veld',
    /** 'SECA15' : 'Wachtwoord en Herhaal wachtwoord komen niet overeen' */
    'SECA15' : 'Wachtwoord en Herhaal wachtwoord komen niet overeen',
    /** 'SECA16' : 'Niet akkoord gegaan met wijze van facturatie' */
    'SECA16' : 'Niet akkoord gegaan met wijze van facturatie',
    /** 'SECA17' : 'Niet akkoord gegaan met verwerking van persoonsgegevens' */
    'SECA17' : 'Niet akkoord gegaan met verwerking van persoonsgegevens',
    /** 'SECA18' : 'Niet akkoord gegaan met algemene voorwaarden' */
    'SECA18' : 'Niet akkoord gegaan met algemene voorwaarden',
    /** 'SECA19' : 'Het veld e-mailadres bevat een geen geldig emailadres' */
    'SECA19' : 'Het veld e-mailadres bevat een geen geldig emailadres',
    /** 'SECA20' : 'E-mailadres mag maar {1} karakters lang zijn' */
    'SECA20' : 'E-mailadres mag maar {1} karakters lang zijn',
    /** 'SECA21' : 'Aanhef mag maar {1} karakters lang zijn' */
    'SECA21' : 'Aanhef mag maar {1} karakters lang zijn',
    /** 'SECA22' : 'Voornaam mag maar {1} karakters lang zijn' */
    'SECA22' : 'Voornaam mag maar {1} karakters lang zijn',
    /** 'SECA23' : 'Tussenvoegsel mag maar {1} karakters lang zijn' */
    'SECA23' : 'Tussenvoegsel mag maar {1} karakters lang zijn',
    /** 'SECA24' : 'Achternaam mag maar {1} karakters lang zijn' */
    'SECA24' : 'Achternaam mag maar {1} karakters lang zijn',
    /** 'SECA25' : 'Straat mag maar {1} karakters lang zijn' */
    'SECA25' : 'Straat mag maar {1} karakters lang zijn',
    /** 'SECA26' : 'Huisnummer mag maar {1} karakters lang zijn' */
    'SECA26' : 'Huisnummer mag maar {1} karakters lang zijn',
    /** 'SECA27' : 'Toevoeging mag maar {1} karakters lang zijn' */
    'SECA27' : 'Toevoeging mag maar {1} karakters lang zijn',
    /** 'SECA28' : 'Postcode mag maar {1} karakters lang zijn' */
    'SECA28' : 'Postcode mag maar {1} karakters lang zijn',
    /** 'SECA29' : 'Plaats mag maar {1} karakters lang zijn' */
    'SECA29' : 'Plaats mag maar {1} karakters lang zijn',
    /** 'SECA30' : 'KvK mag maar {1} karakters lang zijn' */
    'SECA30' : 'KvK mag maar {1} karakters lang zijn',
    /** 'SECA31' : 'Bedrijfsnaam mag maar {1} karakters lang zijn' */
    'SECA31' : 'Bedrijfsnaam mag maar {1} karakters lang zijn',
    /** 'SECA32' : 'Telefoonnummer mag maar {1} karakters lang zijn' */
    'SECA32' : 'Telefoonnummer mag maar {1} karakters lang zijn',
    /** 'SECA33' : 'Wachtwoord is niet sterk genoeg' */
    'SECA33' : 'Wachtwoord is niet sterk genoeg',
    /** 'SECA34' : 'Postcode is onjuist' */
    'SECA34' : 'Postcode is onjuist',
    /** 'SECA35' : 'Het veld telefoonnummer bevat geen geldig telefoonnummer' */
    'SECA35' : 'Het veld telefoonnummer bevat geen geldig telefoonnummer',
    /** 'SECA36' : 'Sector is een verplicht veld' */
    'SECA36' : 'Sector is een verplicht veld',
    /** 'SECA37' : 'Onbekende sector ingevuld' */
    'SECA37' : 'Onbekende sector ingevuld',
    /** 'SECA38' : 'KvK moet minimaal {1} karakters lang zijn' */
    'SECA38' : 'KvK moet minimaal {1} karakters lang zijn',
    /** 'SECA39' : 'Uw voornaam mag niet voorkomen in het wachtwoord' */
    'SECA39' : 'Uw voornaam mag niet voorkomen in het wachtwoord',
    /** 'SECA40' : 'Uw achternaam mag niet voorkomen in het wachtwoord' */
    'SECA40' : 'Uw achternaam mag niet voorkomen in het wachtwoord',
    /** 'SECA41' : 'Uw e-mailadres mag niet voorkomen in het wachtwoord' */
    'SECA41' : 'Uw e-mailadres mag niet voorkomen in het wachtwoord',
    /** 'SECA42' : 'Eigenaar aanhef is een verplicht veld' */
    'SECA42' : 'Eigenaar aanhef is een verplicht veld',
    /** 'SECA43' : 'Eigenaar aanhef bevat een ongeldige waarde' */
    'SECA43' : 'Eigenaar aanhef bevat een ongeldige waarde',
    /** 'SECA44' : 'Eigenaar aanhef mag maar {1} karakters lang zijn' */
    'SECA44' : 'Eigenaar aanhef mag maar {1} karakters lang zijn',
    /** 'SECA45' : 'Eigenaar voornaam is een verplicht veld' */
    'SECA45' : 'Eigenaar voornaam is een verplicht veld',
    /** 'SECA46' : 'Eigenaar voornaam mag maar {1} karakters lang zijn' */
    'SECA46' : 'Eigenaar voornaam mag maar {1} karakters lang zijn',
    /** 'SECA47' : 'Eigenaar tussenvoegsel mag maar {1} karakters lang zijn' */
    'SECA47' : 'Eigenaar tussenvoegsel mag maar {1} karakters lang zijn',
    /** 'SECA48' : 'Eigenaar achternaam is een verplicht veld' */
    'SECA48' : 'Eigenaar achternaam is een verplicht veld',
    /** 'SECA49' : 'Eigenaar achternaam mag maar {1} karakters lang zijn' */
    'SECA49' : 'Eigenaar achternaam mag maar {1} karakters lang zijn',
    /** 'SECA50' : 'Eigenaar is een verplicht veld' */
    'SECA50' : 'Eigenaar is een verplicht veld',
    // BevestigenAccount (ErrorCodes.Account.cs)
    /** 'SEBA01' : 'Uw account kon niet worden gevonden' */
    'SEBA01' : 'Uw account kon niet worden gevonden',
    /** 'SEBA02' : 'Het eerste deel van de bevestigingscode is niet ingevuld' */
    'SEBA02' : 'Het eerste deel van de bevestigingscode is niet ingevuld',
    /** 'SEBA03' : 'Het tweede deel van de bevestigingscode is niet ingevuld' */
    'SEBA03' : 'Het tweede deel van de bevestigingscode is niet ingevuld',
    // ForgotPassword (ErrorCodes.Account.cs)
    /** 'SEFP01' : 'E-mailadres is een verplicht veld' */
    'SEFP01' : 'E-mailadres is een verplicht veld',
    /** 'SEFP02' : 'Het veld e-mailadres bevat een geen geldig e-mailadres' */
    'SEFP02' : 'Het veld e-mailadres bevat een geen geldig e-mailadres',
    /** 'SEFP03' : 'E-mailadres mag maar {1} karakters lang zijn' */
    'SEFP03' : 'E-mailadres mag maar {1} karakters lang zijn',
    // ResetPassword (ErrorCodes.Account.cs)
    /** 'SERP01' : 'Wachtwoord is een verplicht veld' */
    'SERP01' : 'Wachtwoord is een verplicht veld',
    /** 'SERP02' : 'Wachtwoord en Herhaal wachtwoord komen niet overeen' */
    'SERP02' : 'Wachtwoord en Herhaal wachtwoord komen niet overeen',
    /** 'SERP03' : 'Wachtwoord is niet sterk genoeg' */
    'SERP03' : 'Wachtwoord is niet sterk genoeg',

    // Verwerkingsregister (ErrorCodes.Verwerkingsregister.cs)
    /** 'SEV01' : 'SEV01' */
    'SEV01' : 'SEV01',
    /** 'SEV02' : 'SEV02' */
    'SEV02' : 'SEV02',
    /** 'SEV03' : 'SEV03' */
    'SEV03' : 'SEV03',
    /** 'SEV04' : 'SEV04' */
    'SEV04' : 'SEV04',
    /** 'SEV05' : 'SEV05' */
    'SEV05' : 'SEV05',
    /** 'SEV06' : 'SEV06' */
    'SEV06' : 'SEV06',
    /** 'SEV07' : 'SEV07' */
    'SEV07' : 'SEV07',
    /** 'SEV08' : 'SEV08' */
    'SEV08' : 'SEV08',
    /** 'SEV09' : 'SEV09' */
    'SEV09' : 'SEV09',
    /** 'SEV10' : 'SEV10' */
    'SEV10' : 'SEV10',
    /** 'SEV11' : 'SEV11' */
    'SEV11' : 'SEV11',
    /** 'SEV12' : 'SEV12' */
    'SEV12' : 'SEV12',
    /** 'SEV13' : 'SEV13' */
    'SEV13' : 'SEV13',
    /** 'SEV14' : 'SEV14' */
    'SEV14' : 'SEV14',
    /** 'SEV15' : 'SEV15' */
    'SEV15' : 'SEV15',

    // Verwerkersregister (ErrorCodes.Verwerkersregister.cs)
    /** 'SEVI01' : 'Firma naam is verplicht' */
    'SEVI01' : 'Firma naam is verplicht',
    /** 'SEVI02' : 'Firma naam mag maar {1} karakters lang zijn' */
    'SEVI02' : 'Firma naam mag maar {1} karakters lang zijn',
    /** 'SEVI03' : 'Contactpersoon is verplicht' */
    'SEVI03' : 'Contactpersoon is verplicht',
    /** 'SEVI04' : 'Contactpersoon mag maar {1} karakters lang zijn' */
    'SEVI04' : 'Contactpersoon mag maar {1} karakters lang zijn',
    /** 'SEVI05' : 'Contact gegevens is verplicht' */
    'SEVI05' : 'Contact gegevens is verplicht',
    /** 'SEVI06' : 'Contact gegevens mag maar {1} karakters lang zijn' */
    'SEVI06' : 'Contact gegevens mag maar {1} karakters lang zijn',
    /** 'SEVI07' : 'Doel is verplicht' */
    'SEVI07' : 'Doel is verplicht',
    /** 'SEVI08' : 'Persoonsgegevens is verplicht' */
    'SEVI08' : 'Persoonsgegevens is verplicht',
    /** 'SEVI09' : 'Datum overeenkomst is verplicht' */
    'SEVI09' : 'Datum overeenkomst is verplicht',
    /** 'SEVI10' : 'Datum overeenkomst mag niet in de toekomst liggen' */
    'SEVI10' : 'Datum overeenkomst mag niet in de toekomst liggen',
    /** 'SEVI11' : 'Link naar de overeenkomst mag maar {1} karakters lang zijn' */
    'SEVI11' : 'Link naar de overeenkomst mag maar {1} karakters lang zijn',
    /** 'SEVI12' : 'Link naar de overeenkomst is geen geldige link' */
    'SEVI12' : 'Link naar de overeenkomst is geen geldige link',
    /** 'SEVI13' : 'Omschrijving van de overeenkomst mag maar {1} karakters lang zijn' */
    'SEVI13' : 'Omschrijving van de overeenkomst mag maar {1} karakters lang zijn',
    /** 'SEVI14' : 'Het veld e-mailadres bevat een geen geldig e-mailadres' */
    'SEVI14' : 'Het veld e-mailadres bevat een geen geldig e-mailadres',

    // Persoon (ErrorCodes.Persoon.cs)
    /** 'SEP01' : 'SEP01' */
    'SEP01' : 'SEP01',
    /** 'SEP02' : 'SEP02' */
    'SEP02' : 'SEP02',
    /** 'SEP03' : 'SEP03' */
    'SEP03' : 'SEP03',
    /** 'SEP04' : 'SEP04' */
    'SEP04' : 'SEP04',
    /** 'SEP05' : 'SEP05' */
    'SEP05' : 'SEP05',
    /** 'SEP06' : 'SEP06' */
    'SEP06' : 'SEP06',
    /** 'SEP07' : 'SEP07' */
    'SEP07' : 'SEP07',
    /** 'SEP08' : 'SEP08' */
    'SEP08' : 'SEP08',
    /** 'SEP09' : 'SEP09' */
    'SEP09' : 'SEP09',
    /** 'SEP10' : 'SEP10' */
    'SEP10' : 'SEP10',
    /** 'SEP11' : 'SEP11' */
    'SEP11' : 'SEP11',
    /** 'SEP12' : 'SEP12' */
    'SEP12' : 'SEP12',
    /** 'SEP13' : 'SEP13' */
    'SEP13' : 'SEP13',
    /** 'SEP14' : 'SEP14' */
    'SEP14' : 'SEP14',
    /** 'SEP15' : 'SEP15' */
    'SEP15' : 'SEP15',

    // RegisterInformatie (ErrorCodes.RegisterInformatie.cs)
    /** 'SERI01' : 'SERI01' */
    'SERI01' : 'SERI01',
    /** 'SERI02' : 'SERI02' */
    'SERI02' : 'SERI02',
    /** 'SERI03' : 'SERI03' */
    'SERI03' : 'SERI03',
    /** 'SERI04' : 'SERI04' */
    'SERI04' : 'SERI04',
    /** 'SERI05' : 'SERI05' */
    'SERI05' : 'SERI05',
    /** 'SERI06' : 'SERI06' */
    'SERI06' : 'SERI06',

};
export default validationErrorsBase;
