import * as ActionTypes from './actionTypes';
import * as Constants from './constants';

/*
 * Reducer for the select account component.
*/
const reducer = (state = {}, action) => {

    switch(action.type){
    case ActionTypes.LOAD: {
        const newState = Object.assign({}, state, {
            [ Constants.StateFields.DATA ]: undefined,
            [ Constants.StateFields.LOADING ] : true
        });
        return newState;
    }
    case ActionTypes.LOADED: {
        const newState = Object.assign({}, state, {
            [ Constants.StateFields.DATA ]: action.data,
            [ Constants.StateFields.LOADING ] : false
        });
        return newState;
    }
    default:
        return state;
    }
};

reducer.STATE_KEY = Constants.STATE_KEY;

export default reducer;