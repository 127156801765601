import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Panel } from 'react-bootstrap';
import FaIcon from 'react-fontawesome';
import Formsy from 'formsy-react';

import {
    Button,
    Container,
    FieldSetup,
    ReadOnly,
    Loading,
    MijnAccountWorkingModel,
    MijnAccountDetailWorkingModel,
    CheckBox
} from './_references';

class MijnAccount extends React.Component {

    componentDidMount() {
        this.props.load();
    }

    render() {
        const { bind, onChange, update, isLoading } = this.props;

        return (

            <Container>
                {isLoading ? <Loading/> :
                    <Formsy className="form-horizontal" data-toggle="validator" onValidSubmit={(model) => {
                        update(model);
                    }}>
                        <Panel>
                            <Panel.Body>
                                <div className="panel-inline-title">ACCOUNT INFORMATIE</div>

                                <ReadOnly
                                    {...bind(MijnAccountWorkingModel.Naam)}
                                    {...new FieldSetup()
                                        .for(MijnAccountWorkingModel.Naam)
                                        .withFriendlyName('Naam')
                                        .setLabel()
                                        .setPlaceholder()
                                    }
                                />

                                <CheckBox
                                    {...bind(MijnAccountDetailWorkingModel.MagConnectieMaken)}
                                    {...new FieldSetup()
                                        .for(MijnAccountDetailWorkingModel.MagConnectieMaken)
                                        .setLabel('Ik geef een W.T. Privacy Officer toestemming om aan mijn account te koppelen')
                                        .onChange(onChange)
                                    }
                                />

                            </Panel.Body>
                        </Panel>

                        <Row>
                            <Col xsHidden sm={12} className="text-right">
                                <span className="v-spacer"/>
                                <Button bsStyle="primary" type="submit"><FaIcon name="floppy-o" /> Opslaan</Button>
                            </Col>

                            <Col smHidden mdHidden lgHidden xs={12} >
                                <Button bsStyle="primary" block type="submit"><FaIcon name="floppy-o" /> Opslaan</Button>
                                <span className="h-spacer"/>
                            </Col>
                        </Row>
                    </Formsy>
                }
            </Container>
        );
    }
}

MijnAccount.propTypes = {
    load: PropTypes.func.isRequired,
    bind: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    isLoading: PropTypes.bool
};

export default MijnAccount;