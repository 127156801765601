import { all, call, put, takeLatest, delay } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { actions as ToastrActions } from 'react-redux-toastr';

import {
    Api,
    Routes
} from './_references';

import * as ActionTypes from './actionTypes';
import * as Actions from './actions';

export default function* () {
    yield all([
        takeLatest(ActionTypes.CREATE_ACCOUNT, createAccount),
        takeLatest(ActionTypes.VALIDATION_ERROR, throwValidationError)
    ]);
}

function* createAccount(action) {
    yield delay(250);

    try{
        const result = yield call (Api.createAcccount, action.model);

        if (result.success) {
            yield put(Actions.clear());

            action.form.reset();

            yield put (ToastrActions.add({
                type: 'success',
                title: 'Account aanvragen',
                attention: true, // This will add a shadow like the confirm method.
                message: 'Uw account is aangevraagd.',
                options: {
                    showCloseButton: true
                }
            }));
            yield put (push(Routes.Account.Aangevraagd.path));
        }
        else {

            yield put (ToastrActions.add({
                type: 'error',
                title: 'Account aangevraagd',
                attention: true, // This will add a shadow like the confirm method.
                message: 'Uw account kon niet worden aangevraagd. Neem contact op met W.T. Privacy.',
                options: {
                    showCloseButton: true
                }
            }));

        }
    }
    catch(e){
        if ((e.response || {}).status !== 422) {
            yield put (ToastrActions.add({
                type: 'error',
                title: 'Account aanvragen',
                attention: true, // This will add a shadow like the confirm method.
                message: 'Uw account kon niet worden aangevraagd. Neem contact op met W.T. Privacy.',
                options: {
                    showCloseButton: true
                }
            }));
        }
    }

}

function* throwValidationError() {
    yield put (ToastrActions.add({
        type: 'warning',
        title: 'Account aanvragen',
        message: 'Nog niet alle velden zijn correct ingevuld.',
        options: {
            showCloseButton: true,
            timeOut: 6000,
            attention: false, // This will add a shadow like the confirm method.
        }
    }));
}