import React from 'react';

import { Row, Col } from 'react-bootstrap';

const NPia = () => (
    <Row>
        <Col xs={12}>
            U hoeft geen Privacy Impact Assessment uit te voeren. Echter kan het wellicht verstandig zijn om dit toch te doen om er zeker van te zijn dat u geen risico's loopt
            ten aanzien van het verlies van persoonsgegevens.
        </Col>
    </Row>
);

export default NPia;