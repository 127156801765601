import React from 'react';
import PropTypes from 'prop-types';

import {
    FieldSetup,
    Display,
    VerwerkingWorkingModel,
    Display as Text, Display as RadioButton, Display as YesNoRadioButton
} from './_references';

const VerwerkingsregisterInzage = ({ bind }) => (
    <div>
        <Text
            {...bind(VerwerkingWorkingModel.Doel)}
            {...new FieldSetup()
                .for(VerwerkingWorkingModel.Doel)
                .withFriendlyName('Verwerkingsdoel')
                .setLabel('Verwerkingsdoel')
                .setPlaceholder('Doel van de gegevensverwerking')
            }
        />

        <Text
            {...bind(VerwerkingWorkingModel.Categorieen)}
            {...new FieldSetup()
                .for(VerwerkingWorkingModel.Categorieen)
                .withFriendlyName('Categorieën')
                .setLabel()
                .setPlaceholder('Categorieën, bijvoorbeeld uitkeringsgerechtigden, klanten, patiënten etc.')
            }
        />

        <RadioButton
            noOffset
            wrapperClassName="radio-compact"
            {...bind(VerwerkingWorkingModel.CategorieenPersoonsgegevens)}
            {...new FieldSetup()
                .for(VerwerkingWorkingModel.CategorieenPersoonsgegevens)
                .withFriendlyName('Persoonsgegevens')
                .setLabel()
                .setPlaceholder('Categoriën Persoonsgegevens, Gewone pof  ')
            }
            options={[ {
                key: 'G', value: 'Gewone persoonsgegevens'
            },
            {
                key: 'B', value: 'Bijzondere persoonsgegevens'
            },
            {
                key: 'A', value: 'Zowel gewone als bijzondere persoonsgegevens'
            },
            ]}
            optionKey="key"
            optionValue="value"
        />

        {/* Tonen bij B & A */}
        { bind(VerwerkingWorkingModel.CategorieenPersoonsgegevens).value === 'B' || bind(VerwerkingWorkingModel.CategorieenPersoonsgegevens).value === 'A' ?
            <YesNoRadioButton
                wrapperClassName="radio-compact"
                noOffset
                {...bind(VerwerkingWorkingModel.WettelijkeGrondslag)}
                {...new FieldSetup()
                    .for(VerwerkingWorkingModel.WettelijkeGrondslag)
                    .withFriendlyName('Wettelijke Grondslag')
                    .setLabel('Wettelijke Grondslag')
                }
            />
            : null }

        { bind(VerwerkingWorkingModel.WettelijkeGrondslag).value === 'true' ?
            <Text
                wrapperClassName="radio-compact"
                noOffset
                {...bind(VerwerkingWorkingModel.WettelijkeGrondslagToelichting)}
                {...new FieldSetup()
                    .for(VerwerkingWorkingModel.WettelijkeGrondslagToelichting)
                    .withFriendlyName('Toelichting Wettelijke Grondslag')
                    .setLabel('Toelichting Wettelijke Grondslag')
                    .setPlaceholder('Welke grondslag geldt er?')
                }
            />
            : null }

        <YesNoRadioButton
            wrapperClassName="radio-compact"
            noOffset
            {...bind(VerwerkingWorkingModel.DelenBuitenEU)}
            {...new FieldSetup()
                .for(VerwerkingWorkingModel.DelenBuitenEU)
                .setLabel('Deelt u buiten de EU')
                .withFriendlyName('Deelt u gegevens met een land of internationale organisatie buiten de EU?')
            }
        />

        <Text
            {...bind(VerwerkingWorkingModel.Bewaartermijn)}
            {...new FieldSetup()
                .for(VerwerkingWorkingModel.Bewaartermijn)
                .withFriendlyName('Bewaartermijn')
                .setLabel()
                .setPlaceholder()
            }
        />

        <Text
            {...bind(VerwerkingWorkingModel.Opdrachtgever)}
            {...new FieldSetup()
                .for(VerwerkingWorkingModel.Opdrachtgever)
                .withFriendlyName('Opdrachtgever')
                .setLabel()
                .setPlaceholder('Wie is de opdrachtgever? U, een specifieke klant of al uw klanten?')
            }
        />

        <YesNoRadioButton
            wrapperClassName="radio-compact"
            noOffset
            {...bind(VerwerkingWorkingModel.AfwijkendeVerantwoordelijke)}
            {...new FieldSetup()
                .for(VerwerkingWorkingModel.AfwijkendeVerantwoordelijke)
                .setLabel('Is er een afwijkende verantwoordelijke')
                .withFriendlyName('Afwijkende verantwoordelijke')
            }
        />

        {bind(VerwerkingWorkingModel.AfwijkendeVerantwoordelijke).value === 'true' ?

            <Display
                {...bind(VerwerkingWorkingModel.AfwijkendeVerantwoordelijkePersoon)}
                {...new FieldSetup()
                    .for(VerwerkingWorkingModel.AfwijkendeVerantwoordelijkePersoon)
                    .withFriendlyName('Naam')
                    .setLabel()
                    .setPlaceholder('Naam')
                }
            />
            : null }
    </div>
);

VerwerkingsregisterInzage.propTypes = {
    bind: PropTypes.func.isRequired
};

export default VerwerkingsregisterInzage;