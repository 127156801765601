import Log from './log';
import { FIELD_CHANGED } from '../configuration/actionTypes';
import { toFieldsString } from './fieldNameHelper';

const logger = Log('reduxBinder');

const normalizeFieldName = (field) => {
    if (field){
        if (Object.prototype.toString.call( field) === '[object Array]') {
            return field.join('.');
        }
    }
    return field;
};

const valueOf = (state, field, index) => {
    if (state === undefined){
        logger.warn('valueOf: state was undefined.');
    }

    const parts = normalizeFieldName(field).split('.');
    let partIndex = 0;
    let fieldValue = state || {};
    for (partIndex; partIndex < parts.length; partIndex += 1) {
        fieldValue = fieldValue || {};
        if (fieldValue.hasOwnProperty(parts[ partIndex ])) {
            fieldValue = fieldValue[ parts[ partIndex ] ];
        }
        else {
            logger.warn(`Field ${field} not found because part '${parts[ partIndex ]}' of '${field}' could not be found.`, state);
            fieldValue = undefined;
            break;
        }
    }

    if (Array.isArray(fieldValue)) {
        if (index != null) {
            fieldValue = fieldValue[ index ];
        }
        else {
            logger.warn(`Field value of '${field}' is an array, did you forget to add an index to the bind function?`);
            return null;
        }
    }

    // avoid passing through booleans
    if (typeof(fieldValue) === 'boolean'){
        fieldValue = fieldValue ? 'true' : 'false';
    }

    return fieldValue || '';
};

const binderInternal = (state, field, arrayIndex, additionalProps) => {
    logger.group();
    logger.info('loading data using valueOf', state, field, arrayIndex);
    let modelValue = valueOf(state, field, arrayIndex);

    const fieldName = normalizeFieldName(field);

    logger.info('binder: bind', fieldName, 'with value' , modelValue);

    const result = {
        name: fieldName,
        field: fieldName,
        value: modelValue,
        arrayIndex,
        ...additionalProps,
    };

    logger.info('binder: binding the following props', result);
    logger.groupEnd();
    return result;
};

// slightly faster binder
export const binder2 = (modelKeys) => {
    return (model, field, index, additionalProps) => {
        const modelToUse = (modelKeys == null) ? model : valueOf(model, modelKeys);
        return binderInternal(modelToUse, field, index, additionalProps);
    };
};

/**
 *
 * @param {object} model - (a part of) the state
 * @param {string|Array} modelKeys - (optional) keys to follow (e.g. 'wizard.data' or ['wizard', 'data'])
 */
const binder = (model, modelKeys) => {
    const modelToUse = (modelKeys == null) ? model : valueOf(model, modelKeys);
    return (field, index, additionalProps) => {
        return binderInternal(modelToUse, field, index, additionalProps);
    };
};

const onChange = (stateKey, dispatch, options = {}) => (target, props) => {
    if (stateKey == null || typeof stateKey !== 'string'){
        logger.warn('the first parameter of onChange needs to be a string that points to the root of the state (STATE_KEY).');
    }
    else if (dispatch == null || typeof dispatch !== 'function') {
        logger.warn('the first parameter of onChange needs to be a string that points to the root of the state (STATE_KEY).');
    }

    if (props == null) {
        logger.warn('props is empty, has the component been added correctly to a formsy form?');
    }

    const actionToDispatch = Object.assign({
        type: FIELD_CHANGED,
        field: toFieldsString([ stateKey, props.field ]),
        value: target.type === 'checkbox' ? target.checked : target.value,
        fieldType: target.type
    }, options);

    dispatch(actionToDispatch);
};

export default binder;
export { onChange, valueOf, normalizeFieldName };