import * as ActionTypes from './actionTypes';

export const addCounterAction = () => ({
    type: ActionTypes.ADD_LOADING_COUNTER,
});

export const removeCounterAction = () => ({
    type: ActionTypes.REMOVE_LOADING_COUNTER,
});

export const resetAction = () => ({
    type: ActionTypes.RESET,
});