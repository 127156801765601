import { NAME } from './constants';

export const FIELD_CHANGED = `${NAME}/FIELD_CHANGED`;

export const CLEAR = `${NAME}/CLEAR`;

export const ADD_INCIDENT = `${NAME}/ADD_INCIDENT`;
export const UPDATE_INCIDENT = `${NAME}/UPDATE_INCIDENT`;
export const GET_INCIDENT = `${NAME}/GET_INCIDENT`;
export const LOAD_INCIDENTS = `${NAME}/LOAD_INCIDENTS`;
export const LOADED_INCIDENT = `${NAME}/LOADED_INCIDENT`;
export const LOADED_INCIDENTS = `${NAME}/LOADED_INCIDENTS`;
export const INTERMEDIATE_ANALYSIS_COMPLETE = `${NAME}/INTERMEDIATE_ANALYSIS_COMPLETE`;
export const ADD_TO_RAPPORT = `${NAME}/ADD_TO_RAPPORT`;

export const EDIT_RAPPORT_ROW = `${NAME}/EDIT_RAPPORT_ROW`;
export const DELETE_RAPPORT_ROW = `${NAME}/DELETE_RAPPORT_ROW`;
export const CONFIRM_DELETE_RAPPORT_ROW = `${NAME}/CONFIRM_DELETE_RAPPORT_ROW`;
export const CONFIRMED_DELETE_RAPPORT_ROW = `${NAME}/CONFIRMED_DELETE_RAPPORT_ROW`;
export const CANCEL_EDIT_RAPPORT_ROW = `${NAME}/CANCEL_EDIT_RAPPORT_ROW`;
export const CONFIRM_EDIT_RAPPORT_ROW = `${NAME}/CONFIRM_EDIT_RAPPORT_ROW`;

export const CONFIRM_CLOSE = `${NAME}/CONFIRM_CLOSE`;

export const ADD_TO_DOCUMENT = `${NAME}/ADD_TO_DOCUMENT`;
export const EDIT_DOCUMENT_ROW = `${NAME}/EDIT_DOCUMENT_ROW`;
export const DELETE_DOCUMENT_ROW = `${NAME}/DELETE_DOCUMENT_ROW`;
export const CONFIRM_DELETE_DOCUMENT_ROW = `${NAME}/CONFIRM_DELETE_DOCUMENT_ROW`;
export const CONFIRMED_DELETE_DOCUMENT_ROW = `${NAME}/CONFIRMED_DELETE_DOCUMENT_ROW`;
export const CANCEL_EDIT_DOCUMENT_ROW = `${NAME}/CANCEL_EDIT_DOCUMENT_ROW`;
export const CONFIRM_EDIT_DOCUMENT_ROW = `${NAME}/CONFIRM_EDIT_DOCUMENT_ROW`;
