import * as ActionTypes from './actionTypes';
import * as Constants from './constants';

/*
 * Reducer for the authentication state.
*/
const reducer = (state = {}, action) => {
    switch(action.type){
    case ActionTypes.UPDATED_ACCOUNT_INFO: {
        const newState = Object.assign({}, state, {
            [ Constants.Fields.ACCOUNT_INFO ]: action.data
        });

        return newState;
    }
    case ActionTypes.LOGGED_IN: {
        const newState = Object.assign({}, state, {
            LoggedIn: action.value,
            [ Constants.Fields.ACCOUNT_INFO ]: action.value === true ? action.accountInfo : undefined
        });

        return newState;
    }
    default:
        return state;
    }
};

reducer.STATE_KEY = Constants.STATE_KEY;

export default reducer;