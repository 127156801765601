import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Panel, Col, Row } from 'react-bootstrap';
import Container from '../../universe/molecules/container/pageContainers/pageContainer';

import Credentials from './credentials';
import Verification from './verification';

import { mapDispatchToProps, mapStateToProps } from './index.map';

class Login extends React.Component{

    componentWillUnmount(){
        this.props.clear();
    }

    render() {
        const { requiresVerification, ...rest } = this.props;

        return(
            <Container>
                <Row>
                    <Col mdOffset={2} lgOffset={3} md={8} lg={6}>
                        <Panel>
                            <Panel.Body>
                                { requiresVerification === true ? <Verification {...rest}/> : <Credentials {...rest}/> }
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </Container>
        );
    }
}
Login.propTypes = {
    requiresVerification: PropTypes.bool.isRequired,
    clear: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);