import React from 'react';
import PropTypes from 'prop-types';

import {
    Display as Radio,
    Display as Text,
    PersoonDetailWorkingModel,
    FieldSetup
} from './_references';

const PersoonInzage = ({ bind }) => (
    <div>
        <Radio
            {...bind(PersoonDetailWorkingModel.Aanhef)}
            {...new FieldSetup()
                .for(PersoonDetailWorkingModel.Aanhef)
                .withFriendlyName('Aanhef')
                .setLabel()
                .setPlaceholder()
            }

            options={[ {
                key: 'm', value: 'Dhr'
            },{
                key: 'v', value: 'Mvr'
            } ]}
            optionKey="key"
            optionValue="value"
        />

        <Text
            {...bind(PersoonDetailWorkingModel.Voornaam)}
            {...new FieldSetup()
                .for(PersoonDetailWorkingModel.Voornaam)
                .withFriendlyName('Voornaam')
                .setLabel()
                .setPlaceholder()
            }
        />

        <Text
            {...bind(PersoonDetailWorkingModel.Tussenvoegsel)}
            {...new FieldSetup()
                .for(PersoonDetailWorkingModel.Tussenvoegsel)
                .withFriendlyName('Tussenvoegsels')
                .setLabel()
                .setPlaceholder('Tussenvoegsels (optioneel)')
            }
        />

        <Text
            {...bind(PersoonDetailWorkingModel.Achternaam)}
            {...new FieldSetup()
                .for(PersoonDetailWorkingModel.Achternaam)
                .withFriendlyName('Achternaam')
                .setLabel()
                .setPlaceholder()
            }
        />

        <Text
            {...bind(PersoonDetailWorkingModel.Telefoonnummer)}
            {...new FieldSetup()
                .for(PersoonDetailWorkingModel.Telefoonnummer)
                .withFriendlyName('Telefoonnummer')
                .setLabel('Telefoonnummer')
                .setPlaceholder()
            }
        />

        <Text
            {...bind(PersoonDetailWorkingModel.Email)}
            {...new FieldSetup()
                .for(PersoonDetailWorkingModel.Email)
                .withFriendlyName('E-mailadres')
                .setLabel('E-mailadres')
                .setPlaceholder()
            }
        />
    </div>
);

PersoonInzage.propTypes = {
    bind: PropTypes.func.isRequired
};

export default PersoonInzage;