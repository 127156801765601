import { combineReducers } from 'redux';
import { FIELD_CHANGED } from './actionTypes';
import setStateValue from '../util/setStateValue';

import {
    AuthenticationActionTypes,
} from './_references.js';

import * as Reducers from './configureRootReducer.reducers';

const configureRootReducer = () => {

    var reducers = combineReducers(
        Object.assign(
            {},
            // loop over all reducers that we import and export for each reducer an object consisting of a STATE_KEY and the actual reducer.
            ...Object.keys(Reducers)
                .map((reducerKey) => {
                    if (!Reducers[ reducerKey ].STATE_KEY) {
                        throw new Error ( `Reducer ${reducerKey} does not have a STATE_KEY property. Please add it before exporting the reducer function.` );
                    }

                    return {
                        [ Reducers[ reducerKey ].STATE_KEY ] : Reducers[ reducerKey ]
                    };
                })
        ));

    return (state, action) => {
        switch(action.type) {
        case FIELD_CHANGED: {
            return reducers(setStateValue(action.field, action.value, state), action);
        }
        case AuthenticationActionTypes.LOGGED_OUT: {
            return {
                // Keep the routing to avoid strange behavior when logging out
                // When clearing the state completely, the router will return to the initial
                // entry url. Thus /login/<guid> will be called again when logging out
                [ Reducers.routerReducer.STATE_KEY ]: state[ Reducers.routerReducer.STATE_KEY ],
                [ Reducers.toastrReducer.STATE_KEY ]: state[ Reducers.toastrReducer.STATE_KEY ],
            };
            // Keep the configuration because it contains the API uri's
            //                [ Reducers.configurationReducer.STATE_KEY ]: state[ Reducers.configurationReducer.STATE_KEY ],
        }
        default: {
            return reducers(state, action);
        }
        }
    };
};

export default configureRootReducer;