import { NAME } from './constants';

export const SECURE_ACCOUNT = `${NAME}/SECURE_ACCOUNT`;

export const SECURE_ACCOUNT_LOADED = `${NAME}/SECURE_ACCOUNT_LOADED`;

export const ADD_SECURITY = `${NAME}/ADD_SECURITY`;

export const FIELD_CHANGED = `${NAME}/FIELD_CHANGED`;

export const CLEAR = `${NAME}/CLEAR`;