import Axios from 'axios';
import uuid from 'uuid';
import jwtDecode from 'jwt-decode';

import { Actions, ActionTypes, logger, axiosLogger, LoadingActions } from './_references';

import * as Headers from './constants.headers';

/**
 * Configures the component that is responsible for communication to the backend.
 *
 * @param [config] {Object} - The configuration
 * @param [store] {Object} - The store
 *
 * see: https://github.com/mzabriskie/axios#global-axios-defaults
 */
const configureApi = (config, store) => {
    logger.info('Configuration received', config);

    Axios.defaults.baseURL = process.env.NODE_ENV !== 'production' ? (config.apiUrl || '').replace(/localhost/i, window.location.hostname) : config.apiUrl;
    Axios.defaults.loginURL = process.env.NODE_ENV !== 'production' ? (config.loginUrl || '').replace(/localhost/i, window.location.hostname) : config.loginUrl;
    Axios.defaults.withCredentials = true;

    logger.info('Axios defaults have been set', Axios.defaults);

    Axios.interceptors.request.use(
        (request) => {

            store.dispatch(LoadingActions.addCounterAction()); //set application state to loading when an api call is dispatched

            // always add a conversation id
            if (!request.headers[ Headers.HEADER_WTP_CONVERSATION ]) {
                request.headers[ Headers.HEADER_WTP_CONVERSATION ] = uuid.v1();
            }

            return request;
        }
    );

    // hooks to handle the response
    Axios.interceptors.response.use(
        /* istanbul ignore next */
        (response) => {

            store.dispatch(LoadingActions.removeCounterAction()); //set application state to not loading when an api call dispatch is resolved

            if (response.headers[ 'jwt-data' ] != null) {
                store.dispatch(Actions.JwtTokenFound(jwtDecode(response.headers[ 'jwt-data' ])));
            }

            try {
                axiosLogger.info(`received from ${response.request.responseURL}`, response);
            }
            catch (err) {
                axiosLogger.error('Could not parse response.data', response, err);
            }

            // set success to true when status is OK
            response.success = (response.status === 200 || response.status === 204);

            return response;
        },
        /* istanbul ignore next */
        (error) => {
            store.dispatch(LoadingActions.removeCounterAction()); //set application state to not loading when an api call dispatch is resolved

            store.dispatch(Actions.ApiCallFailed(error));

            axiosLogger.error('Request failed', error);

            if (error.config.suppressErrors) {
                axiosLogger.log('Rejecting the api call');
                return Promise.reject(error);
            }
            else {
                axiosLogger.log('Returning the promise');
                // Always return a default action for redux
                return {
                    type: ActionTypes.API_CALL_FAILED,
                    response: error.response
                };
            }
        });

    // Axios has been configured by now so dispatch an action to pre load data
    store.dispatch(Actions.PreLoad());
};

export default configureApi;
