import { browserHistory } from 'react-router';
import { routerMiddleware } from 'react-router-redux';
import { createStore, compose, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import {
    setupBuilder
} from './_references';

import getPreservedState from './configureStore.getPreservedState';
import configureRootReducer from './configureRootReducer';
import logConfiguration from './logConfiguration';
import rootSaga from './configureSaga';

const configureStore = (config) => {
    const middleware = [];

    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'production'
        && (logConfiguration[ 'action' ] === undefined || logConfiguration[ 'action' ])) {
        middleware.push(logger);
    }

    const sagaMiddleware = createSagaMiddleware();

    middleware.push(routerMiddleware(browserHistory), sagaMiddleware);

    // next line is to support the redux devtools: https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd?utm_source=chrome-app-launcher-info-dialog
    // when this isn't a development environment we disable the redux devtools
    const composeEnhancers =
        (process.env.NODE_ENV !== 'production' || config.exposeReduxStore ) &&
        typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
            /* istanbul ignore next */
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

    const store = createStore(
        configureRootReducer(),
        getPreservedState(),
        composeEnhancers(
            applyMiddleware(...middleware)
        )
    );

    const sagaContext = {};
    sagaMiddleware.run(rootSaga, sagaContext);

    setupBuilder(store);

    return store;
};

export default configureStore;