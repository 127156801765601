import React from 'react';
import { all, call, put, takeLatest, select, delay } from 'redux-saga/effects';

import{
    Api,
    AuthenticationActions,
    stateHelper,
    Builder
} from './_references';

import * as ActionTypes from './actionTypes';
import * as Actions from './actions';
import * as Constants from './constants';

export default function* () {
    yield all([
        takeLatest(ActionTypes.SECURE_ACCOUNT, secureAccount),
        takeLatest(ActionTypes.ADD_SECURITY, addSecurity)
    ]);
}

function* secureAccount() {

    yield delay(250);

    try{

        const result = yield call (Api.secureAccount);

        yield put(Actions.secureAccountLoaded(result.data));
    }
    catch(e){
        //TODO: Afhandeling
    }
}

function* addSecurity() {

    yield delay(250);

    try{

        const model = yield select((state) => stateHelper(state, [ Constants.STATE_KEY ]));

        const response = yield call (Api.addSecurity, model);

        if (response.success && response.data.success) {

            yield put(Actions.clear());

            yield put(AuthenticationActions.setAccountWarning(false));

            const defaultDialog = Builder.ok({
                title: 'Uw account is beveiligd',
                body: <p>Uw account is succesvol beveiligd.<br/> Daardoor zult u nu uitgelogd worden en opnieuw moeten inloggen met deze beveiliging.</p>,
                onOk: AuthenticationActions.logout()
            }).prepare();

            yield call(defaultDialog);
        }
        else {

            const defaultDialog = Builder.ok({
                title: 'Beveiligingsfout',
                body: <p>De opgegeven code kon niet gekoppeld worden, probeert u het nogmaals.<br/> Als het weer niet lukt, klik op Annuleren en start het beveiligingsproces opnieuw. U zult dan opnieuw de QR code moeten scannen en de vorige registratie in uw app moeten weghalen.</p>,
            }).prepare();

            yield call(defaultDialog);
        }
    }
    catch(e){
        //TODO: Afhandeling
    }
}
