import Axios from 'axios';
import configureApi from './configureApi';
import * as ActionTypes from './actionTypes';
import configureFormsy from './configureFormsy';
import SolutionInfo from '../solutionInfo.json';

import { logger } from './_references';

const setApplicationVersionMeta = (toolVersion) => {
    try {
        let productVersionMetaTag;
        let toolVersionMetaTag;

        const metaTags = document.getElementsByTagName('meta');
        for (let index = 0; index < metaTags.length; index += 1) {
            if (metaTags[ index ].getAttribute('name') === 'product-version') {
                productVersionMetaTag = metaTags[ index ];
            }
            else if (metaTags[ index ].getAttribute('name') === 'tool-version') {
                toolVersionMetaTag = metaTags[ index ];
            }
        }

        if (productVersionMetaTag == null) {
            const head = document.getElementsByTagName('head');

            if (head && head.length > 0) {
                productVersionMetaTag = document.createElement('META');
                productVersionMetaTag.setAttribute('name', 'product-version');
                head[ 0 ].appendChild(productVersionMetaTag);
            }
        }

        if (toolVersionMetaTag == null) {
            const head = document.getElementsByTagName('head');

            if (head && head.length > 0) {
                toolVersionMetaTag = document.createElement('META');
                toolVersionMetaTag.setAttribute('name', 'tool-version');
                head[ 0 ].appendChild(toolVersionMetaTag);
            }
        }

        productVersionMetaTag.setAttribute('content', SolutionInfo.solutionInfo.version);
        toolVersionMetaTag.setAttribute('content', toolVersion);
    }
    catch(error) {
    /* ignore errors: avoid a complete application failure when writing meta information */
    }
};

const loadConfiguration = (callback, configureStore) => {
    const url = process.env.PUBLIC_URL + '/config.json';
    const epoch = new Date() / 1;
    const urlNoCache = url + '?no-cache=' + epoch;

    logger.info('Loading configuration from public folder');
    Axios.get(urlNoCache).then(function(response) {

        logger.info('Configuration loaded', response.data);
        const config = response.data;

        if (typeof config === 'string') {

            document.getElementById('center-message').innerText = 'Door een configuratie fout is de applicatie momenteel niet beschikbaar.';

            return;
        }

        // sanity checking of some toggles
        if (typeof(config.exposeReduxStore) !== 'boolean') {
            config.exposeReduxStore = false;
        }

        if (typeof(config.enableLogging) !== 'boolean') {
            config.enableLogging = false;
        }

        if (typeof config.featureToggles !== 'object') {
            // making sure the feature toggles part is an object
            config.featureToggles = {};
        }

        if (typeof(config.showToolVersion) !== 'boolean') {
            config.showToolVersion = false;
        }

        if (typeof(config.showProductVersion) !== 'boolean') {
            config.showProductVersion = false;
        }

        Axios.defaults.toolBoxConfig = config;

        // HACK: Enable logger
        Axios.defaults.loggerEnabled = config.enableLogging;

        Axios.defaults.logConfig = config.logConfig;

        Axios.defaults.version = config.version;
        setApplicationVersionMeta(config.version);

        const store = configureStore(config);
        // add the config to the redux store
        logger.info('dispatching the configuration');
        store.dispatch({
            type: ActionTypes.ACTION_CONFIGURATION_CHANGED, config
        });

        logger.info('Configuring the API');
        configureApi(config, store);
        if (callback != null) {
            logger.info('Callback with configuration and store.');
            callback(config, store);
        }
    })
        .catch(
            /* istanbul ignore next : we do test this, but coverage isn't captured */
            (err)=> logger.error('Unable to load configuration.', err)
        );

    //add the custom validations to Formsy
    configureFormsy();
};
export default loadConfiguration;