import React from 'react';
import PropTypes from 'prop-types';
import { DropdownButton, MenuItem, Panel, Row, Col } from 'react-bootstrap';
import Formsy from 'formsy-react';
import FaIcon from 'react-fontawesome';

import {
    Button,
    Constants,
    FieldSetup,
    IncidentDocumentLinkWorkingModel,
    IncidentDocumentLinkDetailWorkingModel,
    Text
} from './_references';

class DocumentLink extends React.Component{

    render() {

        const { documentFrmId, bind, onChange, document, documentEditId, addToDocument, editDocumentRow, confirmEditDocumentRow, cancelEditDocumentRow, deleteDocumentRow } = this.props;

        return(
            <Panel>
                <Panel.Body>
                    <div className="panel-inline-title">Document links</div>
                    <Formsy key={documentFrmId} ref={(frm)=> {
                        this.documentFrm = frm;
                    }} className="form-horizontal" data-toggle="validator" onValidSubmit={() => {
                        addToDocument(this.documentFrm);
                    }}>

                        <p>Hier kunt u documenten met betrekking tot het incident toevoegen.</p>

                        <Text
                            {...bind(IncidentDocumentLinkDetailWorkingModel.DocumentLink)}
                            {...new FieldSetup()
                                .for(IncidentDocumentLinkDetailWorkingModel.DocumentLink)
                                .withFriendlyName('Link naar het document')
                                .setLabel()
                                .setPlaceholder('Link naar het document')
                                .isRequired()
                                .readOnly(documentEditId != null)
                                .maxLength(512)
                                .isUrl()
                                .onChange(onChange)
                            }
                        />

                        <Text
                            {...bind(IncidentDocumentLinkDetailWorkingModel.DocumentOmschrijving)}
                            {...new FieldSetup()
                                .for(IncidentDocumentLinkDetailWorkingModel.DocumentOmschrijving)
                                .withFriendlyName('Document omschrijving')
                                .setLabel()
                                .setPlaceholder('Omschrijving van het document')
                                .isRequired()
                                .readOnly(documentEditId != null)
                                .maxLength(512)
                                .onChange(onChange)
                            }
                        />
                        <Row>
                            <Col xs={12} className="text-right">
                                <Button type="submit" bsStyle="primary" disabled={documentEditId != null}><FaIcon name="plus" /> Toevoegen</Button>
                            </Col>
                        </Row>
                        <div className="v-spacer"/>
                    </Formsy>

                    { document == null || document.length === 0 ? null :
                        <Panel>
                            <Panel.Body>
                                <div className="panel-inline-title">Links naar de incident documenten</div>
                                {
                                    document
                                        .sort((a, b) => (a[ IncidentDocumentLinkWorkingModel.DocumentOmschrijving ] > b[ IncidentDocumentLinkWorkingModel.DocumentOmschrijving ]) - (a[ IncidentDocumentLinkWorkingModel.DocumentOmschrijving ] < b[ IncidentDocumentLinkWorkingModel.DocumentOmschrijving ]) )
                                        .map((dg, index) =>(
                                            documentEditId !== dg[ IncidentDocumentLinkWorkingModel.Id ] ?
                                                <Row className={`row-table row-table-${index % 2 === 0 ? 'even' : 'odd'}`} key={dg[ IncidentDocumentLinkWorkingModel.Id ]}>
                                                    <Col xs={1}><a target="_blank" rel="noopener noreferrer" title={dg[ IncidentDocumentLinkWorkingModel.DocumentOmschrijving ]} href={dg[ IncidentDocumentLinkWorkingModel.DocumentLink ]}><FaIcon name="file-text-o"/></a></Col>
                                                    <Col xs={9}><pre className="rapport">{dg[ IncidentDocumentLinkWorkingModel.DocumentOmschrijving ]}</pre></Col>
                                                    <Col xs={2} className="text-right">
                                                        <DropdownButton title={<span><FaIcon name="table"/> Rij</span>} id="bg-nested-dropdown" disabled={documentEditId != null}>
                                                            <MenuItem eventKey="1" onClick={() => editDocumentRow(dg[ IncidentDocumentLinkWorkingModel.Id ])}><FaIcon name="pencil"/> bewerken</MenuItem>
                                                            <MenuItem eventKey="2" onClick={() => deleteDocumentRow(dg[ IncidentDocumentLinkWorkingModel.Id ])}><FaIcon name="minus-square-o"/> verwijderen</MenuItem>
                                                        </DropdownButton>
                                                    </Col>
                                                </Row>
                                                :
                                                <Formsy key={documentFrmId} ref={(frm)=> {
                                                    this.documentFrm = frm;
                                                }} className="form-horizontal" data-toggle="validator" onValidSubmit={() => {
                                                    confirmEditDocumentRow();
                                                }}>

                                                    <Row className={`row-table row-table-${index % 2 === 0 ? 'even' : 'odd'}`} key={dg[ IncidentDocumentLinkWorkingModel.Id ]}>
                                                        <Col xs={12}>
                                                            <Text
                                                                {...bind(Constants.FIELDS.EDIT_LINK)}
                                                                {...new FieldSetup()
                                                                    .for(Constants.FIELDS.EDIT_LINK)
                                                                    .withFriendlyName('Link naar de overeenkomst')
                                                                    .setLabel()
                                                                    .setPlaceholder('Plaats hier de link in naar de overeenkomst')
                                                                    .isUrl()
                                                                    .maxLength(512)
                                                                    .onChange(onChange)
                                                                }
                                                            />

                                                            <Text
                                                                {...bind(Constants.FIELDS.EDIT_OMSCHRIJVING)}
                                                                {...new FieldSetup()
                                                                    .for(Constants.FIELDS.EDIT_OMSCHRIJVING)
                                                                    .withFriendlyName('Omschrijving van de overeenkomst')
                                                                    .setLabel()
                                                                    .setPlaceholder('Zet hier de overeenkomst van de overeenkomst in')
                                                                    .maxLength(512)
                                                                    .onChange(onChange)
                                                                }
                                                            />
                                                        </Col>
                                                        <Col xs={12} className="text-right">
                                                            <Button onClick={() => cancelEditDocumentRow()}><FaIcon name="ban" /> Annuleren</Button>
                                                            <span className="v-spacer"/>
                                                            <Button type="submit"><FaIcon name="check" /> Opslaan</Button>
                                                        </Col>
                                                    </Row>
                                                </Formsy>

                                        ))
                                }
                            </Panel.Body>
                        </Panel>
                    }

                </Panel.Body>
            </Panel>

        );
    }
}

DocumentLink.propTypes = {
    documentFrmId: PropTypes.string,
    bind: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    document: PropTypes.arrayOf(PropTypes.object),
    documentEditId: PropTypes.string,
    addToDocument: PropTypes.func.isRequired,
    editDocumentRow: PropTypes.func.isRequired,
    confirmEditDocumentRow: PropTypes.func.isRequired,
    cancelEditDocumentRow: PropTypes.func.isRequired,
    deleteDocumentRow : PropTypes.func.isRequired
};

export default DocumentLink;