import React from 'react';
import PropTypes from 'prop-types';

import { Navbar, Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';
import { Link } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';
import FaIcon from 'react-fontawesome';

import { Routes as RouteOptions } from '../../../configuration/constants.routes';
import Version from '../version';
import logo from '../../../logo_bar.png';
import { IfLoggedIn, IfLoggedOut, IfShow2FAWarning, IfAccountSelected } from '../../../modules/login/wrappers';
import Axios from 'axios';
import { UserNavDropDown } from '../../../modules/shared/userAccount';
import { Roles } from '../../../modules/shared/authentication/constants';

const Container = ({ children, className }) => (
    <div className="container">
        <Navbar collapseOnSelect>
            <Navbar.Header>
                <Navbar.Brand>
                    <Link to={RouteOptions.Home.path}><img src={logo} alt="logo" className="privacy-logo" /><span className="privacy-brand">W.T. Privacy</span></Link>
                </Navbar.Brand>
                <Navbar.Toggle />
            </Navbar.Header>
            <Navbar.Collapse>
                <Nav>
                    <IfLoggedOut><LinkContainer to={RouteOptions.Check.path}><NavItem eventKey={1}><FaIcon name="check-square-o"/> Privacy Check</NavItem></LinkContainer></IfLoggedOut>
                    <LinkContainer target="_blank" rel="noopener noreferrer" to={RouteOptions.Woordenboek.path}><NavItem eventKey={2}><FaIcon name="book"/> Woordenboek</NavItem></LinkContainer>
                    <NavDropdown eventKey={3} title={<span><FaIcon name="info"/> Informatie</span>} id="mnu-informatie">
                        <LinkContainer to={RouteOptions.OverOns.path}><MenuItem eventKey={3.1}><FaIcon name="question-circle-o"/> Waarom W.T. Privacy</MenuItem></LinkContainer>
                    </NavDropdown>
                </Nav>
                <Nav pullRight>
                    <IfLoggedIn>
                        <IfAccountSelected>
                            <LinkContainer to={RouteOptions.Dashboard.path}><NavItem eventKey={5}><FaIcon name="tachometer"/> Dashboard</NavItem></LinkContainer>
                            <LinkContainer to={RouteOptions.Templates.path}><NavItem eventKey={6}><FaIcon name="file-text-o"/> Templates</NavItem></LinkContainer>
                            <NavDropdown title={<span><FaIcon name="cogs"/> Beheer</span>} eventKey={7} id="mnu-beheer">
                                <LinkContainer to={RouteOptions.Beheer.Personen.path}><NavItem eventKey={7.1}><FaIcon name="user"/> Personen</NavItem></LinkContainer>
                            </NavDropdown>
                        </IfAccountSelected>
                        <IfShow2FAWarning>
                            <LinkContainer to={RouteOptions.Account.Beveiligen.path}><NavItem eventKey={8}><span title="Uw account moet extra beveiligd worden" className="beveiligen"><FaIcon name="exclamation-triangle"/> Beveiligen</span></NavItem></LinkContainer>
                        </IfShow2FAWarning>
                        <UserNavDropDown eventKey={9} id="mnu-informatie">
                            <IfLoggedIn role={Roles.Officer}>
                                <LinkContainer to={RouteOptions.Account.Select.path}><NavItem eventKey={9.1}><FaIcon name="address-book"/> Klant kiezen</NavItem></LinkContainer>
                            </IfLoggedIn>
                            <IfLoggedIn denied={Roles.Officer}>
                                <LinkContainer to={RouteOptions.Account.MijnAccount.path}><NavItem eventKey={9.2}><FaIcon name="male"/> Mijn account</NavItem></LinkContainer>
                            </IfLoggedIn>
                            <LinkContainer to={RouteOptions.Account.Beveiligen.path}><NavItem eventKey={9.3}><FaIcon name="lock"/> Beveiligen</NavItem></LinkContainer>
                            <LinkContainer to={RouteOptions.Account.Loguit.path}><NavItem eventKey={9.4}><FaIcon name="sign-out"/> Uitloggen</NavItem></LinkContainer>
                        </UserNavDropDown>

                    </IfLoggedIn>
                    <IfLoggedOut><LinkContainer to={RouteOptions.Account.Login.path}><NavItem eventKey={4}><FaIcon name="user"/> Account</NavItem></LinkContainer></IfLoggedOut>
                </Nav>
            </Navbar.Collapse>
        </Navbar>

        <div className={`content${className ? ' ' + className : ''}`}>

            {children}

            {Axios.defaults.toolBoxConfig.versionConfig.showToolVersion === false ? null :
                <React.Fragment>
                    <div className="size">
                        <span className="visible-xs">XS</span>
                        <span className="visible-sm">SM</span>
                        <span className="visible-md">MD</span>
                        <span className="visible-lg">LG</span>
                    </div>
                    <div className="version"><Version /></div>
                </React.Fragment>
            }
        </div>
    </div>
);

Container.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
};

export default Container;