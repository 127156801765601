import * as ActionTypes from './actionTypes';

export const clear = () => ({
    type: ActionTypes.CLEAR
});

export const createAccount = (model, form) => ({
    type: ActionTypes.CREATE_ACCOUNT,
    model,
    form
});

export const validationError = (...rest) => ({
    type: ActionTypes.VALIDATION_ERROR,
    ...rest
});