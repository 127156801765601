import { put, call, all, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from './actionTypes';

export default function* saga () {
    yield all([
        takeEvery(ActionTypes.HIDE_DIALOG, hideDialog)
    ]);
}

function* hideDialog(action) {

    // action.actions -> is a single value or an array
    //     when a single value -> object? -> put if contains type
    //                         -> method? -> execute and if returns a type with property type, then put
    //                         -> array?  -> first is method? execute, use rest as parameters
    //                                    -> first object with type property? -> put, and put any
    //                                       subsequent object that contains a type property
    //      when an array -> for each do the same as above

    if (action.actions == null) {
        return;
    }

    yield executeAction(action.actions);
}

function* executeAction(action) {
    if (action == null) {
        return;
    }

    switch(typeof action) {
    case 'object': {
        if (Array.isArray(action)) {
            if (action.length > 0) {
                if (typeof action[ 0 ] === 'function') {
                    const result = yield call(...action);
                    if (result != null ) {
                        yield executeAction(result);
                    }
                }
                else {
                    for (let index = 0; index < action.length; index += 1) {
                        yield executeAction(action[ index ]);
                    }
                }
            }
        }
        else if(typeof action[ 'type' ] === 'string' && action[ 'type' ] != null) {
            yield put(action);
        }
        else {
            console.log(`Tried to process an object on click in method, but it isn't an action: ${action}`);
        }
        break;
    }

    case 'function': {
        const result = yield call(action);
        if (typeof result === 'object' && typeof result[ 'type' ] === 'string' && result[ 'type' ] != null) {
            yield put(result);
        }
        break;
    }
    default: {
        console.warn(`dialog close found an unknown action to take of type ${typeof action}`);
        break;
    }
    }
}