import {
    stateHelper
} from './_references';

import * as Actions from './actions';
import * as Constants from './constants';

export const mapState = (state) => ({

    list: stateHelper(state, [ Constants.STATE_KEY, Constants.FIELDS.DATA ], []),
    isLoading : stateHelper(state, [ Constants.STATE_KEY, Constants.FIELDS.LOADING ])

});

export const mapDispatch = (dispatch) => ({

    load: () => dispatch(Actions.load()),
    select: (id) => dispatch(Actions.select(id)),
    unlink: (accountId) => dispatch(Actions.unlink(accountId))

});