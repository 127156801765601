import { connect } from 'react-redux';

import * as Components from './index.component';
import { mapState } from './index.map';

const UserAccountIcon = connect(mapState)(Components.UserAccountIcon);
const UserNavDropDown = connect(mapState)(Components.UserNavDropDown);

export {
    UserAccountIcon,
    UserNavDropDown
};