import React from 'react';
import PropTypes from 'prop-types';

import getValidInputProps from './getValidInputProps';

const Input = (props) => (<input {...getValidInputProps(props)} />);

Input.propTypes = {
    children: PropTypes.node
};

export default Input;