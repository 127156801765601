import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import FaIcon from 'react-fontawesome';

class CollapsablePanel extends React.Component {

    constructor (props, context) {
        super(props, context);

        const { isOpen = false } = props;

        this.state = {
            open : isOpen
        };

        this.toggle = this.toggle.bind(this);

    }

    toggle() {
        this.setState({
            open : !this.state.open
        });
    }

    render() {
        return (
            <div>
                <Button onClick={this.toggle}><FaIcon name={this.state.open ? 'sticky-note' : 'sticky-note-o'}></FaIcon> Notitie</Button>

                { !this.state.open ? null :
                    <div>
                        <div className="h-spacer">
                        </div>
                        {this.props.children}
                    </div>
                }
            </div>
        );
    }

}

CollapsablePanel.propTypes = {
    children: PropTypes.any,
    isOpen: PropTypes.bool
};

export default CollapsablePanel;