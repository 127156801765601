import React from 'react';
import PropTypes from 'prop-types';
import { Panel, Col, Row } from 'react-bootstrap';
import FaIcon from 'react-fontawesome';
import moment from 'moment';

import {
    RouteParams,
    Container,
    IncidentDetailUpdateWorkingModel,
    IncidentAnalyseVraagWorkingModel,
    IncidentAnalyseAntwoordWorkingModel,
    IncidentAnalyseConclusieWorkingModel,
    IncidentRapportWorkingModel,
    IncidentDocumentLinkWorkingModel,
    Button,
    Routes,
    Loading
} from './_references';

class IncidentInzien extends React.Component {

    componentDidMount() {

        const { getIncident, params } = this.props;

        getIncident(params[ RouteParams.Incident.Inzien.Id ]);

    }

    componentWillUnmount() {
        this.props.clear();
    }

    render() {
        const {
            bind,
            bindAntwoord,
            rapport,
            document,
            vragen,
            isLoading
        } = this.props;

        return (

            <Container title="Incident inzien">
                {isLoading ? <Loading/> :
                    <Row>
                        <Col sm={12} xs={12}>
                            <Panel>
                                <Panel.Body>
                                    <div className="panel-inline-title">Algemene gegevens</div>
                                    <Row>
                                        <Col xs={12} md={2} className="field-label">Naam:</Col>
                                        <Col xs={12} md={10}>{bind(IncidentDetailUpdateWorkingModel.Naam)}</Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={2} className="field-label">Moment ontdekking:</Col>
                                        <Col xs={12} md={10}>{moment(bind(IncidentDetailUpdateWorkingModel.DatumIncident)).format('YYYY-MM-DD HH:mm')}</Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} className="field-label">Omschrijving:</Col>
                                        <Col xs={12}><pre className="omschrijving">{bind(IncidentDetailUpdateWorkingModel.Beschrijving)}</pre></Col>
                                    </Row>
                                </Panel.Body>
                            </Panel>
                            <Panel>
                                <Panel.Body>
                                    <div className="panel-inline-title">Analyse</div>

                                    {vragen.map((v) => {
                                        const antwoord = v[ IncidentAnalyseVraagWorkingModel.Antwoorden ].filter(a => (a[ IncidentAnalyseAntwoordWorkingModel.AntwoordId ] === bindAntwoord(v[ IncidentAnalyseVraagWorkingModel.VraagId ]))).map(a => a[ IncidentAnalyseAntwoordWorkingModel.Antwoord ]);

                                        return (<Row key={v[ IncidentAnalyseVraagWorkingModel.VraagId ]}>

                                            {v[ IncidentAnalyseVraagWorkingModel.Conclusie ] == null ?
                                                (<div>
                                                    <Col xs={12} md={8} className="field-label">{v[ IncidentAnalyseVraagWorkingModel.Vraag ]}</Col>
                                                    <Col xs={12} md={4}>{ antwoord == null || antwoord.length === 0 ? <i>Geen antwoord gegeven</i> : antwoord }</Col>
                                                </div>
                                                )
                                                : (
                                                    <Col xs={12}>
                                                        <Col xs={12} className={`bs-callout bs-callout-${v[ IncidentAnalyseVraagWorkingModel.Conclusie ][ IncidentAnalyseConclusieWorkingModel.KleurCode ]}`}>
                                                            <p>
                                                                {v[ IncidentAnalyseVraagWorkingModel.Conclusie ][ IncidentAnalyseConclusieWorkingModel.Tekst ]}
                                                            </p>
                                                        </Col>
                                                    </Col>
                                                )}
                                        </Row>);
                                    }
                                    )
                                    }
                                </Panel.Body>
                            </Panel>

                            { vragen.some(v => (v[ IncidentAnalyseVraagWorkingModel.Conclusie ] != null && v[ IncidentAnalyseVraagWorkingModel.Conclusie ][ IncidentAnalyseConclusieWorkingModel.Meldplicht ] ))
                                ?
                                bind(IncidentDetailUpdateWorkingModel.MeldingDoorWTP) == null ? null :
                                    <Panel>
                                        <Panel.Body>
                                            <div className="panel-inline-title">Melding bij AP</div>

                                    U heeft { bind(IncidentDetailUpdateWorkingModel.MeldingDoorWTP) === 'true' ? '' : <u>niet</u>} ervoor gekozen om de afhandeling door W.T. Privacy te laten uitvoeren.

                                        </Panel.Body>
                                    </Panel> :null
                            }

                            <Panel>
                                <Panel.Body>
                                    <div className="panel-inline-title">Melding bij de Autoriteit Persoongegevens</div>

                                    <Row>
                                        <Col xs={12} md={2} className="field-label">Meldingsnummer:</Col>
                                        <Col xs={12} md={10}>{bind(IncidentDetailUpdateWorkingModel.Meldingsnummer)}</Col>
                                    </Row>

                                    <Row>
                                        <Col xs={12} className="field-label">Conclusie:</Col>
                                        <Col xs={12}><pre className="omschrijving">{bind(IncidentDetailUpdateWorkingModel.Conclusie)}</pre></Col>
                                    </Row>

                                    <Row>
                                        <Col xs={12} className="field-label">Eind conclusie:</Col>
                                        <Col xs={12}><pre className="omschrijving">{bind(IncidentDetailUpdateWorkingModel.EindConclusie)}</pre></Col>
                                    </Row>

                                    <Row>
                                        <Col xs={12} className="field-label">Vervolgacties:</Col>
                                        <Col xs={12}><pre className="omschrijving">{bind(IncidentDetailUpdateWorkingModel.VervolgActies)}</pre></Col>
                                    </Row>

                                </Panel.Body>
                            </Panel>

                            <Panel>
                                <Panel.Body>
                                    <div className="panel-inline-title">Links naar de incident documenten</div>
                                    <Panel>
                                        <Panel.Body>
                                            {
                                                document
                                                    .sort((a, b) => (a[ IncidentDocumentLinkWorkingModel.DocumentOmschrijving ] > b[ IncidentDocumentLinkWorkingModel.DocumentOmschrijving ]) - (a[ IncidentDocumentLinkWorkingModel.DocumentOmschrijving ] < b[ IncidentDocumentLinkWorkingModel.DocumentOmschrijving ]) )
                                                    .map((dg, index) =>(
                                                        <Row className={`row-text row-table row-table-${index % 2 === 0 ? 'even' : 'odd'}`} key={dg[ IncidentDocumentLinkWorkingModel.Id ]}>
                                                            <Col xs={1}><a target="_blank" rel="noopener noreferrer" title={dg[ IncidentDocumentLinkWorkingModel.DocumentOmschrijving ]} href={dg[ IncidentDocumentLinkWorkingModel.DocumentLink ]}><FaIcon name="file-text-o"/></a></Col>
                                                            <Col xs={11}><pre className="rapport">{dg[ IncidentDocumentLinkWorkingModel.DocumentOmschrijving ]}</pre></Col>
                                                        </Row>
                                                    ))
                                            }
                                        </Panel.Body>
                                    </Panel>
                                </Panel.Body>
                            </Panel>

                            <Panel>
                                <Panel.Body>
                                    <div className="panel-inline-title">Incident rapport</div>

                                    { rapport == null ? null :

                                        rapport.sort( (a,b) =>
                                            a[ IncidentRapportWorkingModel.Moment ] < b[ IncidentRapportWorkingModel.Moment ] ? -1 : a[ IncidentRapportWorkingModel.Moment ] > b[ IncidentRapportWorkingModel.Moment ] ? 1 : 0
                                        ).reduce((result, r) => {
                                            const dt = moment(r[ IncidentRapportWorkingModel.Moment ]).format('YYYYMMDD');

                                            if (result[ 0 ][ dt ] == null) {
                                                result[ 0 ][ dt ] = [];
                                            }

                                            result[ 0 ][ dt ].push(r);
                                            return result;
                                        }, [ {} ])
                                            .map(rg => (
                                                Object.keys(rg).map(rgk => (
                                                    <Panel key={rgk}>
                                                        <Panel.Body>
                                                            <div className="panel-inline-title">{moment(rg[ rgk ][ 0 ][ IncidentRapportWorkingModel.Moment ]).format('DD MMMM YYYY')}</div>

                                                            {
                                                                rg[ rgk ].map((r, index) => (
                                                                    <Row className={`row-table row-table-${index % 2 === 0 ? 'even' : 'odd'}`} key={r[ IncidentRapportWorkingModel.Id ]}>
                                                                        <Col xs={1}>{moment(r[ IncidentRapportWorkingModel.Moment ]).format('HH:mm')}</Col>
                                                                        <Col xs={11}><pre className="rapport">{r[ IncidentRapportWorkingModel.SituatieOmschrijving ]}</pre></Col>
                                                                    </Row>
                                                                ))
                                                            }

                                                        </Panel.Body>
                                                    </Panel>
                                                ))
                                            ))
                                    }

                                </Panel.Body>
                            </Panel>
                            <div className="text-right">
                                <Button to={Routes.Dashboard.path} bsStyle="primary"><FaIcon name="undo" /> Sluiten</Button>
                            </div>
                        </Col>
                    </Row>
                }
            </Container>
        );
    }
}

IncidentInzien.propTypes = {
    bind: PropTypes.func.isRequired,
    bindAntwoord: PropTypes.func.isRequired,
    clear: PropTypes.func.isRequired,
    getIncident: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    params: PropTypes.object,
    rapport: PropTypes.arrayOf(PropTypes.object),
    document: PropTypes.arrayOf(PropTypes.object),
    vragen: PropTypes.arrayOf(PropTypes.object)
};

export default IncidentInzien;