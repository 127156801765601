import createLogger from '../util/log';

const logger = createLogger('Router');

// private method for the configureRouter
const onError = (error) => {

    logger.log('Error in router:', onError);

};

export default onError;