import React from 'react';

import { Row, Col } from 'react-bootstrap';

const Pia = () => (
    <Row>
        <Col xs={12}>
            U verwerkt (bijzondere) persoonsgegevens, u dient een Privacy Impact Assessment uit te voeren om eventuele risico's ten aanzien van het verlies van persoonsgegevens in kaart te brengen.
        </Col>
    </Row>
);

export default Pia;