import React from 'react';
import PropTypes from 'prop-types';

import getValidProps from './getValidTextAreaProps';

const TextArea = (props) => (<textarea {...getValidProps(props)} />);

TextArea.propTypes = {
    children: PropTypes.node
};

export default TextArea;