import{
    binder,
    onChange
} from './_references';

import * as Actions from './actions';
import * as Constants from './constants';

export const mapStateToProps = (state) => ({
    bind: (field) => binder(state, [ Constants.STATE_KEY ])(field)
});

export const mapDispatchToProps = (dispatch) => ({
    clear: () => dispatch(Actions.clear()),
    onChange: onChange([ Constants.STATE_KEY ], dispatch),
    requestPassword: (model, form) => dispatch(Actions.requestPassword(model, form))
});