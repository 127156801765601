import { v4 } from 'uuid';

import {
    stateHelper,
    IncidentDetailUpdateWorkingModel,
    IncidentRapportWorkingModel,
    IncidentRapportDetailWorkingModel,
    IncidentDocumentLinkWorkingModel,
    IncidentDocumentLinkDetailWorkingModel
} from './_references';

import * as ActionTypes from './actionTypes';
import * as Constants from './constants';

/*
 * Reducer for the incident component.
*/
const reducer = (state = {}, action) => {
    switch(action.type){
    case ActionTypes.CLEAR:
    {
        return Object.assign({},state,{
            [ Constants.FIELDS.DOSSIER ]: {}
        });
    }
    case ActionTypes.GET_INCIDENT:
    {
        return Object.assign({},state,{
            [ Constants.FIELDS.LOADING ] : true
        });
    }
    case ActionTypes.LOADED_INCIDENT:
    {
        return Object.assign({},state,{
            [ Constants.FIELDS.DOSSIER ]: action.model,
            [ Constants.FIELDS.LOADING ] : false
        });
    }
    case ActionTypes.LOAD_INCIDENTS: {
        return Object.assign({},state,{
            [ Constants.FIELDS.LIST ]: action.model,
            [ Constants.FIELDS.LOADING ] : true
        });
    }
    case ActionTypes.LOADED_INCIDENTS:
    {
        return Object.assign({},state,{
            [ Constants.FIELDS.LIST ]: action.model,
            [ Constants.FIELDS.LOADING ] : false
        });
    }
    case ActionTypes.INTERMEDIATE_ANALYSIS_COMPLETE:
    {
        const dossier = stateHelper(state, Constants.FIELDS.DOSSIER, {});
        const newDossier = Object.assign({}, dossier, action.model);

        const result = Object.assign({}, state, {
            [ Constants.FIELDS.DOSSIER ]: newDossier
        });
        return result;
    }
    case ActionTypes.ADD_TO_RAPPORT:{
        const newDossier = stateHelper(state, Constants.FIELDS.DOSSIER, {});
        const newRapport = stateHelper(newDossier, IncidentDetailUpdateWorkingModel.Rapport, []).slice();

        newRapport.push({
            [ IncidentRapportWorkingModel.Id ] : v4(),
            [ IncidentRapportDetailWorkingModel.Moment ] : newDossier[ IncidentRapportDetailWorkingModel.Moment ],
            [ IncidentRapportDetailWorkingModel.SituatieOmschrijving ] : newDossier[ IncidentRapportDetailWorkingModel.SituatieOmschrijving ]
        });

        // changing the form id so the formsy form is rerendered and will not give validation errors when the form is cleared.
        newDossier[ Constants.FIELDS.RAPPORT_FRM_ID ] = v4();
        newDossier[ IncidentRapportDetailWorkingModel.Moment ] = null;
        newDossier[ IncidentRapportDetailWorkingModel.SituatieOmschrijving ] = null;

        return Object.assign({}, state, {
            [ Constants.FIELDS.DOSSIER ] : Object.assign({}, newDossier, {
                [ IncidentDetailUpdateWorkingModel.Rapport ] : newRapport
            })
        });
    }
    case ActionTypes.CONFIRMED_DELETE_RAPPORT_ROW: {
        const newDossier = stateHelper(state, Constants.FIELDS.DOSSIER, {});
        const newRapport = stateHelper(newDossier, IncidentDetailUpdateWorkingModel.Rapport, [])
            .slice()
            .filter(r => r[ IncidentRapportWorkingModel.Id ] !== action.id);

        return Object.assign({}, state, {
            [ Constants.FIELDS.DOSSIER ] : Object.assign({}, newDossier, {
                [ IncidentDetailUpdateWorkingModel.Rapport ] : newRapport
            })
        });
    }
    case ActionTypes.EDIT_RAPPORT_ROW: {
        const newDossier = stateHelper(state, Constants.FIELDS.DOSSIER, {});
        const rapports = stateHelper(newDossier, IncidentDetailUpdateWorkingModel.Rapport, []).filter(r => r[ IncidentRapportWorkingModel.Id ] === action.id);
        if (rapports != null && rapports.length === 1) {
            const rapport= rapports[ 0 ];
            return Object.assign({}, state, {
                [ Constants.FIELDS.DOSSIER ] : Object.assign({}, newDossier, {
                    [ Constants.FIELDS.RAPPORT_EDIT_ID ] : action.id,
                    [ Constants.FIELDS.EDIT_TIME ] : rapport[ IncidentRapportWorkingModel.Moment ],
                    [ Constants.FIELDS.EDIT_TEXT ] : rapport[ IncidentRapportWorkingModel.SituatieOmschrijving ],
                })
            });
        }
        else {
        // insanity, the report row does not exist anymore?
            return state;
        }
    }
    case ActionTypes.CONFIRM_EDIT_RAPPORT_ROW: {
        const newDossier = stateHelper(state, Constants.FIELDS.DOSSIER, {});
        const editId = newDossier[ Constants.FIELDS.RAPPORT_EDIT_ID ];
        const newRapports = stateHelper(newDossier, IncidentDetailUpdateWorkingModel.Rapport, []).filter(r => r[ IncidentRapportWorkingModel.Id ] !== editId).slice();
        const editRapports = stateHelper(newDossier, IncidentDetailUpdateWorkingModel.Rapport, []).filter(r => r[ IncidentRapportWorkingModel.Id ] === editId).slice();
        if (editRapports != null && editRapports.length === 1) {
            const rapport= editRapports[ 0 ];

            rapport[ IncidentRapportWorkingModel.Moment ] = newDossier[ Constants.FIELDS.EDIT_TIME ];
            rapport[ IncidentRapportWorkingModel.SituatieOmschrijving ] = newDossier[ Constants.FIELDS.EDIT_TEXT ];

            newRapports.push(rapport);

            return Object.assign({}, state, {
                [ Constants.FIELDS.DOSSIER ] : Object.assign({}, newDossier, {
                    [ IncidentDetailUpdateWorkingModel.Rapport ] : newRapports,
                    [ Constants.FIELDS.RAPPORT_EDIT_ID ] : null,
                    [ Constants.FIELDS.EDIT_TIME ] : null,
                    [ Constants.FIELDS.EDIT_TEXT ] : null,
                })
            });
        }
        else {
        // insanity, the report row does not exist anymore?
            return state;
        }
    }
    case ActionTypes.CANCEL_EDIT_RAPPORT_ROW: {
        const newDossier = stateHelper(state, Constants.FIELDS.DOSSIER, {});
        return Object.assign({}, state, {
            [ Constants.FIELDS.DOSSIER ] : Object.assign({}, newDossier, {
                [ Constants.FIELDS.RAPPORT_EDIT_ID ] : null,
                [ Constants.FIELDS.EDIT_TIME ] : null,
                [ Constants.FIELDS.EDIT_TEXT ] : null,
            })
        });
    }
    case ActionTypes.ADD_TO_DOCUMENT:{
        const newDossier = stateHelper(state, Constants.FIELDS.DOSSIER, {});
        const newDocument = stateHelper(newDossier, IncidentDetailUpdateWorkingModel.Document, []).slice();

        newDocument.push({
            [ IncidentDocumentLinkWorkingModel.Id ] : v4(),
            [ IncidentDocumentLinkDetailWorkingModel.DocumentLink ] : newDossier[ IncidentDocumentLinkDetailWorkingModel.DocumentLink ],
            [ IncidentDocumentLinkDetailWorkingModel.DocumentOmschrijving ] : newDossier[ IncidentDocumentLinkDetailWorkingModel.DocumentOmschrijving ]
        });

        // changing the form id so the formsy form is rerendered and will not give validation errors when the form is cleared.
        newDossier[ Constants.FIELDS.DOCUMENT_FRM_ID ] = v4();
        newDossier[ IncidentDocumentLinkDetailWorkingModel.DocumentLink ] = null;
        newDossier[ IncidentDocumentLinkDetailWorkingModel.DocumentOmschrijving ] = null;

        return Object.assign({}, state, {
            [ Constants.FIELDS.DOSSIER ] : Object.assign({}, newDossier, {
                [ IncidentDetailUpdateWorkingModel.Document ] : newDocument
            })
        });
    }
    case ActionTypes.EDIT_DOCUMENT_ROW: {
        const newDossier = stateHelper(state, Constants.FIELDS.DOSSIER, {});
        const documents = stateHelper(newDossier, IncidentDetailUpdateWorkingModel.Document, []).filter(r => r[ IncidentDocumentLinkWorkingModel.Id ] === action.id);
        if (documents != null && documents.length === 1) {
            const document= documents[ 0 ];
            return Object.assign({}, state, {
                [ Constants.FIELDS.DOSSIER ] : Object.assign({}, newDossier, {
                    [ Constants.FIELDS.DOCUMENT_EDIT_ID ] : action.id,
                    [ Constants.FIELDS.EDIT_LINK ] : document[ IncidentDocumentLinkWorkingModel.DocumentLink ],
                    [ Constants.FIELDS.EDIT_OMSCHRIJVING ] : document[ IncidentDocumentLinkWorkingModel.DocumentOmschrijving ],
                })
            });
        }
        else {
        // insanity, the document row does not exist anymore?
            return state;
        }
    }
    case ActionTypes.CONFIRM_EDIT_DOCUMENT_ROW: {
        const newDossier = stateHelper(state, Constants.FIELDS.DOSSIER, {});
        const editId = newDossier[ Constants.FIELDS.DOCUMENT_EDIT_ID ];
        const newDocuments = stateHelper(newDossier, IncidentDetailUpdateWorkingModel.Document, []).filter(r => r[ IncidentDocumentLinkWorkingModel.Id ] !== editId).slice();
        const editDocuments = stateHelper(newDossier, IncidentDetailUpdateWorkingModel.Document, []).filter(r => r[ IncidentDocumentLinkWorkingModel.Id ] === editId).slice();
        if (editDocuments != null && editDocuments.length === 1) {
            const document= editDocuments[ 0 ];

            document[ IncidentDocumentLinkWorkingModel.DocumentLink ] = newDossier[ Constants.FIELDS.EDIT_LINK ];
            document[ IncidentDocumentLinkWorkingModel.DocumentOmschrijving ] = newDossier[ Constants.FIELDS.EDIT_OMSCHRIJVING ];

            newDocuments.push(document);

            return Object.assign({}, state, {
                [ Constants.FIELDS.DOSSIER ] : Object.assign({}, newDossier, {
                    [ IncidentDetailUpdateWorkingModel.Document ] : newDocuments,
                    [ Constants.FIELDS.DOCUMENT_EDIT_ID ] : null,
                    [ Constants.FIELDS.EDIT_LINK ] : null,
                    [ Constants.FIELDS.EDIT_OMSCHRIJVING ] : null,
                })
            });
        }
        else {
        // insanity, the document row does not exist anymore?
            return state;
        }
    }
    case ActionTypes.CANCEL_EDIT_DOCUMENT_ROW: {
        const newDossier = stateHelper(state, Constants.FIELDS.DOSSIER, {});
        return Object.assign({}, state, {
            [ Constants.FIELDS.DOSSIER ] : Object.assign({}, newDossier, {
                [ Constants.FIELDS.DOCUMENT_EDIT_ID ] : null,
                [ Constants.FIELDS.EDIT_LINK ] : null,
                [ Constants.FIELDS.EDIT_OMSCHRIJVING ] : null,
            })
        });
    }
    case ActionTypes.CONFIRMED_DELETE_DOCUMENT_ROW: {
        const newDossier = stateHelper(state, Constants.FIELDS.DOSSIER, {});
        const newDocument = stateHelper(newDossier, IncidentDetailUpdateWorkingModel.Document, [])
            .slice()
            .filter(r => r[ IncidentDocumentLinkWorkingModel.Id ] !== action.id);

        return Object.assign({}, state, {
            [ Constants.FIELDS.DOSSIER ] : Object.assign({}, newDossier, {
                [ IncidentDetailUpdateWorkingModel.Document ] : newDocument
            })
        });
    }
    default:
        return state;
    }
};

reducer.STATE_KEY = Constants.STATE_KEY;

export default reducer;