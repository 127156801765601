import moment from 'moment';

const prepareValue = ({ value, options, optionKey, optionValue, dateFormat = 'DD-MM-YYYY' }) => {

    let valueToDisplay = value;

    if (options != null) {
        valueToDisplay = options.find(o => (o[ optionKey ] === value))[ optionValue ];
    }
    else {
        switch (value) {
        case 'true':
        {
            valueToDisplay = 'Ja';
            break;
        }
        case 'false':
        {
            valueToDisplay = 'Nee';
            break;
        }
        default:
            break;
        }

        if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}Z$/.test(value)) {
            valueToDisplay = moment(value).format(dateFormat);
        }
    }

    if (valueToDisplay == null || valueToDisplay === '') {
        valueToDisplay = '-';
    }

    return valueToDisplay;
};

export default prepareValue;
