import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import FaIcon from 'react-fontawesome';
import { Panel, Row, Col, DropdownButton, MenuItem } from 'react-bootstrap';

import {
    PersoonWorkingModel,
    Button,
    Editor,
    Inzage,
    Constants,
    Loading
} from './_references';

class PersonenLijst extends React.Component {

    componentDidMount() {
        this.props.loadPersonen();
    }

    render() {
        const { isLoading, list, top, to, editable = false, showDetails, hideDetails, editPersoon, deletePersoon, editorObject, bind, onChange, cancelEdit, updatePersoon } = this.props;

        return (
            isLoading ? <Loading/> :
                <Panel>
                    <Panel.Body>
                        <div className="panel-inline-title">Personen</div>

                        <Row><Col xsHidden sm={12}>
                            <Row className="row-table row-table-header">
                                <Col xs={editable ? 10 : 12}>Volledige naam</Col>
                                {editable ? <Col xs={2} className="text-right"></Col> : null}
                            </Row>
                        </Col></Row>

                        {
                            list.map((persoon, index) => (
                                [
                                    editorObject[ PersoonWorkingModel.Id ] === persoon[ PersoonWorkingModel.Id ] ? null :

                                        <Row key={persoon[ PersoonWorkingModel.Id ]} className={`row-table row-table-${index % 2 === 0 ? 'even' : 'odd'}`}>
                                            <Col xs={12} smHidden mdHidden lgHidden className="row-table-header-title">Volledige naam</Col>
                                            <Col xs={12} sm={editable ? 10 : 12}>{persoon[ PersoonWorkingModel.FullName ]}</Col>
                                            {editable ?
                                                <Col xsHidden sm={2} className="text-right">

                                                    <DropdownButton title={<span><FaIcon name="file-text-o"/> Details</span>} id="bg-nested-dropdown">
                                                        { persoon[ Constants.FIELDS.DETAILS ] == null ?
                                                            <MenuItem eventKey="1" onClick={()=>showDetails(persoon[ PersoonWorkingModel.Id ])}><FaIcon name="eye"/> Details tonen</MenuItem>
                                                            : <MenuItem eventKey="1" onClick={()=>hideDetails(persoon[ PersoonWorkingModel.Id ])}><FaIcon name="eye-slash"/> Details verbergen</MenuItem>}
                                                        <MenuItem eventKey="2" onClick={() => editPersoon(persoon[ PersoonWorkingModel.Id ])}><FaIcon name="pencil"/> Bewerken</MenuItem>
                                                        <MenuItem eventKey="3" onClick={() => deletePersoon(persoon[ PersoonWorkingModel.Id ])}><FaIcon name="trash"/> Verwijderen</MenuItem>
                                                    </DropdownButton>

                                                </Col>
                                                : null }
                                            {editable ?
                                                <Col xs={12} smHidden mdHidden lgHidden className="text-right">
                                                    <div className="h-spacer"/>
                                                    { persoon[ Constants.FIELDS.DETAILS ] == null ?
                                                        <Button block onClick={()=>showDetails(persoon[ PersoonWorkingModel.Id ])}><FaIcon name="eye"/> Details tonen</Button>
                                                        : <Button block onClick={()=>showDetails(persoon[ PersoonWorkingModel.Id ])}><FaIcon name="eye-slash"/> Details verbergen</Button>
                                                    }
                                                    <Button disabled block onClick={() => editPersoon(persoon[ PersoonWorkingModel.Id ])}><FaIcon name="pencil"/> Bewerken</Button>
                                                    <Button bsStyle="danger" block onClick={() => deletePersoon(persoon[ PersoonWorkingModel.Id ])}><FaIcon name="trash"/> Verwijderen</Button>

                                                </Col>
                                                : null }
                                        </Row>
                                    ,
                                    !editable || editorObject[ PersoonWorkingModel.Id ] === persoon[ PersoonWorkingModel.Id ] || persoon[ Constants.FIELDS.DETAILS ] == null ? null :
                                        <Row key={`${persoon[ PersoonWorkingModel.Id ]}-details`} className={`row-table row-table-${index % 2 === 0 ? 'even' : 'odd'}`}>
                                            <Col>
                                                <Inzage model={persoon} />
                                            </Col>
                                        </Row>
                                    ,
                                    !editable || editorObject[ PersoonWorkingModel.Id ] !== persoon[ PersoonWorkingModel.Id ] ? null :
                                        <Row key={`${persoon[ PersoonWorkingModel.Id ]}-details`} className={`row-table row-table-${index % 2 === 0 ? 'even' : 'odd'}`}>
                                            <Formsy ref={(frm)=> {
                                                this.frm = frm;
                                            }} className="form-horizontal" data-toggle="validator" onValidSubmit={() => {
                                                updatePersoon();
                                            }}>
                                                <Col xs={12}><Editor {...{
                                                    bind, onChange
                                                }}/></Col>
                                                <Col xs={12} className="text-right">
                                                    <Button onClick={() => cancelEdit()}><FaIcon name="ban" /> Annuleren</Button>
                                                    <span className="v-spacer"/>
                                                    <Button type="submit"><FaIcon name="check" /> Opslaan</Button>
                                                </Col>
                                            </Formsy>
                                        </Row>
                                ]
                            ))
                        }

                        {
                            to != null && top != null && top < list.length ?
                                <Row><Col xs={12} className="row-table-footer"><i>Alleen de {top} (van {list.length}) meest recente toevoegingen worden hier getoond.</i></Col></Row>
                                : null
                        }

                    </Panel.Body>
                </Panel>
        );
    }
}

PersonenLijst.propTypes = {
    isLoading: PropTypes.bool,
    loadPersonen: PropTypes.func.isRequired,
    list: PropTypes.array,
    top: PropTypes.number,
    to: PropTypes.string,
    editable: PropTypes.bool,
    showDetails: PropTypes.func.isRequired,
    hideDetails: PropTypes.func.isRequired,
    editPersoon: PropTypes.func.isRequired,
    deletePersoon: PropTypes.func.isRequired,
    bind: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    cancelEdit: PropTypes.func.isRequired,
    updatePersoon: PropTypes.func.isRequired,
    editorObject: PropTypes.object
};

export default PersonenLijst;