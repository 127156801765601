import * as AuthentictionActions from '../shared/authentication/actions';
import * as Actions from './actions';
import * as Constants from './constants';
import binder, { onChange } from '../../util/reduxBinder';
import { stateHelper } from '../../util/stateManager';

export const mapDispatchToProps = (dispatch) => ({
    handleLogin: (model) => dispatch(AuthentictionActions.handleLogin(model)),
    onChange : onChange(Constants.STATE_KEY, dispatch),
    back: () => dispatch(AuthentictionActions.setRequiresVerification(false)),
    clear: () => dispatch(Actions.clear(true))
});

export const mapStateToProps = (state) => {
    return {
        bind: (field) => binder(state[ Constants.STATE_KEY ])(field),
        requiresVerification: stateHelper(state, [ Constants.STATE_KEY, Constants.FIELDS.REQUIRES_VERIFICATION ], false),
    };
};
