import React from 'react';
import PropTypes from 'prop-types';
import { Panel, Col, Row } from 'react-bootstrap';
import FaIcon from 'react-fontawesome';

import {
    Button,
    Container,
    Loading,
    PiaAntwoordWorkingModel,
    PiaConclusieWorkingModel,
    PiaVraagCategorieWorkingModel,
    PiaVraagWorkingModel,
    RouteParams,
    Routes
} from './_references';

class PiaInzien extends React.Component {

    componentDidMount() {
        this.props.load(this.props.params[ RouteParams.Pia.Inzien.Id ]);
    }

    render() {

        const { isLoading, categorien, vragen, bind, bindNotitie } = this.props;

        return (
            <Container title="Privacy Impact Assessment">
                {isLoading ? <Loading /> :
                    <Row>
                        <Col sm={12} xs={12}>

                            {categorien.filter(c => vragen
                            // filter out any category that isn't used (yet)
                                .find(v => v[ PiaVraagWorkingModel.Categorie ] === c[ PiaVraagCategorieWorkingModel.Naam ]))
                                .map(c => (

                                    <Panel key={c[ PiaVraagCategorieWorkingModel.Id ]}>
                                        <Panel.Body>
                                            <div className="panel-inline-title">{c[ PiaVraagCategorieWorkingModel.Naam ]}</div>

                                            {vragen
                                            // show only questions within the current category
                                                .filter(v => v[ PiaVraagWorkingModel.Categorie ] === c[ PiaVraagCategorieWorkingModel.Naam ])

                                                .map((v) => {
                                                    const antwoord = v[ PiaVraagWorkingModel.Antwoorden ].filter(a => (a[ PiaAntwoordWorkingModel.AntwoordId ] === bind(v[ PiaVraagWorkingModel.VraagId ]).value)).map(a => a[ PiaAntwoordWorkingModel.Antwoord ]);
                                                    const notitie = bindNotitie(v[ PiaVraagWorkingModel.VraagId ]).value;

                                                    return (<Row key={v[ PiaVraagWorkingModel.VraagId ]}>

                                                        {v[ PiaVraagWorkingModel.Conclusie ] == null ?
                                                            (<div>
                                                                <Col xs={12} className="field-label">{v[ PiaVraagWorkingModel.Vraag ]}</Col>
                                                                <Col xs={12} >
                                                                    <span className="inzien-antwoord">
                                                                        { antwoord == null || antwoord.length === 0 ? <i>Geen antwoord gegeven</i> : antwoord }
                                                                    </span>
                                                                    { notitie == null || notitie === '' ? null :
                                                                        <div className="inzien-notitie">
                                                                            <FaIcon name="sticky-note-o"></FaIcon>
                                                                            <div>{notitie}</div>
                                                                        </div>
                                                                    }
                                                                </Col>
                                                            </div>
                                                            )
                                                            : (
                                                                <Col xs={12}>
                                                                    <Col xs={12} className={`bs-callout bs-callout-${v[ PiaVraagWorkingModel.Conclusie ][ PiaConclusieWorkingModel.KleurCode ]}`}>
                                                                        <p>
                                                                            {v[ PiaVraagWorkingModel.Conclusie ][ PiaConclusieWorkingModel.Tekst ]}
                                                                        </p>
                                                                    </Col>
                                                                </Col>
                                                            )}
                                                    </Row>);
                                                }
                                                )
                                            }

                                        </Panel.Body>

                                    </Panel>

                                ))}
                            <div className="pull-down">
                                <div className="text-right">
                                    <Button to={Routes.Dashboard.path} bsStyle="primary"><FaIcon name="undo" /> Sluiten</Button>
                                </div>

                            </div>

                        </Col>
                    </Row>
                }
            </Container>
        );
    }
}

PiaInzien.propTypes = {
    isLoading: PropTypes.bool,
    categorien: PropTypes.array,
    vragen: PropTypes.array,
    bind: PropTypes.func.isRequired,
    bindNotitie: PropTypes.func.isRequired,
    params: PropTypes.array,
    load: PropTypes.func.isRequired
};

export default PiaInzien;