import React from 'react';

import {
    Container
} from './_references';

const Account = () => (
    <Container>
        Account informatie
    </Container>
);

export default Account;