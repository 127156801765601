import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import { Col, Row } from 'react-bootstrap';
import FaIcon from 'react-fontawesome';
import { Link } from 'react-router';

import {
    Button,
    Container,
    IncidentDetailUpdateWorkingModel,
    Loading,
    RouteParams,
    Routes,
} from './_references';

import AlgemeneGegevens from './algemeneGegevens.component';
import Analyse from './analyse.component';
import Melding from './melding.component';
import DocumentLink from './documentLink.component';
import Rapport from './rapport.component';

class IncidentBewerken extends React.Component {

    componentDidMount() {

        const { getIncident, params } = this.props;

        getIncident(params[ RouteParams.Incident.Bewerken.Id ]);

    }

    componentWillUnmount() {
        this.props.clear();
    }

    render() {
        const {
            confirmClose,
            params,
            updateIncident,
            isLoading,
            ...rest
        } = this.props;

        return (
            <Container title="Incident bewerken">
                { isLoading ? <Loading/> :
                    <Row>
                        <Col sm={12} xs={12}>
                            <Formsy ref={(frm)=> {
                                this.frm = frm;
                            }} className="form-horizontal" data-toggle="validator" onValidSubmit={(model) => {

                                if (model[ IncidentDetailUpdateWorkingModel.Afgesloten ] === 'true') {
                                    confirmClose(params[ RouteParams.Incident.Bewerken.Id ]);
                                }
                                else {
                                    updateIncident(params[ RouteParams.Incident.Bewerken.Id ]);
                                }
                            }}>

                                <AlgemeneGegevens {...rest}/>
                                <Analyse {...rest}/>
                                <Melding {...rest}/>

                                <div className="pull-down">
                                    <div className="text-right">
                                        <Link to={Routes.Dashboard.path}>annuleren</Link>
                                        <span className="v-spacer"/>
                                        <Button type="submit" bsStyle="primary"><FaIcon name="floppy-o" /> Opslaan</Button>
                                    </div>
                                </div>
                            </Formsy>

                            <DocumentLink {...rest}/>
                            <Rapport {...rest}/>
                        </Col>
                    </Row>
                }
            </Container>
        );
        // Looking for the buttons? They are in the middle because of the use of 2 forms... search for pull-down
    }
}

IncidentBewerken.propTypes = {
    clear: PropTypes.func.isRequired,
    confirmClose: PropTypes.func.isRequired,
    getIncident: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    params: PropTypes.object,
    updateIncident: PropTypes.func.isRequired
};

export default IncidentBewerken;