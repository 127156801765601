export const NAME = 'PrivacyCheck';

export const STATE_KEY = `${NAME}/b26eea96-d8f3-44cd-bf72-4c3ed7db7c68`;

export const FIELDS = {
    LOADING : 'loading',
    QUESTION: 'question',
    ANSWER: 'answer',
    SCORE: 'score',
    OUTCOME: 'outcome',
};

export const OUTCOME = {
    NEED_PIA: 'needPia',
    DONT_NEED_PIA: 'dontNeedPia'
};