import * as Helpers from './helpers';
import * as Routes from './constants.routes';

export const activateAccount = (model) => {
    return Helpers.put(Routes.AccountActiveren, model, {
        suppressErrors: true,
        onValidationError: {
            showModal: true,
            modalTitle: 'Account activeren niet gelukt'
        }
    });
};
