import { put, takeEvery, call } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import createLogger from '../util/log';

import { Routes } from './constants.routes';
import * as ActionTypes from './actionTypes';
import * as Actions from '../modules/shared/error/actions';
import { Builder } from '../structure/dialog/dialogBuilder';
import * as Constants from './constants.configuration';
import resolve from '../resources';
import {
    AuthenticationActions
} from './_references.js';

// TODO: dit moet een andere constante worden
const logger = createLogger(Constants.NAME);

export default function* handleApiErrorSaga() {
    yield takeEvery(ActionTypes.API_CALL_FAILED, handleApiError);
}

export function* handleApiError(action) {

    switch(action.status) {
    case 401:
    {
        logger.info('Handling 401');
        if (!action || !action.error || !action.error.config || !action.error.config.suppressErrors) {
            // need to log in

            const defaultDialog = Builder.ok({
                title: 'Geen toegang',
                body: 'U moet eerst ingelogd zijn voordat u deze pagina kunt bezoeken.',
                onOk: AuthenticationActions.logout()
            }).prepare();

            yield call(defaultDialog);
        }
        break;
    }
    case 403:
    {
        logger.info('Handling 403');
        // not allowed
        yield put (push(Routes.Home.path));

        const defaultDialog = Builder.ok({
            title: 'Geen toegang',
            body: 'U heeft niet voldoende rechten om deze pagina te bezoeken.',
        }).prepare();

        yield call(defaultDialog);
        break;
    }
    case 422:

        logger.info('Handling 422');
        try {
            // do nothing, the call failed due to a validation error. This should be handled by the component calling the api.
            if ((action.error.config.onValidationError || {}).showModal) {

                const items = [];
                for (var property in action.error.response.data) {
                    if (action.error.response.data.hasOwnProperty(property) && (Array.isArray(action.error.response.data[ property ])) ) {
                        action.error.response.data[ property ].forEach((item) => {
                            const name = item.propertyName;
                            const validation = resolve(item.errorCode, name, item.parameter || '');
                            items.push(validation);
                        });
                    }
                }

                if (items.length !== 0) {

                    const defaultDialog = Builder.ok({
                        title: action.error.config.onValidationError.modalTitle || 'Het opslaan is mislukt',
                        body: 'De volgende validatiemeldingen zijn opgetreden:',
                        items: items
                    }).prepare();

                    yield call(defaultDialog);
                }
                else {

                    const defaultDialog = Builder.ok({
                        title: action.error.config.onValidationError.modalTitle || 'Het opslaan is mislukt',
                        body: 'Het was op dit moment helaas niet mogelijk om de gegevens op te slaan. Probeer het later nogmaals.',
                    }).prepare();

                    yield call(defaultDialog);
                }
            }
            else {
                logger.info('422 error was not configured');
            }
        }
        catch(e) {
            logger.error('Cannot handle 422 error:', e);
        }

        break;
    default:
        logger.info('Handling other');
        if (!action || !action.error || !action.error.config || !action.error.config.suppressErrors) {
            if (!action || !action.error || !action.error.config || !action.error.config.allowErrors || !action.error.config.allowErrors.some(ae => (ae === action.status))) {
                if (action.error.config.showModal) {

                    const defaultDialog = Builder.ok({
                        title: 'Communicatieprobleem',
                        body: 'Het was niet mogelijk om met de server te communiceren. Probeer het op een later moment nogmaals.',
                    }).prepare();

                    yield call(defaultDialog);

                }
                else {
                    logger.info('Rerouting to error page');
                    yield put (push(Routes.Error.path));
                    yield put (Actions.setErrorInformation(
                        'Communicatieprobleem',
                        'Het was niet mogelijk om met de server te communiceren. Probeer het op een later moment nogmaals.'
                    ));
                }
            }
        // ignore the error because it was expected
        }
        break;
    }
}