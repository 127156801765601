import React from 'react';
import PropTypes from 'prop-types';
import { Panel, Row, Col } from 'react-bootstrap';
import Formsy from 'formsy-react';
import QRCode from 'qrcode.react';

import {
    AccountBeveiligenWorkingModel,
    FieldSetup,
    Button,
    Text
} from './_references';

class AddSecurity extends React.Component{

    constructor(props) {
        super(props);
        this.form = React.createRef();
    }

    componentWillUnmount(){
        this.props.clear();
    }

    render() {
        const { bind, onChange, confirm, clear } = this.props;

        return (<Formsy className="form-horizontal" data-toggle="validator" ref={this.form} onValidSubmit={(model)=>{
            confirm(model, this.form.current);
        }}>
            <Panel>
                <Panel.Body>
                    <div className="panel-inline-title">Account beveiliging instellen</div>

                    <div>U kunt hier nu extra beveiliging instellen door gebruik te maken van bijvoorbeeld de Google Authenticator app.</div>
                    <div>Nadat de beveiliging goed is ingesteld, wordt u automatisch uitgelogd en vragen wij u om opnieuw in te loggen met de extra beveiliging.</div>
                    <p>Scan de QR code en vul hier de code van bijvoorbeeld de Google Authenticator app in en klik vervolgens op de knop &apos;Instellen&apos;.</p>

                    <Row >
                        <Col xs={2}>
                            <div>
                                <QRCode value={ decodeURI(bind(AccountBeveiligenWorkingModel.BarcodeUrl).value)}/>
                            </div>
                        </Col>

                        <Col xs={4}>
                            <Text autoComplete="off"
                                {...bind(AccountBeveiligenWorkingModel.Token)}
                                {...new FieldSetup()
                                    .for(AccountBeveiligenWorkingModel.Token)
                                    .withFriendlyName('Code')
                                    .setAutoFocus()
                                    .isRequired()
                                    .minLength(6)
                                    .maxLength(6)
                                    .isNumeric()
                                    .onChange(onChange)
                                }
                            />
                        </Col>
                    </Row>
                </Panel.Body>
            </Panel>
            <Row>
                <Col xs={12} className="text-right">
                    <Button onClick={clear} className="btn btn-link"> Annuleren</Button>
                    <span className="v-spacer"/>
                    <Button bsStyle="primary" type="submit"> Instellen</Button>
                </Col>
            </Row>
        </Formsy>
        );
    }
}

AddSecurity.propTypes = {
    bind: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    confirm: PropTypes.func.isRequired,
    clear: PropTypes.func.isRequired,
};

export default AddSecurity;