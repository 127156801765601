import React from 'react';
import Feedback from '../../feedback';

const HomepageContainer = ({ children }) => (
    <div className='homepage-container'>
        <Feedback />

        {children}
    </div>
);

export default HomepageContainer;