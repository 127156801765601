import {
    stateHelper,
    binder,
    Constants,
    Actions,
    onChange
} from './_references';

export const mapStateToProps = (state) => ({
    list: stateHelper(state, [ Constants.STATE_KEY, Constants.FIELDS.LIST ], []),
    editorObject: stateHelper(state,[ Constants.STATE_KEY, Constants.FIELDS.EDITOR ], {}),
    bind: (field) => binder(state, [ Constants.STATE_KEY, Constants.FIELDS.EDITOR ])(field),
    isLoading: stateHelper(state,[ Constants.STATE_KEY, Constants.FIELDS.LOADING ], false)
});

export const mapDispatchToProps = (dispatch) => ({
    onChange : onChange([ Constants.STATE_KEY, Constants.FIELDS.EDITOR ], dispatch),
    loadVerwerkeren: () => dispatch(Actions.getVerwerkeren()),
    showDetails: (id) => dispatch(Actions.getVerwerker(id, Constants.SOURCES.LIST)),
    hideDetails: (id) => dispatch(Actions.hideVerwerker(id)),
    editVerwerker: (id) => dispatch(Actions.editVerwerker(id, Constants.SOURCES.EDITOR)),
    cancelEdit: () => dispatch(Actions.cancelEdit()),
    deleteVerwerker: (id) => dispatch(Actions.deleteVerwerker(id)),
    updateVerwerker: () => dispatch(Actions.updateVerwerker())
});