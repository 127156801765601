import { toFieldsArray } from './fieldNameHelper';

/**
 * Helper function for setting up a state object.
 *
 * @param {array} keys - The state keys for the entry.
 * @param {any} value - The value for the entry
 * @param {object} state - Optional previous state that will be merged with the new key.
 */
export default function setStateValue (keys, value, state = {}) {
    const keysToUse = toFieldsArray(keys);

    const newState = Object.assign({}, state);

    const key = keysToUse.shift();

    if (keysToUse.length === 0) {
        newState[ key ] = value;
    }
    else {
        newState[ key ] = setStateValue(keysToUse, value, newState[ key ]);
    }

    return newState;
}

/**
 * Helper function for setting up a state object based on an array of keys. The final entry in each array will be used as value.
 *
 * @param {args} newKeys - An N amount of parameters in the form [ stateKey, stateKey2, ... , stateKeyN, value ].
 */
export const setStateValues = (...newKeys) => {
    if(newKeys == null || !Array.isArray(newKeys)) {
        throw Error(`setStateValues: expected newKeys to be an array but instead received ${newKeys}`);
    }

    let returnState;

    newKeys.forEach((array, index) => {

        if(array == null || !Array.isArray(array)) {
            throw Error(`setStateValues: expected newKeys[${index}] to be an array but instead received ${newKeys[ index ]}`);
        }

        if(array.length < 2) {
            throw Error(`setStateValues: expected newKeys[${index}] to have at least 2 entries in the form [ stateKey, stateKey2, ... , stateKeyN, value ]`);
        }

        const keys = array.slice(0, array.length-1);
        const value = array[ array.length-1 ];

        returnState = setStateValue(keys, value, returnState);
    });

    return returnState;
};