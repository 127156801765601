import merge from 'deepmerge';

const combine = (validations) => {
    const validationArray = Array.isArray(validations) ? validations : [ validations ];

    if (validationArray.length > 1) {
        return merge(validationArray[ 0 ], combine(validationArray.slice(1)));
    }

    return validationArray[ 0 ];
};

export default combine;