import { all, call, put, takeLatest, delay } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { actions as ToastrActions } from 'react-redux-toastr';

import {
    Api,
    AccountKoppelenDetailWorkingModel,
    Routes
} from './_references';

import * as ActionTypes from './actionTypes';
import * as Actions from './actions';

export default function* () {
    yield all([
        takeLatest(ActionTypes.LOAD, load),
        takeLatest(ActionTypes.KOPPEL, link)
    ]);
}

function* load() {
    yield delay(250);

    const response = yield call(Api.availableAccounts);

    yield put (Actions.loaded(response.data));
}

function* link(action) {
    yield delay(250);

    try {
        const result = yield call(Api.linkAccount, {
            [ AccountKoppelenDetailWorkingModel.AccountId ] : action.accountId
        });

        if (!result.success) {
            yield put (ToastrActions.add({
                type: 'error',
                title: 'Account koppelen',
                attention: true, // This will add a shadow like the confirm method.
                message: 'Het koppelen van het account is mislukt.',
                options: {
                    showCloseButton: true
                }
            }));
        }
        else {
            yield put(push(Routes.Account.Select.path));
        }
    }
    catch(e) {
        yield put (ToastrActions.add({
            type: 'error',
            title: 'Account koppelen',
            attention: true, // This will add a shadow like the confirm method.
            message: e.message,
            options: {
                showCloseButton: true
            }
        }));
    }
}
