import React from 'react';
import 'react-activity-indicator/src/activityindicator.css';
import ActivityIndicator from 'react-activity-indicator';

const Loading = () => (
    <ActivityIndicator
        number={3}
        diameter={20}
        duration={150}
        activeColor="#009640"
        borderColor="#009640"
        borderWidth={2}
        borderRadius="15%"
    />
);

export default Loading;