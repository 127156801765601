const maxLengthValidation = (propertyName, max) => {
    return {
        validators: {
            maxLength: max,
        },
        validationErrors: {
            maxLength: `${ propertyName } mag maar ${max} tekens lang zijn.`,
        },
        validationError: `${ propertyName } mag maar ${max} tekens lang zijn.`,
    };
};

export default maxLengthValidation;