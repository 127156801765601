import { all, takeEvery, call, put, select, takeLatest, delay } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { actions as ToastrActions } from 'react-redux-toastr';

import {
    Builder,
    FIELD_CHANGED,
    PiaAdvisoryWorkingModel,
    PiaDetailUpdateWorkingModel,
    PiaDetailWorkingModel,
    PiaVraagAntwoordWorkingModel,
    PiaVraagNotitieWorkingModel,
    PiaWorkingModel,
    PrivacyImpactAssessmentApi,
    Routes,
    stateHelper
} from './_references';

import * as Actions from './actions';
import * as ActionTypes from './actionTypes';
import * as Constants from './constants';

export default function* saga() {
    yield all([
        takeEvery(ActionTypes.LOAD_PIA_ADVISORY, loadPiaAdvisory),
        takeLatest(ActionTypes.LOAD_PIA_LIST, loadPiaList),
        takeLatest(ActionTypes.LOAD_PIA, loadPia),
        takeEvery(FIELD_CHANGED, analysePia),
        takeEvery(ActionTypes.UPDATE_PIA, updatePia),
        takeLatest(ActionTypes.CREATE_NEW, createNew),
        takeLatest(ActionTypes.COPY_EXISTING, copyExisting),
        takeLatest(ActionTypes.CLOSE_PIA, closePia)
    ]);
}

function convertToAnswersObject(answersArray = []) {
    const answers = {};

    answersArray.forEach(answer => {
        answers[ answer[ PiaVraagAntwoordWorkingModel.VraagId ] ] = answer[ PiaVraagAntwoordWorkingModel.AntwoordId ];
    });

    return answers;
}

function convertToNotesObject(answersArray = []) {
    const answers = {};

    answersArray.forEach(answer => {
        answers[ answer[ PiaVraagNotitieWorkingModel.VraagId ] ] = answer[ PiaVraagNotitieWorkingModel.Notitie ];
    });

    return answers;
}

function convertToAnswersArray(answersObject = {}) {
    const answers =
         Object.keys(answersObject).map(k => ({
             [ PiaVraagAntwoordWorkingModel.VraagId ]: k,
             [ PiaVraagAntwoordWorkingModel.AntwoordId ]: answersObject[ k ]
         }))
    ;
    return answers;
}

function convertToNotesArray(answersObject = {}) {
    const answers =
         Object.keys(answersObject).map(k => ({
             [ PiaVraagNotitieWorkingModel.VraagId ]: k,
             [ PiaVraagNotitieWorkingModel.Notitie ]: answersObject[ k ]
         }))
    ;
    return answers;
}

function* loadPiaAdvisory() {
    const result = yield call(PrivacyImpactAssessmentApi.getAdvisory);
    if (result.success) {

        if (result.data[ PiaAdvisoryWorkingModel.CanCopyExisting ] === false && result.data[ PiaAdvisoryWorkingModel.CanStartNew ] === true) {

            const newPia = yield call(PrivacyImpactAssessmentApi.createNew, {
                [ PiaDetailWorkingModel.VragenlijstId ] : result.data[ PiaAdvisoryWorkingModel.MostRecentVragenlijstId ]
            });

            if (newPia.success) {
                // if the initial record has been created we will only receive the Guid
                yield put (push(Routes.Dashboard.Pia.Bewerken.createUrl(newPia.data)));
            }

        }
        else {
            // the user needs to take action (either create a new one or copy an existing one)
            yield put (Actions.loadedAdvisory(result.data));
        }
    }

}

function* createNew() {
    const vragenLijstId = yield select(
        state => ( stateHelper(state, [ Constants.STATE_KEY, Constants.FIELDS.Advisory, PiaAdvisoryWorkingModel.MostRecentVragenlijstId ] ) )
    );

    if (vragenLijstId != null) {
        const newPia = yield call(PrivacyImpactAssessmentApi.createNew, {
            [ PiaDetailWorkingModel.VragenlijstId ] : vragenLijstId
        });

        if (newPia.success) {
            // if the initial record has been created we will only receive the Guid
            yield put (push(Routes.Dashboard.Pia.Bewerken.createUrl(newPia.data)));
        }
    }
    // TODO: Geen vragenlijst, wat nu?
}

function* copyExisting() {
    // TODO: Dit moet naar de back end
    const piaId = yield select(
        state => ( stateHelper(state, [ Constants.STATE_KEY, Constants.FIELDS.Advisory, PiaAdvisoryWorkingModel.MostRecentPiaId ] ) )
    );

    if (piaId != null) {
        const result = yield call(PrivacyImpactAssessmentApi.getPrivacyImpactAssessment, piaId);

        if (result.success && result.data != null) {

            const newPia = yield call(PrivacyImpactAssessmentApi.createNew, {
                [ PiaDetailWorkingModel.VragenlijstId ] : result.data[ PiaWorkingModel.VragenlijstId ],
                [ PiaDetailWorkingModel.Antwoorden ]: result.data[ PiaWorkingModel.Antwoorden ],
                [ PiaDetailWorkingModel.Notities ] : result.data[ PiaWorkingModel.Notities ]
            });

            if (newPia.success) {
                // if the initial record has been created we will only receive the Guid
                yield put (push(Routes.Dashboard.Pia.Bewerken.createUrl(newPia.data)));
            }
            else {
                // TODO
            }
        }
        else {
            // TODO:
        }
    }
    // TODO: Geen vragenlijst, wat nu?
}

function* loadPiaList() {
    yield delay(250);

    const result = yield call(PrivacyImpactAssessmentApi.getPrivacyImpactAssessments);

    if (result.success) {
        yield put (Actions.loadedList(result.data));
    }
}

function *loadPia(action) {
    yield delay(250);

    const result = yield call(PrivacyImpactAssessmentApi.getPrivacyImpactAssessment, action.id);

    if (result.success) {

        console.log('pia', action, result.data);

        if (action.readonly !== true && result.data[ PiaWorkingModel.Afgesloten ] === true) {

            const defaultDialog = Builder.ok({
                title: 'PIA afgesloten',
                body: 'Deze PIA is afgesloten, u kunt deze niet langer meer bewerken.',
            }).prepare();

            yield call(defaultDialog);

            yield put (push(Routes.Dashboard.path));
        }
        else {

            const answersArray = stateHelper(result.data, [ PiaWorkingModel.Antwoorden ], []);
            const notesArray = stateHelper(result.data, [ PiaWorkingModel.Notities ], []);

            const data = Object.assign({}, result.data);

            data[ PiaWorkingModel.Antwoorden ] = convertToAnswersObject(answersArray);
            data[ PiaWorkingModel.Notities ] = convertToNotesObject(notesArray);

            yield put (Actions.loadedPia(data));
        }
    }
}

function *analysePia(action) {

    if (action[ Constants.ACTION_OPTIONS.PiaAnalysis ] === Constants.ACTION_OPTIONS.PiaAnalysis) {

        var piaAnalyse = yield select((state) => (stateHelper( state, [ Constants.STATE_KEY, Constants.FIELDS.Pia ], {})));
        var gegevenAntwoorden = stateHelper(piaAnalyse, PiaWorkingModel.Antwoorden);
        //var notities = stateHelper(piaAnalyse, PiaWorkingModel.Notities);

        // convert from object back to array
        const answers = convertToAnswersArray(gegevenAntwoorden);
        //const notes = convertToNoteArray(notities);

        const payload = Object.assign({}, piaAnalyse, {
            [ PiaWorkingModel.Antwoorden ] : answers,
            //  [ PiaWorkingModel.Notities ] : notes
        } );

        try {
            const result = yield call(PrivacyImpactAssessmentApi.analyse, payload);

            const answersArray = stateHelper(result.data, [ PiaWorkingModel.Antwoorden ], []);

            const data = Object.assign({}, result.data);

            data[ PiaWorkingModel.Antwoorden ] = convertToAnswersObject(answersArray);

            yield put (Actions.intermediateAnalysisComplete(data));
        }
        catch (e) {
            // TODO:
        }
    }

}

function* updatePia(action) {
    try {

        const pia = yield select((state) => (stateHelper( state, [ Constants.STATE_KEY, Constants.FIELDS.Pia ], {})));

        const gegevenAntwoorden = stateHelper(pia, PiaWorkingModel.Antwoorden);
        const gegevenAntwoordenArray = convertToAnswersArray(gegevenAntwoorden);

        const gegevenNotities = stateHelper(pia, PiaWorkingModel.Notities);
        const gegevenNotitiesArray = convertToNotesArray(gegevenNotities);

        const putData = Object.assign({} , pia, {
            [ PiaDetailUpdateWorkingModel.Id ] : action.id,
            [ PiaDetailUpdateWorkingModel.Antwoorden ] : gegevenAntwoordenArray,
            [ PiaDetailUpdateWorkingModel.Notities ] : gegevenNotitiesArray.filter(n => gegevenAntwoordenArray.some(v => v[ PiaVraagAntwoordWorkingModel.VraagId ] === n[ PiaVraagNotitieWorkingModel.VraagId ]) ),
            [ PiaDetailUpdateWorkingModel.Afgesloten ] : stateHelper(pia, PiaDetailUpdateWorkingModel.Afgesloten )
        });

        const result = yield call (PrivacyImpactAssessmentApi.updatePia, putData);

        if (result.success) {
            yield put(ToastrActions.add({
                type: 'success',
                title: 'Opslaan gelukt',
                attention: true, // This will add a shadow like the confirm method.
                message: 'Privacy Impact Assessment is opgeslagen.',
                options: {
                    showCloseButton: true
                }
            }));
            yield put (push(Routes.Dashboard.path));
        }
        else {
            yield put(ToastrActions.add({
                type: 'error',
                title: 'Opslaan mislukt',
                attention: true, // This will add a shadow like the confirm method.
                message: 'Privacy Impact Assessment kon niet worden opgeslagen.',
                options: {
                    showCloseButton: true
                }
            }));
        }

    }
    catch (e) {
        yield put(ToastrActions.add({
            type: 'error',
            title: 'Opslaan mislukt',
            attention: true, // This will add a shadow like the confirm method.
            message: 'Privacy Impact Assessment kon niet worden opgeslagen.',
            options: {
                showCloseButton: true
            }
        }));
    }
}

function* closePia (action) {

    yield call (action.form.submit);

}