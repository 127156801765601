import * as ActionTypes from './actionTypes';

export const clear = () => ({
    type: ActionTypes.CLEAR
});

export const requestPassword = (model, form) => ({
    type: ActionTypes.REQUEST_PASSWORD,
    model,
    form
});