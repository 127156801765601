import React from 'react';
import PropTypes from 'prop-types';
import  { withFormsy } from 'formsy-react';

import DefaultInputComponent from '../text';
import DefaultValidationComponent from '../validation';
import DefaultWrapperComponent from '../validationWrapper';

const createFormsyInputComponent = ({
    LabelComponent = null,
    InputComponent = DefaultInputComponent,
    ValidationComponent = DefaultValidationComponent,
    Wrapper = DefaultWrapperComponent
} = {}) => {
    class WrappedComponent extends React.Component {

        constructor(props) {
            super(props);
            this.changeValue = this.changeValue.bind(this);
        }

        changeValue(event, value) {
            event.persist();

            // setValue() will set the value of the component, which in
            // turn will validate it and the rest of the form
            // Important: Don't skip this step. This pattern is required
            // for Formsy to work.
            this.props.setValue(value != null ? value : event.currentTarget.value);

            if (this.props.onChange) {
                this.props.onChange(event.target, this.props);
            }
        }

        render() {
            const { isPristine, showRequired, showError, validators, required, setValidations, validationErrors, wrapperClassName, ...props } = this.props;

            // add a fallback to make sure we always have a isDefaultRequiredValue error string
            if (validationErrors[ 'required' ] && !validationErrors[ 'isDefaultRequiredValue' ]) {
                validationErrors[ 'isDefaultRequiredValue' ] = validationErrors[ 'required' ];
            }

            // we set some custom validations (if any) // TODO: -> naar ctor?
            setValidations(validators, required);

            // show validations only when there are errors, of the field is required and you tried to post it
            const showValidation = (showRequired() || showError());

            // we only show errors when the field is no longer prestine, otherwise you will always see an error when the
            // forms is shown for the first time
            const hasValidationErrors = !isPristine() && showValidation;

            const isValid = !showValidation;
            const isInValid = !isValid;

            const sharedProps = {
                isPristine: isPristine(),
                isValid,
                isInValid,
                hasValidationErrors
            };

            const inputProps = {
                name: props.name,
                onChange: this.changeValue,
                ...sharedProps
            };

            const validationProps = {
                errorMessage: this.props.getErrorMessage(),
                ...sharedProps
            };

            return (
                <Wrapper {...props} {...sharedProps} className={wrapperClassName || ''}
                    InputComponent={<InputComponent {...props} {...inputProps} />}
                    ValidationComponent={hasValidationErrors ? <ValidationComponent {...props} {...validationProps} /> : null }
                    LabelComponent={LabelComponent ? <LabelComponent {...props} /> : null}
                />
            );
        }
    }

    WrappedComponent.propTypes = {
        onChange: PropTypes.func.isRequired
    };

    return withFormsy(WrappedComponent);
};

export default createFormsyInputComponent;