import React from 'react';
import PropTypes from 'prop-types';

class ShouldUpdateComponent extends React.Component {

    shouldComponentUpdate(nextProps) {

        const propsToMatch = nextProps.matchOn || [];

        const result = propsToMatch.length === 0 || propsToMatch.some(p => JSON.stringify(this.props[ p ]) !== JSON.stringify(nextProps[ p ]));

        return result;

    }

    render() {
        const { children } = this.props;

        return (children);
    }
}

ShouldUpdateComponent.propTypes = {
    matchOn: PropTypes.array,
    children: PropTypes.any
};

export default ShouldUpdateComponent;