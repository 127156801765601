import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import { FormGroup, Col, Row } from 'react-bootstrap';

import Button from '../../universe/molecules/button';

import requiredValidation from '../../validation/required';
import isNumericValidation from '../../validation/isNumeric';
import maxLengthValidation from '../../validation/maxLength';
import combine from '../../validation/combine';

import { LoginInputs } from '../../universe/molecules/createFormsyInputComponent/prefab';

const Verification = ({ handleLogin, bind, onChange, back }) => {

    return (
        <Formsy className="form-horizontal" data-toggle="validator" onValidSubmit={(model) => {
            model.email = bind('email').value;
            model.password = bind('password').value;
            handleLogin(model);
        }} >
            <LoginInputs.Text {...bind('key')} autoComplete="off" autoFocus onChange={onChange} label="Code" type="text" placeholder="Code" {...combine([ requiredValidation('Code'), isNumericValidation('Code'), maxLengthValidation('Code,', 6) ])}/>

            <FormGroup>
                <Col xsHidden sm={12} className="text-right">
                    <Button className="btn btn-link" onClick={back}>Terug</Button>
                    <span className="v-spacer"/>
                    <Button type="submit" bsStyle="primary" default>Verifieren</Button>
                </Col>
                <Col smHidden mdHidden lgHidden xs={12} >
                    <Button className="btn btn-link" onClick={back}>Terug</Button>
                    <span className="v-spacer"/>
                    <Button type="submit" bsStyle="primary" block default>Verifieren</Button>
                </Col>
            </FormGroup>
            <Row>
                <Col sm={12} className="center one-em-margin-bottom">
                            Vul hier de code uit de app
                </Col>
            </Row>
        </Formsy>
    );
};

Verification.propTypes = {
    handleLogin: PropTypes.func.isRequired,
    bind: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    back: PropTypes.func.isRequired
};

export default Verification;