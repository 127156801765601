import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

class CustomDialog extends React.Component {

    render() {

        const { show, title, body, bodyItems, buttons, onHide } = this.props;

        return (
            <Modal show={show} onHide={onHide} role="dialog" aria-labelledby="modalTitle" aria-describedby="modalBody">
                <Modal.Header>
                    <Modal.Title id="modalTitle">{title}</Modal.Title>
                </Modal.Header>

                <Modal.Body id="modalBody">
                    {body}
                    {bodyItems && bodyItems.length > 0 ?
                        <ul id="modalbodyItems">
                            {bodyItems.map((item, index) => {
                                return (<li id={`modal_${index}`} key={`modal_${index}`}>{item}</li>);
                            })}
                        </ul>
                        : null }
                </Modal.Body>

                {buttons && buttons.length > 0 ?
                    <Modal.Footer>
                        {buttons && buttons.map((Button,index)=>{
                            return (<span key={index}>{Button}</span>);
                        })}
                    </Modal.Footer>
                    : null }
            </Modal>
        );
    }
}

CustomDialog.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
    body: PropTypes.oneOfType([ PropTypes.string, PropTypes.node, PropTypes.array ]),
    bodyItems: PropTypes.oneOfType([ PropTypes.string, PropTypes.node, PropTypes.array ]),
    buttons: PropTypes.array,
    onHide: PropTypes.func
};

export default CustomDialog;