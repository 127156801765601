import { FIELDS, STATE_KEY } from './constants';
import * as ActionTypes from './actionTypes';

const reducer = (state = {}, action = {}) => {
    switch (action.type) {
    case ActionTypes.LOADING_QUESTION: {
        const newState = Object.assign({}, state, {
            [ FIELDS.LOADING ] : action[ FIELDS.LOADING ],
            [ FIELDS.OUTCOME ] : undefined
        });
        return newState;
    }
    case ActionTypes.QUESTION_LOADED: {
        const newState = Object.assign({}, state, {
            [ FIELDS.QUESTION ] : action[ FIELDS.QUESTION ]
        });
        return newState;
    }
    case ActionTypes.SET_SCORE: {
        const newState = Object.assign({}, state, {
            [ FIELDS.SCORE ] : action[ FIELDS.SCORE ]
        });
        return newState;
    }
    case ActionTypes.FINISHED: {
        const newState = Object.assign({}, state, {
            [ FIELDS.QUESTION ] : undefined,
            [ FIELDS.OUTCOME ] : action[ FIELDS.OUTCOME ]
        });
        return newState;
    }
    default: {
        return state;
    }
    }
};

reducer.STATE_KEY = STATE_KEY;

export default reducer;