import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import { Panel, Col, Row, HelpBlock } from 'react-bootstrap';
import FaIcon from 'react-fontawesome';
import Md from 'react-markdown-renderer';
import { Link } from 'react-router';

import {
    Button,
    CheckBox,
    Container,
    FieldSetup,
    Loading,
    PiaAntwoordWorkingModel,
    PiaConclusieWorkingModel,
    PiaDetailUpdateWorkingModel,
    PiaVraagCategorieWorkingModel,
    PiaVraagWorkingModel,
    PiaWorkingModel,
    RouteParams,
    Routes,
    TextArea,
    YesNoRadioButton
} from './_references';

import Note from './collapsablePanel';
import ShouldUpdateComponent from './shouldUpdateComponent';

class PiaBewerken extends React.Component {

    componentDidMount() {
        this.props.load(this.props.params[ RouteParams.Pia.Bewerken.Id ]);
    }

    render() {

        const { categorien, vragen, isLoading, update, bind, bindNotitie, onChange, onChangeNotitie, onToggleClose, params, model } = this.props;

        return (
            <Container title="Privacy Impact Assessment bewerken">
                {isLoading ? <Loading /> :
                    <Row>
                        <Col sm={12} xs={12}>
                            <Formsy ref={(frm)=> {
                                this.frm = frm;
                            }} className="form-horizontal" data-toggle="validator" onValidSubmit={() => {

                                update(params[ RouteParams.Pia.Bewerken.Id ]);

                            }}>

                                {categorien.filter(c => vragen
                                // filter out any category that isn't used (yet)
                                    .find(v => v[ PiaVraagWorkingModel.Categorie ] === c[ PiaVraagCategorieWorkingModel.Naam ]))
                                    .map(c => (

                                        <Panel key={c[ PiaVraagCategorieWorkingModel.Id ]}>
                                            <Panel.Body>
                                                <div className="panel-inline-title">{c[ PiaVraagCategorieWorkingModel.Naam ]}</div>

                                                {vragen
                                                // show only questions within the current category
                                                    .filter(v => v[ PiaVraagWorkingModel.Categorie ] === c[ PiaVraagCategorieWorkingModel.Naam ])
                                                    .map(v =>
                                                        v[ PiaVraagWorkingModel.Conclusie ] == null ?
                                                            (
                                                                <Panel className="question" key={`panel-${v[ PiaVraagWorkingModel.VraagId ]}`}>
                                                                    <Panel.Body>

                                                                        <span>{v[ PiaVraagWorkingModel.Vraag ]}{ process.env.NODE_ENV === 'development' ? ` (${v[ PiaVraagWorkingModel.VraagCode ]})` : null}</span>
                                                                        <ShouldUpdateComponent
                                                                            key={v[ PiaVraagWorkingModel.VraagId ]}
                                                                            matchOn={[ 'value' ]}
                                                                            value={model[ PiaWorkingModel.Antwoorden ][ v[ PiaVraagWorkingModel.VraagId ] ]}
                                                                        >

                                                                            <YesNoRadioButton noOffset
                                                                                {...bind(v[ PiaVraagWorkingModel.VraagId ])}
                                                                                {...new FieldSetup()
                                                                                    .for(v[ PiaVraagWorkingModel.VraagId ])
                                                                                    .onChange(onChange)
                                                                                }
                                                                                options={ v[ PiaVraagWorkingModel.Antwoorden ] }
                                                                                optionKey={PiaAntwoordWorkingModel.AntwoordId}
                                                                                optionValue={PiaAntwoordWorkingModel.Antwoord}
                                                                            />

                                                                            {
                                                                                v[ PiaVraagWorkingModel.Toelichting ] == null ? null :
                                                                                    <HelpBlock>
                                                                                        <Md options={{
                                                                                            preset: 'default', html: true
                                                                                        }} markdown={v[ PiaVraagWorkingModel.Toelichting ]}/>
                                                                                    </HelpBlock>
                                                                            }

                                                                        </ShouldUpdateComponent>
                                                                        <ShouldUpdateComponent
                                                                            key={`${v[ PiaVraagWorkingModel.VraagId ]}-note`}
                                                                            matchOn={[ 'value' ]}
                                                                            value={model[ PiaWorkingModel.Notities ][ v[ PiaVraagWorkingModel.VraagId ] ]}
                                                                        >
                                                                            <Note isOpen={bindNotitie(v[ PiaVraagWorkingModel.VraagId ]).value != null && bindNotitie(v[ PiaVraagWorkingModel.VraagId ]).value !== ''}>
                                                                                <TextArea noOffset
                                                                                    {...bindNotitie(v[ PiaVraagWorkingModel.VraagId ])}
                                                                                    {...new FieldSetup()
                                                                                        .for(v[ PiaVraagWorkingModel.VraagId ])
                                                                                        .onChange(onChangeNotitie)
                                                                                    }
                                                                                />
                                                                            </Note>
                                                                        </ShouldUpdateComponent>

                                                                    </Panel.Body>
                                                                </Panel>
                                                            )
                                                            : (

                                                                <div key={v[ PiaVraagWorkingModel.VraagId ]} className={`bs-callout bs-callout-${v[ PiaVraagWorkingModel.Conclusie ][ PiaConclusieWorkingModel.KleurCode ]}`}>
                                                                    <Md options={{
                                                                        preset: 'default', html: true
                                                                    }} markdown={v[ PiaVraagWorkingModel.Conclusie ][ PiaConclusieWorkingModel.Tekst ]}/>
                                                                </div>

                                                            )
                                                    )
                                                }
                                            </Panel.Body>

                                        </Panel>

                                    ))}
                                <Panel>
                                    <Panel.Body>

                                        <Col smOffset={0} className="bs-callout bs-callout-default">
                                            <h3><small>Privacy Impact Assessment afsluiten</small></h3>
                                            <p>Zodra u klaar bent met het uitvoeren van de PIA, dan kunt u deze afsluiten. Na het afsluiten kan de PIA niet meer worden bewerkt, wel kunt u op basis van de laatste PIA een nieuwe PIA starten.</p>

                                            <CheckBox noOffset
                                                {...bind(PiaDetailUpdateWorkingModel.Afgesloten)}
                                                {...new FieldSetup()
                                                    .for(PiaDetailUpdateWorkingModel.Afgesloten)
                                                    .withFriendlyName('Sluit de PIA af')
                                                    .setLabel('Sluit de PIA af.')
                                                    .onChange(onToggleClose)
                                                }
                                            />

                                        </Col>

                                    </Panel.Body>
                                </Panel>

                                <div className="pull-down">
                                    <div className="text-right">
                                        <Link to={Routes.Dashboard.path}>annuleren</Link>
                                        <span className="v-spacer"/>
                                        <Button type="submit" bsStyle="primary"><FaIcon name="floppy-o" /> Opslaan</Button>
                                    </div>

                                </div>
                            </Formsy>
                        </Col>
                    </Row>
                }
            </Container>
        );
    }
}

PiaBewerken.propTypes = {
    load: PropTypes.func.isRequired,
    categorien: PropTypes.array,
    vragen: PropTypes.array,
    isLoading: PropTypes.bool,
    update: PropTypes.func.isRequired,
    bind: PropTypes.func.isRequired,
    bindNotitie: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onChangeNotitie: PropTypes.func.isRequired,
    onToggleClose: PropTypes.func.isRequired,
    params: PropTypes.array,
    model: PropTypes.object
};

export default PiaBewerken;