import ValidationErrors from './validationErrors';

const resolve = (code, ...params) => {
    let result = ValidationErrors[ code ] || `Onbekende validatiefout. (${code})`;

    params.forEach((p,index) => {
        result = result.replace(new RegExp(`\\{${index}\\}`, 'g'), p);
    });

    return result;
};

export default resolve;