import React from 'react';
import { PageHeader } from 'react-bootstrap';
import Breadcrumb from '../../breadcrumb';
import Feedback from '../../feedback';

const PageContainer = ({ title, children }) => (
    <div className='page-container'>
        <Breadcrumb />
        <Feedback />
        { title ? <PageHeader>{title}</PageHeader> : null}
        {children}
    </div>
);

export default PageContainer;