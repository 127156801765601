import { put, delay } from 'redux-saga/effects';

import * as Actions from './actions';

export function* handleTimedMessage(action) {

    if (action.displayTime && action.displayTime > 0) {
        yield delay(action.displayTime * 1000);

        yield put(Actions.removeMessage(action.id));
    }
}