import React from 'react';
import PropTypes from 'prop-types';

import Input from '../../atoms/input';

const RadioComponent = ({ value, ...props }) => {
    return (<Input {...props} type='radio' value={value} />);
};

RadioComponent.propTypes = {
    value: PropTypes.any.isRequired
};

const LabeledRadioComponent = ({ text, ...props }) => (<label className="radio-inline" title=""><RadioComponent {...props} /> {text}</label>);

LabeledRadioComponent.propTypes = {
    text: PropTypes.any.isRequired
};

const Radio = ({ value, options, optionKey, optionValue, ...props }) => (
    (options || []).map(option => (<LabeledRadioComponent {...props} key={option[ optionKey ]} text={option[ optionValue ]} value={option[ optionKey ]} checked={option[ optionKey ] === value} /> ))
);

Radio.propTypes = {
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    optionKey: PropTypes.string.isRequired,
    optionValue: PropTypes.string.isRequired
};

export default Radio;