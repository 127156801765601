import React from 'react';
import PropTypes from 'prop-types';
import { Panel, Row, Col } from 'react-bootstrap';
import google from '../../../images/google-play-badge.png';
import apple from '../../../images/Download_on_the_App_Store_Badge_NL_RGB_blk_100317.svg';

import {
    UserSecurityWorkingModel,
    Button
} from './_references';

const NotSecured = ({ security, secure }) => {

    const daysLeft = security[ UserSecurityWorkingModel.UserSecurityDaysLeft ];

    return (
        <div>
            <Panel>
                <Panel.Body>
                    <div className="panel-inline-title">Account beveiligen</div>

                    <div>Uw account is niet extra beveiligd. Dit is voor de applicatie wel verplicht. </div>
                    { daysLeft > 0 ?
                        <div>U heeft nog { daysLeft } dagen om uw account extra te beveiligen. Tot die tijd kunt u gebruik blijven maken van de applicatie. Na {daysLeft} dagen verplichten wij u om uw account extra te beveiligen.</div>
                        : daysLeft === 0 ?
                            <div>Vandaag is de laatste dag dat u gebruik kunt maken van de applicatie zonder extra beveiliging. Wij vragen u om vandaag de extra beveiliging in te stellen.</div>
                            :
                            <div>Wij vragen u om nu de extra beveiliging in te schakelen.</div>
                    }
                    <div>Nadat de extra beveiliging ingeschakeld is, zult u opnieuw moeten inloggen met de extra beveiliging.</div>
                    <div>U start de extra beveiliging instellen door  op de knop &apos;Beveiligen&apos; te klikken.</div>
                    <div>Gebruik hiervoor bijvoorbeeld de Google Authenticator:</div>
                    <div>
                        <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=nl"><img alt="Ontdek het op Google Play" width="150px" src={google}></img></a>
                        <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/nl/app/google-authenticator/id388497605"><img alt="Download in de App Store" src={apple}></img></a>
                    </div>

                </Panel.Body>
            </Panel>
            <Row>
                <Col xs={12} className="text-right">
                    <Button bsStyle="primary" onClick={secure}> Beveiligen</Button>
                </Col>
            </Row>
        </div>
    );
};

NotSecured.propTypes = {
    security: PropTypes.object.isRequired,
    secure: PropTypes.func.isRequired
};

export default NotSecured;