export const NAME = 'loading';

export const STATE_KEY = 'loading';

export const Fields = {
    IS_LOADING: 'isLoading',
    COUNT: 'count',
};

/**
 * The loading classname can be used in Selenium tests to determine whether a page is done loading content.
 */
export const LOADING_CLASSNAME = 'loading';