import * as Helpers from './helpers';

import * as ApiRoutes from './constants.routes';

export const getTemplate = (id) => {
    return Helpers.get(`${ApiRoutes.Template}/${id}`, {
        responseType: 'blob',
        suppressErrors : false
    });
};

export const getTemplates = () => {
    return Helpers.get(ApiRoutes.Template);
};