import React from 'react';
import PropTypes from 'prop-types';

import {
    Text,
    FieldSetup,
    VerwerkerDetailWorkingModel,
    DateTime
} from './_references';

const VerwerkersregisterEditor = ({ bind, onChange }) => (
    <div>
        <Text
            {...bind(VerwerkerDetailWorkingModel.NaamOrganisatie)}
            {...new FieldSetup()
                .for(VerwerkerDetailWorkingModel.NaamOrganisatie)
                .withFriendlyName('Naam organisatie')
                .setLabel()
                .setPlaceholder()
                .setAutoFocus()
                .isRequired()
                .maxLength(255)
                .onChange(onChange)
            }
        />

        <Text
            {...bind(VerwerkerDetailWorkingModel.ContactPersoon)}
            {...new FieldSetup()
                .for(VerwerkerDetailWorkingModel.ContactPersoon)
                .withFriendlyName('Contactpersoon')
                .setLabel()
                .setPlaceholder()
                .isRequired()
                .maxLength(512)
                .onChange(onChange)
            }
        />

        <Text
            {...bind(VerwerkerDetailWorkingModel.EmailAddress)}
            {...new FieldSetup()
                .for(VerwerkerDetailWorkingModel.EmailAddress)
                .withFriendlyName('E-mailadres')
                .setLabel()
                .setPlaceholder()
                .isRequired()
                .isEmail()
                .maxLength(255)
                .onChange(onChange)
            }
        />

        <Text
            {...bind(VerwerkerDetailWorkingModel.Doel)}
            {...new FieldSetup()
                .for(VerwerkerDetailWorkingModel.Doel)
                .withFriendlyName('Doel')
                .setLabel('Doel')
                .setPlaceholder('Doel van de gegevensverwerker')
                .isRequired()
                .onChange(onChange)
            }
        />

        <Text
            {...bind(VerwerkerDetailWorkingModel.Persoonsgegevens)}
            {...new FieldSetup()
                .for(VerwerkerDetailWorkingModel.Persoonsgegevens)
                .withFriendlyName('Persoonsgegevens')
                .setLabel()
                .setPlaceholder('Welke persoonsgegevens worden verwerkt?')
                .isRequired()
                .onChange(onChange)
            }
        />

        <DateTime
            {...bind(VerwerkerDetailWorkingModel.DatumOvereenkomst)}
            {...new FieldSetup()
                .for(VerwerkerDetailWorkingModel.DatumOvereenkomst)
                .setLabel('Datum verwerkingsovereenkomst')
                .withFriendlyName('Per welke datum heeft u een verwerkingsovereenkomst')
                .onChange(onChange)
                .isRequired()
                .isNotInTheFuture()
            }
            dateOnly
        />

        <Text
            {...bind(VerwerkerDetailWorkingModel.DocumentLink)}
            {...new FieldSetup()
                .for(VerwerkerDetailWorkingModel.DocumentLink)
                .withFriendlyName('Link naar de overeenkomst')
                .setLabel()
                .setPlaceholder('Plaats hier de link in naar de overeenkomst')
                .isUrl()
                .maxLength(512)
                .onChange(onChange)
            }
        />

        <Text
            {...bind(VerwerkerDetailWorkingModel.DocumentOmschrijving)}
            {...new FieldSetup()
                .for(VerwerkerDetailWorkingModel.DocumentOmschrijving)
                .withFriendlyName('Omschrijving van de overeenkomst')
                .setLabel()
                .setPlaceholder('Zet hier de overeenkomst van de overeenkomst in')
                .maxLength(512)
                .onChange(onChange)
            }
        />
    </div>
);

VerwerkersregisterEditor.propTypes = {
    bind: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
};

export default VerwerkersregisterEditor;