import React from 'react';
import PropTypes from 'prop-types';

import FaIcon from 'react-fontawesome';
import { Button } from 'react-bootstrap';

class Feedback extends React.Component {

    render () {

        const { location, messages, removeMessage } = this.props;

        // no location or no messages? then show them all
        const messagesToShow = (!messages || !location) ? messages : messages.filter((message) => {
            return message.showOnRoute == null || ((typeof message.showOnRoute === 'function') ? message.showOnRoute(location) : message.showOnRoute === location.pathname);
        });

        return (!messagesToShow || messagesToShow.length === 0 ? null :
            <div className='feedback-box'>
                {messagesToShow.map((message, index) => {

                    const translate = message.translate || (key => key);

                    return (

                        <div id={`message_${index}`} key={message.id} className={`feedback-message ${message.className}`}>
                            <div className='feedback-text' id={`feedbackMessage_${index}`}>{translate(message.message)}
                                <Button className='feedback-button' id={`feedbackButton_${index}`} onClick={removeMessage(message.id)}>
                                    <FaIcon name="close"/>
                                </Button>
                            </div>
                        </div>

                    );
                })}
            </div>
        );
    }
}

Feedback.propTypes = {
    location: PropTypes.object,
    messages: PropTypes.arrayOf(PropTypes.object),
    removeMessage: PropTypes.func.isRequired
};

export default Feedback;