import * as Helpers from './helpers';
import * as Routes from './constants.routes';

export const getMijnAccount = () => {
    return Helpers.get(`${Routes.MijnAccount}`);
};

/**
 * Creates a new Incident
 *
 * @param {*} model
 */
export const updateMijnAccount = (model) => {
    return Helpers.put(`${Routes.MijnAccount}`, model);
};