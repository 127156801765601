import {
    binder,
    Constants,
    onChange,
    Actions
} from './_references';

export const MapStateToProps = (state) => ({
    bind: (field) => binder(state, [ Constants.STATE_KEY, Constants.FIELDS.ADD ])(field),
});

export const MapDispatchToProps = (dispatch) => ({
    onChange : onChange([ Constants.STATE_KEY, Constants.FIELDS.ADD ], dispatch),
    addVerwerking: (model) => dispatch(Actions.addVerwerking(model)),
    clear: () => dispatch(Actions.clear()),
});